import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    helperText: {
        padding: 20,
        paddingTop: 0
    }
}));

const IntegrationIframeSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Obrigatório"),
    width: Yup.number().required("Obrigatório"),
    url: Yup.string().required("Obrigatório")
});

const IntegrationIframeModal = ({ open, onClose, iframeId }) => {
    const classes = useStyles();

    const initialState = {
        name: "",
        url: "",
        width: 320
    };

    const [integrationIframe, setIntegrationIframe] = useState(initialState);

    useEffect(() => {
        const fetchIntegrationIframe = async () => {
            if (!iframeId) return;
            try {
                const { data } = await api.get(`/integration/iframes/${iframeId}`);
                setIntegrationIframe(prevState => {
                    return { ...prevState, ...data };
                });
                // This is to load config variables (JSON) to current iframe form data
                if (data.config) {
                    setIntegrationIframe(prevState => {
                        return { ...prevState, ...data.config };
                    });
                }

            } catch (err) {
                toastError(err);
            }
        };

        fetchIntegrationIframe();
    }, [iframeId, open]);

    const handleClose = () => {
        onClose();
        setIntegrationIframe(initialState);
    };

    const handleSaveIntegrationIframe = async values => {
        const integrationIframeData = values;
        
        try {
            if (iframeId) {
                await api.put(`/integration/iframes/${iframeId}`, integrationIframeData);
            } else {
                await api.post("/integration/iframes", integrationIframeData);
            }
            toast.success(i18n.t("integrationIframeModal.success"));
            handleClose();
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {iframeId
                        ? `${i18n.t("integrationIframeModal.title.edit")}`
                        : `${i18n.t("integrationIframeModal.title.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={integrationIframe}
                    enableReinitialize={true}
                    validationSchema={IntegrationIframeSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveIntegrationIframe(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <p className={classes.helperText}>
                                Você pode utilizar as seguintes variáveis na URL: <strong>contactId</strong>, <strong>ticketId</strong>, <strong>ticketUserId</strong> e <strong>userId</strong> que são respectivamente o 
                                 <strong> ID do contato</strong>, <strong>ID do atendimento</strong>, <strong>ID do usuário associado ao atendimento</strong> e <strong>ID do usuário logado</strong>. Utilize dessa forma: {"{contactId}"}, {"{ticketId}"}, {"{ticketUserId}"} ou {"{userId}"}
                            </p>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("integrationIframeModal.form.name")}
                                        autoFocus
                                        name="name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <div>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("integrationIframeModal.form.url")}
                                        name="url"
                                        error={touched.url && Boolean(errors.url)}
                                        helperText={touched.url && errors.url}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <div>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("integrationIframeModal.form.width")}
                                        name="width"
                                        error={touched.width && Boolean(errors.width)}
                                        helperText={touched.width && errors.width}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("integrationIframeModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {iframeId
                                        ? `${i18n.t("integrationIframeModal.buttons.okEdit")}`
                                        : `${i18n.t("integrationIframeModal.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default IntegrationIframeModal;
