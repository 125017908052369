import React from 'react';
import { FormControlLabel, Checkbox } from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { TbCopy } from "react-icons/tb"
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(theme => ({
  inputTime: {
    background: 'rgba(255,255,255, 0.1)',
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    border: theme.palette.mode === 'dark' ? '1px solid #c6c6c6' : '1px solid #000'
  }
}));


const TimeInOutLogin = ({ isPermission, isRestrictedTime, setIsRestrictedTime, times, setTimes }) => {
  const classes = useStyles();

  const handleTimeChange = (day, type, value) => {
    setTimes(prevTimes => ({
      ...prevTimes,
      [day]: {
        ...prevTimes[day],
        [type]: value,
      },
    })
    );
  };

  const closeDay = (e, action, day) => {
    setTimes((prevTimes) => ({
      ...prevTimes,
      [day]: { open: times[day].open, close: times[day].close, isClosed: action === 'open' ? false : true },
    }));
    e.preventDefault();
  };

  const dayNames = {
    'dom': 'Domingo',
    'seg': 'Segunda',
    'ter': 'Terça',
    'qua': 'Quarta',
    'qui': 'Quinta',
    'sex': 'Sexta',
    'sab': 'Sábado'
  };

  const cloneToAll = (e, day) => {
    e.preventDefault();
    const { open, close } = times[day];
    console.log(day)

    for (const otherDay in times) {
      if (otherDay !== day) {
        setTimes((prevTimes) => ({
          ...prevTimes,
          [otherDay]: {
            open,
            close,
            isClosed: false,
          },
        }));
      }
    }
  };

  return (
    <>

      <FormControlLabel
        control={<Checkbox checked={isRestrictedTime} onChange={() => setIsRestrictedTime(!isRestrictedTime)} />}
        label={isPermission ? 'Restrição de horários login dos grupos' : 'Restrição de horários login do usuário'} />
      {isRestrictedTime &&
        <div style={{ padding: 15, borderRadius: 10, border: '2px solid #eee' }}>
          {Object.keys(times)
            .sort((a, b) => Object.keys(dayNames).indexOf(a) - Object.keys(dayNames).indexOf(b))
            .map(day => (
              <div className="line-hour" key={day}
                style={{ display: 'flex', flexDirection: 'row', gap: 6, maxWidth: '80%', margin: '10px auto', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <h6 style={{ marginRight: 10, minWidth: 100, width: 100 }}>
                  {dayNames[day] || day}
                </h6>
                {times[day].isClosed ? (
                  <label style={{ marginRight: 15, width: 300, textAlign: 'center' }}>
                    Sem atendimento
                  </label>
                ) : (
                  <>
                    <label style={{ marginRight: 15 }}>
                      Entrada:
                      <input
                        type="time"
                        style={{ borderRadius: 5, border: '1px solid #ddd', padding: 5, marginLeft: 5 }}
                        className={classes.inputTime}
                        value={times[day].open}
                        onChange={e => handleTimeChange(day, 'open', e.target.value)}
                      />
                    </label>
                    <label>
                      Saída:
                      <input
                        type="time"
                        style={{ borderRadius: 5, border: '1px solid #ddd', padding: 5, marginLeft: 5 }}
                        className={classes.inputTime}
                        value={times[day].close}
                        onChange={e => handleTimeChange(day, 'close', e.target.value)}
                      />
                    </label>
                  </>
                )}
                {times[day].isClosed ? (
                  <button onClick={(e) => closeDay(e, 'open', day)}
                    style={{
                      background: '#000', color: '#fff', fontWeight: 'bold',
                      alignItems: 'center', display: 'flex', height: 30, borderRadius: 20
                    }}>Abrir horários</button>
                ) : (
                  <button onClick={(e) => closeDay(e, 'close', day)}
                    style={{
                      background: '#ddd', color: '#000', fontWeight: 'bold',
                      alignItems: 'center', display: 'flex', height: 30, width: 30, borderRadius: 20
                    }}
                  ><FaTimes /></button>
                )}
                {!times[day].isClosed && (
                  <button className="btn-copy" onClick={(e) => cloneToAll(e, day)}>
                    <TbCopy /> Copiar para Todos
                  </button>
                )}
              </div>
            ))}
        </div>
      }
    </>
  );
};

export default TimeInOutLogin;