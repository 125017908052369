import WhatsApp from "@mui/icons-material/WhatsApp";
import FileCopy from "@mui/icons-material/FileCopy";
import React, { useRef, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router-dom";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    whatsappButton: {
        marginRight: theme.spacing(1)
    }
}));

const ContactImportModal = ({ open, handleClose, handleWhatsappImport }) => {
    const classes = useStyles();
    const history = useHistory();

    const historyRef = useRef(history);

    const handleFileImport = () => {
        historyRef.current.push("/contacts/import");
    }

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle>
                    <p>{i18n.t("contactModal.import.type_import")}</p>
                </DialogTitle>
                <DialogContent>
                    <Button variant="contained" color="primary" onClick={handleWhatsappImport} className={classes.whatsappButton}>
                        <WhatsApp /> {i18n.t("contactModal.import.import_whats")}
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleFileImport}>
                        <FileCopy /> {i18n.t("contactModal.import.import_file")}
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ContactImportModal;