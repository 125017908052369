import React, { useEffect, useRef, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import { Box, Grid, Modal } from "@mui/material";
import { Button } from "@mui/material";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	notificationModal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: 700,
		backgroundColor: '#fff',
		boxShadow: '0 0 1em rgba(0, 0, 0, 0.2)',
		borderRadius: 10,
		padding: 0,
		maxHeight: '100vh',
		overflowY: "scroll",
		[theme.breakpoints.down('md')]: {
			width: '100%',
			textAlign: 'center',
			height: '100%',
			minWidth: 'auto'
		},
		'& img': {
			height:'700px',
			[theme.breakpoints.down('md')]: {
				height:'auto',
				width:'90%'
			},
			['@media (min-height: 450px) and (max-height: 800px)']: {
				height:'60vh',
			}
		}
	},
	notificationModalHeader: {
		borderBottom: '1px solid #e0e0e0',
		padding: 20,
		fontSize: '2em',
		fontWeight: "bold"
	},
	notificationModalBody: {
		padding: 10,
		minHeight: 400,
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	notificationModalFooter: {
		borderTop: '1px solid #e0e0e0',
		padding: 10
	},
	notificationModalClose: {
		position: 'absolute',
		right: 0,
		top: 20,
		color: '#000',
		"& .MuiSvgIcon-root": {
			fontSize: "2rem"
		}
	},
	previousButton: {
		
	},
	nextButton: {
		
	}
}));

const NotificationModal = ({open, onClose, notifications}) => {
    const classes = useStyles();
	const [notification, setNotification] = useState(notifications ? notifications[0] : null);
	const [notificationsLocal, setNotificationsLocal] = useState(notifications);

	useEffect(() => {
		setNotificationsLocal(notifications);
	}, [notifications]);

	const currentIndex = useRef(0);

	const handleClose = async() => {
		try {
            await api.post(`/notifications/${notification.notification.id}/dismiss`, {
                notificationUserId: notification.id,
            });
            if (onClose) {
                onClose();
            }
        } catch (err) {
            toastError(err);
            if (onClose) {
                onClose();
            }
        }
	}

	useEffect(() => {
		(async() => {
			try {
				await api.post(`/notifications/${notification.notification.id}/view`, {
					notificationUserId: notification.id
				});
			} catch (err) {
				toastError(err);
			}
		})();
	}, [notification]);

	const handleNext = () => {
		if (notificationsLocal.length > (currentIndex.current + 1)) {
			currentIndex.current = currentIndex.current + 1;
			setNotification(notificationsLocal[currentIndex.current]);
		}
	}

	const handlePrevious = () => {
		if (currentIndex.current > 0) {
			currentIndex.current = currentIndex.current - 1;
			setNotification(notificationsLocal[currentIndex.current]);
		}
	}

	const canPrevious = () => {
        return currentIndex.current > 0;
    }

    const canNext = () => {
        return notificationsLocal.length > (currentIndex.current + 1);
    }

    return (
        <div className={classes.root}>
            <Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box className={classes.notificationModal}>
					<div className={classes.notificationModalHeader}>
						Importante!
						<Button className={classes.notificationModalClose} onClick={handleClose}><Close /></Button>
					</div>
					<div className={classes.notificationModalBody}>
						<div dangerouslySetInnerHTML={{__html: notification && notification.notification ? notification.notification.message : ''}}></div>
					</div>
					<Grid container className={classes.notificationModalFooter}>
						<Grid item xs={6} md={4} className={classes.notificationModalFooterNavButtons}>
							{canPrevious() && <Button className={classes.previousButton} onClick={() => handlePrevious()}>
								<ArrowBack /> Anterior
							</Button>}
							{canNext() && <Button className={classes.nextButton} onClick={() => handleNext()}>
								<ArrowForward /> Próximo
							</Button>}
						</Grid>
						<Grid item xs={0} md={4} className={classes.notificationModalFooterNav}>

						</Grid>
						<Grid item xs={6} md={4} align="right">
							<Button className={classes.notificationModalFooterCloseButton} onClick={handleClose}><Close /> Sair</Button>
						</Grid>
					</Grid>
				</Box>
            </Modal>
        </div>
    )
}

export default NotificationModal;