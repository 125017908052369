import React, { useEffect, useRef, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';

import { IconButton } from "@mui/material";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Close, ArrowBack, ArrowForward } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
	root: {
		position: "fixed",
        width:'100%',
        height:'30px',
		top: 0,
        overflow: "hidden",
        padding: "5px",
        zIndex: 9999,
        color: "white",
	},
    content: {
        float: "left",
        display: "block"
    },
    buttons: {
        float: "right",
        color: "#FFF",
        '& .MuiIconButton-sizeSmall': {
            padding: "0px",
            fontSize: '12px',
            '& .MuiSvgIcon-root': {
                width: '16px',
                height: '16px'
            }
        }
    }
}));

const NotificationBar = ({open, onClose, notifications}) => {
    const classes = useStyles();
    const [notification, setNotification] = useState(notifications ? notifications[0] : null);
	const currentIndex = useRef(0);

    useEffect(() => {
		(async() => {
			try {
				await api.post(`/notifications/${notification.notification.id}/view`, {
					notificationUserId: notification.id
				});
			} catch (err) {
				toastError(err);
			}
		})();
	}, [notification]);

	const handleClose = async(notification) => {
        try {
            await api.post(`/notifications/${notification.notification.id}/dismiss`, {
                notificationUserId: notification.id,
            });
            if (onClose) {
                onClose();
            }
        } catch (err) {
            toastError(err);
            if (onClose) {
                onClose();
            }
        }
	}

    const handleNext = () => {
		if (notifications.length > (currentIndex.current + 1)) {
            currentIndex.current = currentIndex.current + 1;
			setNotification(notifications[currentIndex.current]);
		}
	}

	const handlePrevious = () => {
		if (currentIndex > 0) {
            currentIndex.current = currentIndex.current + 1;
			setNotification(notifications[currentIndex.current]);
		}
	}

    const canPrevious = () => {
        return currentIndex.current > 0;
    }

    const canNext = () => {
        return notifications.length > (currentIndex.current + 1);
    }

    return (
        <div className={classes.root} style={{backgroundColor: notification && notification.notification.alertType === 'top_danger' ? '#A00' : 'green'}}>
            <span className={classes.content} dangerouslySetInnerHTML={{__html: notification && notification.notification ? notification.notification.message : ''}}></span>
            <div className={classes.buttons}>
                {canPrevious() && <IconButton size="small" onClick={() => handlePrevious()}>
                    <ArrowBack />
                </IconButton>}
                {canNext() && <IconButton size="small" onClick={() => handleNext()}>
                    <ArrowForward />
                </IconButton>}
                <IconButton size="small" onClick={() => {handleClose(notification)}}>
                    <Close />
                </IconButton>
            </div>
        </div>
    )
}

export default NotificationBar;