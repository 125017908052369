import api from "../../services/api";
import { useState, useEffect, useContext } from "react";
import toastError from "../../errors/toastError";
import getSocket from "../../helpers/socket";
import { AuthContext } from "../../context/Auth/AuthContext";

const socket = getSocket();

const useSettings = () => {
    const [settings, setSettings] = useState({});
    const [info, setInfo] = useState({});
    const [hashKey, setHashKey] = useState(null);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const settingsEvent = data => {
            if (data.action === "update") {
                setSettings(prevState => {
                    const aux = [...prevState];
                    const settingIndex = aux.findIndex(s => s.key === data.setting.key);
                    aux[settingIndex].value = data.setting.value;
                    return aux;
                });
                setHashKey(prevState => {
                    return prevState ? prevState + 1 : 1;
                });
            }
        }

        socket.on("settings", settingsEvent);

        return () => {
            socket.off("settings", settingsEvent);
        };
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchSettings = async () => {
                try {
                    if (user.id) {
                        const { data } = await api.get("/settings");
                        setSettings(data.settings);
                        setInfo(data.info);
                        setHashKey(prevState => {
                            return prevState ? prevState + 1 : 1;
                        });
                    }
                } catch (err) {
                    toastError(err);
                }
            };
            fetchSettings();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [user]);

    const getSettingValue = key => {
        if (!settings || !settings.length) return null;
        const { value } = settings.find(s => s.key === key);
        return value;
    };

    const isActive = setting => {
        return getSettingValue(setting) === "enabled";
    }

    return { isActive, getSettingValue, settings, info, hashKey }
}

export default useSettings;