import { makeStyles } from "@mui/styles";
import React from "react";
import contrastColor from "../../helpers/contrastColor";

const useStyles = makeStyles(theme => ({
	tag: {
        padding: "1px 5px",
        borderRadius: "3px",
        fontSize: "11px",
        fontWeight: "bold",
        marginRight: "5px",
        whiteSpace: "nowrap",
        position: 'relative',
    },
    triangle: {
        position: "absolute",
        left: "-8px", // Adjust the distance from the left side
        top: "0",
        width: "0",
        height: "0",
        borderStyle: "solid",
        borderWidth: "9px 10px 10px 0"
      },
}));

const ContactTag = ({ tag }) => {
    const classes = useStyles();

    return (
        <div className={classes.tag} style={{backgroundColor: tag.color,  color: contrastColor(tag.color)}}>
            <div className={classes.triangle} style={{borderColor: `transparent ${tag.color} transparent transparent`}}></div>
            {tag.name}
        </div>
    )
}

export default ContactTag;