import React, { useState, useContext, useEffect } from "react";

import * as Yup from "yup";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { toast } from "react-toastify";
import { FaPlay } from "react-icons/fa";


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap"
	},
	answerLoading: {
		minHeight: 200,
		maxHeight: 300,
		width: 300,
		margin: 20,
		borderRadius: 10,
		background: theme.palette.blackWhiteBackground,
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'center'
	},
	answerBlock: {
		fontSize: 16,
		lineHeight: '27px',
		padding: 20,
		background: theme.palette.blackWhiteBackground,
		borderRadius: 10,
		overflow: 'auto'
	},

	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},
	circleLoading: {
		color: green[500],
		opacity: "70%"
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	messageSaved: {
		boxShadow: "0 1px 1px #b3b3b3",
		background: "#f5f5f5",
		padding: '5px 15px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	cont: {
		fontStyle: "italic",
	},
	timestamp: {
		fontStyle: 'normal',
		fontSize: 11,
		color: '#666666'
	}
}));



const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
	greetingMessage: Yup.string(),
});

const PromptTextToSpeechModal = ({ open, onClose, message, icon, handleAIMessage }) => {
	const classes = useStyles();
	const [answer, setAnswer] = useState('');

	//const { settings } = useContext(UserSettingsContext);
	//const voiceSound = settings && settings.voiceSound ? settings.voiceSound:'nova';
	const { settings, getSettingValue } = useContext(SettingsContext);
	const voiceSound = getSettingValue('voiceSound');
	const apiKey = getSettingValue('keyOpenAi');
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		onClose();
		setAnswer('');
	};

	async function getAudio() {
		//console.log(voiceSound, 'voiceSound')
		message.body && console.log('works predelay');
		try {
			let data = {
				text: message.body,
				voice: voiceSound,
				apikey: await apiKey
			}
			setLoading(true);
			const response = await api.post("/ai/text/speech", data);
			if (response.data) {
			//	console.log(response.data)
				setAnswer(`${process.env.REACT_APP_BACKEND_URL}/public/tts_audio${response.data.url}`);
				
			} else {

				console.error("Invalid response data:", response.data);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toast.error(i18n.t("backendErrors.ERR_OPENAI"));
		}
	}



	useEffect(() => {
		getAudio();
	}, [message.body])
	//const originalUrl = message.rawMediaUrl;
	//const updatedUrl = originalUrl.replace(".ogg", ".mp3");


	return (
		<div className={classes.root}>
			{icon && !answer && (
				<div style={{borderRadius: 100, width: 32, height: 32, background:'#2ecc71', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<FaPlay onClick={() => getAudio()} size={16} />
				</div>
			)}

			{icon && 
			loading && !answer ? (
					<div>Carregando audio...</div>
				) : answer && icon &&
				<div>
					<audio controls autoPlay>
						<source src={`${answer}`} type="audio/mp3"></source>
					</audio>
				</div>
			}

			<Dialog open={open} onClose={handleClose} scroll="paper">
				{open && 
				loading && !answer ? (
					<div className={classes.answerBlock}>Carregando audio...</div>
				) : (
					<div className={classes.answerBlock}>

						<audio controls autoPlay>
							<source src={`${answer}`} type="audio/mp3"></source>
						</audio>

					</div>
				)}
			</Dialog>
		</div>
	);
};

export default PromptTextToSpeechModal