import openSocket from "socket.io-client";

var connection;
const getSocket = (newConnection) => {
    if (newConnection) {
        return openSocket(process.env.REACT_APP_BACKEND_URL, {
            upgrade: false, 
            transports: ['websocket'], 
            query: {token: window.localStorage.getItem('public-token')}
        });
    }
    if (!connection) {
        connection = openSocket(process.env.REACT_APP_BACKEND_URL, { 
            upgrade: false,
            transports: ['websocket'],
            auth: {token: window.localStorage.getItem('public-token')}
        });
    } else {
        if (connection.disconnected) {
            connection.connect();
        }
    }
    return connection;
}

export default getSocket;