import { Avatar, Badge } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import moment from "moment";
import UserStatusIcon from "../User/statusIcon";

const useStyles = makeStyles(theme => ({
    item: {
        display: "flex",
        flexFlow: "row",
        borderRadius: "5px",
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        padding: 10,
        cursor: "pointer",
        color: theme.palette.dark.main,
        '&:hover': {
            backgroundColor: "#F3F3F3",
            color: "#333"
        }
    },
    avatar: {
        width: "52px",
        textAlign: "center",
        marginRight: 10,
        '& img': {
            borderRadius: '50%',
            width: "52px",
            height: "52px",
        },
        position: "relative"
    },
    content: {
        flex: 1,
        maxWidth: 350
    },
    name: {
        color: "inherit"
    },
    lastMessage: {
        display: "block",
        color: "#999",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    date: {
        display: "block",
        float: "right",
        fontSize: "0.8em"
    },
    statusIcon: {
        position: "absolute",
    }
}));

const RoomListItem = ({ room, handleRoomClick, unreadMessages, to }) => {
    const classes = useStyles();
    const [recipient, setRecipient] = useState(null);
    const [roomMe, setRoomMe] = useState({});

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (to) {
            setRecipient(to);
        } else if (room.userId == user.id) {
            setRecipient(room.participant);
        } else {
            setRecipient(room.user);
        }
        if (room && room.participants) {
            const me = room.participants.find(p => p.userId === user.id);
            if (me) setRoomMe(me);
        }

    }, [room, user, to]);

    return (

        <div className={`${classes.item} item-internal-chat`} onClick={() => { handleRoomClick(room ? room : to) }}>
            <div className={`${classes.avatar} avatar-chat`}>
                {/*recipient && <img src={`https://ui-avatars.com/api/?name=${recipient.name}`} />*/}
                {recipient && recipient.userSettings && recipient.userSettings[0] && recipient.userSettings[0].avatar  ?
                    <><Avatar src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${recipient.userSettings[0].avatar}`} style={{
                        boxShadow: recipient && recipient.online ? '0px 0px 5px 0px rgba(5,252,13,1)' : '0px 0px 5px 0px rgba(250,5,5,1)',
                        outline: recipient && recipient.online ? '3px solid #2ecc71' : '3px solid #ff7979'
                    }} /></>
                    : <Avatar src={`https://ui-avatars.com/api//?background=0D8ABC&color=fff&name=${recipient && recipient.name}`} style={{
                        boxShadow: recipient && recipient.online ? '0px 0px 5px 0px rgba(5,252,13,1)' : '0px 0px 5px 0px rgba(250,5,5,1)',
                        outline: recipient && recipient.online ? '3px solid #2ecc71' : '3px solid #ff7979',
                    }} />
                }
                {/*  <UserStatusIcon user={room ? room.userId == user.id ? room.participant : room.user : to} className={`${classes.statusIcon} item-status-icon`}/>*/}
            </div>
            <div className={`${classes.content} content-chat`}>
                <span className={`${classes.name} content-chat-name`}>{recipient && recipient.name}</span>
                {room && <span className={`${classes.lastMessage} content-chat-lastMessage`}>{room.lastMessage}</span>}
                {room && <span className={`${classes.date} content-chat-date`}>{moment(room.updatedAt).fromNow()}</span>}
            </div>
            <Badge badgeContent={unreadMessages} color="secondary"></Badge>
        </div>
    );
}

export default RoomListItem;