import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const colors = {
    "nivo": [
        "#e8c1a0",
        "#f47560",
        "#f1e15b",
        "#e8a838",
        "#61cdbb",
        "#97e3d5"
    ],
    "category10": [
        "#1f77b4",
        "#ff7f0e",
        "#2ca02c",
        "#d62728",
        "#9467bd",
        "#8c564b",
        "#e377c2",
        "#7f7f7f",
        "#bcbd22",
        "#17becf"
    ],
    "accent": [
        "#7fc97f",
        "#beaed4",
        "#fdc086",
        "#ffff99",
        "#386cb0",
        "#f0027f",
        "#bf5b17",
        "#666666"
    ],
    "dark2": [
        "#1b9e77",
        "#d95f02",
        "#7570b3",
        "#e7298a",
        "#66a61e",
        "#e6a400",
        "#a6761d",
        "#666666"
    ],
    "paired": [
        "#a6cee3",
        "#1f78b4"
    ],
    "spectral": [
        "#a6cee3",
        "#1f78b4",
        "#b2df8a",
        "#33a02c",
        "#fb9a99",
        "#e31a1c",
        "#fdbf6f",
        "#ff7f00",
        "#cab2d6",
        "#6a3d9a"
    ],
    "greys": [
        "#ffffff",
        "#f0f0f0",
        "#d9d9d9",
        "#bdbdbd",
        "#969696",
        "#737373",
        "#525252",
        "#252525",
        "#000000"
    ],
    "oranges": [
        "#fff5eb",
        "#feedce",
        "#fdd0a2",
        "#fdae6b",
        "#fd8d3c",
        "#f16515",
        "#d94701",
        "#a63603",
        "#7f2704"
    ]
};

const ColorChartSelect = ({ selectedTheme, handleSelectChange }) => {
    return (
        <Select
            value={selectedTheme}
            onChange={handleSelectChange}
            renderValue={(selected) => (
                <div>
                    {selected && colors[selected] && (
                        <div>
                            {colors[selected].map((color, colorIndex) => (
                                <span
                                    key={colorIndex}
                                    style={{
                                        display: 'inline-block',
                                        backgroundColor: color,
                                        width: '18px',
                                        height: '18px',
                                        marginRight: '5px'
                                    }}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
        >
            {Object.keys(colors).map((key, index) => (
                <MenuItem key={index} value={key}>
                    {colors[key].map((color, colorIndex) => (
                        <span
                            key={colorIndex}
                            style={{
                                display: 'inline-block',
                                backgroundColor: color,
                                width: '18px',
                                height: '18px',
                                marginRight: '5px'
                            }}
                        />
                    ))}
                </MenuItem>
            ))}
        </Select>
    );
};

export default ColorChartSelect;