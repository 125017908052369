import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";

import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import { Switch } from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../ConfirmationModal";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import Swal from "sweetalert2";
import axios from "axios";
import IOSSwitch from "../IOSSwitch";
import AsyncSelect from "../AsyncSelect";


const useStyles = makeStyles(theme => ({
    mainWrapper: {
        background: theme.palette.tabHeaderBackground,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        [theme.breakpoints.down('md')]: {
            position: "fixed",
            bottom: 0,
            width: "100%"
        }
    },
    newMessageBox: {
        background: theme.palette.tabHeaderBackground,
        width: "100%",
        display: "flex",
        padding: "7px",
        alignItems: "center",
    },
    forwardLabel: {
        color: '#4d4d4d'
    },
    messageInputWrapper: {
        padding: 6,
        marginRight: 7,
        background: theme.palette.total,
        display: "flex",
        borderRadius: 20,
        flex: 1,
        position: "relative"
    },

    messageInput: {
        paddingLeft: 10,
        flex: 1,
        border: "none",
    },

    sendMessageIcons: {
        color: theme.palette.messageIcons,
    },

    uploadInput: {
        display: "none",
    },

    viewMediaInputWrapper: {
        display: "flex",
        padding: "10px 13px",
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.tabHeaderBackground,
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },

    emojiBox: {
        position: "absolute",
        bottom: 63,
        width: 40,
        borderTop: "1px solid #e8e8e8",
    },

    circleLoading: {
        color: green[500],
        opacity: "70%",
        position: "absolute",
        top: "20%",
        left: "50%",
        marginLeft: -12,
    },
    circleLoadingSmall: {
        color: green[500],
        opacity: "70%",
        width: 25,
        height: 25,
        marginLeft: 0,
    },

    audioLoading: {
        color: green[500],
        opacity: "70%",
    },

    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
    },

    cancelAudioIcon: {
        color: "red",
    },

    sendAudioIcon: {
        color: "green",
    },

    replyginMsgWrapper: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 8,
        paddingLeft: 73,
        paddingRight: 7,
    },

    replyginMsgContainer: {
        flex: 1,
        marginRight: 5,
        overflowY: "hidden",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        borderRadius: "7.5px",
        display: "flex",
        position: "relative",
    },

    replyginMsgBody: {
        padding: 10,
        height: "auto",
        display: "block",
        whiteSpace: "pre-wrap",
        overflow: "auto",
        maxHeight: 100,
        ...theme.scrollbarStylesSoftBig
    },

    replyginContactMsgSideColor: {
        flex: "none",
        width: "4px",
        backgroundColor: "#35cd96",
    },

    replyginSelfMsgSideColor: {
        flex: "none",
        width: "4px",
        backgroundColor: "#6bcbef",
    },

    messageContactName: {
        display: "flex",
        color: "#6bcbef",
        fontWeight: 500,
    },
    messageShortcutWrapper: {
        margin: 0,
        position: "absolute",
        bottom: "50px",
        background: theme.palette.total,
        padding: "2px",
        border: "1px solid #CCC",
        left: 0,
        width: "100%",
        "& li": {
            listStyle: "none",
            "&:focus-within a, & a": {
                display: "block",
                padding: "8px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxHeight: "32px",
                textDecoration: "none",
                color: theme.palette.dark.main,
                "&:hover, &:active": {
                    background: theme.palette.primary.main,
                    color: "#FFF",
                    cursor: "pointer"
                }
            }
        }
    },
    formControl: {
        marginRight: 7,
        color: theme.palette.messageIcons
    }
}));

const MessageInputForward = ({ ticketStatus, droppedFiles, message, onClose, recipients }) => {
    const classes = useStyles();
    const { ticketId } = useParams();
    const [settings, setSettings] = useState([]);
    const { isActive } = useContext(SettingsContext);
    const enableTextForwardedMessages = isActive("enableTextForwardedMessages");

    const [medias, setMedias] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [caption, setCaption] = useState("");
    const [showEmoji, setShowEmoji] = useState(false);
    const [loading, setLoading] = useState(false);
    const [recording, setRecording] = useState(false);
    const [typeBar, setTypeBar] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const inputRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadMessagesConfirmationOpen, setLoadMessagesConfirmationOpen] = useState(false);
    const [uploadFromShortcut, setUploadFromShortcut] = useState(false);
    const [listConnections, setListConnections] = useState({})
    const [connectionIds, setConnectionIds] = useState(null);
    const [isSearched, setisSearched] = useState(false);
    const [blockEnter, setBlockEnter] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);

    const { setReplyingMessage, replyingMessage } = useContext(
        ReplyMessageContext
    );
    const { user } = useContext(AuthContext);

    const shortcutMessagesListRef = useRef();

    const [signMessage, setSignMessage] = useLocalStorage("signOption", true);

    const getSettingValue = key => {
        const { value } = settings && settings.length > 0 && settings.find(s => s.key === key);
        return value;
    };

    useEffect(() => {
        inputRef.current.focus();
    }, [replyingMessage]);

    useEffect(() => {
        const count = recipients.length;
        setInputMessage(`${count} destinatário${count !== 0 && count !== 1 ? 's' : ''}`);
    }, [recipients]);

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const { data } = await api.get("/settings");
                setSettings(data.settings);
            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();
    }, []);

    const handleChangeBooleanSetting = async (e) => {
        const selectedValue = e.target.checked ? "enabled" : "disabled";
        const settingKey = e.target.name;

        try {
            await api.put(`/settings/${settingKey}`, {
                value: selectedValue,
            });
            //	toast.success(i18n.t("settings.success"));
        } catch (err) {
            toastError(err);
        }
    }

    useEffect(() => {
        inputRef.current.focus();
        return () => {
            setInputMessage("");
            setShowEmoji(false);
            setMedias([]);
            setReplyingMessage(null);
        };
    }, [ticketId, setReplyingMessage]);

    useEffect(() => {
        if (droppedFiles && droppedFiles.length > 0) {
            const selectedMedias = Array.from(droppedFiles);
            setMedias(selectedMedias);
        }
    }, [droppedFiles]);

    const handleChangeInput = e => {
        setInputMessage(e.target.value);
    };

    const handleSuggestionClick = value => {
        if (value.mediaUrl) {
            (async () => {
                const res = await fetch(value.absoluteMediaUrl);
                const blob = await res.blob();
                const contentType = res.headers.get('content-type');
                setMedias([...medias, new File([blob], value.mediaUrl, { type: contentType })]);
                setCaption(value.content);
                setInputMessage("");
                setUploadFromShortcut(true);
            })();
        } else {
            setInputMessage(value.content);
        }
        setTypeBar(false);
        setTimeout(() => {
            setBlockEnter(false);
        }, 500);
    }

    const handleAddEmoji = e => {
        let emoji = e.native;
        setInputMessage(prevState => prevState + emoji);
    };

    const handleChangeMedias = e => {
        if (!e.target.files) {
            return;
        }

        const selectedMedias = Array.from(e.target.files);
        setMedias(selectedMedias);
    };

    const handleInputPaste = e => {
        if (e.clipboardData.files[0]) {
            setMedias([e.clipboardData.files[0]]);
        }
    };


    //Enviar Audio Função
    const handleUploadAudio = async () => {
        setLoading(true);
        try {
            const media_url = message.mediaUrl;

            //Pegar arquivo do localhost
            const fileName = media_url.split('/').pop();
            const response = await axios.get(media_url, { responseType: 'blob' });
            const file = new File([response.data], fileName);

            const formData = new FormData();
            const filename = `${new Date().getTime()}.mp3`;
            formData.append("medias", file);
            formData.append("body", filename);
            formData.append("fromMe", true);
            formData.append("to", recipients);
            let isShared = false;
            enableTextForwardedMessages ? isShared = true : isShared = false;
            formData.append("isShared", isShared);
            formData.append("isAudio", true);
            connectionIds && formData.append("connectionId", connectionIds);
            const res = await api.post(`/messages/forwards`, formData);
            if (res.status == 200) {
                await toast.success('Mensagem encaminhada com sucesso!');
                onClose();
            }
        } catch (err) {
            toastError(err);
        }

        setRecording(false);
        setLoading(false);
    };

    //Enviar Media Função
    const handleUploadMedia = async e => {
        setLoading(true);
        //e.preventDefault();

        const formData = new FormData();
        const media_url = message.mediaUrl;
        //console.log('media', media_url);
        //Pegar imagem do localhost
        const fileExtension = media_url.split('.').pop();
        const isImage = ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension.toLowerCase());
        const responseType = isImage ? 'blob' : 'arraybuffer';
        const response = await axios.get(media_url, { responseType });
        const fileType = isImage ? `image/${fileExtension}` : response.headers['content-type'];
        const file = new File([response.data], `filename.${fileExtension}`, { type: fileType });

        formData.append("medias", file);
        let isShared = false;
        enableTextForwardedMessages ? isShared = true : isShared = false;
        formData.append("isShared", isShared);
        formData.append("fromMe", true);
        formData.append("body", caption);
        formData.append("fromMe", true);
        connectionIds && formData.append("connectionId", connectionIds);
        formData.append("to", recipients);
        // });
        /*
        if (uploadFromShortcut) {
            formData.append("asAudio", true);
        }*/

        try {
            const res = await api.post(`/messages/forwards`, formData);
            if (res.status == 200) {
                await toast.success('Mensagem encaminhada com sucesso!');
                onClose();
            }
        } catch (err) {
            Swal.fire({
                title: "Error!",
                text: getErrorMessage(err),
                icon: 'error',
                confirmButtonText: "OK"
            });
        }

        setLoading(false);
        setUploadFromShortcut(false);
        setMedias([]);
        setCaption("");
    };


    //Enviar Media Função
    const handleUploadFile = async e => {
        setLoading(true);
        //e.preventDefault();

        const formData = new FormData();
        const media_url = message.mediaUrl;

        const fileName = media_url.split('/').pop();
        const fileExtension = fileName.split('.').pop().toLowerCase();
        const response = await axios.get(media_url, { responseType: 'blob' });
        const blob = response.data;

        let mimeType = blob.type || 'application/octet-stream';
        if (fileExtension === 'pdf') {
            mimeType = 'application/pdf';
        }
        const file = new File([blob], fileName, { type: mimeType });

        formData.append("medias", file);
        let isShared = false;
        enableTextForwardedMessages ? isShared = true : isShared = false;
        formData.append("isShared", isShared);
        formData.append("fromMe", true);
        formData.append("body", caption);
        connectionIds && formData.append("connectionId", connectionIds);
        formData.append("fromMe", true);
        formData.append("isFile", true);
        formData.append("to", recipients);

        try {
            const res = await api.post(`/messages/forwards`, formData);
            if (res.status == 200) {
                toast.success('Mensagem encaminhada com sucesso!');
                onClose();
            }
        } catch (err) {
            Swal.fire({
                title: "Error!",
                text: getErrorMessage(err),
                icon: 'error',
                confirmButtonText: "OK"
            });
        }

        setLoading(false);
        setUploadFromShortcut(false);
        setMedias([]);
        setCaption("");
    };

    //Enviar Mensagem
    const handleUploadMessage = async e => {
        const message_forward = {
            read: 1,
            fromMe: true,
            mediaUrl: "",
            to: recipients,
            body: message.body,
            isShared: enableTextForwardedMessages ? true : false,
            connectionId: connectionIds
        };
        try {
            const response = await api.post(`/messages/forwards`, message_forward);
            //console.log(response);
            if (response.status == 200) {
                toast.success('Mensagem encaminhada com sucesso!');
                onClose();
            }
        } catch (err) {
            Swal.fire({
                title: "Error!",
                text: getErrorMessage(err),
                icon: 'error',
                confirmButtonText: "OK"
            });
        }
    }



    const handleSendMessageForward = async (event) => {
        event.preventDefault();

        const cantDisableSignMessage = !isActive('showDisableSignOption') && user.profile !== "admin";

        if (message.mediaType === 'image' || message.mediaType === 'video') {
            handleUploadMedia();
        } else if (message.mediaType === 'application' || message.mediaType === 'document') {
            handleUploadFile();
        } else if (message.mediaType === 'audio') {
            handleUploadAudio();
        } else {
            handleUploadMessage();
        }
    }

    const getErrorMessage = (err) => {
        const errorMsg = err.response?.data?.error;
        if (i18n.exists(`backendErrors.${errorMsg}`)) {
            return i18n.t(`backendErrors.${errorMsg}`);
        } else {
            return errorMsg;
        }
    }





    const handleLoadMessages = async (event) => {
        try {
            await api.post(`/tickets/${ticketId}/load-messages`);
        } catch (err) {
            toastError(err);
        }
    }

    let shortcutCurrentNavIndex = -1;

    const handleShortcutKeyPressEvent = (event) => {
        if (typeBar && event.key === "ArrowUp" || event.key === "ArrowDown") {
            setBlockEnter(true);
            const itemsCount = shortcutMessagesListRef.current.children.length;
            if (event.key === "ArrowDown") {
                shortcutCurrentNavIndex++;
                if (shortcutCurrentNavIndex >= itemsCount) {
                    shortcutCurrentNavIndex = 0;
                }
            } else {
                shortcutCurrentNavIndex--;
                if (shortcutCurrentNavIndex < 0) {
                    shortcutCurrentNavIndex = itemsCount - 1;
                }
            }
            shortcutMessagesListRef.current.children[shortcutCurrentNavIndex].focus();
        }
    }

    const handleShortcutKeyPressStart = (event) => {
        shortcutCurrentNavIndex = -1;
        handleShortcutKeyPressEvent(event);
    }

    const handleShortcutKeyPress = (event) => {
        if (typeBar && event.key === "Enter") {
            handleSuggestionClick(suggestions[shortcutCurrentNavIndex]);
        }
        handleShortcutKeyPressEvent(event);
    }

    const renderReplyingMessage = message => {
        return (
            <div className={classes.replyginMsgWrapper}>
                <div className={classes.replyginMsgContainer}>
                    <span
                        className={clsx(classes.replyginContactMsgSideColor, {
                            [classes.replyginSelfMsgSideColor]: !message.fromMe,
                        })}
                    ></span>
                    <div className={classes.replyginMsgBody}>
                        {!message.fromMe && (
                            <span className={classes.messageContactName}>
                                {message.contact?.name}
                            </span>
                        )}
                        <i className={classes.forwardLabel}>{isActive('enableTextForwardedMessages') ? '\u{21B1} Encaminhada\n' : ''}</i>
                        {message.body}
                    </div>
                </div>
                <IconButton
                    aria-label="showRecorder"
                    component="span"
                    disabled={loading || (ticketStatus !== "open" && ticketStatus !== "group")}
                    onClick={() => setReplyingMessage(null)}
                    size="large">
                    <ClearIcon className={classes.sendMessageIcons} />
                </IconButton>
            </div>
        );
    };

    if (medias.length > 0)
        return (
            <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
                <IconButton
                    aria-label="cancel-upload"
                    component="span"
                    onClick={e => setMedias([])}
                    size="large">
                    <CancelIcon className={classes.sendMessageIcons} />
                </IconButton>

                {loading ? (
                    <div>
                        <CircularProgress className={classes.circleLoading} />
                    </div>
                ) : (
                    <span>
                        {medias[0]?.name}
                        {/* <img src={media.preview} alt=""></img> */}
                    </span>
                )}
            </Paper>
        );
    else {
        return <>
            <Paper square elevation={0} className={classes.mainWrapper} style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                {message && renderReplyingMessage(message)}
                <div className={classes.newMessageBox}>
                    {showEmoji ? (
                        <div className={classes.emojiBox}>
                            <ClickAwayListener onClickAway={e => setShowEmoji(false)}>
                                <Picker
                                    perLine={16}
                                    showPreview={false}
                                    showSkinTones={false}
                                    onSelect={handleAddEmoji}
                                />
                            </ClickAwayListener>
                        </div>
                    ) : null}
                    <ConfirmationModal
                        title="Confirmar Carregamento de todas as mensagens"
                        open={loadMessagesConfirmationOpen}
                        onClose={setLoadMessagesConfirmationOpen}
                        onConfirm={handleLoadMessages}
                    >
                        Tem certeza que deseja carregar todas as mensagens desse atendimento? O processo é feito de forma assíncrona e pode levar alguns minutos até ser concluído.
                        Para manter a estabilidade do sistema, não realize esse procedimento frequentemente.
                    </ConfirmationModal>


                    <div className={classes.messageInputWrapper}>
                        <InputBase
                            inputRef={input => {
                                input && input.focus();
                                input && (inputRef.current = input);
                            }}
                            className={classes.messageInput}
                            placeholder={
                                ticketStatus === "open" || ticketStatus === "group"
                                    ? i18n.t("messagesInput.placeholderOpen")
                                    : i18n.t("messagesInput.placeholderClosed")
                            }
                            multiline
                            maxRows={5}
                            value={inputMessage}
                            onChange={handleChangeInput}
                            disabled={true}
                            onPaste={e => {
                                (ticketStatus === "open" || ticketStatus === "group") && handleInputPaste(e);
                            }}
                            onKeyDown={handleShortcutKeyPressStart}
                            onKeyPress={e => {
                                if (loading || e.shiftKey) return;
                                else if (!blockEnter && e.key === "Enter") {
                                    handleSendMessageForward();
                                }
                                if (blockEnter) {
                                    setBlockEnter(false);
                                }
                            }}
                        />
                        {typeBar ? (
                            <ul className={classes.messageShortcutWrapper} ref={shortcutMessagesListRef} onKeyDown={handleShortcutKeyPress}>
                                {suggestions.map((value, index) => {
                                    return (<li className={classes.messageShortcutWrapperItem} tabIndex={0} key={index}>
                                        <a onClick={(event) => { event.preventDefault(); handleSuggestionClick(value) }} href="#">{value.mediaUrl && '[Arquivo] '}{value.content}</a></li>)
                                })}
                            </ul>
                        ) : (<div></div>)}
                    </div>

                    <IconButton
                        aria-label="sendMessage"
                        component="span"
                        onClick={handleSendMessageForward}
                        disabled={loading}
                        size="large">
                        {loading ? (<CircularProgress className={classes.circleLoadingSmall} />)
                            : (
                                <SendIcon className={classes.sendMessageIcons} />
                            )}
                    </IconButton>




                </div>
            </Paper>
            <Paper className={classes.paper} style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Switch
                        focusVisibleClassName={classes.focusVisible}
                        disableRipple
                        color={'primary'}
                        checked={isActive('enableTextForwardedMessages')} onChange={handleChangeBooleanSetting} name={'enableTextForwardedMessages'}
                    />
                    <span>Descrição de Mensagem Encaminhada</span>
                </div>


                <div style={{ width: 220, paddingTop: 5, paddingBottom: 5, paddingRight: 5, display: listConnections.length === 1 ? 'none' : 'block' }} >
                    <AsyncSelect
                        url="/whatsapp"
                        isSearched={isSearched}
                        listConnections={listConnections}
                        setListConnections={setListConnections}
                        //label={i18n.t("tickets.search.connections")}
                        label={'Conexão para envio'}
                        width="100%"
                        dictKey="whatsapps"
                        onChange={(event, data) => setConnectionIds(data.id)}
                    >
                    </AsyncSelect>
                </div>


            </Paper>
        </>;
    }
};

export default MessageInputForward;