import { Button, Dialog } from "@mui/material";
import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { SketchPicker } from "react-color";

const useStyles = makeStyles(theme => ({
	blockModal: {
		background: theme.palette.fancyBackground
	},
	buttonModal: {
		borderBottomLeftRadius: 20,
		borderBottomRightRadius: 20
	}
}));

const ColorPicker = ({ onChange, currentColor, handleClose, open }) => {
	const classes = useStyles();
	const [selectedColor, setSelectedColor] = useState(currentColor);

	const handleChange = color => {
		setSelectedColor(color.hex);
		//handleClose();
	};

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="simple-dialog-title"
			open={open}
		>
			<SketchPicker
				width={320}
				triangle="hide"
				color={selectedColor}
				onChange={handleChange}
				onChangeComplete={color => onChange(color.hex)}
				className={classes.blockModal}
			/>
			<Button variant="contained" color="error" 
			className={classes.buttonModal} 
			onClick={handleClose}>Fechar</Button>
		</Dialog>
	);
};

export default ColorPicker;
