import { Button, IconButton, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import { AddCircle, Delete, Description, FileCopy, RemoveRedEye, Save } from "@mui/icons-material";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { toast } from "react-toastify";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import ConfirmationModal from "../../components/ConfirmationModal";

const useStyles = makeStyles(theme => ({
    addContainer: {
        marginTop: 10,
        borderRadius: 10,
        padding: 10,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        '& h6': {
            marginBottom: 20
        }
    },
    itemDisabled: {
        textDecoration: 'line-through'
    },
    docsLink: {
        fontSize: 12,
    },
    addTextInput: {
        marginRight: 10
    },
    warning: {
        marginTop: 10,
        fontSize: 10
    }
}));

const ApiKeys = () => {
    const [apiKeys, setApiKeys] = useState([]);
    const [showApiKey, setShowApiKey] = useState({});
    const [addMode, setAddMode] = useState(false);
    const [name, setName] = useState("");
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const apiURL = process.env.REACT_APP_BACKEND_URL;

    const classes = useStyles();

    useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			const fetchApiKeys = async () => {
				try {
					const { data } = await api.get("/api-keys/");
					setApiKeys(data.apiKeys);
				} catch (err) {
					toastError(err);
				}
			};
			fetchApiKeys();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, []);

    const handleAddApiKey = async () => {
        if (!name) {
            toast.error("Informe o nome por favor");
            return;
        }
        try {
            const { data } = await api.post("/api-keys/", { name });
            setApiKeys([...apiKeys, data.apiKey]);
            setName("");
            setAddMode(false);
        } catch (err) {
            toastError(err);
        }
    }

    const handleDeleteApiKey = async() => {
        if (!deleteModalOpen) return;
        try {
            await api.delete(`/api-keys/${deleteModalOpen.id}`);
            setDeleteModalOpen(false);
        } catch (err) {
            toastError(err);
        }
    }

    const openDeleteModal = (apiKey) => {
        setDeleteModalOpen(apiKey);
    }

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    }

    return (
        <div>
            <ConfirmationModal
                title={i18n.t("apikeys.confirmationModal.deleteTitle")}
                onConfirm={() => handleDeleteApiKey()}
                onClose={() => closeDeleteModal()}
                open={deleteModalOpen}>
                 {i18n.t("apikeys.confirmationModal.deleteMessage")}
                </ConfirmationModal>
            <Title>{i18n.t("apikeys.title")}</Title>
            <div style={{marginBottom: 10}}>{i18n.t("apikeys.table.url")} <strong>{apiURL}</strong></div>
            <Button color="primary" onClick={() => setAddMode(true)} variant="contained" style={{marginRight: 10}}><AddCircle /> {i18n.t("apikeys.buttons.add")}</Button>
            <Button variant="contained" color="secondary" onClick={() => window.open(`${apiURL}/api-docs`)}><Description /> {i18n.t("apikeys.buttons.documentation")}</Button>
            <div className={classes.warning}>{i18n.t("apikeys.buttons.warning")}</div>
            {addMode && <div className={classes.addContainer}>
                <Typography variant="h6">{i18n.t("apikeys.addTitle")}</Typography>
                <TextField className={classes.addTextInput} InputLabelProps={{ shrink: true }} variant="outlined" onChange={(e) => setName(e.target.value)} 
                label={i18n.t("apikeys.labelText")} placeholder={i18n.t("apikeys.labelText")} /> 
                <Button color="primary" variant="contained" onClick={handleAddApiKey}><Save /> {i18n.t("apikeys.buttonAdd")}</Button>
            </div>}
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>{i18n.t("apikeys.table.name")}</TableCell>
                        <TableCell>{i18n.t("apikeys.table.key")}</TableCell>
                        <TableCell>{i18n.t("apikeys.table.actions")}</TableCell>
                    </TableRow>
                    {apiKeys && apiKeys.map(apiKey => (
                        <TableRow key={apiKey.id}>
                            <TableCell>
                                {apiKey.enabled && apiKey.name}
                                {!apiKey.enabled && <span className={classes.itemDisabled}>{apiKey.name}</span>}
                            </TableCell>
                            <TableCell style={{overflowWrap: "anywhere"}}>
                                {showApiKey && showApiKey[apiKey.id] ? apiKey.key : '********* '}
                                {apiKey.enabled && <IconButton size="small" onClick={() => {setShowApiKey({[apiKey.id]: !showApiKey[apiKey.id]})}}><RemoveRedEye /></IconButton>}
                                {apiKey.enabled && <IconButton size="small" 
                                onClick={() => {navigator.clipboard.writeText(apiKey.key);toast.success(i18n.t("apikeys.toasts.success"))}}><FileCopy /></IconButton>}
                            </TableCell>
                            <TableCell>
                                {apiKey.enabled && <IconButton size="small" title="Excluir" onClick={() => {openDeleteModal(apiKey)}}><Delete /></IconButton>}
                            </TableCell>
                        </TableRow>
                    ))}
                    {!apiKeys || apiKeys.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={3}> {i18n.t("apikeys.noapikeys")}<IconButton onClick={() => setAddMode(true)}><AddCircle /></IconButton></TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

export default ApiKeys;