import React, { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { useTheme } from "@mui/material";
import { Tooltip, IconButton } from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";
import { IoArrowUpCircleOutline, IoArrowDownCircleOutline } from "react-icons/io5";
import makeStyles from '@mui/styles/makeStyles';
import formatTime from "../../helpers/formatTime";

const useStyles = makeStyles(theme => ({
  iconTitle: {
    color: theme.palette.dark.main,
    fontSize: 24,
    cursor: 'pointer'
  }
}));

const ChartResponseTimeComponent = ({ period, userIds, contactIds, statuses, queueIds, tagIds, endpoint, title, yAxisTitle, colorChart, setLoading, theme, subtitle }) => {
  const classes = useStyles();
  const [chartData, setChartData] = useState(null);
  const [internalLoading, setInternalLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [titleAxis, setYtitle] = useState('');
  const [allNames, setAllNames] = useState([]);
  const [openBoxChart, setOpenBoxChart] = useState(true);
  const themeApp = useTheme();

  const preprocessName = (name) => {
    return name
      .normalize("NFD") // Normalize to NFD (Normalization Form Decomposition)
      .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
      .replace(/[^\w\s]/g, '') // Remove special characters
      .trim(); // Trim whitespace
  };


  const transformData = (data) => {
    const { series, categories } = data;

    const filteredSeries = series.filter(serie => serie.name !== "Total");
    const names = filteredSeries.map(serie => preprocessName(serie.name));
    //console.log('names selected:', names);
    setAllNames(names);

    const transformedData = [];
    categories.forEach((category, index) => {
      const dayData = { day: category };

      filteredSeries.forEach(serie => {
        const processedName = preprocessName(serie.name);
        if (serie.data[index] !== undefined && serie.data[index] !== null && serie.data[index] !== 0) {
          dayData[processedName] = serie.data[index];
        }
      });

      // Check if all values (except 'day') are zero
      const hasValue = Object.values(dayData).some((value, idx) => idx > 0 && value !== 0);
      if (hasValue) {
        transformedData.push(dayData);
      }
    });

    // console.log('was transformed:', transformedData);
    setChartData(transformedData);

    return transformedData;
  };


  useEffect(() => {
    let isMounted = true;
    setYtitle(yAxisTitle);
    const fetchData = async () => {
      try {
        setInternalLoading(true);
        const response = await api.get(endpoint, {
          params: {
            dateStart: moment(period[0]).format("YYYY-MM-DD"),
            dateEnd: moment(period[1]).format("YYYY-MM-DD"),
            contactIds: JSON.stringify(contactIds),
            userIds: JSON.stringify(userIds),
            queueIds: JSON.stringify(queueIds),
            statuses: JSON.stringify(statuses),
            tagIds: JSON.stringify(tagIds),
          },
        });

        if (response.data && isMounted) {
          //  console.log('received data response time:', response);
          transformData(response.data.data);
          setInternalLoading(false);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setInternalLoading(false);
          setLoading(false);
          toastError(err);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [period, userIds, contactIds, queueIds, tagIds, statuses, endpoint, colorChart]);



  return (
    <>
      <h2 style={{ fontSize: 20 }}>
        <div className="block-title-chart" style={{ display: 'flex', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span className="title-chart" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
            onClick={() => setOpenBoxChart(!openBoxChart)}
          >
            {title}
            <Tooltip title={title === 'Tempo médio de Aceite do Aguardando' ?
              `Este gráfico representa o tempo médio que um usuário leva a aceitar um atendimento.`
              : `Este gráfico representa o tempo médio que leva os usuários a responder um cliente quando o cliente manda uma mensagem.`}>
              <IconButton>
                <FaInfoCircle size={15} />
              </IconButton>
            </Tooltip>
            {openBoxChart ? <IoArrowUpCircleOutline className={classes.iconTitle} /> : <IoArrowDownCircleOutline className={classes.iconTitle} />}
          </span>
        </div>
      </h2>

      {openBoxChart ?
        chartData && chartData.length > 0 ? (
          <div style={{ height: 500, width: '100%', display: 'flex' }}>
            <ResponsiveBar
              data={chartData}
              keys={allNames}
              indexBy="day"
              height={500}
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={{ scheme: theme }}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 0,
                tickRotation: -15,
                legendPosition: 'start',
                legendOffset: 2,
                truncateTickAt: 100
              }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 0,
                tickRotation: 0,
                //legend: formatTime(Number(titleAxis, true)),
                //format: value => value !== 0 ? `${formatTime(value, true)}` : null,
                legendPosition: 'middle',
                legendOffset: -80,
              }}
              valueFormat={value => `${formatTime(Number(value, true))}`}
              labelSkipWidth={12}
              labelSkipHeight={12}
              theme={{
                text: {
                  fill: themeApp.mode === 'dark' ? '#ffffff' : '#000000'
                }
              }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'top-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 4,
                  itemWidth: 100,
                  itemHeight: 10,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={e => e.formattedValue + e.indexValue + "atendimentos"}
              tooltip={({
                id,
                value,
                color
              }) => <div style={{
                padding: 12,
                color,
                background: '#222222',
                textAlign: 'center'
              }}>
                  <span>{title}</span>
                  <br />
                  <strong>
                    {id}: {formatTime(value)}
                  </strong>
                </div>}
            />
          </div>
        ) : chartData && chartData.length === 0 ? (
          <div
            className="boxChart"
            style={{ height: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', justifyContent: 'center' }}
          >
            {/*<img src="/loading/loadinginfinite.gif" height={30} alt="Loading..." />*/}
            Nada encontrado
          </div>) : (
          <div
            className="boxChart"
            style={{ height: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', justifyContent: 'center' }}
          >
            <img src="/loading/loadinginfinite.gif" height={30} alt="Loading..." />
            Carregando dados...
          </div>
        ) : <></>}
    </>
  );
};

export default ChartResponseTimeComponent;