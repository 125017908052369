import React, { useState, useContext, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { i18n } from "../../translate/i18n";
//import { saveAs } from 'file-saver';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import LoadingBox from "../LoadingBox";
import { toast } from "react-toastify";
import PromptTextToSpeechModal from "../PromptTexttoSpeechModal";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	answerLoading: {
		minHeight: 300,
		maxHeight: 300,
		width: 300,
		margin: 20,
		borderRadius: 10,
		background: theme.palette.blackWhiteBackground,
		display: 'flex',
		alignContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	h1Loading: {
		fontSize: 18
	},
	answerBlock: {
		fontSize: 16,
		lineHeight: '27px',
		padding: 20,
		background: theme.palette.fancyBackground,
		borderRadius: 10,
		//margin: 20,
		minHeight: 200,
		maxHeight: 400,
		overflow: 'auto',
		...theme.scrollbarStylesSoft,
	},

	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},
	circleLoading: {
		color: green[500],
		opacity: "70%"
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
	greetingMessage: Yup.string(),
});

const PromptChatAIModal = ({ open, onClose, handleAIMessage }) => {
	const classes = useStyles();
	const [answer, setAnswer] = useState('');
	const [answerImage, setAnswerImage] = useState('');
	const { getSettingValue } = useContext(SettingsContext);
	const apiKey = getSettingValue('keyOpenAi');
	const [loading, setLoading] = useState(false);
	const initialState = {
		name: "",
		color: "",
		greetingMessage: "",
	};
	const [queue, setQueue] = useState(initialState);
	const greetingRef = useRef();

	const paragraphs = answer && answer.split('\n');

	const handleClose = () => {
		onClose();
		setQueue(initialState);
	};

	const CopytoMessage = () => {
		onClose();
		setQueue(initialState);
		handleAIMessage(answer);
		navigator.clipboard.writeText(answer);
	};


	const handleReloadForm = () => {
		setAnswer('');
		setAnswerImage('');
	}


	const handleAI = async values => {
		try {
			let data = {
				text: values.text,
				apikey: apiKey
			}
			setLoading(true);
			const response = await api.post("/ai/prompt", data);
			if (response) {
				//console.log(response.data);
				setAnswer(response.data.choices[0].message.content)
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);			
			toast.error(i18n.t("backendErrors.ERR_OPENAI"));
		}
	};


	const handleGenerateImage = async values => {
		try {
			let data = {
				prompt: values.text,
				apikey: apiKey
			}
			setLoading(true);
			const response = await api.post("/ai/generateimage", data);
			if (response.data) {
				//console.log(response.data);
				setAnswerImage(response.data.data[0].url)

			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};


	/*const downloadImage = () => {
		fetch(answerImage, {
			headers: {
				'Content-Type': 'image/png', // Replace with the appropriate content type
			}
		})
			.then((response) => {
				console.log("Response Status:", response.status);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.blob();
			})
			.then((blob) => {
				saveAs(blob, 'image.jpg');
			})
			.catch((error) => {
				console.error('Fetch error:', error);
			});
	};*/
	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					Assistente AI
				</DialogTitle>

				{loading ? 
					<LoadingBox /> : (
					answer ? (
						<div className={classes.answerBlock}>
							<PromptTextToSpeechModal
									open={false}
									aria-labelledby="form-dialog-title"
									message={{ body: answer }}
									icon={true}
								/>
							{paragraphs.map((paragraph, index) => (
								<p className={classes.answer} key={index}>{paragraph}</p>
							))}
						</div>
					) : answerImage ? (
						<div className={classes.answerBlock}>
							<span>Salvar a imagem, botão direito do mouse e <strong>Salvar Imagem Como</strong></span>
							<img className={classes.answer} src={answerImage} alt={'Generated'} style={{ maxWidth: '100%', borderRadius: 10 }} />
						</div>
					) : (
						<Formik
							initialValues={queue}
							enableReinitialize={true}
							validationSchema={QueueSchema}
							onSubmit={(values, actions) => {
								setTimeout(() => {
									handleAI(values);
									actions.setSubmitting(false);
								}, 400);
							}}
						>
							{({ touched, errors, isSubmitting, values }) => (
								<Form>
									<DialogContent dividers>
										<div>
											<Field
												as={TextField}
												label={i18n.t("promptAiModal.form.prompt")}
												type="prompt"
												multiline
												inputRef={greetingRef}
												rows={5}
												fullWidth
												name="text"
												error={
													touched.greetingMessage && Boolean(errors.greetingMessage)
												}
												helperText={
													touched.greetingMessage && errors.greetingMessage
												}
												variant="outlined"
												margin="dense"
											/>
										</div>
									</DialogContent>
									<DialogActions>
										<Button
											onClick={handleClose}
											color="secondary"
											disabled={isSubmitting}
											variant="outlined"
										>
											{i18n.t("promptAiModal.buttons.cancel")}
										</Button>

										<Button
											onClick={() => handleGenerateImage(values)}
											color="warning"
											variant="outlined"
										>
											{i18n.t("promptAiModal.buttons.generateImage")}
										</Button>

										<Button
											type="submit"
											color="primary"
											disabled={isSubmitting}
											variant="contained"
											className={classes.btnWrapper}
											onClick={() => handleAI(values)}
										>

											{i18n.t("promptAiModal.buttons.okSearch")}
											{isSubmitting && (
												<CircularProgress
													size={24}
													className={classes.buttonProgress}
												/>
											)}
										</Button>
									</DialogActions>
								</Form>
							)}
						</Formik>
					)
				)}

				{answer && (
					<DialogActions>
						<Button
							onClick={handleClose}
							color="secondary"
							variant="outlined"
						>
							{i18n.t("promptAiModal.buttons.cancel")}
						</Button>
						<Button
							onClick={handleReloadForm}
							color="warning"
							variant="outlined"
						>
							{i18n.t("promptAiModal.buttons.reload")}
						</Button>
						<Button
							type="submit"
							color="primary"
							variant="contained"
							className={classes.btnWrapper}
							onClick={() => CopytoMessage()}>
							{i18n.t("promptAiModal.buttons.copy")}
						</Button>
					</DialogActions>
				)}


				{answerImage && (
					<DialogActions>
						<Button
							onClick={handleClose}
							color="secondary"
							variant="outlined"
						>
							{i18n.t("promptAiModal.buttons.cancel")}
						</Button>
						<Button
							onClick={handleReloadForm}
							color="warning"
							variant="outlined"
						>
							{i18n.t("promptAiModal.buttons.reload")}
						</Button>
					</DialogActions>
				)}
			</Dialog>
		</div>
	);
};

export default PromptChatAIModal