import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Autocomplete, {
    createFilterOptions,
} from '@mui/material/Autocomplete';

import { i18n } from "../../translate/i18n";
import { Can } from "../../components/Can";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControlLabel, Switch } from "@mui/material";
import UserStatusIcon from "../User/statusIcon";
import AsyncSelect from "../AsyncSelect";

const filterOptions = createFilterOptions({
    trim: true,
});


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    blockCustomerField: {
        marginTop: 10,
        display: 'flex',
        padding: '30px 20px',
        borderRadius: 10,
        border: '1px solid #ddd'
    }
}));

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
    email: Yup.string().email("Invalid email"),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const { user } = useContext(AuthContext);

    const initialState = {
        name: "",
        number: "",
        email: "",
        blocked: false
    };

    const [options, setOptions] = useState([]);
    const [contact, setContact] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [loadingWhatsapp, setLoadingWhatsapp] = useState(false);
    const [loadingQueue, setLoadingQueue] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const [searchParam, setSearchParam] = useState("");
    const [searchParamWhatsapp, setSearchParamWhatsapp] = useState("");
    const [searchParamQueue, setSearchParamQueue] = useState("");
    const [selectedWhatsapp, setSelectedWhatsapp] = useState(null);
    const [queues, setQueues] = useState([]);


    useEffect(() => {
        const fetchContact = async () => {
            if (initialValues) {
                setContact(prevState => {
                    return { ...prevState, ...initialValues };
                });
            }

            if (!contactId) return;

            try {
                const { data } = await api.get(`/contacts/${contactId}`);
                if (data) {
                    setContact(data);
                   // console.log(data, 'fetching contact')
                    data.queueId && setSelectedQueue(data.queue)
                    data.userId && setSelectedUser(data.user)
                }
            } catch (err) {
                toastError(err);
            }
        };

        fetchContact();
    }, [contactId, open, initialValues]);



    const fetchUsers = async () => {
        try {
            const { data } = await api.get("/users/", {
                params: { queueId: selectedQueue?.id },
            });
            setOptions(data.users);

            setLoading(false);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    };

    useEffect(() => {
        if (!open) {
            setLoadingQueue(false);
            return;
        }
        setLoadingQueue(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchQueues = async () => {
                try {
                    const { data } = await api.get("/queue/", {
                        params: { searchParam: searchParamQueue, whatsappId: selectedWhatsapp?.id },
                    });
                    setQueues(data);

                    setLoadingQueue(false);
                } catch (err) {
                    setLoadingQueue(false);
                    toastError(err);
                }
            };

            fetchQueues();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParamQueue, open]);


    useEffect(() => {
        if (!open) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchUsers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, open, selectedQueue]);


    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);



    const handleClose = () => {
        onClose();
        setContact(initialState);
    };

    const handleSaveContact = async values => {
        try {
           // console.log('data sent', values)
            if (contactId) {
                await api.put(`/contacts/${contactId}`, values);
                handleClose();
            } else {
                const { data } = await api.post("/contacts", values);                
                if (onSave) {
                    onSave(data);
                }
                handleClose();
            }
            toast.success(i18n.t("contactModal.success"));
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {contactId
                        ? `${i18n.t("contactModal.title.edit")}`
                        : `${i18n.t("contactModal.title.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={contact}
                    enableReinitialize={true}
                    validationSchema={ContactSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveContact(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                        <Form>
                            <DialogContent dividers>
                                <Typography variant="subtitle1" gutterBottom>
                                    {i18n.t("contactModal.form.mainInfo")}
                                </Typography>
                                <Field
                                    as={TextField}
                                    label={i18n.t("contactModal.form.name")}
                                    name="name"
                                    autoFocus
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textField}
                                />
                                <Field
                                    as={TextField}
                                    label={i18n.t("contactModal.form.number")}
                                    name="number"
                                    error={touched.number && Boolean(errors.number)}
                                    helperText={touched.number && errors.number}
                                    placeholder="5513912344321"
                                    variant="outlined"
                                    margin="dense"
                                />
                                <div>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("contactModal.form.email")}
                                        name="email"
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        placeholder="Email address"
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                    />
                                </div>
                                <Can
                                    role={user.roleId}
                                    perform="contacts:block:any"
                                    yes={() => (<div>
                                        <FormControlLabel control={<Switch checked={values.blocked} onChange={(event) => setFieldValue("blocked", event.target.checked)} />} label={i18n.t("contactModal.form.block")} />
                                    </div>)}
                                    no={() => <></>}
                                />
                                <Can
                                    role={user.roleId}
                                    perform="contacts:edit:wallet:any"
                                    yes={() => (<div>
                                        <Typography variant="subtitle1" gutterBottom>{i18n.t("contactModal.form.customer_portfolio")}</Typography>
                                        {/*<div style={{ marginTop: 10 }}>
                                        <AsyncSelect url="/users" dictKey={"users"}
                                            initialValue={values.user} width="100%" label={i18n.t("contactModal.form.attendant")}
                                            onChange={(event, value) => setFieldValue("userId", value ? value.id : null)} />
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <AsyncSelect url="/queue" dictKey={null}
                                            initialValue={values.queue} width="100%" label={i18n.t("contactModal.form.queue")}
                                            onChange={(event, value) => setFieldValue("queueId", value ? value.id : null)} />
                                    </div>*/}
                                        <div className={classes.blockCustomerField}>
                                            <Autocomplete
                                                style={{ marginBottom: 10 }}
                                                className={classes.textField}
                                                getOptionLabel={option => `${option.name}`}
                                                onChange={(e, newValue) => {
                                                    setSelectedQueue(newValue);
                                                    setFieldValue("queueId", newValue ? newValue.id : null);
                                                }}
                                                options={queues}
                                                filterOptions={filterOptions}
                                                value={selectedQueue}
                                                freeSolo
                                                autoHighlight
                                                noOptionsText={i18n.t("transferTicketModal.noOptions")}
                                                loading={loadingQueue}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        label={i18n.t("transferTicketModal.fieldLabelQueue")}
                                                        variant="outlined"
                                                        className={classes.textField}
                                                        autoFocus
                                                        onChange={e => setSearchParamQueue(e.target.value)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Autocomplete
                                                style={{}}
                                                className={classes.textField}
                                                getOptionLabel={option => `${option.name}`}
                                                onChange={(e, newValue) => setFieldValue("userId", newValue ? newValue.id : null)}
                                                options={options}
                                                filterOptions={filterOptions}
                                                value={selectedUser}
                                                freeSolo
                                                autoHighlight
                                                noOptionsText={i18n.t("transferTicketModal.noOptions")}
                                                loading={loading}
                                                renderOption={(props, option) => (
                                                    <Box component="li" {...props}>
                                                        <span>{option.name} <UserStatusIcon user={option} /></span>
                                                    </Box>
                                                )}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        className={classes.textField}
                                                        label={i18n.t("transferTicketModal.fieldLabel")}
                                                        variant="outlined"
                                                        autoFocus
                                                        onChange={e => setSearchParam(e.target.value)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>)}
                                    no={() => <></>}
                                />

                                <Typography
                                    style={{ marginBottom: 8, marginTop: 12 }}
                                    variant="subtitle1"
                                >
                                    {i18n.t("contactModal.form.extraInfo")}
                                </Typography>

                                <FieldArray name="extraInfo">
                                    {({ push, remove }) => (
                                        <>
                                            {values.extraInfo &&
                                                values.extraInfo.length > 0 &&
                                                values.extraInfo.map((info, index) => (
                                                    <div
                                                        className={classes.extraAttr}
                                                        key={`${index}-info`}
                                                    >
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("contactModal.form.extraName")}
                                                            name={`extraInfo[${index}].name`}
                                                            variant="outlined"
                                                            margin="dense"
                                                            className={classes.textField}
                                                        />
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("contactModal.form.extraValue")}
                                                            name={`extraInfo[${index}].value`}
                                                            variant="outlined"
                                                            margin="dense"
                                                            className={classes.textField}
                                                        />
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => remove(index)}
                                                        >
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                    </div>
                                                ))}
                                            <div className={classes.extraAttr}>
                                                <Button
                                                    style={{ flex: 1, marginTop: 8 }}
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => push({ name: "", value: "" })}
                                                >
                                                    {`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </FieldArray>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("contactModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {contactId
                                        ? `${i18n.t("contactModal.buttons.okEdit")}`
                                        : `${i18n.t("contactModal.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default ContactModal;
