import React, { useState, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import makeStyles from '@mui/styles/makeStyles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { LiaTagsSolid } from "react-icons/lia";
import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TagModal from "../../components/TagModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import Checkbox from '@mui/material/Checkbox';
import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import getSocket from "../../helpers/socket";
import Swal from "sweetalert2";
import ForbiddenPage from "../../components/ForbiddenPage";

const socket = getSocket();

const reducer = (state, action) => {
	if (action.type === "LOAD_TAGS") {
		const tags = action.payload;
		const newTags = [];

		tags.forEach(tag => {
			const index = state.findIndex(c => c.id === tag.id);
			if (index !== -1) {
				state[index] = tag;
			} else {
				newTags.push(tag);
			}
		});

		return [...state, ...newTags];
	}

	if (action.type === "UPDATE_TAGS") {
		const tag = action.payload;
		const index = state.findIndex(c => c.id === tag.id);

		if (index !== -1) {
			state[index] = tag;
			return [...state];
		} else {
			return [tag, ...state];
		}
	}
	if (action.type === "DELETE_TAGS") {
		const tagId = action.payload;
		const index = state.findIndex(c => c.id === tagId);

		if (index !== -1) {
			const newState = [...state.slice(0, index), ...state.slice(index + 1)];
			return newState;
		}

		return state;
	}


	if (action.type === "DELETE_MULTIPLE_TAGS") {
		const tagsIds = action.payload;
		console.log(tagsIds)
		console.log(state)
		const newState = state.filter(tag => !tagsIds.includes(String(tag.id)));
		console.log(newState)
		return newState;
	}

	if (action.type === "RESET") {
		return [];
	}
};

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		marginTop: 40,
		borderRadius: 20,
		border: '0px !important',
		marginBottom: 40,
		overflow: 'hidden'
	},
	mainPaperTable: {
		flex: 1,
		overflow: 'auto',
		height: '68vh',
		...theme.scrollbarStylesSoft,
	},
	helpMessage: {
		padding: "10px"
	},
	tag: {
		padding: 3,
		paddingLeft: 10,
		paddingRight: 10,
		color: "#FFF",
		fontWeight: "bold",
		borderRadius: 4,
		display: "inline-block"
	},
	mainTagsButton: {
		marginTop: 15,
		float: 'right'
	},
	buttonsTicket: {
		height: 40,
		borderRadius: '5px!important',
		display: 'inline-flex',
		alignItems: 'center',
		boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.07) !important',
		'&:hover': {
			boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2) !important',
		},
	}
}));

const Tags = () => {
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const [checked, setChecked] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [searchParam, setSearchParam] = useState("");
	const [tags, dispatch] = useReducer(reducer, []);
	const [selectedTagId, setSelectedTagId] = useState(null);
	const [tagModalOpen, setTagModalOpen] = useState(false);
	const [deletingTag, setDeletingTag] = useState(null);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [hasMore, setHasMore] = useState(false);

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [searchParam]);

	const handleToggle = (event) => {
		const currentIndex = checked.indexOf(event.target.value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(event.target.value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	}

	const toggleAll = (event) => {
		if (event.target.checked) {
			checkAll();
		} else {
			uncheckAll();
		}
	}
	const checkAll = () => {
		setChecked(tags.map((element) => { return "" + element.id }));
	}

	const uncheckAll = () => {
		setChecked([]);
	}

	const handleDeleteTags = () => {
		Swal.fire({
			title: 'Atenção! Excluir tags selecionadas.',
			text: "Tem certeza que deseja excluir todos as tags selecionados?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sim!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const deleteAll = await api.delete(`/tags_many`, { data: { tagsIds: checked } });
					if (deleteAll) {
						toast.success(i18n.t("Tags deletadas com successo!"));
						setDeletingTag(null);
						setSearchParam("");
						setChecked([]);
					}
				} catch (err) {
					toastError(err);
				}

			}
		});
	}
	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchTags = async () => {
				try {
					const { data } = await api.get("/tags/", {
						params: { searchParam, pageNumber },
					});
					dispatch({ type: "LOAD_TAGS", payload: data.tags });
					setHasMore(data.hasMore);
					setLoading(false);
				} catch (err) {
					toastError(err);
				}
			};
			fetchTags();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, pageNumber]);

	useEffect(() => {
		const tagEvent = data => {
			if (data.action === "update" || data.action === "create") {
				dispatch({ type: "UPDATE_TAGS", payload: data.tag });
			}

			if (data.action === "delete") {
				if (data.tagsIds && data.tagsIds.length > 0) {
					dispatch({ type: "DELETE_MULTIPLE_TAGS", payload: data.tagsIds });
				} else if (data.tagId) {
					dispatch({ type: "DELETE_TAGS", payload: +data.tagId });
				}
			}
		}

		socket.on("tag", tagEvent);

		return () => {
			socket.off("tag", tagEvent);
		};
	}, []);

	const handleSearch = event => {
		setSearchParam(event.target.value.toLowerCase());
	};

	const handleOpenTagModal = () => {
		setSelectedTagId(null);
		setTagModalOpen(true);
	};

	const handleCloseTagModal = () => {
		setSelectedTagId(null);
		setTagModalOpen(false);
	};

	const hadleEditTag = tagId => {
		setSelectedTagId(tagId);
		setTagModalOpen(true);
	};

	const handleDeleteTag = async tagId => {
		try {
			await api.delete(`/tags/${tagId}`);
			toast.success(i18n.t("tags.toasts.deleted"));
		} catch (err) {
			toastError(err);
		}
		setDeletingTag(null);
		setSearchParam("");
		setPageNumber(1);
	};

	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = e => {
		if (!hasMore || loading) return;
		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			loadMore();
		}
	};

	return (
		<MainContainer className={classes.mainContainer}>
			<ConfirmationModal
				title={i18n.t("contacts.confirmationModal.deleteTitle")}
				open={confirmOpen}
				onClose={setConfirmOpen}
				onConfirm={e => handleDeleteTags()}>
				${i18n.t("contacts.confirmationModal.deleteMessage")}`
			</ConfirmationModal>



			<TagModal
				open={tagModalOpen}
				onClose={handleCloseTagModal}
				aria-labelledby="form-dialog-title"
				tagId={selectedTagId}
			></TagModal>
			<ConfirmationModal
				title={
					deletingTag
						? `${i18n.t("tags.confirmationModal.deleteTitle")} ${deletingTag.name
						}?`
						: `${i18n.t("tags.confirmationModal.importTitlte")}`
				}
				open={confirmOpen}
				onClose={setConfirmOpen}
				onConfirm={e =>
					deletingTag
						? handleDeleteTag(deletingTag.id)
						: null
				}
			>
				{deletingTag
					? `${i18n.t("tags.confirmationModal.deleteMessage")}`
					: `${i18n.t("tags.confirmationModal.importMessage")}`}
			</ConfirmationModal>
			<Can
			role={user.roleId}
			perform="tags:show:page"
			yes={() => (
			<Paper
				className={classes.mainPaper}
				variant="outlined"
				onScroll={handleScroll}
			>
				<MainHeader>
					<Title><LiaTagsSolid size={24} /> {i18n.t("tags.title")}</Title>
					<MainHeaderButtonsWrapper>
						<TextField
							placeholder={i18n.t("tags.searchPlaceholder")}
							type="search"
							size="small"
							value={searchParam}
							onChange={handleSearch}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon style={{ color: "gray" }} />
									</InputAdornment>
								),
							}}
						/>

						{checked && checked.length > 0 &&
							<Can
								perform="tags:delete:any"
								yes={() => (
									<Button
										className={classes.buttonsTicket}
										variant="contained"
										color="secondary"
										onClick={handleDeleteTags}
									>
										Deletar ({checked.length}) tags
									</Button>)}
								no={() => <></>}
							/>
						}
						<Can
							role={user.roleId}
							perform="tags:create"
							yes={() => (
								<Button
									variant="contained"
									color="primary"
									onClick={handleOpenTagModal}
									className={classes.buttonsTicket}
								>
									<AddIcon style={{ fontSize: 13 }} /> {i18n.t("tags.buttons.add")}
								</Button>)}
							no={() => <></>}
						/>
					</MainHeaderButtonsWrapper>
				</MainHeader>
				<Paper
					className={classes.mainPaperTable}
					variant="outlined"
					onScroll={handleScroll}
				>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell padding="checkbox">
									<Checkbox onChange={toggleAll} />
								</TableCell>
								<TableCell>{i18n.t("tags.table.name")}</TableCell>
								<TableCell align="center">
									{i18n.t("tags.table.contacts")}
								</TableCell>
								<TableCell align="center">
									{i18n.t("tags.table.actions")}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<>
								{tags.map(tag => (
									<TableRow key={tag.id}>
										<TableCell>
											<Checkbox value={tag.id} checked={checked.indexOf("" + tag.id) !== -1} onChange={handleToggle} />
										</TableCell>
										<TableCell><div className={classes.tag} style={{ backgroundColor: tag.color }}>{tag.name}</div></TableCell>
										<TableCell align="center">{tag.contacts_count ? (<span>{tag.contacts_count}</span>) : <span>0</span>}</TableCell>
										<TableCell align="center">
											<Can
												role={user.roleId}
												perform="tags:edit:any"
												yes={() => (
													<IconButton
														size="small"
														onClick={() => hadleEditTag(tag.id)}
													>
														<EditIcon />
													</IconButton>)}
												no={() => <></>}
											/>
											<Can
												role={user.roleId}
												perform="tags:delete:any"
												yes={() => (
													<IconButton
														size="small"
														onClick={e => {
															setConfirmOpen(true);
															setDeletingTag(tag);
														}}
													>
														<DeleteOutlineIcon />
													</IconButton>
												)}
												no={() => <></>}
											/>
										</TableCell>
									</TableRow>

								))}
								{loading && <TableRowSkeleton avatar columns={3} />}
							</>
						</TableBody>
					</Table>
				</Paper>
			</Paper>
			)}
			no={() => <>
				<ForbiddenPage />
			</>}
		/>


		</MainContainer>
	);
};

export default Tags;
