import React, { createContext, useContext, useState } from 'react';
import toastError from "../../../errors/toastError";
import api from "../../../services/api";

const TicketCountsContext = createContext();



export const TicketCountsProvider = ({ children }) => {


  const [ticketCounts, setTicketCounts] = useState({
    countOpen: 0,
    countPending: 0,
    countBot: 0,
    countGroup: 0,
    countPaused: 0,
    countClosed: 0,
  });


  const [showAllTickets, setNewShowAll] = useState(false);
  const [selectedQueueIds, setNewQueueIds] = useState([-1]);
  const [selectedTagIds, setNewTagIds] = useState([]);

  const handleUpdateCounts = (status, counts) => {
   // console.log('chegou aqui', status, counts)
    let newStatus = '';
    switch (status) {
      case 'open':
        newStatus = 'countOpen';
        break;
      case 'pending':
        newStatus = 'countPending';
        break;
      case 'bot':
        newStatus = 'countBot';
        break;
      case 'group':
        newStatus = 'countGroup';
      break;
      case 'paused':
        newStatus = 'countPaused';
      break;
      default:
        break;
    }
    setTicketCounts(prevCounts => {
      let updatedCount;
      if (counts === '-1') {
        updatedCount = Math.max(prevCounts[newStatus] - 1, 0); //Afirma que nunca vai abaixo de zero
      } else if (counts === '+1') {
        updatedCount = prevCounts[newStatus] + 1;
      } else {
        updatedCount = counts;
      }
      
      return {
        ...prevCounts,
        [newStatus]: updatedCount
      };
    });
  }

  return (
    <TicketCountsContext.Provider value={{ ticketCounts, handleUpdateCounts, setNewShowAll, setNewQueueIds, setNewTagIds }}>
      {children}
    </TicketCountsContext.Provider>
  );
};

export const useTicketCounts = () => useContext(TicketCountsContext);

