import React, { useState } from "react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { messages } from "./languages";
import { MenuItem, Menu, IconButton } from "@mui/material";


i18n.use(LanguageDetector).init({
  debug: false,
  defaultNS: ["translations"],
  fallbackLng: "pt",
  ns: ["translations"],
  resources: messages,
});

const LanguageSelector = () => {
  const [, setSelectedLanguage] = useState(i18n.language);
  const [anchorEl, setAnchorEl] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
  const flag = i18n.language === 'en' ? '/icons/eua.png' : 
  i18n.language === 'es' ? '/icons/esp.png': '/icons/brazil.png'

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    window.location.reload();
  };

  const options = [
    {
      label: i18n.t("settings.settings.language.options.english"),
      value: "en",
      flag: "/icons/eua.png"
    },
    {
      label: i18n.t("settings.settings.language.options.spanish"),
      value: "es",
      flag: "/icons/esp.png"
    },
    {
      label: i18n.t("settings.settings.language.options.portuguese"),
      value: "pt",
      flag: "/icons/brazil.png"
    },
  ];

  const handleMenu = event => {
		setAnchorEl(event.currentTarget);
		setMenuOpen(true);
	};
  
	const handleCloseMenu = () => {
		setAnchorEl(null);
		setMenuOpen(false);
	};


  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        size="large">
          <>
        <img 
            src={flag}
            width={32} />
        </>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleCloseMenu}
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value} onClick={() => changeLanguage(option.value)}>
            <img 
            src={option.flag}
            width={32} style={{marginRight: 10}}/> {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export { i18n, LanguageSelector };
