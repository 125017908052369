import React, { useContext } from "react";
import { Avatar, CardHeader, Grid } from "@mui/material";
import { i18n } from "../../translate/i18n";
import { SettingsContext } from "../../context/Settings/SettingsContext";



const TicketInfo = ({ contact, ticket, onClick, infoButton }) => {
	const { isActive } = useContext(SettingsContext);
	const marqueeActived = isActive('enableMarqueeContactName');

	const renderCardReader = () => {
		return (
			<CardHeader
				onClick={onClick}
				style={{ cursor: "pointer", overflow: 'hidden', whiteSpace: 'initial' }}
				titleTypographyProps={{ noWrap: true }}
				className={`${contact?.name.length >= 20 && marqueeActived && 'marquee'}`}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={contact?.profilePicUrl} alt="contact_image" />}
				title={`${contact?.name || '(sem contato)'} #${ticket.id}`}
				subheader={
					ticket.user &&
					`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
				}

			/>
		);
	}

	return (
		<React.Fragment>
			{infoButton ? <Grid container justifyContent="flex-start" alignItems="center"
				style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
				<Grid item>{infoButton}</Grid>
				<Grid item>{renderCardReader()}</Grid>
			</Grid> : renderCardReader()}
		</React.Fragment>
	);
};

export default TicketInfo;
