import React, { useState, useContext } from "react";

import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

import { i18n } from "../../translate/i18n";

import ContactModal from "../ContactModal";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import { ListNotes } from "../ListNotes";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor:  theme.palette.darkBackground,
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: theme.palette.darkBackground,
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStylesSoftBig,
	},

	contactAvatar: {
		margin: 15,
		width: 160,
		height: 160,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
}));

const ContactDrawer = ({ open, handleDrawerClose, contact, loading, ticketId, groupInfo }) => {
	const classes = useStyles();
	const { user } = useContext(AuthContext);

	const [modalOpen, setModalOpen] = useState(false);

	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.header}>
				<IconButton onClick={handleDrawerClose} size="large">
					<CloseIcon />
				</IconButton>
				<Typography style={{ justifySelf: "center" }}>
					{i18n.t("contactDrawer.header")}
				</Typography>
			</div>
			{loading ? (
				<ContactDrawerSkeleton classes={classes} />
			) : (
				<div className={classes.content}>
					<Paper square variant="outlined" className={classes.contactHeader}>
						<Avatar
							alt={contact.name}
							src={contact.profilePicUrl}
							className={classes.contactAvatar}
						></Avatar>

						<Typography>{contact.name}</Typography>
						<Can
							role={user.roleId}
							perform="contacts:list:any"
							yes={() => (
								<Typography>
									<Link href={`tel:${contact.number}`}>{contact.number}</Link>
								</Typography>
							)}
							no={() => <></>}
						/>

						{groupInfo && groupInfo.desc && 
						<Typography>{groupInfo.desc}</Typography>
						}
						<Can
							role={user.roleId}
							perform="contacts:edit:any"
							yes={() => (
								<Button
									variant="outlined"
									color="primary"
									onClick={() => setModalOpen(true)}
								>
									{i18n.t("contactDrawer.buttons.edit")}
								</Button>)}
							no={() => <></>}
						/>
					</Paper>
					<Can
						role={user.roleId}
						perform="contacts:list:any"
						yes={() => (
							<Paper square variant="outlined" className={classes.contactDetails}>
								<ContactModal
									open={modalOpen}
									onClose={() => setModalOpen(false)}
									contactId={contact.id}
								></ContactModal>
								<Typography variant="subtitle1">
									{i18n.t("contactDrawer.extraInfo")}
								</Typography>
								{contact?.extraInfo?.map(info => (
									<Paper
										key={info.id}
										square
										variant="outlined"
										className={classes.contactExtraInfo}
									>
										<InputLabel>{info.name}</InputLabel>
										<Typography component="div" noWrap style={{ paddingTop: 2 }}>
											<MarkdownWrapper>{info.value}</MarkdownWrapper>
										</Typography>
									</Paper>

								))}
							</Paper>)}
						no={() => <></>}
					/>


					<Paper square variant="outlined" className={classes.contactDetails}>

						<Typography variant="subtitle1">
							Notas do Atendimento
						</Typography>
						<ListNotes ticketId={ticketId} />
					</Paper>
				</div>
			)}
		</Drawer>
	);
};

export default ContactDrawer;