import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const UserRoleSelect = ({ selectedUserRoleId, userRoleId, onChange }) => {
	const classes = useStyles();
	const [roles, setRoles] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/roles");
				//console.log(data)
				setRoles(data.roles);
			} catch (err) {
				toastError(err);
			}
		})();
	}, []);

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<div style={{ marginTop: 2, width: '100%' }}>
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel>{i18n.t("roleSelect.inputLabel")}</InputLabel>
				<Select
					value={selectedUserRoleId}
					onChange={handleChange}
					defaultValue={userRoleId}
					size="small"
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						}
					}}
					
				>
					{roles.map(role => (
						<MenuItem key={role.id} value={role.id}>
							{role.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default UserRoleSelect;
