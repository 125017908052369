import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import { Menu, MenuItem, IconButton, MenuList, Divider, gridClasses } from "@mui/material";
import {
    MoreVert, Replay, Repeat, DeleteForever, DoneAll, Pause, PlayArrow,
    VolumeUp, VolumeOff, Fullscreen, SensorWindow, AspectRatio
} from "@mui/icons-material";
import SettingsIcon from '@mui/icons-material/Settings';
import TransferTicketModal from "../TransferTicketModal";
import SearchTicketModal from "../SearchTicketModal";
import ConfirmationModal from "../ConfirmationModal";
import { IoExitOutline } from "react-icons/io5";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import ResolveTicketModal from "../MessagesList/ResolveTicketModal";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { MutedItemsContext } from '../../context/Tickets/MutedItems';
import ChatSettingsModal from "../ChatSettingsModal";
import PromptResumeTicketModal from "../PromptResumeTicketModal"
import { FaPlus, FaSearch, FaUsers } from "react-icons/fa";
import { useTicketCounts } from '../../context/Tickets/Count';
import GroupLeaveModal from "../GroupLeaveModal";
import GroupSettingsModal from "../GroupSettingsModal";
import GroupChartModal from "../GroupChartModal";
import GroupAddContacts from "../GroupAddContacts";
import GroupListContacts from "../GroupListContacts";

const useStyles = makeStyles(theme => ({
    actionButtons: {
        marginRight: 6,
        //flex: "none",
        display: 'flex',
        flexWrap: 'wrap',
        justifyItems: 'flex-end',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
            width: '100%',
            padding: '0px 5px'
        },
        alignSelf: "center",
        alignItems: 'center',
        marginLeft: "auto",
        "& > *": {
            margin: theme.spacing(0),
        },
    },
    iconButton: {
        fontSize: 16,
        transition: 'all ease 0.5s'
    },
    buttonsTicket: {
        height: 30,
        borderRadius: '5px!important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        fontSize: 9,
        fontWeight: 'bold',
        paddingLeft: 8,
        paddingRight: 8,
        boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.07) !important',
        '&:hover': {
            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2) !important',
        },
    },
    iconButtonBigger: {
        color: theme.palette.text.main
    },
    smallGroups: {
        padding: '0px 15px',
        color: theme.palette.text.main,
        borderRadius: 5,
        background: theme.palette.fancyBackground
    }
}));

const TicketActionButtons = ({ ticket, handleClose, handleFullscreen, fullscreen, integrationIframes, onLoadIframe, handleAIMessage }) => {
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
    const [searchTicketModalOpen, setSearchTicketModalOpen] = useState(false);
    const [settingChatModalOpen, setSettingsChatModalOpen] = useState(false);
    const [resolveTicketModalOpen, setResolveTicketModalOpen] = useState(false);
    const [resumeTicketModal, setResumeTicketModal] = useState(false);
    const isMounted = useRef(true);
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { user, permissions } = useContext(AuthContext);
    const { getSettingValue } = useContext(SettingsContext);
    const { mutedItems, muteTicket } = useContext(MutedItemsContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const keyOpenAi = getSettingValue('keyOpenAi');
    const { handleUpdateCounts } = useTicketCounts();

    const [addContactsGroup, setAddContactsGroupModal] = useState(false);
    const [listContactsGroup, setListContactsGroupModal] = useState(false);
    const [settingsGroup, setSettingsGroupModal] = useState(false);
    const [statsGroup, setStatsGroupModal] = useState(false);
    const [exitGroup, setExitGroupModal] = useState(false);


    const [infoTicketClosed, setInfoTicketClosed] = useState(null);
    const [motive, setMotive] = useState('');
    const [amount, setAmount] = useState(null);
    const [notes, setNotes] = useState('');

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        console.log('infoTicket', ticket)
    }, [ticket])


    const handleOpenTransferModal = e => {
        setTransferTicketModalOpen(true);
        if (typeof (handleClose) == "function") handleClose();
    };


    const handleOpenSearchModal = e => {
        setSearchTicketModalOpen(true);
        if (typeof (handleClose) == "function") handleClose();
    };

    const handleOpenConfirmationModal = e => {
        setConfirmationOpen(true);
        if (typeof (handleClose) == "function") handleClose();
    };

    const handleCloseTransferTicketModal = () => {
        if (isMounted.current) {
            setTransferTicketModalOpen(false);
        }
    };

    const handleOpenTicketOptionsMenu = e => {
        //setAnchorEl(e.currentTarget);
    };

    const handleCloseTicketOptionsMenu = e => {
        setAnchorEl(null);
    };

    const handleDeleteTicket = async () => {
        try {
            await api.delete(`/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleResolveTicket = (e) => {
        if (user.roleId !== 1 && permissions.some((permission) => permission.key === 'tickets:required:qualify')) {
            if (!motive) {
                toast.error("Motivo é requerido para finalizar o atendimento");
                return;
            }
        }
        handleUpdateTicketStatus(e, ticket.status, "closed", user?.id);
    }

    const handleResolveTicketFeedback = (e) => {
        if (user.roleId !== 1 && permissions.some((permission) => permission.key === 'tickets:required:qualify')) {
            if (!motive) {
                toast.error("Motivo é requerido para finalizar o atendimento");
                return;
            }
        }
        handleUpdateTicketStatus(e, ticket.status, "feedback", user?.id);
    }

    const handleResolveTicketNoMessage = (e) => {
        if (permissions.some((permission) => permission.key === 'tickets:required:qualify')) {
            if (!motive) {
                toast.error("Motivo é requerido para finalizar o atendimento");
                return;
            }
        }
        handleUpdateTicketStatus(e, ticket.status, "closed", user?.id, true);
    }

    const handleSettingsChat = () => {
        setSettingsChatModalOpen(true);
        if (typeof (handleClose) == "function") handleClose();
    }


    const handleIframeClick = (iframe) => {
        setMenuOpen(false);
        if (onLoadIframe) {
            onLoadIframe(iframe);
        }
    }

    const handleUpdateTicketStatus = async (e, oldStatus, status, userId, closeNow) => {
        setLoading(true);

        let infoTicket = {
            reason: motive,
            amount: amount,
            notes: notes
        }

        try {
            await api.put(`/tickets/${ticket.id}`, {
                status: status,
                userId: userId || null,
                closeNow,
                infoTicket
            });
            setLoading(false);
            if (status === "open") {
                history.push('/tickets');
                setTimeout(() => {
                    history.push(`/tickets/${ticket.id}`);
                }, 0);
            }
            else {
                history.push("/tickets");
            }
        } catch (err) {
            setLoading(false);
            toastError(err)
        }
    };


    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleResumeTicket = () => {
        setResumeTicketModal(true);

    }

    const handleCloseResumeTicket = () => {
        setResumeTicketModal(false);
    }

    const handleCloseSearchTicketModal = () => {
        setSearchTicketModalOpen(false);
    }

    const handleAddContactsGroup = () => {
        setAddContactsGroupModal(true);
    }

    const handleListContactsGroup = () => {
        setListContactsGroupModal(true);
    }

    const handleCloseAddContactsGroup = () => {
        setAddContactsGroupModal(false);
    }

    const handleCloseListContactsGroup = () => {
        setListContactsGroupModal(false);
    }

    const handleSettingsGroup = () => {
        setSettingsGroupModal(true);
    }

    const handleCloseSettingsGroup = () => {
        setSettingsGroupModal(false);
    }

    const handleStatsGroup = () => {
        setStatsGroupModal(true);
    }

    const handleCloseStatsGroup = () => {
        setStatsGroupModal(false);
    }

    const handleExitGroup = () => {
        setExitGroupModal(true);

    }

    const handleCloseExitGroup = () => {
        setExitGroupModal(false);
    }


    return (
        <div className={classes.actionButtons}>
            <ButtonWithSpinner onClick={(e) => muteTicket(ticket.id)} width={30} className={`${classes.buttonsTicket} no-mobile`} style={{ minWidth: 30, width: 30, marginRight: 5 }}>
                {mutedItems && mutedItems.includes(ticket.id) ? <VolumeOff className={classes.iconButtonBigger} /> : <VolumeUp className={classes.iconButtonBigger} />}
            </ButtonWithSpinner>
            {(ticket.status === "closed" || ticket.status === "feedback") && (
                <ButtonWithSpinner
                    style={{ backgroundColor: 'black', color: 'white', margin: '2px', borderRadius: '0px' }}
                    loading={loading}
                    className={`${classes.buttonsTicket} no-mobile`}
                    size="small"
                    variant="contained"
                    onClick={e => handleUpdateTicketStatus(e, ticket.status, "open", user?.id)}
                >
                    <Replay /> {i18n.t("messagesList.header.buttons.reopen")}
                </ButtonWithSpinner>
            )}


            {(ticket.status === "open" || ticket.status === "group") && (
                <>
                    <Can
                        role={user.roleId}
                        perform="tickets:return:any"
                        yes={() => (

                            ticket.status === "open" && <ButtonWithSpinner
                                style={{ backgroundColor: 'black', color: 'white', margin: '2px', borderRadius: '0px' }}
                                loading={loading}
                                className={`${classes.buttonsTicket} no-mobile`}
                                size="small"
                                variant="contained"
                                onClick={e => handleUpdateTicketStatus(e, ticket.status, "pending", null)}
                            >
                                <Replay className={classes.iconButton} />  {i18n.t("messagesList.header.buttons.return")}
                            </ButtonWithSpinner>
                        )}
                        no={() => <></>}
                    />
                    <Can
                        role={user.roleId}
                        perform="tickets:pause:any"
                        yes={() => (
                            <ButtonWithSpinner
                                style={{ backgroundColor: 'white', color: 'black', margin: '2px', borderRadius: '0px' }}
                                loading={loading}
                                className={`${classes.buttonsTicket} no-mobile`}
                                size="small"
                                variant="contained"
                                onClick={e => handleUpdateTicketStatus(e, ticket.status, "paused", ticket.isGroup === true ? ticket.contact?.id : user?.id)}
                            >
                                <Pause className={classes.iconButton} /> {i18n.t("messagesList.header.buttons.pause")}
                            </ButtonWithSpinner>
                        )}
                        no={() => <></>}
                    />
                    <Can
                        role={user.roleId}
                        perform="tickets:finalize:any"
                        yes={() => (
                            <ButtonWithSpinner
                                style={{ backgroundColor: '#2ECC71', color: '#000000', margin: '2px', borderRadius: '0px' }}
                                loading={loading}
                                className={`${classes.buttonsTicket} no-mobile`}
                                size="small"
                                variant="contained"
                                onClick={e => setResolveTicketModalOpen(true)}
                            >
                                <DoneAll className={classes.iconButton} />  {i18n.t("messagesList.header.buttons.resolve")}
                            </ButtonWithSpinner>
                        )}
                        no={() => <></>}
                    />
                    {ticket.status !== 'group' && (
                        <Can
                            role={user.roleId}
                            perform="tickets:transfer:any"
                            yes={() => (
                                <ButtonWithSpinner
                                    style={{ backgroundColor: '#F1C40F', color: 'black', margin: '2px', borderRadius: '0px' }}
                                    loading={loading}
                                    className={`${classes.buttonsTicket} no-mobile`}
                                    size="small"
                                    variant="contained"
                                    onClick={handleOpenTransferModal}
                                >
                                    <Repeat className={classes.iconButton} /> Transferir
                                </ButtonWithSpinner>
                            )}
                            no={() => <></>}
                        />
                    )}

                    {/*ticket.status === 'group' && (
                        <Can
                            role={user.roleId}
                            perform="tickets:transfer:groups:any"
                            yes={() => (
                                <ButtonWithSpinner
                                    style={{ backgroundColor: '#F1C40F', color: 'black', margin: '2px', borderRadius: '0px' }}
                                    loading={loading}
                                    className={classes.buttonsTicket}
                                    size="small"
                                    variant="contained"
                                    onClick={handleOpenTransferModal}
                                >
                                    <Repeat className={classes.iconButton} /> Transferir
                                </ButtonWithSpinner>
                            )}
                            no={() => <></>}
                            />)*/}
                    {getSettingValue('canDeleteTickets') === 'enabled' && (
                        <Can
                            role={user.roleId}
                            perform="tickets:delete:any"
                            yes={() => (
                                <ButtonWithSpinner
                                    style={{ backgroundColor: '#E74C3C', color: '#ffffff', margin: '2px', borderRadius: '0px' }}
                                    loading={loading}
                                    className={`${classes.buttonsTicket} no-mobile`}
                                    size="small"
                                    variant="contained"
                                    onClick={handleOpenConfirmationModal}
                                >
                                    <DeleteForever className={classes.iconButton} /> Excluir
                                </ButtonWithSpinner>
                            )}
                            no={() => (<></>)}
                        />)}


                    <ButtonWithSpinner className={`${classes.buttonsTicket} no-mobile`} onClick={handleOpenSearchModal} width={30} style={{ minWidth: 30, width: 30 }}>
                        <FaSearch size={16} className={classes.iconButtonBigger} />
                    </ButtonWithSpinner>
                    <ConfirmationModal
                        title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")} #${ticket.id
                            }?`}
                        open={confirmationOpen}
                        onClose={setConfirmationOpen}
                        onConfirm={handleDeleteTicket}
                    >
                        {i18n.t("ticketOptionsMenu.confirmationModal.message")}
                    </ConfirmationModal>
                    {(ticket.status === "open" || ticket.status === "group") &&
                        <TransferTicketModal
                            modalOpen={transferTicketModalOpen}
                            onClose={handleCloseTransferTicketModal}
                            ticket={ticket}
                        />}
                    {resumeTicketModal &&
                        <PromptResumeTicketModal
                            open={resumeTicketModal}
                            onClose={handleCloseResumeTicket}
                            ticket={ticket}
                            handleAIMessage={handleAIMessage}
                        />}
                    {searchTicketModalOpen &&
                        <SearchTicketModal
                            modalOpen={searchTicketModalOpen}
                            onClose={handleCloseSearchTicketModal}
                            ticket={ticket}
                        />}

                    <ChatSettingsModal
                        open={settingChatModalOpen}
                        onClose={() => setSettingsChatModalOpen(false)}
                        onAccept={handleResolveTicket}
                        onAcceptFeedback={handleResolveTicketFeedback}
                        onAcceptNoEndMessage={handleResolveTicketNoMessage}
                        ticket={ticket}
                    />
                    <ResolveTicketModal
                        open={resolveTicketModalOpen}
                        onClose={() => setResolveTicketModalOpen(false)}
                        onAccept={handleResolveTicket}
                        onAcceptFeedback={handleResolveTicketFeedback}
                        onAcceptNoEndMessage={handleResolveTicketNoMessage}
                        ticket={ticket}
                        motive={motive}
                        amount={amount}
                        notes={notes}
                        setMotive={setMotive}
                        setAmount={setAmount}
                        setNotes={setNotes}
                    />
                    {ticket.status === 'group' && (
                        <>
                            {addContactsGroup &&
                                <GroupAddContacts
                                    modalOpen={addContactsGroup}
                                    onClose={handleCloseAddContactsGroup}
                                    ticket={ticket}
                                />}
                            {listContactsGroup &&
                                <GroupListContacts
                                    modalOpen={listContactsGroup}
                                    onClose={handleCloseListContactsGroup}
                                    ticket={ticket}
                                />}

                            {statsGroup &&
                                <GroupChartModal
                                    open={statsGroup}
                                    onClose={handleCloseStatsGroup}
                                    ticket={ticket}
                                />}

                            {exitGroup &&
                                <GroupLeaveModal
                                    open={exitGroup}
                                    onClose={handleCloseExitGroup}
                                    ticket={ticket}
                                />}

                            {settingsGroup &&
                                <GroupSettingsModal
                                    ticket={ticket}
                                    open={settingsGroup}
                                    onClose={handleCloseSettingsGroup}
                                />}
                        </>)}
                </>

            )}


            {ticket.status === "paused" && (
                <ButtonWithSpinner
                    style={{ backgroundColor: '#2ECC71', color: '#000000', margin: '2px', borderRadius: '0px' }}
                    loading={loading}
                    className={classes.buttonsTicket}
                    size="small"
                    variant="contained"
                    onClick={e => handleUpdateTicketStatus(e, ticket.status, "open", user?.id)}
                >
                    <PlayArrow className={classes.iconButton} /> {i18n.t("messagesList.header.buttons.unpause")}
                </ButtonWithSpinner>
            )}
            <Can
                role={user.roleId}
                perform="tickets:accept:any"
                yes={() => (
                    (ticket.status === "pending" || ticket.status === "bot") && (
                        <ButtonWithSpinner
                            style={{ backgroundColor: 'black', color: 'white', margin: '2px', borderRadius: '0px', paddingHorizontal: 2 }}
                            loading={loading}
                            className={classes.buttonsTicket}
                            size="small"
                            variant="contained"
                            onClick={e => handleUpdateTicketStatus(e, ticket.status, "open", user?.id)}
                        >
                            {i18n.t("messagesList.header.buttons.accept")}
                        </ButtonWithSpinner>
                    )
                )}
                no={() => (<></>)}
            />

            {fullscreen &&
                <ButtonWithSpinner
                    style={{ margin: '2px', paddingHorizontal: 2 }}
                    onClick={handleFullscreen} className={classes.buttonsTicket}>
                    <Fullscreen style={{ fontSize: 16, paddingRight: 5 }} /> Sair Tela cheia
                </ButtonWithSpinner>}
            <div>

                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    className="vertMenuButtons"
                    color="inherit"
                    style={{ paddingTop: 10, width: 30, height: 30 }}
                    size="large">
                    <MoreVert style={{ fontSize: 16, padding: 0 }} />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={menuOpen}
                    onClose={handleCloseMenu}
                    style={{zIndex: 99999}}
                >
                    {/**from buttons */}
                    <MenuItem className="no-desktop" onClick={(e) => muteTicket(ticket.id)}>
                        {mutedItems && mutedItems.includes(ticket.id) ?
                            <><VolumeUp style={{ fontSize: 16, paddingRight: 5 }} /> Ativar Som</> :
                            <><VolumeOff style={{ fontSize: 16, paddingRight: 5 }} /> Desativar Som</>}
                    </MenuItem>

                    {(ticket.status === "closed" || ticket.status === "feedback") && (
                        <MenuItem className="no-desktop" onClick={(e) => handleUpdateTicketStatus(e, ticket.status, "open", user?.id)}>
                            <Replay /> {i18n.t("messagesList.header.buttons.reopen")}
                        </MenuItem>
                    )}

                    {(ticket.status === "open" || ticket.status === "group") && (
                        <>
                            <Can
                                role={user.roleId}
                                perform="tickets:return:any"
                                yes={() => (
                                    ticket.status === "open" && (
                                        <MenuItem className="no-desktop" onClick={(e) => handleUpdateTicketStatus(e, ticket.status, "pending", null)}>
                                            <Replay style={{ fontSize: 16, paddingRight: 5 }} /> {i18n.t("messagesList.header.buttons.return")}
                                        </MenuItem>
                                    )
                                )}
                                no={() => null}
                            />
                            <Can
                                role={user.roleId}
                                perform="tickets:pause:any"
                                yes={() => (
                                    <MenuItem className="no-desktop" onClick={(e) => handleUpdateTicketStatus(e, ticket.status, "paused", ticket.isGroup === true ? ticket.contact?.id : user?.id)}>
                                        <Pause style={{ fontSize: 16, paddingRight: 5 }} />{i18n.t("messagesList.header.buttons.pause")}
                                    </MenuItem>
                                )}
                                no={() => null}
                            />
                            <Can
                                role={user.roleId}
                                perform="tickets:finalize:any"
                                yes={() => (
                                    <MenuItem className="no-desktop" onClick={(e) => setResolveTicketModalOpen(true)}>
                                        <DoneAll style={{ fontSize: 16, paddingRight: 5 }} /> {i18n.t("messagesList.header.buttons.resolve")}
                                    </MenuItem>
                                )}
                                no={() => null}
                            />
                            <Can
                                role={user.roleId}
                                perform="tickets:transfer:any"
                                yes={() => (
                                    ticket.status === "open" && (
                                        <MenuItem className="no-desktop" onClick={handleOpenTransferModal}>
                                            <Repeat style={{ fontSize: 16, paddingRight: 5 }} />  Transferir
                                        </MenuItem>
                                    )
                                )}
                                no={() => null}
                            />
                            {getSettingValue('canDeleteTickets') === 'enabled' && (
                                <MenuItem className="no-desktop" onClick={handleOpenConfirmationModal}>
                                    <DeleteForever style={{ fontSize: 16, paddingRight: 5 }} />  Excluir
                                </MenuItem>
                            )}
                        </>
                    )}

                    {ticket.status === "paused" && (
                        <MenuItem className="no-desktop" onClick={(e) => handleUpdateTicketStatus(e, ticket.status, "open", user?.id)}>
                            {i18n.t("messagesList.header.buttons.unpause")}
                        </MenuItem>
                    )}

                    <Can
                        role={user.roleId}
                        perform="tickets:accept:any"
                        yes={() => (
                            (ticket.status === "pending" || ticket.status === "bot") && (
                                <MenuItem className="no-desktop" onClick={(e) => handleUpdateTicketStatus(e, ticket.status, "open", user?.id)}>
                                    {i18n.t("messagesList.header.buttons.accept")}
                                </MenuItem>
                            )
                        )}
                        no={() => null}
                    />

                    <MenuItem className="no-desktop" onClick={handleOpenSearchModal}>
                        <FaSearch style={{ fontSize: 16, paddingRight: 5 }} />
                        Buscar
                    </MenuItem>
                    {/**from buttons */}

                    {ticket.status !== 'group' && (keyOpenAi || user.roleId === 1) && (
                        <MenuItem onClick={handleResumeTicket}>
                            <Can
                                role={user.roleId}
                                perform="tickets:resume:any"
                                yes={() => (
                                    <>
                                        <SensorWindow style={{ fontSize: 16, paddingRight: 5 }} /> Resumir Atendimento
                                    </>
                                )}
                                no={() => <></>}
                            />
                        </MenuItem>)}
                    <MenuItem onClick={handleFullscreen}>
                        <Fullscreen style={{ fontSize: 16, paddingRight: 5 }} /> Fullscreen
                    </MenuItem>
                    <MenuItem onClick={handleSettingsChat}>
                        <SettingsIcon style={{ fontSize: 16, paddingRight: 5 }} /> Configurações do Chat
                    </MenuItem>
                    {integrationIframes && integrationIframes.length > 0 && integrationIframes.map((iframe) => (
                        <MenuItem key={iframe.id} onClick={() => handleIframeClick(iframe)}>
                            <AspectRatio style={{ fontSize: 16, paddingRight: 5 }} /> {iframe.name}
                        </MenuItem>
                    ))}
                    {ticket.status === 'group' && (
                        <MenuList>
                            <Divider />
                            <MenuItem>
                                <hr style={{ margin: 0 }} />
                                <small className={classes.smallGroups} >GRUPOS</small>
                            </MenuItem>
                            {ticket.groupOwner &&
                                <Can
                                    role={user.roleId}
                                    perform="tickets:groups:contacts:add"
                                    yes={() => (
                                        <MenuItem onClick={handleAddContactsGroup}>
                                            <FaPlus style={{ fontSize: 18, paddingRight: 5 }} /> Adicionar contatos
                                        </MenuItem>)}
                                    no={() => <></>}
                                />
                            }
                            <Can
                                role={user.roleId}
                                perform="tickets:groups:participants"
                                yes={() => (
                                    <MenuItem onClick={handleListContactsGroup}>
                                        <FaUsers style={{ fontSize: 18, paddingRight: 5 }} /> Ver Participantes
                                    </MenuItem>)}
                                no={() => <></>}
                            />

                            {ticket.groupOwner &&
                                <Can
                                    role={user.roleId}
                                    perform="tickets:groups:settings"
                                    yes={() => (
                                        <MenuItem onClick={handleSettingsGroup}>
                                            <SettingsIcon style={{ fontSize: 18, paddingRight: 5 }} /> Configurações do Grupo
                                        </MenuItem>)}
                                    no={() => <></>}
                                />}

                            {(keyOpenAi || user.roleId === 1) && (
                                <Can
                                    role={user.roleId}
                                    perform="tickets:groups:resume"
                                    yes={() => (
                                        <MenuItem onClick={handleResumeTicket}>
                                            <SensorWindow style={{ fontSize: 18, paddingRight: 5 }} /> Resumir Grupo
                                        </MenuItem>)}
                                    no={() => <></>}
                                />
                            )}

                            {/*ticket.groupOwner && <MenuItem onClick={handleStatsGroup}>
                                <SettingsIcon style={{ fontSize: 18, paddingRight: 5 }} /> Relatório do Grupo
                                        </MenuItem>*/}
                            <Can
                                role={user.roleId}
                                perform="tickets:groups:unfollow"
                                yes={() => (
                                    <MenuItem onClick={handleExitGroup}>
                                        <IoExitOutline style={{ fontSize: 18, paddingRight: 5 }} /> Sair do Grupo
                                    </MenuItem>)}
                                no={() => <></>}
                            />
                        </MenuList>
                    )}

                </Menu>
            </div>

        </div>
    );
};

export default TicketActionButtons;
