import { Button, IconButton, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddCircle, Delete, Description, Edit, FileCopy, RemoveRedEye, Save } from "@mui/icons-material";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import UserRolesEdit from "./edit";
import ConfirmationModal from "../../components/ConfirmationModal";
import makeStyles from '@mui/styles/makeStyles';
import { i18n } from "../../translate/i18n.js";

const useStyles = makeStyles(theme => ({
    addContainer: {
        marginTop: 10,
        borderRadius: 10,
        padding: 10,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        '& h6': {
            marginBottom: 20
        }
    },
    docsLink: {
        fontSize: 12
    }
}));

const UserRoles = () => {
    const [roles, setRoles] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [editId, setEditId] = useState(0);
    const [reload, setReload] = useState(true);
    const [showDelete, setShowDelete] = useState(0);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchRoles = async () => {
                try {
                    const { data } = await api.get("/roles/");
                    setRoles(data.roles);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchRoles();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [reload]);

    const handleDelete = (roleId) => {
        (async() => {
            try {
                const { data } = await api.delete(`/roles/${roleId}`);
                setReload(!reload);
            } catch (err) {
                toastError(err);
            }
        })();
    }

    const handleEdit = (roleId) => {
        setShowEdit(true);
        setEditId(roleId);
    }

    const handleCopy = (roleId) => {
        
    }

    const onSaveRole = (role) => {
        setShowEdit(false);
        setReload(!reload);
    }

    return (
        <div>
            {showEdit ? <div><Button onClick={() => setShowEdit(false)}>{i18n.t("settings.settings.permissions.back")}</Button><UserRolesEdit roleId={editId} onSave={onSaveRole} /></div> : 
            <div>
                <ConfirmationModal 
                    title={i18n.t("settings.settings.permissions.confirmDelete")} 
                    onConfirm={() => handleDelete(showDelete)}
                    onClose={() => setShowDelete(0)}
                    open={showDelete}>
                        {i18n.t("settings.settings.permissions.confirmDeleteSpan")}
                </ConfirmationModal>
                <Button onClick={() => {setEditId(null);setShowEdit(true)}}>
                    <AddCircle /> {i18n.t("settings.settings.permissions.add")}
                </Button>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>{i18n.t("settings.settings.permissions.name")}</TableCell>
                            <TableCell>{i18n.t("settings.settings.permissions.userNumbers")}</TableCell>
                            <TableCell>{i18n.t("settings.settings.permissions.actions")}</TableCell>
                        </TableRow>
                        {roles && roles.map(role => (
                            <TableRow key={role.id}>
                                <TableCell>{role.name}</TableCell>
                                <TableCell>{role.users_count}</TableCell>
                                <TableCell style={{overflowWrap: "anywhere"}}>
                                    {!role.isAdmin && <IconButton size="small" onClick={() => {handleEdit(role.id)}}><Edit /></IconButton>}
                                    {!role.isAdmin && <><IconButton size="small" onClick={() => {handleCopy(role.id)}}><FileCopy /></IconButton>
                                    <IconButton size="small" onClick={() => {setShowDelete(role.id)}}><Delete /></IconButton></>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>}
        </div>
    );
}

export default UserRoles;