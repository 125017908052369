import { makeStyles } from "@mui/styles";
import { Block } from "@mui/icons-material";
import React from "react";

const useStyles = makeStyles({
    deletedIcon: {
        fontSize: 18,
        verticalAlign: "middle",
        marginRight: 4,
    },
    unsupportedMessage: {
        fontStyle: "italic",
        color: "#AAA"
    }
});

const MessageUnsupported = ({ message }) => {
    const classes = useStyles();

    return <span className={classes.unsupportedMessage}>
    <Block
        color="disabled"
        fontSize="small"
        className={classes.deletedIcon}
    />
     Esse tipo de mensagem {message.mediaType === 'poll' && "(mensagem de votação)"} não é suportada ou não foi possível processá-la. Verifique o seu conteúdo diretamente no Whatsapp.
</span>
}

export default MessageUnsupported;