import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { PiChatsBold } from "react-icons/pi";
import { IconButton } from "@mui/material";
import { SettingsContext } from "../../context/Settings/SettingsContext";

const NotificationMenuButton = ({ }) => {
    const { info } = useContext(SettingsContext);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (info) {
            setShow(info.showNotificationsIcon);
        }
    }, [info]);

    return show ? <RouterLink to="/notifications" style={{background: '#fff !important'}}>
        <IconButton edge="start" size="large" style={{background: 'transparent'}}>
            <PiChatsBold />
        </IconButton>
    </RouterLink> : <></>;
}

export default NotificationMenuButton;