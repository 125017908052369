import { useState } from 'react';

function useCountPaused() {
  const [countPaused, setCountPaused] = useState(0);


    const handleAddCountPaused = async () => {
      setCountPaused(1);
    }


  return { countPaused, setCountPaused, handleAddCountPaused };
}

export default useCountPaused;
