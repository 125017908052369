import React from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";

const SettingVoiceSelectionField = ({ name, settings, label, helpText, userSettings, userId }) => {
    let debounce;
    const playSound = (sound) => {
        const audio = new Audio(`/voices/${sound}.mp3`);
        audio.play();
    }



    const handlePlaySound = (e, sound) => {
        e.stopPropagation();
        e.preventDefault();
        playSound(sound);
    }

    const handleChangeSetting = async e => {
        if (debounce) {
            clearTimeout(debounce);
        }
        const selectedValue = e.target.value;

        debounce = setTimeout(async () => {
            try {

                if (!userSettings) {
                    await api.put(`/settings/${name}`, {
                        value: selectedValue
                    });
                } else {				
                    await api.put(`/user/${userId}/settings/${name}`, {
                        value: selectedValue,
                        settings: settings
                    });
                }
                toast.success(i18n.t("settings.success"));
            } catch (err) {
                toastError(err);
            }
        }, 500);
    }

    const getSettingValue = key => {
      
        if (!userSettings) {
            const { value } = settings && settings.length > 0 && settings.find(s => s.key === key);
            return value;
        } else {
            const value  = settings && settings.length > 0 && settings.voiceSound;
            return value;
        }
    
    };

    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select placeholder={label} value={getSettingValue(name)} onChange={handleChangeSetting}>
                <MenuItem value="nova"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'nova') }}><PlayArrow /></IconButton> Jéssica</MenuItem>
                <MenuItem value="shimmer"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'shimmer') }}><PlayArrow /></IconButton> Adriana</MenuItem>
                <MenuItem value="onyx"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'onyx') }}><PlayArrow /></IconButton> Matheus</MenuItem>
                <MenuItem value="fable"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'fable') }}><PlayArrow /></IconButton> Suzanne</MenuItem>
                <MenuItem value="echo"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'echo') }}><PlayArrow /></IconButton> Jonas</MenuItem>
                <MenuItem value="alloy"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'alloy') }}><PlayArrow /></IconButton> Maria</MenuItem>
            </Select>
        </FormControl>
    )
}

export default SettingVoiceSelectionField; 