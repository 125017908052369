import React, { useContext, useEffect } from "react";
import { Route as RouterRoute, Redirect, useLocation } from "react-router-dom";

import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { SettingsContext } from "../context/Settings/SettingsContext";


const Route = ({ component: Component, checkSetting, onlyAdmin, isPrivate = false, ...rest }) => {
	const { isAuth, loading, nextTimeOff,setNextTimeOff, handleLogout } = useContext(AuthContext);
	const { user } = useContext(AuthContext);
	const { isActive } = useContext(SettingsContext);
	let location = useLocation();

	useEffect(() => {
	//	console.log('nextTime (timestamp):', nextTimeOff);
		if (nextTimeOff) {
			let nextTimeDate;
	
			if (typeof nextTimeOff === 'string') {
				const [hours, minutes] = nextTimeOff.split(':').map(Number);
				const now = new Date();
				nextTimeDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
			} else {
				nextTimeDate = new Date(nextTimeOff);
			}
	
			const formattedTime = nextTimeDate.toLocaleTimeString('pt-BR', {
				hour: '2-digit',
				minute: '2-digit',
			});
		//	console.log('nextTime (formatted):', formattedTime);
	
			const currentTime = Date.now();
			if (currentTime >= nextTimeDate.getTime()) {
				setNextTimeOff(null);
				handleLogout();
			}
		}
	}, [location]);
	
	

	if (checkSetting && user.profile !== "admin" && !isActive(checkSetting)) {
		return (<></>);
	}

	if (onlyAdmin && user.profile !== "admin") {
		return (<></>);
	}

	if (!isAuth && isPrivate) {
		return (
			<>
				{loading && <BackdropLoading />}
				<Redirect to={{ pathname: "/login", state: { from: rest.location } }} />
			</>
		);
	}

	if (isAuth && !isPrivate) {
		return (
			<>
				{loading && <BackdropLoading />}
				<Redirect to={{ pathname: "/tickets", state: { from: rest.location } }} />;
			</>
		);
	}

	return (
		<>
			{loading && <BackdropLoading />}
			<RouterRoute {...rest} component={Component} />
		</>
	);
};

export default Route;
