import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";

const toastError = (err, interpolationObject = null) => {
    const errorMsg = err.response?.data?.error;
    if (errorMsg) {
        if (i18n.exists(`backendErrors.${errorMsg}`)) {
            if (!interpolationObject) {
                const errorData = err.response?.data?.errorData;
                interpolationObject = { errorData };
            }

            toast.error(i18n.t(`backendErrors.${errorMsg}`, interpolationObject), {
                toastId: errorMsg,
            });
        } else {
            toast.error(errorMsg, {
                toastId: errorMsg,
            });
        }
    } else {
        toast.error("Erro de Rede! Por gentileza recarregue a página.");
        console.log(err);
    }
};

export default toastError;
