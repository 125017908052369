import { Button, Dialog, DialogTitle, FormControlLabel, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import IOSSwitch from "../IOSSwitch";

const useStyles = makeStyles(theme => ({
	body: {
        padding: theme.spacing(2),
    },
    inputContainer: {
        padding: theme.spacing(2),
        marginBottom: 10,
        '& label': {
            fontSize: 14,
            fontWeight: 'bold',
            marginBottom: 5,
        },
        '& p': {
            fontSize: 12,
            fontWeight: 'normal',
            color: '#CCC',
            marginBottom:10
        }
    },
    buttonContainer: {
        padding: theme.spacing(2)
    },
    toggleContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

const ShortcutMessageImportModal = ({open, onClose}) => {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [sameNameStrategy, setSameNameStrategy] = useState('duplicate');

    const handleOnClose = () => {
        if (onClose) {
            onClose();
        }
    }

    const handleChangeSameNameStrategy = () => {
        setSameNameStrategy(sameNameStrategy === 'duplicate' ? 'replace' : 'duplicate');
    }

    const processImport = async() => {
        try {
            setLoading(true);
            const formData = new FormData();
			formData.append("medias", file);
            formData.append("sameNameStrategy", sameNameStrategy);
            const { data } = await api.post("/shortcut-messages/import", formData, {headers: {'Content-Type': "multipart/form-data"}});

            toast.success(`Importação realizada com sucesso! Registros criados: ${data.countCreated}. Registros atualizados: ${data.countUpdated}`);
            setLoading(false);
            handleOnClose();
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleOnClose} maxWidth="lg" scroll="paper">
                <DialogTitle>
                    Importar mensagens 
                </DialogTitle>
                <Paper square variant="outlined" className={classes.body}>
                    <div className={classes.inputContainer}>
                        <label htmlFor="file">Informe o arquivo a ser importado</label>
                        <p>O arquivo de importação tem um formato próprio, somente é aceito o tipo de arquivo que foi exportado diretamente pela plataforma Júpiter</p>
                        <input type="file" name="file" id="file" onChange={(event) => {
                            setFile(event.currentTarget.files[0]);
                        }} />
                    </div>
                    <div className={classes.toggleContainer}>
                        <FormControlLabel 
                            control={<IOSSwitch checked={sameNameStrategy === 'replace'} onChange={handleChangeSameNameStrategy} />}
                            label="Substituir mensagens com mesmo nome"
                        />
                    </div>
                    <div className={classes.buttonContainer}>
                        {!loading ? <Button onClick={processImport} variant="secondary">Importar</Button> : <p>Importando... Aguarde a importação terminar</p>}
                    </div>
                </Paper>
            </Dialog>
        </div>
    )
}

export default ShortcutMessageImportModal;