import React, { useState, useContext, useEffect, useRef } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import {
	AppBar,
	Toolbar,
	List,
	MenuItem,
	IconButton,
	Menu,
	useTheme,
	Drawer,
	Hidden,
	Divider,
	Avatar,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from "@mui/icons-material/Menu";
import MainListItems from "./MainListItems";
import Logo from "../components/Logo";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { LanguageSelector, i18n } from "../translate/i18n";
import Chat from "../components/Chat/main";
import ColorModeContext from "../layout/themeContext";
import NotificationMenuButton from "../components/Notifications/menuButton";
import getSocket from "../helpers/socket";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { UserSettingsContext } from "../context/Settings/UserSettingsContext";
import { PiChatTeardropText, PiSunDimBold, PiMoonStarsBold } from "react-icons/pi";
import { FaTimes } from "react-icons/fa";
import { PiTimerBold } from "react-icons/pi";
import { Support } from "../components/Support";
import { BiSupport } from "react-icons/bi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const socket = getSocket();
const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		height: "100vh",
		[theme.breakpoints.down('md')]: {
			display: "block"
		},
		//backgroundColor: theme.mode === `light` ? '#eeeeee' : '#0e2132',
		'& .MuiButton-outlinedPrimary': {
			color: theme.mode === 'light' ? theme.palette.primary : '#FFF',
			border: theme.mode === `light` ? `1px solid ${theme.palette.primary}` : `1px solid rgba(255, 255, 255, 0.5)`,
		},
		'& .MuiTab-textColorPrimary.Mui-selected': {
			color: theme.mode === 'light' ? theme.palette.primary : '#FFF',
		}
	},
	avatar: {
		width: "100%",
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
		color: theme.palette.dark.main,
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		minHeight: "48px",
		boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)",
		[theme.breakpoints.down('md')]: {
			height: "48px",
			justifyContent: "space-between",

		}
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		boxShadow: 'none',
		background: theme.mode === `light` ? '#eeeeee' : '#0e2132',
		transition: 'all ease 0.5s'
	},
	appBarShift: {
		zIndex: -1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		})
	},
	openedMenu: {
		background: theme.palette.blackWhiteBackground,
		boxShadow: '0px -20px 73px 0px rgba(0,0,0,0.19)'
	},
	menuButton: {
		marginRight: 0,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: "relative",
		borderRight: 'none',
		boxShadow: '5px 0px 61px 0px rgba(0,0,0,0.10)',
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		/*([theme.breakpoints.down('md')]: {
			width: "100%"
		},*/
		...theme.scrollbarStylesSoft
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: 0,
		[theme.breakpoints.up("sm")]: {
			width: 0,
		},
		[theme.breakpoints.down('md')]: {
			width: "100%"
		}
	},
	appBarSpacer: {
		minHeight: "48px",
	},
	content: {
		flex: 1,
		overflow: "auto",
		background: theme.palette.fancyBackground,
		paddingTop: 63,
		[theme.breakpoints.down('md')]: {
			height: "calc(100vh - 50px)",
			paddingTop: 63
		},
		...theme.scrollbarStylesSoftBig
	},
	container: {
		//paddingTop: theme.spacing(4),
		//paddingBottom: theme.spacing(4),
	},
	paper: {
		//padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column"
	},
	mainListItemsVerticalContainer: {
		overflowY: 'auto',
		...theme.scrollbarStylesSoftBig,
		[theme.breakpoints.down('md')]: {
			//	display: "none"
		},
		display: 'flex',
		flexDirection: 'column'
	},
	mainListItemsContainer: {
		[theme.breakpoints.down('md')]: {
			//	display: "none"
		},
		display: 'flex',
		flexDirection: 'row'
	},
	mainListItemsContainerClose: {
		display: 'flex',
		flexDirection: 'row'
	},
	searchInputWrapper: {
		flex: 18,
		background: theme.palette.total,
		display: "flex",
		borderRadius: '20px !important',
		padding: 4,
		marginRight: theme.spacing(1),
	},
	searchToggleShow: {
		flex: 1
	},
	searchToggleIcon: {
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)"
	},

	searchIcon: {
		color: "grey",
		marginLeft: 6,
		marginRight: 6,
		alignSelf: "center",
		zIndex: 9,
		cursor: 'pointer'
	},

	searchInput: {
		flex: 1,
		border: "1px solid #eee",
		borderRadius: '20px !important',
		paddingLeft: 15
	},
	shineEffect: {
		position: 'relative',
		overflow: 'hidden',
		cursor: 'pointer',
		width: 179,
		height: 50,
		'&:hover::before': {
			content: '""',
			position: 'absolute',
			top: '-10%',
			left: '-10%',
			width: '120%',
			height: '120%',
			background: `linear-gradient(45deg, #fff,  #fff, #fff)`,
			opacity: 0,
			animation: '$shine 0.1s linear forwards',
			zIndex: -1
		},
		[theme.breakpoints.down('md')]: {
			width: 'auto',
			overflow: 'initial'
		},
	},
	'@keyframes shine': {
		'0%': {
			opacity: 0,
			transform: 'rotate(-45deg) translate(-10%, -10%)', // Adjust as needed
		},
		'100%': {
			opacity: 0.9,
			transform: 'rotate(-45deg) translate(0%, 200%)', // Adjust as needed
		},
	},
	blur: {
		content: '""',
		background: 'rgba(255, 255, 255, 0.2)',
		backdropFilter: 'blur(8px)',
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		zIndex: 9,
	},
	bigMenu: {
		display: 'flex',
		//background: theme.palette.primary,
		background: '#2fc6f6',
		justifyContent: 'space-between',
		flexDirection: 'row',
		padding: '5px 15px',
		position: 'fixed',
		width: '100%',
		bottom: -2,
		left: 0
	},
	middleButton: {
		borderRadius: '100px',
		padding: 10,
		fontSize: 30,
		position: 'absolute',
		top: -35,
		background: '#b3e5f9',
		width: 70,
		height: 70,
		left: -35,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		border: '5px solid #eee'
	},
	boxDrop: {
		height: 'auto',
		left: 0,
		borderBottomLeftRadius: 20,
		borderBottomRightRadius: 20,
		boxShadow: '2px 10px 17px 0px rgba(0,0,0,0.12) !important',
		overflow: 'hidden',
		padding: '5px 20px'
	}
}));

const LoggedInLayout = ({ children }) => {
	const classes = useStyles();
	const [userModalOpen, setUserModalOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElAppBar, setAnchorElMobile] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const [menuOpenMobile, setMenuOpenMobile] = useState(false);
	const [menuDropOpen, setOpened] = useState(false);
	const { handleLogout, loading } = useContext(AuthContext);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const { user } = useContext(AuthContext);
	const [notifications, setNotifications] = useState([]);
	const theme = useTheme();
	const { colorMode } = useContext(ColorModeContext);
	const searchInputRef = useRef();
	const [searchParam, setSearchParam] = useState("");
	const [searchClicked, setSearchClicked] = useState(false);
	const [disableControls, setDisableControls] = useState(false);
	const { settings, handleChangeSetting } = useContext(UserSettingsContext);
	const history = useHistory();
	const { term } = useParams();

	useEffect(() => {
		const notificationUserEvent = data => {
			if (data.action === "new") {
				setNotifications(data.notifications);
			}
		}

		socket.on(`notification_${user.id}`, notificationUserEvent);

		return () => {
			socket.off(`notification_${user.id}`, notificationUserEvent);
		};
	}, []);

	const handleMenu = event => {
		setOpened(true);
		setAnchorEl(event.currentTarget);
		setMenuOpen(true);
	};

	const handleMenuMobile = event => {
		setOpened(true);
		setAnchorElMobile(event.currentTarget);
		setMenuOpenMobile(true);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
		setMenuOpen(false);
		setOpened(false);
	};

	const handleCloseMenuMobile = () => {
		setAnchorElMobile(null);
		setMenuOpenMobile(false);
		setOpened(false);
	};

	const handleOpenUserModal = () => {
		setUserModalOpen(true);
		setMenuOpen(false);
		setOpened(false);

	};

	const handleClickLogout = () => {
		handleCloseMenu();
		handleLogout();
	};

	const handleMenuItemClick = () => {
		const { innerWidth: width, innerHeight: height } = window;
		if (width <= 600) {
			setDrawerOpen(false);
		}
	};

	let searchTimeout;
	const handleChangeSearch = e => {
		const searchedTerm = e.target.value.toLowerCase();
		clearTimeout(searchTimeout);
		if (searchedTerm === "") {
			setSearchParam(searchedTerm);
			history.push(`/tickets/`);
			return;
		}
		searchTimeout = setTimeout(() => {
			setSearchParam(searchedTerm);
		}, 500);
	};

	const handleClickSearch = e => {
		setSearchClicked(!searchClicked)
	};

	const handleGoSearch = () => {
		history.push(`/tickets/`);
		setTimeout(() => {
		  history.push(`/search/tickets/${searchParam}`);
		}, 0);
	  };

	const toggleColorMode = () => {
		colorMode.toggleColorMode();
	}

	if (loading) {
		return <BackdropLoading />;
	}

	const handleOpenMenuHeader = (item) => {
		setOpened(item)
	}

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleGoSearch();
		}
	};

	const Icons = ({ isVertical }) => {

		return (
			<div className="flex flex-row items-center">


				<div style={{
					alignItems: 'center',
					justifyContent: isVertical ? 'space-between' : 'center',
					width: 'auto',
					margin: isVertical ? '0px auto' : 'auto',
					gap: isVertical ? 10 : 0
				}}
					className={`flex w-full flex-row justify-center  ${!isVertical ? 'block-flex-end' : ''}`} >
					<NotificationMenuButton />
					<Chat />
					<IconButton edge="start" onClick={toggleColorMode} color="inherit" size="large" className={isVertical ? '' : 'no-mobile'}>
						{theme.mode === 'dark' ? <PiSunDimBold color='#f1c40f' size={24} /> : <PiMoonStarsBold size={24} />}
					</IconButton>
					<div className={isVertical ? '' : 'no-mobile'}>
						<LanguageSelector />
					</div>
					<IconButton
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={isVertical ? handleMenu : handleMenuMobile}
						color="inherit"
						size="large">
						<>
							{settings && settings.avatar ?
								<><Avatar src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${settings.avatar}`} /></>
								: <Avatar src={`https://ui-avatars.com/api//?background=0D8ABC&color=fff&name=${user.name}`} />
							}
						</>
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={isVertical ? anchorEl : anchorElAppBar}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={isVertical ? menuOpen : menuOpenMobile}
						onClose={isVertical ? handleCloseMenu : handleCloseMenuMobile}
						elevation={1}

					>
						<MenuItem onClick={handleOpenUserModal}>
							{i18n.t("mainDrawer.appBar.user.profile")}
						</MenuItem>
					</Menu>
				</div>
			</div>
		)
	}

	return (
		<div className={classes.root}>

			{drawerOpen &&
				<Drawer
					variant="permanent"
					classes={{
						root: clsx(
							'drawerMenu',
							{
								[classes.drawerPaper]: true,
								[classes.drawerPaperClose]: !drawerOpen,
							}
						)
					}}
					open={drawerOpen}
				>

					<List onClick={() => handleMenuItemClick()} className={classes.mainListItemsVerticalContainer}>
						<MainListItems isVertical={true} collapsed={false} />



						{/*<div className={classes.searchInputWrapper} >

							<InputBase
								className={classes.searchInput}
								inputRef={searchInputRef}
								placeholder={i18n.t("tickets.search.placeholder")}
								type="search"
								onChange={handleChangeSearch}

								disabled={disableControls}
							/>
							<SearchIcon className={classes.searchIcon} onClick={handleGoSearch} />
						</div>*/}


						<Icons isVertical={true} />
					</List>
					<Divider />

					<button className="btn btn-close" onClick={() => setDrawerOpen(false)}><FaTimes /></button>
				</Drawer>
			}
			<UserModal
				open={userModalOpen}
				onClose={() => setUserModalOpen(false)}
				userId={user?.id}
			/>
			<AppBar
				position="absolute"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: drawerOpen,
					[classes.openedMenu]: menuDropOpen,
				}, 'appBar-web')}
				color="inherit"
			>
				<Toolbar variant="dense" className={classes.toolbar}>

					<div className={classes.shineEffect} onClick={() => history.push('/')}>
						<Logo />

					</div>

					{!searchClicked && (
						<List
							onClick={() => handleMenuItemClick()}
							className={`fade-in no-mobile ${drawerOpen ? classes.mainListItemsContainerClose : classes.mainListItemsContainer}`}
						>
							<MainListItems collapsed={false} handleOpenMenuHeader={handleOpenMenuHeader} openedMenu={menuDropOpen} />
						</List>
					)}

					<div
						className={`fade-in  ${classes.searchInputWrapper}`}
						onMouseEnter={!searchClicked ? handleClickSearch : undefined}
						onMouseLeave={handleClickSearch}
					>
						<InputBase
							className={classes.searchInput}
							inputRef={searchInputRef}
							placeholder={i18n.t("tickets.search.placeholder")}
							type="search"
							onChange={handleChangeSearch}
							disabled={disableControls}
							onKeyPress={handleKeyPress}
						/>
						<SearchIcon className={classes.searchIcon} onClick={handleGoSearch} />
					</div>

					<Chat
						style={{ background: 'transparent !important' }} handleOpenMenuHeader={handleOpenMenuHeader} />


					<NotificationMenuButton handleOpenMenuHeader={handleOpenMenuHeader} style={{ background: 'transparent !important' }} />
					<IconButton edge="start" onClick={toggleColorMode} color="inherit" size="large">
						{theme.mode === 'dark' ? <PiSunDimBold color='#f1c40f' size={24} /> : <PiMoonStarsBold size={24} />}
					</IconButton>

					<LanguageSelector />


					{/*<Support
						title={'Precisa resolver um problema?'}
						content={'O que você deseja resolver'}
					>
						<IconButton
							aria-haspopup="true"
							color="inherit"
							size="large">
							<BiSupport size={23} />
						</IconButton>
					</Support>*/}

					<IconButton
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleMenuMobile}
						color="inherit"
						size="large">
						<>
							{settings && settings.avatar ?
								<><Avatar src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${settings.avatar}`} /></>
								: <Avatar src={`https://ui-avatars.com/api//?background=0D8ABC&color=fff&name=${user.name}`} />
							}
						</>
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorElAppBar}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={menuOpenMobile}
						onClose={handleCloseMenuMobile}
						elevation={2}
					>
						<MenuItem onClick={handleOpenUserModal}>
							{i18n.t("mainDrawer.appBar.user.profile")}
						</MenuItem>
					</Menu>

					<div className="ml-5 flex flex-end">
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={() => setDrawerOpen(!drawerOpen)}
							className={clsx(
								classes.menuButton
							)}
							size="large">
							<MenuIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>


			<AppBar
				position="absolute"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: drawerOpen,
				}, 'appBar-mobile')}
				color="inherit"
			>
				<Toolbar variant="dense" className={classes.toolbar} style={{ justifyContent: 'space-between' }}>

					<div className={classes.shineEffect} onClick={() => history.push('/')}>
						<Logo />
					</div>


					{/** 
					<Chat />

					<LanguageSelector />
					**/}

					<Icons isVertical={false} />
				</Toolbar>
			</AppBar>
			<main className={`${classes.content} content`}>
				{drawerOpen && <div className={classes.blur} onClick={() => setDrawerOpen(false)}></div>}
				{children ? children : null}
			</main>


			<div className={`menuBar ${classes.bigMenu}`}>
				<div>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={() => setDrawerOpen(!drawerOpen)}
						className={clsx(
							classes.menuButton
						)}
						size="large">
						<MenuIcon size={30} />
					</IconButton>
				</div>


				<div style={{ position: 'relative' }}>
					<button className={classes.middleButton} onClick={() => history.push('/tickets')}>
						<PiChatTeardropText size={30} />
					</button>
				</div>

				<div>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={() => history.push('/scheduled-messages')}
						className={clsx(
							classes.menuButton
						)}
						size="large">
						<PiTimerBold size={28} />
					</IconButton>
				</div>

			</div>
		</div>
	);
};

export default LoggedInLayout;
