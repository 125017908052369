import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FaAlignLeft, FaPlus } from "react-icons/fa";
import { i18n } from "../../translate/i18n";
import Checkbox from '@mui/material/Checkbox';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Avatar, Tooltip } from "@mui/material";
import AddTaskTicketModal from "../AddTaskTicketModal";
import CircularProgress from "@mui/material/CircularProgress";
import { FormControlLabel, Switch } from "@mui/material";
import AsyncSelect from "../AsyncSelect";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    titleH3: {
        fontSize: 15,
        display: 'flex',
        alignItems: 'center'
    },
    description: {
        fontSize: 18,
        marginBottom: 15
    },
    buttonTask: {
        background: 'transparent !important',
        display: 'flex',
        alignItems: 'center'
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    avatares: {
        display: 'flex'
    },
    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
    email: Yup.string().email("Invalid email"),
});



const TaskModal = ({ open, onClose, taskId, initialValues, onSave }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [actionTask, setNewTaskOpen] = useState(false);
    const { user } = useContext(AuthContext);
    const [task, setTask] = useState({
        id: 'task-1',
        title: 'Task 1',
        description: 'Sera que vai descricao aqui nao seii ehn bora ver como funciona isso',
        user: {
            id: 'user_id',
            name: 'Onecode',
            avatar: '1692239890049.jpg'
        },
        tasks: []
    })

    const initialState = {
        title: "",
        description: "",
    };


    const [taskAdd, setAddForm] = useState(initialState);
    const [tasks, setTasks] = useState([task.tasks]);

    const [contact, setContact] = useState(initialState);



    const handleClose = () => {
        onClose();
        setContact(initialState);
    };

    const handleSaveContact = async values => {
        try {
            if (taskId) {
                await api.put(`/contacts/${taskId}`, values);
                handleClose();
            } else {
                const { data } = await api.post("/contacts", values);
                if (onSave) {
                    onSave(data);
                }
                handleClose();
            }
            toast.success(i18n.t("contactModal.success"));
        } catch (err) {
            toastError(err);
        }
    };


    const handleOpenAddTask = () => {
        setNewTaskOpen(true);


    };

    const handleFinishTask = () => {
        setNewTaskOpen(false)
    };

    const taskSchema = Yup.object().shape({
        title: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        description: Yup.string().min(8, "Too Short!").max(3000, "Too Long!"),
    });



    const handleSaveTask = (result) => {
        // Handle drag-and-drop logic here
        //setNewTaskModalOpen(false);
    };

    const handleAddTask = (newTask) => {
        setTasks((prevTasks) => [
            ...prevTasks,
            {
                id: `task-${Math.random()}`,
                title: newTask.title,
                description: newTask.description,
                user: { id: 'user_id', name: 'Onecode' }
            }
        ]);
    };

    const handleCloseAddTask = () => {
        setNewTaskOpen(false);
    };



    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose}
                fullWidth={true}
                maxWidth={"md"} scroll="paper">
                <DialogTitle id="form-dialog-title">
                    <Checkbox onChange={handleFinishTask} /> Titulo da tarefa
                </DialogTitle>
                <DialogContent>

                    <div className={classes.avatares}>
                        <Tooltip title={task.user.name}>
                            {task.user.avatar ? <Avatar alt={task.user.name} sx={{ width: 32, height: 32 }}
                                src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${task.user.avatar}`} /> :
                                <Avatar alt={task.user.name} sx={{ width: 32, height: 32 }}
                                    src={`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${task.user.name}`} />
                            }
                        </Tooltip>


                        <Tooltip title={task.user.name}>
                            {task.user.avatar ? <Avatar alt={task.user.name} sx={{ width: 32, height: 32 }}
                                src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${task.user.avatar}`} /> :
                                <Avatar alt={task.user.name} sx={{ width: 32, height: 32 }}
                                    src={`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${task.user.name}`} />
                            }
                        </Tooltip>
                    </div>


                    <h3 className={classes.titleH3}><FaAlignLeft /> Descrição</h3>
                    <p className={classes.description}>Descricao das coisas</p>
                    <button className={classes.buttonTask} onClick={() => handleOpenAddTask()}>
                        <FaPlus /> Adicionar subtarefa
                    </button>

                    {actionTask ? (
                        <AddTaskTicketModal onClose={handleFinishTask}  taskId={taskId} />
                    ) : null}
                </DialogContent>
            </Dialog>
        </div >
    );
};

export default TaskModal;
