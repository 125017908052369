import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const ContactAllowUserModal = ({ contact, open, onClose }) => {
    useEffect(() => {
		if (contact && contact.id) {
			setLoading(true);
			const delayDebounceFn = setTimeout(() => {
				const fetchList = async () => {
					try {
						const { data } = await api.get(`/contacts/${contact.id}/users`);
						const ids = [];
						setUsers(data.listUsers);
						data.users.map((user) => {
							ids.push(user.userId);
						});
						setUserIds(ids);
						setSelectedUsers(data.users);
						setLoading(false);
					} catch (err) {
						toastError(err);
					}
				};
				fetchList();
			}, 500);
			return () => clearTimeout(delayDebounceFn);
		}
    }, [contact, open]);

	const onConfirm = async() => {
		try {
			await api.post(`/contacts/${contact.id}/users`, {
				userIds
			});
			onClose(false);
		} catch (err) {
			toastError(err);
		}
	}

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [users, setUsers] = useState([]);
	const [userIds, setUserIds] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleChangeUsers = (e, other) => {
		setUserIds(e.target.value);
	}

	return (
        <Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="allow-user-modal"
		>
			<DialogTitle id="allow-user-dialog">Liberar acesso dos atendentes ao grupo {contact.name}</DialogTitle>
			<DialogContent dividers>
				<FormControl fullWidth>
					<InputLabel id="contact-users-label">Usuários</InputLabel>
					<Select
						labelId="contact-users-label"
						id="contact-users"
						value={userIds}
						label="Usuários"
						multiple={true}
						onChange={handleChangeUsers}
					>
						{users.map((user) => (
							<MenuItem value={user.id}>{user.name}</MenuItem>
						))}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={() => onClose(false)}>
					{i18n.t("confirmationModal.buttons.cancel")}
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						onConfirm();
					}}
					color="secondary"
				>
					{i18n.t("confirmationModal.buttons.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
    );
};

export default ContactAllowUserModal;
