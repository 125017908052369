import {
    Button,
    CircularProgress,
    ClickAwayListener,
    Divider,
    IconButton,
    InputBase,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import api from "../../services/api";
import SendIcon from "@mui/icons-material/SendRounded";
import toastError from "../../errors/toastError";
import getSocket from "../../helpers/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import UserStatusIcon from "../User/statusIcon";
import { isSameDay, parseISO, format } from "date-fns";

import whatsBackground from "../../assets/bg-wp-transparent-20.png";
import { i18n } from "../../translate/i18n";
import { CloseOutlined, RemoveOutlined, Mood, AttachFile, Close, AccessTime, Done, DoneAll, GetApp } from "@mui/icons-material";
import { Picker } from "emoji-mart";
import FileIconImage from "../FileIconImage";

import { green } from "@mui/material/colors";
import ModalImageCors from "../ModalImageCors";

const socket = getSocket();

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: "relative",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
        borderRadius: "10px 10px 0 0"
    },
    title: {
        width: "50%",
        color: theme.palette.dark.main,
        padding: 5
    },
    messages: {
        flex: 1,
        margin: 0,
        padding: 0,
        overflowY: 'scroll',
        overflowX: 'hidden',
        background: `url(${whatsBackground})`,
        backgroundColor: "#333",
        paddingBottom: 10,
        paddingTop: 10,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        position: "relative",
        color: "#333",
        ...theme.scrollbarStylesSoft
    },
    message: {
        alignSelf: "flex-start",
        listStyle: "none",
        margin: 0,
        padding: 10,
        background: "#FFFFFF",
        marginLeft: 10,
        boxShadow: "0 1px 1px #b3b3b3",
        marginTop: 10,
        borderRadius: "8px",
        borderTopLeftRadius: 0,
        maxWidth: '50%',
        minWidth: "100px",
        position: "relative"
    },
    messageSent: {
        alignSelf: "flex-end",
        listStyle: "none",
        margin: 0,
        padding: 10,
        background: "#dcf8c6",
        marginRight: 10,
        boxShadow: "0 1px 1px #b3b3b3",
        marginTop: 10,
        borderRadius: "8px",
        borderBottomRightRadius: 0,
        maxWidth: "50%",
        minWidth: "100px",
        position: "relative"
    },
    toolBar: {
        padding: "7px",
        background: theme.palette.tabHeaderBackground,
        display: "flex",
        borderTop: "1px solid #FFF"
    },
    dailyTimestamp: {
        listStyle: "none",
        margin: 20,
        padding: 8,
        alignSelf: "center",
        width: "110px",
        background: '#e1f3fb',
        color: '#808888',
        borderRadius: "10px",
		boxShadow: "0 1px 1px #b3b3b3",
        alignItems: "center",
		textAlign: "center",
    },
    noMessages: {
        listStyle: "none",
        margin: 20,
        padding: 8,
        alignSelf: "center",
        background: "#FFF",
        borderRadius: "10px",
        alignItems: "center",
        textAlign: "center"
    },
    circleLoading: {
		color: "#FFFFFF",
		position: "absolute",
		opacity: "70%",
		top: 0,
		left: "50%",
		marginTop: 12,
	},
    messageInputWrapper: {
		padding: 6,
		marginRight: 7,
		background: theme.palette.light.main,
		display: "flex",
		borderRadius: 20,
		flex: 1,
		position: "relative",
        flexWrap: "wrap"
	},

	messageInput: {
		paddingLeft: 10,
		flex: 1,
		border: "none",
	},
    timestamp: {
		fontSize: 11,
		position: "absolute",
		bottom: 0,
		right: 5,
		color: "#999",
        marginTop: 5
	},
    header: {
        padding: 10,
        display: "flex",
        backgroundColor: theme.palette.light.main
    },
    headerRight: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "right",
        flex: 1
    },
    uploadInput: {
        display: "none"
    },
    emojiBox: {
		position: "absolute",
		bottom: 60,
        right: 0,
		borderTop: "1px solid #e8e8e8",
        zIndex: 100000
	},
    medias: {
        display: "flex",
        width: "100%",
        flexBasis: "100%",
        marginBottom: "10px"
    },
    mediaUploadItem: {
        width: "64px",
        height: "64px",
        position: "relative",
        margin: "0 10px",
    },
    mediaUploadItemIcon: {
        width: "64px",
        height: "64px",
        overflow: "hidden",
        borderRadius: "10px"
    },
    mediaUploadItemCloseButton: {
        position: "absolute",
        top: -10,
        right: -15,
        border: "none",
        color: "#FFF",
        backgroundColor: "red",
        zIndex: 100000000
    },
    ackIcons: {
		fontSize: 18,
		verticalAlign: "middle",
		marginLeft: 4,
	},
    ackDoneAllIcon: {
		color: green[500],
		fontSize: 18,
		verticalAlign: "middle",
		marginLeft: 4,
	},
}));

const reducer = (state, action) => {
	if (action.type === "LOAD_MESSAGES") {
		const messages = action.payload;
		const newMessages = [];

		messages.forEach(message => {
			const messageIndex = state.findIndex(m => m.id === message.id);
			if (messageIndex !== -1) {
				state[messageIndex] = message;
			} else {
				newMessages.push(message);
			}
		});

		return [...newMessages, ...state];
	}

	if (action.type === "ADD_MESSAGE") {
		const newMessage = action.payload;
		const messageIndex = state.findIndex(m => m.id === newMessage.id);

		if (messageIndex !== -1) {
			state[messageIndex] = newMessage;
		} else {
			state.push(newMessage);
		}

		return [...state];
	}

    if (action.type === "READ_MESSAGE") {
        const messageIndex = state.findIndex(m => m.id === action.payload);

        if (messageIndex !== -1) {
            state[messageIndex].read = true;
        }

        return [...state];
    }

    if (action.type === "ACK_MESSAGE") {
        const messageIndex = state.findIndex(m => m.id === action.payload);

        if (messageIndex !== -1) {
            state[messageIndex].ack = true;
        }

        return [...state];
    }

	if (action.type === "UPDATE_MESSAGE") {
		const messageToUpdate = action.payload;
		const messageIndex = state.findIndex(m => m.id === messageToUpdate.id);

		if (messageIndex !== -1) {
			state[messageIndex] = messageToUpdate;
		}

		return [...state];
	}

	if (action.type === "DELETE_MESSAGE") {
		const messageId = action.payload;
		const messageIndex = state.findIndex(m => m.id === messageId);

		if (messageIndex !== -1) {
			state.splice(messageIndex, 1);
		}

		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const Room = ({room, to, onClose, onMinimize}) => {
    const classes = useStyles();
    const [currentRoom, setCurrentRoom] = useState(null);
    const [currentTo, setCurrentTo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sendingMessage, setSendingMessage] = useState(false);
    const [typeMessage, setTypeMessage] = useState("");
    const [messages, dispatch] = useReducer(reducer, []);
    const lastMessageRef = useRef();
    const [hasMore, setHasMore] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [recording] = useState(false);
    const [showEmoji, setShowEmoji] = useState(false);
    const [medias, setMedias] = useState([]);

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const apiURL = process.env.REACT_APP_BACKEND_URL;

    const fileInputRef = useRef();

    var forceScroll = false;

    const inputRef = useRef();

    const { user } = useContext(AuthContext);

    const handleChangeMessage = (e) => {
        setTypeMessage(e.target.value);
    }

    const scrollToBottom = () => {
		if (lastMessageRef.current) {
			lastMessageRef.current.scrollIntoView({});
		}
	};

    const sendMessage = async(message) => {
        try {
            const type = currentRoom ? 'room' : 'direct';
            const to = currentRoom ? currentRoom.id : currentTo.id;
            await api.post(`/chat/send-message/${type}/${to}`, {
                body: message
            });
            setSendingMessage(false);
            setTypeMessage("");
        } catch (e) {
            toastError(e);
            setSendingMessage(false);
        }
    }

    const handleUploadMedia = async e => {
		setLoading(true);
		e.preventDefault();

		const formData = new FormData();
		medias.forEach(media => {
			formData.append("medias", media);
			formData.append("body", typeMessage ? typeMessage : media.name);
		});

		try {
            const type = currentRoom ? 'room' : 'direct';
            const to = currentRoom ? currentRoom.id : currentTo.id;
			await api.post(`/chat/send-message/${type}/${to}`, formData);
		} catch (err) {
			toastError(err);
		}

		setLoading(false);
		setMedias([]);
		setTypeMessage("");
	};

    const handleSendMessage = (e) => {
        if (!typeMessage && typeMessage !== "0") return;
        setSendingMessage(true);
        sendMessage(typeMessage);
    }

    const fetchRoomByTo = async(to) => {
        try {
            const { data } = await api.get(`/chat/room/${to.id}`);
            setCurrentRoom(data.room);
            setCurrentTo(data.to);
            setLoading(false);
        } catch (e) {
            toastError(e);
        }
    }

    useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [room, to]);

    const checkReadAndAckMessages = (payload) => {
        payload.map(message => {
            if (message.userId !== user.id) {
                if (!message.read) {
                    api.post(`/chat/read-message/${message.id}`);
                }
                if (!message.ack) {
                    api.post(`/chat/ack-message/${message.id}`);
                }
            }
        });
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchMessages = async() => {
                setLoadingMessages(true);
                try {
                    const { data } = await api.get(`/chat/${currentRoom.id}`, {
                        params: { pageNumber }
                    });
                    dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
                    setHasMore(data.hasMore);
                    if ((pageNumber === 1 || forceScroll) && data.messages.length > 1) {
						scrollToBottom();
					}
                    setLoadingMessages(false);
                    checkReadAndAckMessages(data.messages);
                } catch (e) {
                    toastError(e);
                }
            }
            if (currentRoom) {
                fetchMessages();
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [currentRoom, pageNumber]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setLoading(true);
            if (room) {
                setCurrentRoom(room);
                setLoading(false);
            } else if (to) {
                fetchRoomByTo(to);
            }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [room, to]);

    const newMessageEvent = data => {
        if (currentRoom && data.message.roomId === currentRoom.id) {
            dispatch({ type: "ADD_MESSAGE", payload: data.message });
            checkReadAndAckMessages([data.message]);
            scrollToBottom();
        }
    }

    const messageReadEvent = data => {
        dispatch({ type: "READ_MESSAGE", payload: data.messageId });
    }

    const messageAckEvent = data => {
        dispatch({ type: "ACK_MESSAGE", payload: data.messageId });
    }

    const userEvent = data => {
        if (data.action === 'status') {
            if (currentRoom) {
                if (data.id === currentRoom.participantId) {
                    setCurrentRoom(prevState => ({
                        ...prevState,
                        participant: {...prevState.participant, status: data.status}
                    }));
                }
                if (data.id === currentRoom.userId) {
                    setCurrentRoom(prevState => ({
                        ...prevState,
                        user: {...prevState.user, status: data.status}
                    }));
                }
            } else if (currentTo) {
                if (data.id === currentTo.id) {
                    setCurrentTo(prevState => ({
                        ...prevState,
                        status: data.status
                    }));
                }
            }
        }
    }

    useEffect(() => {
        const publicToken = window.localStorage.getItem('public-token');

        socket.on(`chat:message:new#${publicToken}`, newMessageEvent);
        socket.on(`chat:message:read#${publicToken}`, messageReadEvent);
        socket.on(`chat:message:ack#${publicToken}`, messageAckEvent);
        socket.on('user', userEvent);

        return () => {
            socket.off(`chat:message:new#${publicToken}`, newMessageEvent);
            socket.off(`chat:message:read#${publicToken}`, messageReadEvent);
            socket.off(`chat:message:ack#${publicToken}`, messageAckEvent);
            socket.off('user', userEvent);
        }
    }, [room, to, currentRoom, currentTo]);

    const loadMore = (force) => {
        setLoadingMessages(true);
		forceScroll = force;
		setPageNumber(parseInt(pageNumber) + 1);
	};

    const handleScroll = e => {
		if (!hasMore) return;
		const { scrollTop } = e.currentTarget;

		if (scrollTop === 0) {
			document.getElementById(room ? `messagesList_${room.id}` : `messagesList_to_${to.id}`).scrollTop = 1;
		}

		if (loading || loadingMessages) {
			return;
		}

		if (scrollTop < 50) {
			loadMore(false);
		}
	};

    const renderDailyTimestamps = (message, index) => {
		if (index === 0) {
			return (
				<li
					className={classes.dailyTimestamp}
					key={`timestamp-${message.id}`}
				>
					<div className={classes.dailyTimestampText}>
						{format(parseISO(messages[index].createdAt), "dd/MM/yyyy")}
					</div>
				</li>
			);
		}
		if (index < messages.length - 1) {
			let messageDay = parseISO(messages[index].createdAt);
			let previousMessageDay = parseISO(messages[index - 1].createdAt);

			if (!isSameDay(messageDay, previousMessageDay)) {
				return (
					<li
						className={classes.dailyTimestamp}
						key={`timestamp-${message.id}`}
					>
						<div className={classes.dailyTimestampText}>
							{format(parseISO(messages[index].createdAt), "dd/MM/yyyy")}
						</div>
					</li>
				);
			}
		}
		if (index === messages.length - 1) {
			return (
				<div
					key={`ref-${message.createdAt}`}
					ref={lastMessageRef}
					style={{ float: "left", clear: "both" }}
				/>
			);
		}
	};

    const handleInputPaste = e => {
		if (e.clipboardData.files[0]) {
			setMedias([e.clipboardData.files[0]]);
		}
	};

    const handleChangeMedias = e => {
		if (!e.target.files) {
			return;
		}

		const selectedMedias = Array.from(e.target.files);
		setMedias([...medias, ...selectedMedias]);
	};

    const handleAddEmoji = e => {
		let emoji = e.native;
		setTypeMessage(prevState => prevState + emoji);
	};

    const renderMessageAck = message => {
		if (!message.id) {
			return <AccessTime fontSize="small" className={classes.ackIcons} />;
		}
		if (!message.ack) {
			return <Done fontSize="small" className={classes.ackIcons} />;
		}
		if (message.ack && !message.read) {
			return <DoneAll fontSize="small" className={classes.ackIcons} />;
		}
		if (message.ack && message.read) {
			return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
		}
	};

    const checkMessageMedia = message => {
		if (message.mediaType === "image") {
			return <ModalImageCors imageUrl={message.absoluteMediaUrl} />;
		}
		if (message.mediaType === "audio") {
			if (!isSafari) {
				return (
					<audio controls src={message.absoluteMediaUrl}>
						<source src="{message.mediaUrl}" type="audio/ogg" />
					</audio>
				)
			} else {
				return (
					<audio controls>
						<source src={`${apiURL}/messages/${message.id}/download/mp3`} type="audio/mp3"></source>
					</audio>
				);
			}
		}

		if (message.mediaType === "video") {
			return (
				<video
					className={classes.messageMedia}
					src={message.absoluteMediaUrl}
					controls
				/>
			);
		} else {
			return (
				<>
					<div className={classes.downloadMedia}>
						<Button
							startIcon={<GetApp />}
							color="primary"
							variant="outlined"
							target="_blank"
							href={message.absoluteMediaUrl}
						>
							Download
						</Button>
					</div>
					<Divider />
				</>
			);
		}
	};

    return (
        <div className={classes.root}>
            {showEmoji ? (
                <div className={classes.emojiBox}>
                    <ClickAwayListener onClickAway={e => setShowEmoji(false)}>
                        <Picker
                            perLine={16}
                            showPreview={false}
                            showSkinTones={false}
                            onSelect={handleAddEmoji}
                        />
                    </ClickAwayListener>
                </div>
            ) : null}
            <div className={classes.header}>
                <div className={classes.title}>
                    {loading ? <CircularProgress size={20} /> : <React.Fragment>
                    {currentRoom && currentRoom.type === 'group' && `${currentRoom.title}`}
                    {currentRoom && currentRoom.type === 'direct' && currentRoom.userId === user.id && <span>{currentRoom.participant.name} <UserStatusIcon user={currentRoom.participant} /></span>}
                    {currentRoom && currentRoom.type === 'direct' && currentRoom.participantId === user.id && <span>{currentRoom.user.name} <UserStatusIcon user={currentRoom.user} /></span>}
                    {currentTo && <span>{currentTo.name} <UserStatusIcon user={currentTo} /></span>}</React.Fragment>}
                </div>
                <div className={classes.headerRight}>
                    {onMinimize && <IconButton size="small" onClick={() => {onMinimize && onMinimize(room)}}><RemoveOutlined /></IconButton>}
                    <IconButton size="small" onClick={() => {onClose && onClose(room)}}><CloseOutlined /></IconButton>
                </div>
            </div>
            <ul className={classes.messages} id={room ? `messagesList_${room.id}` : `messagesList_to_${to.id}`} onScroll={handleScroll}>
                {!loadingMessages && !loading && (!messages || messages.length === 0) && <li className={classes.noMessages}>Sem mensagens</li>}
                {loadingMessages && (
                    <li key="loading">
                        <CircularProgress className={classes.circleLoading} />
                    </li>
                )}
                {messages && messages.map((message, index) => (
                    <React.Fragment key={message.id}>
                        {renderDailyTimestamps(message, index)}
                        <li className={user.id === message.userId ? classes.messageSent : classes.message}>
                            {message.mediaUrl && checkMessageMedia(message)}
                            {message.body}
                            <span className={classes.timestamp}>
                                {format(parseISO(message.createdAt), "HH:mm")} 
                                {user.id === message.userId && renderMessageAck(message)}
                            </span>
                        </li>
                    </React.Fragment>
                ))}
            </ul>
            <div className={classes.toolBar}>
                <input
                    multiple
                    type="file"
                    ref={fileInputRef}
                    disabled={loading || recording}
                    className={classes.uploadInput}
                    onChange={handleChangeMedias}
                />
                <IconButton
                    aria-label="upload"
                    component="span"
                    size="small"
                    onClick={e => {
                        fileInputRef.current.click();
                    }}
                    disabled={loading || recording}
                >
                    <AttachFile className={classes.sendMessageIcons} />
                </IconButton>
                <div className={classes.messageInputWrapper}>
                    {medias && medias.length > 0 && (
                        <div className={classes.medias}>
                            {medias.map(media => (
                                <div className={classes.mediaUploadItem}>
                                    <div className={classes.mediaUploadItemIcon}>
                                        <FileIconImage localFile={media} />
                                    </div>
                                    <IconButton size="small" className={classes.mediaUploadItemCloseButton} onClick={() => {setMedias(medias.filter(m => m.name !== media.name))}}>
                                        <Close />
                                    </IconButton>
                                </div>
                            ))}
                        </div>  
                    )}
                    <InputBase
                        inputRef={input => {
                            input && input.focus();
                            input && (inputRef.current = input);
                        }}
                        className={classes.messageInput}
                        placeholder={
                            i18n.t("messagesInput.placeholderOpen")
                        }
                        multiline
                        maxRows={5}
                        value={typeMessage}
                        onChange={handleChangeMessage}
                        disabled={sendingMessage || loading || loadingMessages}
                        onPaste={e => {
                            handleInputPaste(e);
                        }}
                        onKeyPress={e => {
                            if (loading || loadingMessages || e.shiftKey) return;
                            else if (e.key === "Enter" || e.key === "NumpadEnter") {
                                e.preventDefault();
                                medias && medias.length > 0 ? handleUploadMedia(e) : handleSendMessage();
                            }
                        }}
                    />
                    <IconButton
                        aria-label="emojiPicker"
                        component="span"
                        size="small"
                        disabled={loading || recording}
                        onClick={e => setShowEmoji(prevState => !prevState)}
                    >
                        <Mood className={classes.sendMessageIcons} />
                    </IconButton>
                </div>
                <IconButton size="small" onClick={(e) => medias && medias.length > 0 ? handleUploadMedia(e) : handleSendMessage() }>
                    <SendIcon />
                </IconButton>
            </div>
        </div>
    )
}

export default Room;