import React, {} from "react";
import { FaWhatsapp } from "react-icons/fa";
import makeStyles from '@mui/styles/makeStyles';

/*const useStyles = makeStyles(theme => ({
	
}));*/

const ConnectionIcon = ({ connectionType, width, height, className }) => {
	//const classes = useStyles();

	return (
		<React.Fragment>
            {connectionType === 'whatsapp' && <FaWhatsapp size={18} color={'#0be881'} /> }
            {connectionType === 'instagram' && <img className={className} src="/icons/instagram.png" width={width || 18} height={height || 18} />}
            {connectionType === 'facebook' && <img className={className} src="/icons/facebook.png" width={width || 18} height={height || 18} />}
		</React.Fragment>
	);
};

export default ConnectionIcon;
