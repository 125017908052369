import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const NotificationModalShow = ({open, onClose, notification}) => {
    const classes = useStyles();

	const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }

    return (
        <div className={classes.root}>
            <Dialog open={open}>
                <DialogTitle>
                    {notification ? notification.notification.title : ''}
                </DialogTitle>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{__html: notification ? notification.notification.message : ''}}></div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        variant="outlined"
                    >
                        {i18n.t("notifications.buttons.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default NotificationModalShow;