import React, { useState, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import makeStyles from '@mui/styles/makeStyles';
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SpeakerNotes from "@mui/icons-material/SpeakerNotes";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { TiContacts } from "react-icons/ti";

import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TapAndPlay from "@mui/icons-material/TapAndPlay";
import GroupIcon from "@mui/icons-material/Group";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ContactTagsModal from "../../components/ContactTagsModal";
import ConfirmationModal from "../../components/ConfirmationModal/";
import AddTagToContactModal from "../../components/AddTagToContactModal";
import ContactSyncModal from "../../components/ContactSyncModal";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import { SettingsContext } from "../../context/Settings/SettingsContext";

import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Tooltip } from "@mui/material";
import ContactAllowUserModal from "../../components/ContactAllowUserModal";
import getSocket from "../../helpers/socket";
import SelectTags from "../../components/SelectTags";
import ContactImportModal from "../../components/ContactImportModal";

import ShareIcon from "@mui/icons-material/Share";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import GetAppIcon from '@mui/icons-material/GetApp';

import ConnectionIcon from "../../components/ConnectionIcon";
import NewTicketModal from "../../components/NewTicketModal";
import Swal from "sweetalert2";
import SelectUsers from "../../components/SelectUsers";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import ForbiddenPage from "../../components/ForbiddenPage";
import SelectQueues from "../../components/SelectQueues";
import NewTicketGroupModal from "../../components/NewTicketModal/group";
import { FaPlus } from "react-icons/fa";
import ContactTag from "../../components/ContactTag";

const socket = getSocket();

const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach(contact => {
            const contactIndex = state.findIndex(c => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }

    if (action.type === "UPDATE_CONTACTS") {
        const contact = action.payload;
        const contactIndex = state.findIndex(c => c.id === contact.id);

        if (contactIndex !== -1) {
            state[contactIndex] = contact;
            return [...state];
        } else {
            return [contact, ...state];
        }
    }

    if (action.type === "UPDATE_CONTACT") {
        const contact = action.payload;

        const contactIndex = state.findIndex(c => c.id === contact.id);
        if (contactIndex !== -1) {
            state[contactIndex] = contact;
        }
        return [...state];
    }

    if (action.type === "DELETE_CONTACT") {
        const contactId = action.payload;

        const contactIndex = state.findIndex(c => c.id === contactId);
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles(theme => ({
    mainContainer: {
        background: theme.palette.fancyBackground,

    },
    mainPaper: {
        flex: 1,
        marginTop: 40,
        borderRadius: 20,
        border: '0px !important',
        marginBottom: 40,
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            marginTop: 0
        }
    },
    mainPaperTable: {
        flex: 1,
        overflow: 'auto',
        height: '60vh',
        ...theme.scrollbarStylesSoftBig,
    },
    fifty: {
        width: 200,
        [theme.breakpoints.down('md')]: {
            width: '45%'
        },
    },
    tag: {
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        color: '#FFF',
        textAlign: 'center',
        marginRight: 10,
        borderRadius: 5
    },
    mainHeaderBlock: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexWrap: 'wrap'
        },
    },
    actionsButtons: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 15px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            fontSize: 13,
        },
    },
    actionsDivButton: {
        display: 'flex',
        gap: 10,
    },
    buttonsTicket: {
        height: 40,
        borderRadius: '5px!important',
        display: 'inline-flex',
        alignItems: 'center',
        boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.07) !important',
        '&:hover': {
            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2) !important',
        },
    },
    head: {
        backgroundColor: theme.palette.fancyBackground,
        position: "sticky",
        top: 0,
        zIndex: 999
    },
    tableCell: {
        height: 61,
        maxHeight: 61,
        overflow: 'hidden',
        //display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            textWrap: 'nowrap'
        },
    }
}));

const Contacts = () => {
    const classes = useStyles();
    const history = useHistory();

    const { user } = useContext(AuthContext);
    const { isActive } = useContext(SettingsContext);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [contacts, dispatch] = useReducer(reducer, []);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [selectedContact, setSelectedContact] = useState({});
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [contactSyncSingleModalOpen, setContactSyncSingleModalOpen] = useState(false);
    const [addTagModalOpen, setAddTagModalOpen] = useState(false);
    const [contactTagsModalOpen, setContactTagsModalOpen] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [deletingContact, setDeletingContact] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [importOpen, setImportOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [checked, setChecked] = useState([]);
    const [onlyGroups, setOnlyGroups] = useState(false);
    const [contactAddUserOpen, setContactAddUserOpen] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [tagIds, setTagIds] = useState([]);
    const [queueIds, setQueueIds] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [totalContacts, setTotalContacts] = useState(0);
    const [newTicketContact, setNewTicketContact] = useState(null);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [forceReload, setForceReload] = useState(null);
    const [newTicketModalGroupOpen, setNewTicketModalGroupOpen] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [totalIds, setTotalIds] = useState([]);

    /*Tags*/
    const maxVisibleTags = 1;
    const [anchorEl, setAnchorEl] = useState(null);
    const [visibleContactTag, setContactVisibleTag] = useState(null);

    const handleClick = (event, id) => {
        event.preventDefault();
        setContactVisibleTag(id);
        setAnchorEl(event.currentTarget);

    };

    const handleClose = () => {
        setContactVisibleTag(null);
        setAnchorEl(null);
    };

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam, tagIds, queueIds, userIds, onlyGroups]);

    useEffect(() => {
        if (newTicketContact) {
            setNewTicketModalOpen(true);
        } else {
            setNewTicketModalOpen(false);
        }
    }, [newTicketContact]);

    const settingIsActive = key => {
        return isActive(key);
    }

    const handleToggle = (event) => {
        const currentIndex = checked.indexOf(event.target.value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(event.target.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    }

    const toggleAll = (event) => {
        if (event.target.checked) {
            checkAll();
        } else {
            uncheckAll();
        }
    }
    const checkAll = () => {
        setChecked(contacts.map((element) => { return "" + element.id }));
    }

    const uncheckAll = () => {
        setChecked([]);
    }

    const handleExport = async () => {
        setExporting(true);
        try {
            const format = 'xlsx';
            const { data } = await api.get(`/contacts/export`, {
                params: {
                    format,
                    tagIds,
                    searchParam,
                    userIds,
                    select: 'all'
                },
                responseType: 'arraybuffer',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv' }
            }
            );
            const blob = new Blob([data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `contatos.${format}`;
            link.click();
            setExporting(false);
        } catch (err) {
            toastError(err);
            setExporting(false);
        }
    }

    const handleHistoryExport = async (contactId) => {
        setExporting(true);
        try {
            const format = 'xlsx';
            const { data } = await api.get(`/contacts/${contactId}/history/export?format=${format}`, { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv' } });
            const blob = new Blob([data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `historico_contato.${format}`;
            link.click();
            setExporting(false);
        } catch (err) {
            toastError(err);
            setExporting(false);
        }
    }

    const handleHistoryExportBatch = async () => {
        setExporting(true);
        try {
            if (checked.length <= 20 && !allSelected || allSelected && totalIds.length <= 20) {
                const format = 'xlsx';
                const { data } = await api.get(`/contacts/history/batch/export?format=${format}&ids=${JSON.stringify(allSelected ? totalIds : checked)}&select=all`, { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv' } });
                const blob = new Blob([data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = `historico_contato.zip`;
                link.click();
            } else {
                toast.error('Limite permitido para download de histórico é 20 contatos');
            }
            setExporting(false);
        } catch (err) {
            toastError(err);
            setExporting(false);
        }
    }


    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            if (user.profile !== "admin" && !settingIsActive("showContacts")) return;
            const fetchContacts = async () => {
                try {
                    let newNumber = Number(pageNumber) === 1 ? 1 : Number(pageNumber) - 1;
                    const { data } = await api.get("/contacts/", {
                        params: { searchParam, pageNumber: newNumber, onlyGroups: onlyGroups ? "1" : "0", tagIds, queueIds, userIds },
                    });
                    dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
                    setHasMore(data.hasMore);
                    setTotalContacts(data.count);
                    //console.log(data.totalIds, 'worked the ids');
                    data.totalIds && setTotalIds(data.totalIds);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };

            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, onlyGroups, tagIds, queueIds, userIds, user, forceReload]);

    const removeCheckedItem = (contactId) => {
        const currentIndex = checked.indexOf(contactId);
        const newChecked = [...checked];

        // console.log("INDEXOF", currentIndex, contactId, checked);

        if (currentIndex !== -1) {
            newChecked.splice(currentIndex, 1);
            setChecked(newChecked);
        }
    }

    useEffect(() => {
        const contactEvent = data => {
            if (data.action === "update") {
                dispatch({ type: "UPDATE_CONTACT", payload: data.contact });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
            }
        }

        socket.on("contact", contactEvent);

        return () => {
            socket.off('contact', contactEvent);
        };
    }, []);

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(true);
    };

    const handleOpenAddTagModal = () => {
        setAddTagModalOpen(true);
    }


    const handleOpenContactTagsModal = () => {
        setContactTagsModalOpen(true);
    }

    const handleCloseContactTagsModal = () => {
        setContactTagsModalOpen(false);
    }


    const handleCloseContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(false);
    };

    const handleCloseAddTagModal = () => {
        setAddTagModalOpen(false);
    }

    const handleSaveTicket = async contact => {
        if (!contact || !contact.id) return;
        setLoading(true);
        /*try {
            const { data: ticket } = await api.post("/tickets", {
                contactId: contactId,
                userId: user?.id,
                status: "open",
            });
            history.push(`/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }*/
        setNewTicketContact(contact);
        setLoading(false);
    };

    const hadleEditContact = contactId => {
        setSelectedContactId(contactId);
        setContactModalOpen(true);
    };


    //Sicronizar Unico Contato
    const handleSyncSingleContact = async contactId => {
        setSelectedContactId(contactId);
        setContactSyncSingleModalOpen(true);

    };


    const handleCloseSyncSingleContactModal = () => {
        setSelectedContactId(null);
        setContactSyncSingleModalOpen(false);
        let oldNumber = pageNumber;
        setPageNumber(1);
        setPageNumber(oldNumber);
        //handleSearch();
    };
    //Sicronizar Unico Contato

    /*const handleUpdateContact = async contactId => {
        try {
           const response = await api.post(`/contacts/import/unique/${contactId}`);
            toast.success(i18n.t("contacts.toasts.synced"));
           // console.log(response.data)
            //setSelectedContactId(contactId);
            setSearchParam(response.data.response.name);
            handleSearch();
        } catch (err) {
            toastError(err);
        }
    };*/


    const handleDeleteContact = async contactId => {
        try {
            await api.delete(`/contacts/${contactId}`);
            toast.success(i18n.t("contacts.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const handleDeleteContacts = () => {
        Swal.fire({
            title: 'Atenção! Excluir contatos selecionados.',
            text: "Tem certeza que deseja excluir todos os contatos selecionados? Todos os atendimentos desses contatos também serão excluídos",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await api.delete(`/contacts/`, { data: { contactIds: allSelected ? totalIds : checked } });
                    toast.success(i18n.t("contacts.toasts.deleted"));
                    if (allSelected) {
                        history.go(0);
                    }
                } catch (err) {
                    toastError(err);
                }
                setDeletingContact(null);
                setSearchParam("");
                setPageNumber(1);
                setForceReload(Math.random());

                setChecked([]);

            }
        });
    }

    const handleimportContact = async () => {
        try {
            await api.post("/contacts/import");
            history.go(0);
        } catch (err) {
            toastError(err);
        }
    };

    let pageNumberInterval = null;

    const loadMore = () => {
        if (pageNumberInterval) {
            clearInterval(pageNumberInterval);
        }
        pageNumberInterval = setInterval(() => {
            setPageNumber(prevState => prevState + 1);
            clearInterval(pageNumberInterval);
        }, 100);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;

        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleOnlyGroupChange = e => {
        setOnlyGroups(e.target.checked);
    }

    const handleDeleteTagsContacts = () => {
        Swal.fire({
            title: 'Atenção! Excluir tags dos contatos selecionados.',
            text: "Tem certeza que deseja excluir tags do contato selecionados?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await api.post(`/contacts/tags`, { contactIds: checked, tagIds: tagIds });
                    toast.success(i18n.t("Tags Removidas com sucesso!"));
                    window.location.reload();
                } catch (err) {
                    toastError(err);
                }
                setDeletingContact(null);
                setSearchParam("");
                setPageNumber(1);
                setForceReload(Math.random());
                setChecked([]);
            }
        });
    }

    const handleAllSelected = () => {
        //const allContactsId = contacts.map(contact => contact.id);
        !allSelected && checkAll();
        allSelected && uncheckAll();

        //console.log(allContactsId,'passed in here');
        setAllSelected(!allSelected);
    }

    return (
        <MainContainer className={classes.mainContainer}>
            <ContactAllowUserModal
                open={contactAddUserOpen}
                contact={selectedContact}
                onClose={() => setContactAddUserOpen(false)}
            ></ContactAllowUserModal>
            <ContactModal
                open={contactModalOpen}
                onClose={handleCloseContactModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}
            ></ContactModal>
            <ContactSyncModal
                open={contactSyncSingleModalOpen}
                onClose={handleCloseSyncSingleContactModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}
            ></ContactSyncModal>
            <AddTagToContactModal
                open={addTagModalOpen}
                onClose={handleCloseAddTagModal}
                aria-labelledby="form-dialog-title"
                contactIds={allSelected ? totalIds : checked}
            ></AddTagToContactModal>
            <ContactTagsModal
                open={contactTagsModalOpen}
                onClose={handleCloseContactTagsModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}
            >
            </ContactTagsModal>
            <NewTicketGroupModal
                modalOpen={newTicketModalGroupOpen}
                onClose={e => setNewTicketModalGroupOpen(false)}
            />
            <ConfirmationModal
                title={
                    deletingContact
                        ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name
                        }?`
                        : `${i18n.t("contacts.confirmationModal.importTitlte")}`
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={e =>
                    deletingContact
                        ? handleDeleteContact(deletingContact.id)
                        : handleimportContact()
                }
            >
                {deletingContact
                    ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
                    : `${i18n.t("contacts.confirmationModal.importMessage")}`}
            </ConfirmationModal>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                onClose={e => setNewTicketContact(null)}
                forContact={newTicketContact}
            />
            {importOpen && <ContactImportModal open={importOpen} handleClose={() => setImportOpen(false)} handleWhatsappImport={() => setConfirmOpen(true)} />}
            <Can
                role={user.roleId}
                perform="contacts:show:page"
                yes={() => (
                    <Paper
                        className={classes.mainPaper}
                        variant="outlined"
                    // onScroll={handleScroll}
                    >
                        <MainHeader className={classes.mainHeaderBlock} style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Title><TiContacts size={24} /> {i18n.t("contacts.title")}</Title>

                                <div style={{ display: 'flex', gap: 10 }}>
                                    {openFilter &&
                                        <Button
                                            variant="outlined"
                                            color="success"
                                            className={classes.buttonsTicket}
                                            onClick={handleAllSelected}
                                            style={{ maxWidth: 200 }}
                                        >
                                            {!allSelected ?
                                                <>
                                                    <AddIcon style={{ fontSize: 13 }} /> {i18n.t("Selecionar Todos")}
                                                </> :
                                                <>
                                                    <RemoveCircleOutline style={{ fontSize: 13 }} /> {i18n.t("Deselecionar Todos")}
                                                </>
                                            }
                                        </Button>
                                    }
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => setOpenFilter(!openFilter)}
                                        className={classes.buttonsTicket}
                                        style={{ fontSize: '12px' }}
                                    >
                                        {!openFilter ? <><AddCircleOutline style={{ fontSize: 13 }} />   {i18n.t("Abrir filtro")}</>
                                            : <><RemoveCircleOutline style={{ fontSize: 13 }} />   {i18n.t("Fechar filtro")}</>
                                        }
                                    </Button>


                                </div>
                            </div>
                            {openFilter &&
                                <MainHeaderButtonsWrapper
                                    className="d-flex flex-row flex-wrap"
                                    style={{ overflowX: 'auto', display: 'flex', flexWrap: 'wrap' }}
                                >
                                    <TextField
                                        placeholder={i18n.t("contacts.searchPlaceholder")}
                                        type="search"
                                        size="small"
                                        value={searchParam}

                                        onChange={handleSearch}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" >
                                                    <SearchIcon style={{ color: "gray" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <div className={classes.fifty}>
                                        <SelectUsers className={classes.fifty} multiple onChange={(value) => setUserIds(value ? value.map(userItem => userItem.id) : [])} outlined></SelectUsers>
                                    </div>
                                    <div className={classes.fifty}>
                                        <SelectTags className={classes.fifty} onChange={(value) => { if (value) setTagIds(value.map((tag) => tag.id)); else setTagIds([]) }} outlined></SelectTags>
                                    </div>
                                    <div className={classes.fifty}>
                                        <SelectQueues onChange={(value) => { if (value) setQueueIds(value.map((queue) => queue.id)); else setQueueIds([]) }} outlined></SelectQueues>
                                    </div>
                                    <div className={classes.fifty}>
                                        <FormControlLabel label={i18n.t("contactModal.title.show_only_groups")} control={
                                            <Checkbox
                                                checked={onlyGroups}
                                                onChange={handleOnlyGroupChange}
                                            />
                                        } />
                                    </div>

                                </MainHeaderButtonsWrapper>
                            }
                        </MainHeader>

                        <div className={classes.actionsButtons}>
                            <h5>{totalContacts} {i18n.t("contacts.title")}</h5>
                            <div className={classes.actionsDivButton}>
                                {(checked && checked.length >= 1 || allSelected) &&
                                    <React.Fragment>
                                        {(checked && checked.length >= 2) || allSelected &&
                                            <Can
                                                role={user.roleId}
                                                perform="contacts:any:history-export"
                                                yes={() => (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleHistoryExportBatch}
                                                        className={classes.buttonsTicket}
                                                        style={{ backgroundColor: 'black', color: '#ffffff', fontSize: '12px', gap: 4 }}
                                                    >
                                                        <SpeakerNotes style={{ fontSize: 13 }} />   {i18n.t("Exportar Histórico do Contato")} ({allSelected ? totalContacts : checked.length})
                                                    </Button>
                                                )}
                                                no={() => <></>}
                                            />}

                                        <Can
                                            role={user.roleId}
                                            perform="tags:change"
                                            yes={() => (
                                                tagIds && tagIds.length >= 1 && <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleDeleteTagsContacts}
                                                    className={classes.buttonsTicket}
                                                    style={{ backgroundColor: 'black', color: '#ffffff', fontSize: '12px' }}
                                                >
                                                    <RemoveCircleOutline style={{ fontSize: 13 }} />   {i18n.t("Remover Tags")} ({allSelected ? totalContacts : checked.length})
                                                </Button>
                                            )}
                                            no={() => <></>}
                                        />
                                        <Can
                                            role={user.roleId}
                                            perform="tags:add"
                                            yes={() => (
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleOpenAddTagModal}
                                                    className={classes.buttonsTicket}
                                                >
                                                    <AddIcon style={{ fontSize: 13 }} /> {i18n.t("contacts.buttons.addTags")} ({allSelected ? totalContacts : checked.length})
                                                </Button>
                                            )}
                                            no={() => <></>}
                                        />
                                        <Can
                                            role={user.roleId}
                                            perform="contacts:delete:any"
                                            yes={() => (
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={handleDeleteContacts}
                                                    className={classes.buttonsTicket}
                                                >
                                                    <RemoveCircleOutline style={{ fontSize: 13 }} />   {i18n.t("contacts.buttons.deleteContacts")} ({allSelected ? totalContacts : checked.length})
                                                </Button>)}
                                            no={() => <></>}
                                        />
                                    </React.Fragment>
                                }

                                {/*<Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonsTicket}
                                style={{ height: 31 }}
                                onClick={handleOpenContactTagsModal}
                            >
                                <LoyaltyOutlinedIcon style={{ fontSize: 13 }} /> Gerenciador de Tags
                        </Button>*/}



                                <Can
                                    role={user.roleId}
                                    perform="contacts:import"
                                    yes={() => (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.buttonsTicket}
                                            onClick={e => setImportOpen(true)}
                                        >
                                            <GetAppIcon style={{ fontSize: 13 }} /> {i18n.t("contacts.buttons.import")}
                                        </Button>)}
                                    no={() => <></>}
                                />

                                <Can
                                    role={user.roleId}
                                    perform="contacts:create"
                                    yes={() => (
                                        <Button
                                            variant="contained"
                                            className={classes.buttonsTicket}
                                            style={{ backgroundColor: 'black', color: '#ffffff', fontSize: '12px' }}
                                            onClick={handleOpenContactModal}
                                        >
                                            <AddIcon style={{ fontSize: 13 }} />  {i18n.t("contacts.buttons.add")}
                                        </Button>)}
                                    no={() => <></>}
                                />

                                <Can
                                    role={user.roleId}
                                    perform="contacts:export"
                                    yes={() => (
                                        <Button
                                            variant="contained"
                                            color="error"

                                            className={classes.buttonsTicket}
                                            onClick={handleExport}
                                            disabled={exporting}>
                                            <ShareIcon style={{ fontSize: 13 }} /> {!exporting ? i18n.t("contacts.buttons.export") : i18n.t("exporting")}
                                        </Button>
                                    )}
                                    no={() => <></>}
                                />

                                <Can
                                    role={user.roleId}
                                    perform="tickets:groups:create:contacts"
                                    yes={() => (
                                        <Button
                                            variant="contained"
                                            style={{ background: "#2ecc71", boxShadow: 'none', border: 0, color: '#000', gap: 3, fontWeight: 'bold' }}
                                            onClick={() => setNewTicketModalGroupOpen(true)}
                                        >
                                            <FaPlus size={12} /> {i18n.t("ticketsManager.buttons.newTicket")} Grupo
                                        </Button>
                                    )}
                                    no={() => <></>}
                                />
                            </div>
                        </div>

                        <TableContainer
                            component={Paper}
                            className={classes.mainPaperTable}
                            variant="outlined"
                            onScroll={e => handleScroll(e)}
                            style={{ overflow: 'auto' }}
                        >
                            <Table size="small" stickyHeader>
                                <TableHead className={classes.head}>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            {/**  <Checkbox onChange={toggleAll} />*/}
                                        </TableCell>
                                        <TableCell padding="checkbox" />
                                        <TableCell></TableCell>
                                        <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                                        <Can
                                            role={user.roleId}
                                            perform="contacts:list:any"
                                            yes={() => (
                                                <TableCell align="center">
                                                    {i18n.t("contacts.table.whatsapp")}
                                                </TableCell>)}
                                            no={() => <></>}
                                        />

                                        <TableCell>
                                            {i18n.t("contacts.table.user")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t("contacts.table.email")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t("contacts.table.tags")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t("contacts.table.actions")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ position: 'relative', overflow: 'auto', zIndex: 2 }}>
                                    <>
                                        {contacts.map(contact => (
                                            <TableRow key={contact.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell className={classes.tableCell}>
                                                    <Checkbox value={contact.id} checked={checked.indexOf("" + contact.id) !== -1} onChange={handleToggle} disabled={allSelected ? true : false} />
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {contact.blocked && <BlockIcon />}
                                                </TableCell>
                                                <TableCell style={{ paddingRight: 0, height: 61 }} className={classes.tableCell}>
                                                    {<Avatar src={contact.profilePicUrl} />}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>{contact.name}</TableCell>
                                                <Can
                                                    role={user.roleId}
                                                    perform="contacts:list:any"
                                                    yes={() => (
                                                        <TableCell align="center" className={classes.tableCell} style={{ display: 'flex', alignItems: 'center' }}>
                                                            {contact.isWhatsapp && <ConnectionIcon connectionType={'whatsapp'} />}
                                                            {contact.isFacebook && <ConnectionIcon connectionType={'facebook'} />}
                                                            {contact.isInstagram && <ConnectionIcon connectionType={'instagram'} />}
                                                            {contact.numberFormatted}
                                                        </TableCell>)}
                                                    no={() => <></>}
                                                />
                                                <TableCell className={classes.tableCell}>
                                                    {contact.user ? contact.user.name : ''} {contact.queue ? `(${contact.queue.name})` : ''}
                                                </TableCell>
                                                <TableCell align="center" className={classes.tableCell}>{contact.email}</TableCell>
                                                <TableCell align="center" className={classes.tableCell}>
                                                    {/*contact.tags && contact.tags.map(tag =>
                                                        (<span className={classes.tag} style={{ backgroundColor: tag.color }}>{tag.name}</span>)
                                                    )*/}
                                                    {contact.tags && contact.tags.length > 0 && (
                                                        <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                                                            {/*<AiOutlineTags />*/}
                                                            {contact.tags.slice(0, maxVisibleTags).map((tag) => (
                                                                <ContactTag tag={tag} key={contact.id} />
                                                            ))}
                                                            {contact.tags.length > maxVisibleTags && (
                                                                <>
                                                                    <Button
                                                                        style={{
                                                                            minWidth: 'initial',
                                                                            padding: 0, marginLeft: -5,
                                                                            background: '#f5f5f5', width: 14,
                                                                            height: 14, borderRadius: 100,
                                                                            fontSize: 11, fontWeight: 'bold'
                                                                        }}
                                                                        aria-controls={`tags-menu-${contact.id}`}
                                                                        aria-haspopup="true"
                                                                        onMouseEnter={(event) => handleClick(event, contact.id)}
                                                                        onClick={(event) => handleClick(event, contact.id)}
                                                                    >+{contact.tags.length}
                                                                    </Button>
                                                                    <Menu
                                                                        id={`tags-menu-${contact.id}`}
                                                                        anchorEl={anchorEl}
                                                                        open={Boolean(anchorEl) && visibleContactTag === contact.id}
                                                                        onClick={handleClose}
                                                                    >
                                                                        <div style={{ display: 'flex', flexDirection: 'column' }} onMouseLeave={handleClose}>
                                                                            {contact.tags.map((tag) => (
                                                                                <MenuItem key={contact.id}>
                                                                                    <ContactTag tag={tag} />
                                                                                </MenuItem>
                                                                            ))}
                                                                        </div>
                                                                    </Menu>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center" style={{ display: 'flex' }} className={classes.tableCell}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleSaveTicket(contact)}
                                                    >
                                                        <WhatsAppIcon />
                                                    </IconButton>
                                                    <Can
                                                        role={user.roleId}
                                                        perform="contacts:edit:any"
                                                        yes={() => (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => hadleEditContact(contact.id)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>)}
                                                        no={() => <></>}
                                                    />
                                                    <Can
                                                        role={user.roleId}
                                                        perform="contacts:any:history-export"
                                                        yes={() => (
                                                            <Tooltip title="Exportar Histórico do Contato">
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={e => {
                                                                        handleHistoryExport(contact.id);
                                                                    }}
                                                                >
                                                                    <SpeakerNotes />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        no={() => <></>}
                                                    />
                                                    <Can
                                                        role={user.roleId}
                                                        perform="contacts:delete:any"
                                                        yes={() => (
                                                            <IconButton
                                                                size="small"
                                                                onClick={e => {
                                                                    setConfirmOpen(true);
                                                                    setDeletingContact(contact);
                                                                }}
                                                            >
                                                                <DeleteOutlineIcon />
                                                            </IconButton>
                                                        )}
                                                        no={() => <></>}
                                                    />


                                                    {!contact.isGroup &&
                                                        <Can
                                                            role={user.roleId}
                                                            perform="contacts:import"
                                                            yes={() => (
                                                                <IconButton
                                                                    alt="Sicronizar do Celular"
                                                                    size="small"
                                                                    onClick={() => handleSyncSingleContact(contact.id)}
                                                                >
                                                                    <TapAndPlay />
                                                                </IconButton>)}
                                                            no={() => <></>}
                                                        />
                                                    }


                                                    {contact.isGroup &&
                                                        <Can
                                                            role={user.roleId}
                                                            perform="contacts:edit:any:users"
                                                            yes={() => (
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={e => {
                                                                        setSelectedContact(contact);
                                                                        setContactAddUserOpen(true);
                                                                    }}
                                                                >
                                                                    <GroupIcon />
                                                                </IconButton>
                                                            )}
                                                            no={() => <></>}
                                                        />}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {loading && <TableRowSkeleton avatar columns={7} />}
                                    </>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>)}
                no={() => <>
                    <ForbiddenPage />
                </>}
            />
        </MainContainer>
    );
};

export default Contacts;