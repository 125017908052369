import React, { useState, useContext, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { i18n } from "../../translate/i18n";
//import { saveAs } from 'file-saver';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import LoadingBox from "../LoadingBox";
import { toast } from "react-toastify";
import PromptTextToSpeechModal from "../PromptTexttoSpeechModal";
import { PiNumberTwoFill } from "react-icons/pi";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	answerLoading: {
		minHeight: 300,
		maxHeight: 300,
		width: 300,
		margin: 20,
		borderRadius: 10,
		background: theme.palette.blackWhiteBackground,
		display: 'flex',
		alignContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	h1Loading: {
		fontSize: 18
	},
	answerBlock: {
		fontSize: 16,
		lineHeight: '27px',
		padding: 20,
		background: theme.palette.fancyBackground,
		borderRadius: 10,
		//margin: 20,
		minHeight: 200,
		maxHeight: 400,
		overflow: 'auto',
		...theme.scrollbarStylesSoft,
	},

	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},
	circleLoading: {
		color: green[500],
		opacity: "70%"
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
	greetingMessage: Yup.string(),
});

const PromptReportsAIModal = ({ open, onClose, title, dataAI, handleAIMessage }) => {
	const classes = useStyles();
	const [answer, setAnswer] = useState('');
	const { getSettingValue } = useContext(SettingsContext);
	const apiKey = getSettingValue('keyOpenAi');
	const [loading, setLoading] = useState(false);
	const greetingRef = useRef();
	const paragraphs = answer && answer.split('\n');

	const handleClose = () => {
		setAnswer('');
		onClose();		
	};

	const CopytoMessage = () => {
		onClose();
		handleAIMessage(answer);
		navigator.clipboard.writeText(answer);
	};


	const handleReloadForm = () => {
		setAnswer('');
	}


	const handleAI = async () => {
		try {
			let data = {
				title: title,
				charts: dataAI,
				apikey: apiKey
			}
			setLoading(true);
			if (dataAI && open) {
				const response = await api.post("/ai/reports", data);
				if (response.data) {
					console.log(response.data);
					setAnswer(response.data.choices[0].message.content)
				}
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toast.error(i18n.t("backendErrors.ERR_OPENAI"));
		}
	};


	useEffect(() => {
		if (dataAI && open) {
			handleAI();
		}
	}, [open]);

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					Métricas AI
				</DialogTitle>

				{loading && !answer ?
					<LoadingBox /> : (
						<div className={classes.answerBlock}>
							{answer &&
								<>
									<PromptTextToSpeechModal
										open={false}
										aria-labelledby="form-dialog-title"
										message={{ body: answer }}
										icon={true}
									/>
									{paragraphs && paragraphs.map((paragraph, index) => (
										<p className={classes.answer} key={index}>{paragraph}</p>
									))}
								</>
							}
						</div>
					)}

				{answer && (
					<DialogActions>
						<Button
							onClick={handleClose}
							color="secondary"
							variant="outlined"
						>
							{/*i18n.t("promptAiModal.buttons.close")*/}
							Fechar
						</Button>
						<Button
							type="submit"
							color="primary"
							variant="contained"
							className={classes.btnWrapper}
							onClick={() => CopytoMessage()}>
							{i18n.t("promptAiModal.buttons.copy")}
						</Button>
					</DialogActions>
				)}
			</Dialog>
		</div>
	);
};

export default PromptReportsAIModal