import { FormControlLabel, FormGroup, Grid, Table, TableRow, TextField, Checkbox, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import makeStyles from '@mui/styles/makeStyles';
import Input from "@mui/material/Input";
import SettingIntEnabledField from "../../components/Setting/intEnabledField";
import { ImportExport } from "@mui/icons-material";
//import TimeInOutLogin from "../../components/TimeInOutLogin";


const useStyles = makeStyles(theme => ({
    userRolesEditContainer: {

    },
    permissionsContainer: {
        marginTop: 20,
        marginBottom: 40
    },
    permissionTitle: {
        fontSize: "1.2em"
    },
    controlLabel: {
        marginLeft: 0
    }
}));

const UserRolesEdit = ({ roleId, onSave }) => {
    const [role, setRole] = useState(null);
    const [name, setName] = useState('');
    const [limitTickets, setLimitTickets] = useState(null);
    const [checkedIds, setCheckedIds] = useState([]);
    // const [checked, setChecked] = useState({});
    const [permissions, setPermissions] = useState(null);
    const [selectAllChecked, setSelectAllChecked] = useState(false);


    const handleSelectAllChange = (event, permissionKey) => {
        const allItemIds = permissions[permissionKey].items.map(item => item.id);
        setSelectAllChecked(prevState => ({
            ...prevState,
            [permissionKey]: event.target.checked,
        }));

        if (event.target.checked) {
            setCheckedIds([...checkedIds, ...allItemIds]);
        } else {
            setCheckedIds(checkedIds.filter(checkId => !allItemIds.includes(checkId)));
        }
    };


    const classes = useStyles();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchRole = async () => {
                try {
                    const { data } = await api.get(`/roles/${roleId}`);
                    setData(data);

                    //console.log('result', data)
                } catch (err) {
                    toastError(err);
                }
            };
            const fetchPermissions = async () => {
                try {
                    const { data } = await api.get(`/roles/permissions`);
                    setPermissions(data.permissions);
                } catch (err) {
                    toastError(err);
                }
            }
            if (roleId) {
                fetchRole();
            } else {
                fetchPermissions();
            }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [roleId]);

    const save = () => {
        const data = {
            name: name,
            limitTickets: limitTickets,
            permissionIds: checkedIds
        };
        (async () => {
            if (roleId) {
                const { data: responseData } = await api.put(`/roles/${roleId}`, data);
                setData(responseData);
                if (onSave) {
                    onSave(responseData.role);
                }
            } else {
                const { data: responseData } = await api.post(`/roles/`, data);
                //console.log('ou aqui:', data)
                setData(responseData);
                if (onSave) {
                    onSave(responseData.role);
                }
            }
        })();
    }

    useEffect(() => {
        if (role) {
            setName(role.name);
            setLimitTickets(role.limitTickets)
            setCheckedIds(role.permissions.map(permission => permission.id));
        }
    }, [role]);


    const setData = (data) => {
        setRole(data.role);
        setPermissions(data.permissions);
    }

    const onChangePermission = (permissionId, event) => {

        if (event.target.checked) {
            if (event.target.group) {
                const allPermissionIds = permissions[event.target.group]?.items.map(item => item.id);

                setCheckedIds([...checkedIds, ...allPermissionIds]);
                // console.log(checkedIds)

            } else {
                setCheckedIds([...checkedIds, permissionId]);
            }
        } else {
            if (permissionId === 'selectAll') {
                setCheckedIds([]);
            } else {
                setCheckedIds(checkedIds.filter(checkId => checkId !== permissionId));
            }
        }
    };

    return (<div className={classes.userRolesEditContainer}>
        <TextField variant="outlined" fullWidth placeholder="Nome" value={name} onChange={(event) => setName(event.target.value)} />
        {role && !role.isAdmin && permissions && Object.keys(permissions).map(permissionKey => {
            return (
                <div key={`permission_${permissionKey}`}>
                    <Grid item md={12} xs={12} style={{ display: 'flex' }}>
                        <h2 className={classes.permissionTitle}>{i18n.t(`settings.permissions.title.${permissions[permissionKey].group}`)}</h2>
                        <FormControlLabel
                            className={classes.controlLabel}
                            control={
                                <Checkbox
                                    checked={selectAllChecked[permissionKey] || false}
                                    onChange={(event) => handleSelectAllChange(event, permissionKey)}
                                    name={`selectAll_${permissionKey}`}
                                />
                            }
                            label="Todas"
                        />
                    </Grid>
                    <Grid className={classes.permissionsContainer} container>
                        {permissions[permissionKey].items.map(item => {
                            return (
                                item.label &&
                                (item.key === 'tickets:limit:tickets:by:user' ?
                                    (
                                        <div item md={12} key={`permission_item_${item.id}`} style={{ width: '100%', display: 'flex', paddingTop: 10 }}>
                                            <Grid item md={3} xs={6}>
                                                <FormControlLabel
                                                    className={classes.controlLabel}
                                                    control={<Checkbox checked={checkedIds && checkedIds.indexOf(item.id) > -1} onChange={event => onChangePermission(item.id, event)} name={item.key} />}
                                                    label={item.label}
                                                />
                                            </Grid>
                                            {item.key === 'tickets:limit:tickets:by:user' && checkedIds.some(permission => permission === item.id) && (
                                                <Grid item md={3} xs={6} key={`setting_field_${item.id}`}>
                                                    <TextField
                                                        name="ticketLimitPerUser"
                                                        value={limitTickets}
                                                        label={i18n.t("settings.settings.appointments.limitTicketsOpen.name")}
                                                        placeholder={i18n.t("settings.settings.appointments.limitTicketsOpen.helpText")}
                                                        onChange={(event) => setLimitTickets(event.target.value)}
                                                    />
                                                </Grid>
                                            )}
                                        </div>
                                    )
                                    :

                                    (

                                        <Grid item md={3} xs={6} key={`permission_item_${item.id}`} className={item.key}>
                                            <FormControlLabel
                                                className={classes.controlLabel}
                                                control={<Checkbox checked={checkedIds && checkedIds.indexOf(item.id) > -1} onChange={event => onChangePermission(item.id, event)} name={item.key} />}
                                                label={item.label}
                                            />
                                        </Grid>
                                    )
                                )
                            );
                        })}


                    </Grid>
                </div>
            )
        })}
        {/*role && !role.isAdmin && permissions && (
            <TimeInOutLogin isPermission />
        )*/}
        <div style={{ width: '100%', marginTop: 10, display: 'flex', alignItems: 'end' }}>
            <Button variant="outlined" onClick={save}>Salvar</Button>
        </div>
    </div>);
}

export default UserRolesEdit;