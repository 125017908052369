import React, { useContext } from "react";
import { FormControlLabel } from "@mui/material";
import { UserSettingsContext } from "../../context/Settings/UserSettingsContext";
import IOSSwitch from "../IOSSwitch";

const SettingBoolField = ({ name, value, label }) => {
  const { handleChangeSetting } = useContext(UserSettingsContext);

  const handleChange = event => {
	const newValue = event.target.checked ? true :false;
	handleChangeSetting(name, newValue);
  };

  return (
    <FormControlLabel
      control={
        <IOSSwitch
          checked={value}
          onChange={handleChange}
          name={name}
        />
      }
      label={label}
    />
  );
};

export default SettingBoolField;
