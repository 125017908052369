import React, { useState, useEffect, useReducer } from "react";
import makeStyles from '@mui/styles/makeStyles';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Paper } from "@mui/material";
import { FaTasks } from "react-icons/fa";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import TaskModal from "../../components/TaskModal";
import AddTaskTicketModal from "../../components/AddTaskTicketModal";
import AddColumnTaskTicketModal from "../../components/AddColumnTaskTicketModal";
import { DragDropContext } from 'react-beautiful-dnd';
import getSocket from "../../helpers/socket";
import Task from "../../components/Task";



const socket = getSocket();

const reducer = (state, action) => {
	if (action.type === "LOAD_TASKS") {
		const tasks = action.payload;
		return tasks;
	}

	if (action.type === "UPDATE_TASKS") {
		const tasks = action.payload;
		const index = state.findIndex(c => c.id === tasks.id);

		if (index !== -1) {
			state[index] = tasks;
			return [...state];
		} else {
			return [tasks, ...state];
		}
	}

	if (action.type === "DELETE_TASKS") {
		const tasksId = action.payload;

		const index = state.findIndex(c => c.id === tasksId);
		if (index !== -1) {
			state.splice(index, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};


const useStyles = makeStyles(theme => ({
	Column: {
		margin: '8px',
		border: 'none',
		borderRadius: '2px',
		display: 'flex',
		flexDirection: 'column',
		width: '320px',
		boxShadow: 'none',
		maxHeight: '81vh',
		overflow: 'hidden'
	},
	TaskMiddle: {
		maxHeight: '70vh',
		overflowY: "scroll",
		...theme.scrollbarStylesSoft,
	},
	buttonTask: {
		background: 'transparent !important'
	},
	blockTitleTask: {
		padding: '5px 15px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'space-between'
	},
	avatares: {
		display: 'flex'
	},
	Task: {
		padding: '15px',
		border: '1px solid lightgrey',
		boxShadow: '0 2px 4px 0 rgba(0,0,0,.04)',
		borderRadius: '20px',
		margin: '8px',
		background: 'transparent',
		display: 'flex',
		transition: 'width 0.5s',
		'&:hover': {
			'& $checkboxDiv': {
				width: '70px',
			},
		},
	},
	checkboxDiv: {
		width: 0,
		overflow: 'hidden'
	},
	contDiv: {
		width: '100%'
	},
	titleTask: {
	},
	descTask: {
		fontSize: 14,
		color: '#939292',
		height: 20,
		overflow: 'hidden'
	},
	spanTitleTask: {
		fontWeight: 100,
		fonSize: 13,
		color: '#eee'
	},
	mainPaper: {
		flex: 1,
		//padding: theme.spacing(1),
		borderRadius: 0,
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	helpMessage: {
		padding: "10px"
	},
	tag: {
		padding: 3,
		paddingLeft: 10,
		paddingRight: 10,
		color: "#FFF",
		fontWeight: "bold",
		borderRadius: 4,
		display: "inline-block"
	},
	initialMessage: {
		padding: 10,
		textAlign: 'center'
	},
	mainBox: {
		padding: 20,
		border: "1px solid #CCC",
		borderRadius: 10,
		margin: 20
	},
	options: {
		marginTop: 20
	},
	listItem: {
		listStyle: "none",
		border: "1px solid #CCC",
		padding: 5,
		margin: 5
	},
	listItems: {
		margin: 0,
		padding: 0
	},
	listItemNumber: {
		borderRadius: "50%",
		padding: 15,
		textAlign: "center",
		fontWeight: "bold",
		background: "#666",
		color: "#FFF",
		display: "inline-block",
		fontSize: "16px",
		marginRight: 20
	},
	listItemActionLabel: {
		display: "inline-block",
		marginLeft: 20,
		marginRight: 15
	},
	actions: {
		display: "inline-block"
	},
	cleanButtonWrapper: {
		textAlign: "right"
	},
	flexRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%'
	},
	menuBlock: {
		'& .MuiMenu-paper': {
			boxShadow: '0 2px 4px 0 rgba(0,0,0,.04)'
		}
	},
	boxButtonAdd: {
		padding: 15,
		border: '2px dashed #ddd',
		width: '100%',
		marginTop: 20,
		borderRadius: 10
	},
	buttonAdd: {
		backgroundColor: '#bdc3c7', 
		width: '100%',
		color: '#ffffff', 
		fontSize: '14px', 
		margin: '2px',
		padding: 5,
		borderRadius: 10
	}
}));

const Tasks = () => {
	const classes = useStyles();
	const [taskModalOpen, setTaskModalOpen] = useState(false);
	const [selectedTaskId, setTaskId] = useState(0);
	const [actionTask, setNewTaskOpen] = useState(false);

	const [activeColumn, setActiveColumn] = useState(null);

	const [addTaskModalOpen, setNewTaskModalOpen] = useState(false);
	const [addColumnTaskModalOpen, setNewColumnTaskModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	//form
	const [tasks, dispatch] = useReducer(reducer, []);

	const fetchTasks = async () => {
		try {
			setLoading(true);
			dispatch({ type: "LOAD_TASKS", payload: [] });
			const response = await api.get("/tasks/columns/");
			console.log('foi', response.data)
			if (response.data) {
				dispatch({ type: "LOAD_TASKS", payload: response.data });
			}
			//setTasks(response.data)
			setLoading(false);
		} catch (err) {
			toastError(err);
		}
	};

	useEffect(() => {
		fetchTasks();
	}, []);

	useEffect(() => {
		const tasksEvent = data => {
			if (data.action === "update" || data.action === "create") {
				dispatch({ type: "UPDATE_TASKS", payload: data });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_TASKS", payload: +data.tasksId });
			}
		}

		socket.on("tasks", tasksEvent);

		return () => {
			socket.off("tasks", tasksEvent);
		};
	}, []);


	const handleAddColumn = () => {
		/*const newColumn = {
			id: `column-${tasks.length + 1}`,
			tasks: [],
		};
		setTasks([...tasks, newColumn]);*/
		setNewColumnTaskModalOpen(true)
	};

	const onDragEnd = async (result) => {
		const { source, destination } = result;
		console.log(source, 'source', 'destination', destination, 'taskId', selectedTaskId);
		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			// Reorder tasks within the same column

			//	console.log(movedTask, 'movedTask');

			//column.splice(destination.index, 0, movedTask);


			const response = await api.put(`/tasks/${selectedTaskId}/position/${destination.droppableId}`);
			if (response.data) {
				fetchTasks();
			}


			/*
				 await setTasks((prevTasks) => ({ 
						...prevTasks, 
						[source.droppableId]: { ...prevTasks[source.droppableId], tasks: column } }));*/
		} else {
			// Move task to a different column

			//console.log(movedTask, 'movedTask');
			//console.log(sourceColumn, 'sourceColumn');
			//const destinationColumn = tasks[destination.droppableId].tasks; // Access the tasks array

			//destinationColumn.splice(destination.index, 0, movedTask);
			//console.log(movedTask,'destination', destination.id)
			const response = await api.put(`/tasks/${selectedTaskId}/position/${destination.droppableId}/column/${destination.droppableId}`);
			if (response.data) {
				//console.log(response)
				fetchTasks();
			}


			/*
					  await setTasks((prevTasks) => ({
						...prevTasks,
						[source.droppableId]: { ...prevTasks[source.droppableId], tasks: sourceColumn },
						[destination.droppableId]: { ...prevTasks[destination.droppableId], tasks: destinationColumn },
					  }));*/
		}
	};



	const handleCloseTaskModal = (result) => {
		setTaskModalOpen(false);
	};

	const handleSetIdTask = (id) => {
		setTaskId(id);
		setTaskModalOpen(true)
	
	};



	const handleCloseAddModalTask = () => {
		setActiveColumn(null);
		setNewTaskModalOpen(false);
	};

	const handleCloseAddTask = () => {
		setActiveColumn(null);
		setNewTaskOpen(false);
		//fetchTasks();
	};

	const handleCloseColumnAddTask = () => {
		setActiveColumn(null);
		setNewColumnTaskModalOpen(false);
		fetchTasks();
	};



	return (
		<MainContainer className={classes.mainContainer}>
			<TaskModal
				open={taskModalOpen}
				onClose={handleCloseTaskModal}
				aria-labelledby="form-dialog-title"
				taskId={selectedTaskId}
			>
			</TaskModal>

			<AddTaskTicketModal
				open={addTaskModalOpen}
				isModal={true}
				onClose={handleCloseAddModalTask}
				aria-labelledby="form-dialog-title"
				taskId={selectedTaskId}
			>
			</AddTaskTicketModal>

			<AddColumnTaskTicketModal
				open={addColumnTaskModalOpen}
				isModal={true}
				onClose={handleCloseColumnAddTask}
				aria-labelledby="form-dialog-title"
				taskId={selectedTaskId}
			>
			</AddColumnTaskTicketModal>



			<MainHeader>
				<Title><FaTasks size={18} /> Tarefas</Title>
			</MainHeader>
			<Paper
				className={classes.mainPaper}
				variant="outlined"
			>

				<DragDropContext onDragEnd={onDragEnd}>
					<div style={{ display: 'flex', backgroundColor: '' }}>

						<Task
							tasks={tasks}
							actionTask={actionTask}
							fetchTasks={fetchTasks}
							handleCloseAddTask={handleCloseAddTask}
							setTaskId={handleSetIdTask}						
						/>
						<Paper
							className={classes.Column}
						>

							<div className={classes.blockTitleTask}>
								<div className={classes.flexRow}>
									<h6 className={classes.titleTask}>New Column</h6>
								</div>
							</div>

							<div className={classes.TaskMiddle}>
								<div className={classes.boxButtonAdd}>
									<button
										className={classes.buttonAdd}
										style={{  }}
										onClick={handleAddColumn}>Add Column</button>
								</div>
							</div>
						</Paper>

					</div>
				</DragDropContext>
			</Paper>
		</MainContainer>
	);
};

export default Tasks;