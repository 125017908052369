import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";

const useStyles = makeStyles(theme => ({
	mainContainer: {
		flex: 1,
		//padding: theme.spacing(2),
		padding: '0px 24px 0px 24px',
		[theme.breakpoints.down('md')]: {
			padding: '0px 5px 24px 5px',
		},
		height: '100%',
		background: theme.palette.fancyBackground, 
	},

	contentWrapper: {
		height: "100%",
		overflowY: "hidden",
		display: "flex",
		flexDirection: "column",
	},
}));

const MainContainer = ({ children }) => {
	const classes = useStyles();

	return (
		<Container className={classes.mainContainer} maxWidth={false}>
			<div className={classes.contentWrapper}>{children}</div>
		</Container>
	);
};

export default MainContainer;
