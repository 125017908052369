import React, { useState, useContext, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import { Menu } from "@mui/material";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { EditMessageContext } from "../../context/EditingMessage/EditingMessageContext";
import toastError from "../../errors/toastError";
import ForwardMessageModal from "../ForwardMessageModal";
import AddNoteModal from "../AddNoteModal";
import AddTaskTicketModal from "../AddTaskTicketModal";
import PromptTranslateModal from "../PromptTranslateModal";
import PromptSearchModal from "../PromptSearchModal";
import PromptAudiotoTextModal from "../PromptAudiotoTextModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import PromptImageModal from "../PromptImageModal";
import PromptTextToSpeechModal from "../PromptTexttoSpeechModal";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, handleNoteCreated, handleReactCreated, ticketId, anchorEl, handleAIMessage }) => {
	const { setReplyingMessage } = useContext(ReplyMessageContext);
	const { setEditingMessage } = useContext(EditMessageContext);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [messageForwardModalOpen, setmessageForwardModalOpen] = useState(false);
	const [addNoteModalOpen, setAddNoteModalOpen] = useState(false);
	const [translateModalOpen, setTranslateModalOpen] = useState(false);
	const [searchModalOpen, setSearchModalOpen] = useState(false);
	const [transcribeModalOpen, setTranscribeModalOpen] = useState(false);
	const [addTaskModalOpen, setAddTaskModalOpen] = useState(false);
	const uniqueMessage = Array.isArray(message) ? message[0] : message;
	const { getSettingValue } = useContext(SettingsContext);
	const { user, whatsType } = useContext(AuthContext);
	const keyOpenAi = getSettingValue('keyOpenAi');
	const [promptImageModalOpen, setPromptImageModalOpen] = useState(false);
	const [promptTextToSpeechOpen, setPromptTextToSpeechOpen] = useState(false);
	const [typeWhats, setTypeWhats] = useState('');

	const handleDeleteMessage = async () => {
		try {
			await api.delete(`/messages/${uniqueMessage.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const toDataURL = async (url) => {
		return fetch(url).then((response) => {
			return response.blob();
		}).then(blob => {
			return URL.createObjectURL(blob);
		});
	}

	const download = async (url, name) => {
		const a = document.createElement("a");
		a.href = await toDataURL(url);
		a.download = name;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	const handleDownloadMedias = async () => {
		//window.open(message.mediaUrl);
		var urlParts = message.mediaUrl.split('/');
		download(message.mediaUrl, urlParts[urlParts.length - 1]);
		message.childs.forEach((m) => {
			urlParts = m.mediaUrl.split('/');
			download(m.mediaUrl, urlParts[urlParts.length - 1]);
		});
	}

	const handleDownloadMediaZip = async () => {
		const fileContent = await api.get('/messages/' + message.id + '/download', { responseType: 'arraybuffer' });
		const url = window.URL.createObjectURL(new Blob([fileContent.data], { type: 'arraybuffer' }));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'imagens-' + message.id + '.zip'); //or any other extension
		document.body.appendChild(link);
		link.click();
	}

	const handleReplyMessage = () => {
		setReplyingMessage(uniqueMessage);
		handleClose();
	};

	const handleFixMessage = async () => {
			try {
				await api.post(`/messages/${uniqueMessage.id}/pin`, {ticket: ticketId});
			} catch (err) {
				toastError(err);
			}
		handleClose();
	};

	const handleEditMessage = () => {
		setEditingMessage(uniqueMessage);
		handleClose();
	};


	const handleAddNote = () => {
		setAddNoteModalOpen(true)
		handleClose();
	};

	const handleCloseAddNoteModal = () => {
		setAddNoteModalOpen(false)
		handleClose();
	};

	const handleForwardMessage = () => {
		setmessageForwardModalOpen(true)
		handleClose();
	};

	const handleCloseForwardMessageModal = () => {
		setmessageForwardModalOpen(false)
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
		handleClose();
	};

	const handleNoteAdd = () => {
		handleNoteCreated();
	}

	const handleAddReact = () => {
		handleReactCreated(message.id);
		handleClose();
	};


	const handleAddTask = () => {
		setAddTaskModalOpen(true)
		handleClose();
	};

	const handleCloseAddTaskModal = () => {
		setAddTaskModalOpen(false)
	};

	const handleTranslate = () => {
		setTranslateModalOpen(true)
		handleClose();
	};

	const handleSearch = () => {
		setSearchModalOpen(true)
		handleClose();
	};

	const handleCloseTranslateModal = () => {
		setTranslateModalOpen(false)
	};

	const handleCloseSearchModal = () => {
		setSearchModalOpen(false)
	};

	const handleAudioTranscribe = () => {
		setTranscribeModalOpen(true)
		handleClose();
	};


	const handlePromptImage = () => {
		setPromptImageModalOpen(true)
		handleClose();
	};

	const handleTextToSpeech = () => {
		setPromptTextToSpeechOpen(true)
		handleClose();
	};

	const handleClosePromptImageModal = () => {
		setPromptImageModalOpen(false)
	};

	const handleCloseTextToSpeechModal = () => {
		setPromptTextToSpeechOpen(false)
	};

	const handleCloseAudioTranscribeModal = () => {
		setTranscribeModalOpen(false)
	};

	useEffect(() => {
		const getWhatsappType = () => {
			const type = localStorage.getItem("typeWhats");
			if (!typeWhats && type) {
				setTypeWhats(type);
			}
		}
		getWhatsappType();
	}, [])

	return (
		<>
			<ConfirmationModal
				title={i18n.t("messageOptionsMenu.confirmationModal.title")}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteMessage}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>

			<ForwardMessageModal
				open={messageForwardModalOpen}
				onClose={handleCloseForwardMessageModal}
				aria-labelledby="form-dialog-title"
				message={message}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</ForwardMessageModal>


			<AddNoteModal
				open={addNoteModalOpen}
				onClose={handleCloseAddNoteModal}
				aria-labelledby="form-dialog-title"
				message={message}
				ticketId={ticketId}
				noteCreated={handleNoteAdd}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</AddNoteModal>

			{addTaskModalOpen &&
			<AddTaskTicketModal
				open={addTaskModalOpen}
				onClose={handleCloseAddTaskModal}
				aria-labelledby="form-dialog-title"
				message={message}
				isModal={true}
				ticketId={ticketId}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</AddTaskTicketModal>}


			{translateModalOpen &&
				<PromptTranslateModal
				open={translateModalOpen}
				onClose={handleCloseTranslateModal}
				aria-labelledby="form-dialog-title"
				message={message}
				ticketId={ticketId}
				handleAIMessage={handleAIMessage}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</PromptTranslateModal>}

			{searchModalOpen && 
			<PromptSearchModal
				open={searchModalOpen}
				onClose={handleCloseSearchModal}
				aria-labelledby="form-dialog-title"
				message={message}
				ticketId={ticketId}
				handleAIMessage={handleAIMessage}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</PromptSearchModal>}
			
			{transcribeModalOpen && 
			<PromptAudiotoTextModal
				open={transcribeModalOpen}
				onClose={handleCloseAudioTranscribeModal}
				aria-labelledby="form-dialog-title"
				message={message}
				ticketId={ticketId}
				handleAIMessage={handleAIMessage}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</PromptAudiotoTextModal>}

			{promptImageModalOpen && 
			<PromptImageModal
				open={promptImageModalOpen}
				onClose={handleClosePromptImageModal}
				aria-labelledby="form-dialog-title"
				message={message}
				ticketId={ticketId}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</PromptImageModal>}
			{promptTextToSpeechOpen && 
			<PromptTextToSpeechModal
				open={promptTextToSpeechOpen}
				onClose={handleCloseTextToSpeechModal}
				aria-labelledby="form-dialog-title"
				message={message}
				ticketId={ticketId}				
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</PromptTextToSpeechModal>}


			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				{[
					
					<MenuItem key="fix" onClick={handleFixMessage}>
						{i18n.t("Fixar")}
					</MenuItem>,
					<MenuItem key="reply" onClick={handleReplyMessage}>
						{i18n.t("messageOptionsMenu.reply")}
					</MenuItem>,
					typeWhats === 'baileys' && (
						<MenuItem key="react" onClick={handleAddReact}>
							{i18n.t("messageOptionsMenu.react")}
						</MenuItem>
					),
					uniqueMessage.fromMe && uniqueMessage.mediaType === 'text' && (
						<MenuItem key="edit" onClick={handleEditMessage}>
							{i18n.t("messageOptionsMenu.edit")}
						</MenuItem>
					),
					uniqueMessage.fromMe && (
						<MenuItem key="delete" onClick={handleOpenConfirmationModal}>
							{i18n.t("messageOptionsMenu.delete")}
						</MenuItem>
					),
					<MenuItem key="forward" onClick={handleForwardMessage}>
						{i18n.t("messageOptionsMenu.forward")}
					</MenuItem>,
					<MenuItem key="note" onClick={handleAddNote}>
						{i18n.t("messageOptionsMenu.create_note")}
					</MenuItem>,
					(keyOpenAi || user && user.roleId === 1) && [
						<MenuItem key="translate" onClick={handleTranslate}>
							{i18n.t("messageOptionsMenu.translate")}
						</MenuItem>,
						message.mediaType !== 'image' && (
							<MenuItem key="search" onClick={handleSearch}>
								{i18n.t("messageOptionsMenu.search")}
							</MenuItem>
						),
						message.mediaType === 'audio' && (
							<MenuItem key="transcribe" onClick={handleAudioTranscribe}>
								{i18n.t("messageOptionsMenu.transcribe")}
							</MenuItem>
						),
						message.mediaType === 'image' && (
							<MenuItem key="promptImage" onClick={handlePromptImage}>
								{i18n.t("messageOptionsMenu.promptImage")}
							</MenuItem>
						),
						message.mediaType !== 'image' && (
							<MenuItem key="textToSpeech" onClick={handleTextToSpeech}>
								{i18n.t("messageOptionsMenu.textToSpeech")}
							</MenuItem>
						),
					],
					message.childs && message.childs.length > 0 && (
						<MenuItem key="downloadAll" onClick={handleDownloadMedias}>
							{i18n.t("messageOptionsMenu.download_all")}
						</MenuItem>
					),
					message.childs && message.childs.length > 0 && (
						<MenuItem key="downloadZip" onClick={handleDownloadMediaZip}>
							{i18n.t("messageOptionsMenu.download_zip")}
						</MenuItem>
					)
				]}
			</Menu>

		</>
	);
};

export default MessageOptionsMenu;
