
import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import api from "../../services/api";
import { Formik, Form, Field } from "formik";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import SelectUsers from "../../components/SelectUsers"
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Paper } from "@mui/material";
import { FaPlus, FaTrash, FaPencilAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
	Column: {
		margin: '8px',
		border: 'none',
		borderRadius: '2px',
		display: 'flex',
		flexDirection: 'column',
		width: '320px',
		boxShadow: 'none',
		maxHeight: '81vh',
		overflow: 'hidden'
	},
	TaskMiddle: {
		maxHeight: '70vh',
		overflowY: "scroll",
		...theme.scrollbarStylesSoft,
	},
	buttonTask: {
		background: 'transparent !important'
	},
	blockTitleTask: {
		padding: '5px 15px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'space-between'
	},
	avatares: {
		display: 'flex'
	},
    
	Task: {
		padding: '15px',
		border: '1px solid lightgrey',
		boxShadow: '0 2px 4px 0 rgba(0,0,0,.04)',
		borderRadius: '20px',
		margin: '8px',
		background: 'transparent',
		display: 'flex',
		transition: 'width 0.5s',
		'&:hover': {
			'& $checkboxDiv': {
				width: '70px',
			},
            '& $buttonActionsTask': {
				opacity: 1,
			},
		},
	},
    buttonActionsTask:{
        opacity: 0,
        transition: 'all ease 0.5s'
    },
	checkboxDiv: {
		width: 0,
		overflow: 'hidden'
	},
	contDiv: {
		width: '100%',
	},
	titleTask: {
	},
	descTask: {
		fontSize: 14,
		color: '#939292',
		height: 20,
		overflow: 'hidden'
	},
	spanTitleTask: {
		fontWeight: 100,
		fonSize: 13,
		color: '#eee'
	},
	mainPaper: {
		flex: 1,
		//padding: theme.spacing(1),
		borderRadius: 0,
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	helpMessage: {
		padding: "10px"
	},
	tag: {
		padding: 3,
		paddingLeft: 10,
		paddingRight: 10,
		color: "#FFF",
		fontWeight: "bold",
		borderRadius: 4,
		display: "inline-block"
	},
	initialMessage: {
		padding: 10,
		textAlign: 'center'
	},
	mainBox: {
		padding: 20,
		border: "1px solid #CCC",
		borderRadius: 10,
		margin: 20
	},
	options: {
		marginTop: 20
	},
	listItem: {
		listStyle: "none",
		border: "1px solid #CCC",
		padding: 5,
		margin: 5
	},
	listItems: {
		margin: 0,
		padding: 0
	},
	listItemNumber: {
		borderRadius: "50%",
		padding: 15,
		textAlign: "center",
		fontWeight: "bold",
		background: "#666",
		color: "#FFF",
		display: "inline-block",
		fontSize: "16px",
		marginRight: 20
	},
	listItemActionLabel: {
		display: "inline-block",
		marginLeft: 20,
		marginRight: 15
	},
	actions: {
		display: "inline-block"
	},
	cleanButtonWrapper: {
		textAlign: "right"
	},
	flexRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%'
	},
	menuBlock: {
		'& .MuiMenu-paper': {
			boxShadow: '0 2px 4px 0 rgba(0,0,0,.04)'
		}
	}
}));

const taskSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    description: Yup.string().min(8, "Too Short!").max(3000, "Too Long!"),
});


const Task = ({ tasks, fetchTasks, actionTask, handleCloseAddTask, setTaskId }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
    const [taskId, setTheTaskId] = useState(false);
    const [taskTitle, setTaskTitle] = useState('');

	const [taskModalOpen, setTaskModalOpen] = useState(false);

	const [activeColumn, setActiveColumn] = useState(null);
	
	const [addTaskModalOpen, setNewTaskModalOpen] = useState(false);
	const [addColumnTaskModalOpen, setNewColumnTaskModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const [taskAdd, setAddForm] = useState([{}]);




    const handleAddTask = async ({ title, description, column }) => {

        try {

            console.log(taskTitle, description, 'columns is', column)
            const formData = new FormData();
            formData.append('title', taskTitle);
            formData.append('content', description);
            formData.append('columnId', column);
            /*formData.append('isAudio', isAudio)
            //formData.append('color', hex);
            if (selectedFile) {
                formData.append('medias', selectedFile);
            }*/

            const { data } = await api.post(`/tasks/add`, formData);
            if (data) {
                fetchTasks();
                toast.success(i18n.t('addTaskModal.success'));
            }
        } catch (err) {
            console.log(err);
            toastError(err);
        }
       // setNewTaskOpen(false);
    };

    const handleOpenTask = (id) => {
       // setTaskModalOpen(true);
        setTaskId(id)
    }
    const handleRemoveTask = async (id) => {
      //  setTaskModalOpen(true);
        setTaskId(id)
        const { data } = await api.delete(`/tasks/${id}`);
        if (data.success) {
            console.log(data)
            await fetchTasks();
            toast.success(i18n.t('addTaskModal.removed'));
        }
    }

    const handleEditTask = (id) => {
       // setTaskModalOpen(true);
        setTaskId(id)
    }

    const handleFinishTask = (taskId) => {
	/*	setTasks((prevTasks) => {
			const updatedTasks = { ...prevTasks };

			let sourceColumn = null;
			let taskToMove = null;
			for (const column in updatedTasks) {
				const tasksInColumn = updatedTasks[column];
				const foundTaskIndex = tasksInColumn.findIndex((task) => task.id === taskId);
				if (foundTaskIndex !== -1) {
					sourceColumn = column;
					taskToMove = tasksInColumn[foundTaskIndex];
					updatedTasks[column] = tasksInColumn.filter((_, index) => index !== foundTaskIndex);
					break;
				}
			}
			if (sourceColumn && taskToMove) {
				updatedTasks['Realizado'] = [...updatedTasks['Realizado'], taskToMove];
			}

			return updatedTasks;
		});*/
	};

    const handleOpenAddTask = (columnId) => {
		setActiveColumn(columnId);
		//setNewTaskOpen(true);
	};

	const handleOpenAddModalTask = (columnId) => {
		setActiveColumn(columnId);
		setNewTaskModalOpen(true);
	};


	/*Submenu*/
	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
		setMenuOpen(true);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
		setMenuOpen(false);
	};


    return (
        <>
            {tasks && tasks.map((column, columnId) => (

                <Droppable droppableId={String(column.id)} key={column.id}>
                    {(provided) => (
                        <Paper
                            className={classes.Column}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >

                            <div className={classes.blockTitleTask}>
                                <div className={classes.flexRow}>
                                    <h6 className={classes.titleTask}>{column.title && column.title}</h6>
                                    <span className={classes.spanTitleTask}>{column.tasks.length}</span>
                                </div>


                                <div>

                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                        style={{ paddingHorizontal: 3, paddingTop: 10 }}
                                        size="large">
                                        <MoreVert style={{ fontSize: 16, padding: 0 }} />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        listStyle={{ boxShadow: '0 !important' }}
                                        boxShadow={0}
                                        className={classes.menuBlock}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={menuOpen}
                                        onClose={handleCloseMenu}
                                    >

                                        <MenuItem>
                                            Editar
                                        </MenuItem>
                                        <MenuItem>
                                            Excluir
                                        </MenuItem>

                                    </Menu>
                                </div>
                            </div>

                            <div className={classes.TaskMiddle}>
                                {column.tasks && column.tasks.length > 1 && column.tasks.map((task, index) => (
                                    <Draggable
                                        key={task.id}
                                        draggableId={task.id}
                                        itemId={task.id}
                                        index={index}
                                        onClick={() => setTaskId(task.id)}
                                    >
                                        {(provided) => (
                                            <Paper
                                                className={classes.Task}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div className={classes.checkboxDiv}>
                                                    <Checkbox onChange={() => handleFinishTask(task.id)} />
                                                </div>
                                                <div className={classes.contDiv}>
                                                    <h6
                                                        className={classes.titleTask}
                                                        onClick={() => handleOpenTask(task.id)}>{task.title}</h6>
                                                    <p className={classes.descTask}>{task.content}</p>
                                                    <div className={classes.flexRow}>
                                                        <div className={classes.avatares}>
                                                            
                                                {/*<Tooltip title={task.user.name}>
                                                    {task.user.avatar ? <Avatar alt={task.user.name} sx={{ width: 32, height: 32 }}
                                                        src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${task.user.avatar}`} /> :
                                                        <Avatar alt={task.user.name} sx={{ width: 32, height: 32 }}
                                                            src={`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${task.user.name}`} />
                                                    }
                                                </Tooltip>*/}
                                              
                                                        </div>

                                                        <div className={classes.buttonActionsTask} style={{ display: 'flex', gap: 5 }}>
                                                            <FaTrash onClick={() => handleRemoveTask(task.id)} />
                                                            <FaPencilAlt onClick={() => handleEditTask(task.id)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Paper>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                            {provided.placeholder}

                            {
                                !actionTask ?
                                    <button className={classes.buttonTask} onClick={() => handleOpenAddTask(columnId)}>
                                        <FaPlus /> Adicionar tarefa
                                    </button> :

                                    activeColumn === columnId ? (
                                        <Formik
                                            initialValues={taskAdd}
                                            enableReinitialize={true}
                                            validationSchema={taskSchema}
                                            onSubmit={(values, actions) => {

                                                handleAddTask({ title: values.title, description: values.description, column: column.id });
                                                actions.setSubmitting(false);

                                            }}
                                        >
                                            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                                                <Form>
                                                    <DialogContent dividers>
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("Nome da Tarefa")}
                                                            name="title"
                                                            autoFocus
                                                            error={touched.title && Boolean(errors.title)}
                                                            helperText={touched.title && errors.title}
                                                            variant="outlined"
                                                            margin="dense"
                                                            onChange={(e) => setTaskTitle(e.target.value)}
                                                            className={classes.textField}
                                                        />

                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("Descrição")}
                                                            name="description"
                                                            variant="outlined"
                                                            margin="dense"
                                                            className={classes.textField}
                                                        />

                                                        <SelectUsers size="small" />
                                                        <Button
                                                            type="reset"
                                                            size="small"
                                                            onClick={handleOpenAddModalTask}
                                                        >+ DETALHES</Button>

                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            type="reset"
                                                            onClick={handleCloseAddTask}
                                                            color="secondary"
                                                            disabled={isSubmitting}
                                                            variant="outlined"
                                                        >
                                                            {i18n.t("contactModal.buttons.cancel")}
                                                        </Button>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            disabled={isSubmitting}
                                                            variant="contained"
                                                            className={classes.btnWrapper}
                                                            onSubmit={(values, actions) => {
                                                                handleAddTask({ title: values.title, description: values.description, column: column.id });
                                                                actions.setSubmitting(false);
                                                            }}

                                                        >
                                                            {taskId
                                                                ? `${i18n.t("contactModal.buttons.okEdit")}`
                                                                : `${i18n.t("contactModal.buttons.okAdd")}`}
                                                            {isSubmitting && (
                                                                <CircularProgress
                                                                    size={24}
                                                                    className={classes.buttonProgress}
                                                                />
                                                            )}
                                                        </Button>
                                                    </DialogActions>
                                                </Form>
                                            )}
                                        </Formik>
                                    ) : null}

                        </Paper>
                    )}
                </Droppable>

            ))}
        </>
    )
}

export default Task;