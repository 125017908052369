import React, { useEffect, useState } from 'react';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableSortLabel,
    TablePagination,
    Button,
    Tooltip
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import makeStyles from '@mui/styles/makeStyles';
import DashboardFeedbackVisual from "../../components/Dashboard/feedbackVisual";
import { i18n } from "../../translate/i18n";


const useStyles = makeStyles(theme => ({
    emojiRate: {
        fontSize: "2em"
    }
}));


function TicketFeedbackTable({ chartData }) {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('day');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const history = useHistory();

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const formatTicketsRate = (rate) => {
        if (rate) {
            if (Number.isInteger(parseFloat(rate))) {
                return parseInt(rate);
            }
            return parseFloat(rate).toFixed(2).toString().replace('.', ',');
        }
        return i18n.t("dashboardHome.no_evaluation");
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const sortedData = chartData.sort((a, b) => {
        if (orderBy === 'day') {
            return order === 'asc' ? new Date(a[orderBy]) - new Date(b[orderBy]) : new Date(b[orderBy]) - new Date(a[orderBy]);
        } else if (orderBy === 'amount') {
            return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
        } else {
            return order === 'asc' ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
        }
    });
    let paginatedData = chartData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    useEffect(() => {
        paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [sortedData])

    const formatDate = (item) => {
        const date = new Date(item);

        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        const formattedTime = date.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });

        return `${formattedDate} às ${formattedTime.replace(':', ':')}hrs`;
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="ticket table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'dateEnd'}
                                direction={orderBy === 'dateEnd' ? order : 'asc'}
                                onClick={() => handleRequestSort('dateEnd')}
                            >
                                Data
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            ID
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel
                                active={orderBy === 'contactName'}
                                direction={orderBy === 'contactName' ? order : 'asc'}
                                onClick={() => handleRequestSort('contactName')}
                            >
                                Contato
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            Telefone
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel
                                active={orderBy === 'userName'}
                                direction={orderBy === 'userName' ? order : 'asc'}
                                onClick={() => handleRequestSort('userName')}
                            >
                                Usuário
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel
                                active={orderBy === 'ticketRate'}
                                direction={orderBy === 'ticketRate' ? order : 'asc'}
                                onClick={() => handleRequestSort('ticketRate')}
                            >
                                Avaliação
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <FaEye />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paginatedData && paginatedData.length >= 1 && paginatedData.map((row) => (
                        row.ticketRate >= 1 && (
                            <TableRow key={row.ticketId}>
                                <TableCell component="th" scope="row">
                                    {formatDate(row.dateEnd)}
                                </TableCell>
                                <TableCell align="center">#{row.ticketId}</TableCell>
                                <TableCell align="center">{row.contactName}</TableCell>
                                <TableCell align="center">{row.contactNumber}</TableCell>
                                <TableCell align="center">{!row.userName || row.userName === 'null'  ? 'Sem usuário': row.userName}</TableCell>
                                <TableCell align="center">
                                    <DashboardFeedbackVisual className={classes.emojiRate} rate={row.ticketRate} /> ({formatTicketsRate(row.ticketRate)})
                                </TableCell>
                                <TableCell align="center">
                                    <Button onClick={() => history.push(`/tickets/${row.ticketId}`)}>
                                        <FaEye />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={chartData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Colunas da tabela"
            />
        </TableContainer>
    );
}

export default TicketFeedbackTable;