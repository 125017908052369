import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    answerLoading: {
        minHeight: 300,
        maxHeight: 300,
        width: 300,
        margin: 20,
        borderRadius: 10,
        background: theme.palette.blackWhiteBackground,
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    h1Loading: {
        fontSize: 18
    },
    circleLoading: {
       maxHeight: 100
    },
}));

const LoadingBox = () => {
    const classes = useStyles();
    const contentArray = [
        {
            img: 'infinite',
            h1: 'Aguarde enquanto buscamos as informações para você...',
            p: 'Estamos processando sua busca, por favor, aguarde. Isso pode levar alguns momentos, pois estamos verificando os registros  para fornecer as informações mais recentes.',
        },
        {
            img: 'initial',
            h1: 'Ainda em busca...',
            p: 'Continuamos a verificar as bases de dados, e caso haja alguma atualização, você será informado imediatamente.',
        },
        {
            img: 'camera',
            h1: 'Quase lá...',
            p: 'Estamos na fase final da busca e verificação. Por favor, aguarde um pouco mais enquanto concluímos o processo.',
        },
        {
            img: 'help',
            h1: 'Mais um momento, por favor...',
            p: 'Estamos realizando uma verificação adicional para garantir que todas as informações estejam atualizadas. Agradecemos sua paciência.',
        },
        // Add similar objects for other blocks
    ];

    const [currentBlock, setCurrentBlock] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentBlock((prevBlock) => (prevBlock + 1) % contentArray.length);
        }, 3000);

        return () => clearInterval(intervalId); // Cleanup on component unmount

    }, [contentArray.length]); // Update the dependency array

    return (
        <div className={classes.answerLoading}>
            {contentArray.map((content, index) => (
                <div
                    key={index}
                    className={`block-select ${index === currentBlock ? 'visible' : 'hidden'}`}>
                    <img className={classes.circleLoading} src={`/loading/loading${content.img}.gif`} alt={`Loading ${index}`} />
                    <h1 className={classes.h1Loading}>{content.h1}</h1>
                    <p>{content.p}</p>
                </div>
            ))}
        </div>
    );
};

export default LoadingBox;
