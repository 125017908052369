import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { i18n } from "../../translate/i18n";
import LoadingBox from "../LoadingBox";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import PromptTextToSpeechModal from "../PromptTexttoSpeechModal";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	answerLoading: {
		minHeight: 200,
		maxHeight: 300,
		width: 300,
		margin: 20,
		borderRadius: 10,
		background: theme.palette.blackWhiteBackground,
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'center'
	},
	answerBlock: {
		fontSize: 16,
		lineHeight: '27px',
		padding: 20,
		background: theme.palette.fancyBackground,
		borderRadius: 10,
		//margin: 20,
		minHeight: 200,
		maxHeight: 300,
		overflow: 'auto'
	},

	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},
	circleLoading: {
		color: green[500],
		opacity: "70%"
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));


const GroupLeaveModal = ({ open, onClose, ticket, handleAIMessage }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		onClose();
	};

	const handleExitGroup = async () => {
		try {
			setLoading(true);
			const response = await api.delete("/tickets/group/" + ticket.id);
			//console.log(response);
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};


	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{ticket.contact.name}
				</DialogTitle>

				<DialogContent dividers>
					<div>

						<>
							<div className={classes.messageSaved}>
								<span className={classes.cont}>
									Você tem certeza que deseja sair do grupo?
								</span>
							</div></>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						//disabled={isSubmitting}
						variant="outlined"
					>
						{i18n.t("translateModal.buttons.cancel")}
					</Button>
					<Button
						type="submit"
						color="primary"
						//disabled={isSubmitting}
						variant="contained"
						className={classes.btnWrapper}
						onClick={handleExitGroup}
					>

						Sair
						{/*isSubmitting && (
								<CircularProgress
									size={24}
									className={classes.buttonProgress}
								/>
							)*/}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default GroupLeaveModal