import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { green } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { i18n } from "../../translate/i18n";


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  answerLoading: {
    minHeight: 200,
    maxHeight: 300,
    width: 300,
    margin: 20,
    borderRadius: 10,
    background: '#f5f5f5',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  answerBlock: {
    fontSize: 16,
    lineHeight: '27px',
    padding: 20,
    background: '#f5f5f5',
    borderRadius: 10,
    margin: 20,
    minHeight: 200,
    maxHeight: 300,
    overflow: 'auto'
  },

  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  btnWrapper: {
    position: "relative",
  },
  circleLoading: {
    color: green[500],
    opacity: "70%"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
  messageSaved: {
    boxShadow: "0 1px 1px #b3b3b3",
    background: "#f5f5f5",
    padding: '5px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cont: {
    fontStyle: "italic",
  },
  timestamp: {
    fontStyle: 'normal',
    fontSize: 11,
    color: '#666666'
  },
  blockResume: {
    ...theme.scrollbarStylesSoftBig,
  },
  listItem: {
    fontSize: 12
  }
}));

const UpdatesModal = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);


  const itemListPT = [
    {
      title: "Novo design, mais prático e intuitivo",

    },
    {
      title: "Visualização do youtube nos links inclusos nos atendimentos",

    },
    {
      title: "Integração com ChatGPT",

    },
    {
      title: "Buscar na Imagem",

    },
    {
      title: "Assistante Virtual nas Mensagens",

    },
    {
      title: "Recurso de tradução de Mensagens",

    },
    {
      title: "Busca direta ao ChatGPT em uma mensagem",

    },
    {
      title: "Resumo de atendimento",

    },
    {
      title: "Fala para Texto nos audios nas mensagens",

    },
    {
      title: "Texto para Fala nos textos nas mensagens",

    },
    {
      title: "Avatar nos usuários",

    },
    {
      title: "Gráficos melhorados",

    },
    {
      title: "Controle de permissões de acesso",
    },
    {
      title: "Mudança na visualização de tickets"
    },
    {
      title: "Busca dentro do atendimento"
    },
    {
      title: "Suporte a gifs e stickers"
    },
    {
      title: "Crie imagem a partir do texto"
    },
    {
      title: "Distribuição Automática"
    },
    {
      title: "Tickets em Modo Grid"
    }

  ];

  const itemListES = [
    {
      title: "Nuevo diseño, más práctico e intuitivo",
    },
    {
      title: "Visualización de YouTube en los enlaces incluidos en las atenciones",
    },
    {
      title: "Integración con ChatGPT",
    },
    {
      title: "Asistente Virtual en los Mensajes",
    },
    {
      title: "Recurso de traducción de Mensajes",
    },
    {
      title: "Búsqueda directa al ChatGPT en un mensaje",
    },
    {
      title: "Resumen de atención",
    },
    {
      title: "Voz a Texto en los audios en los mensajes",
    },
    {
      title: "Avatar en los usuarios",
    },
    {
      title: "Gráficos mejorados",
    },
    {
      title: "Control de permisos de acceso",
    },
    {
      title: "Inserción de logo por el admin",
    },
    {
      title: "Cambio en la visualización de tickets",
    },
    {
      title: "Búsqueda dentro de la atención",
    },
    {
      title: "Soporte a gifs y stickers",
    },
    {
      title: "Crea imagen a partir del texto",
    },
    {
      title: "Distribución Automática",
    },
    {
      title: "Tickets en Modo Grid",
    },
  ];

  const itemListEN = [
    {
      title: "New design, more practical and intuitive",
    },
    {
      title: "YouTube preview in links included in conversations",
    },
    {
      title: "Integration with ChatGPT",
    },
    {
      title: "Virtual Assistant in Messages",
    },
    {
      title: "Message Translation Feature",
    },
    {
      title: "Direct search to ChatGPT in a message",
    },
    {
      title: "Service Summary",
    },
    {
      title: "Speech-to-Text in audio messages",
    },
    {
      title: "Avatar in users",
    },
    {
      title: "Improved graphics",
    },
    {
      title: "Access permissions control",
    },
    {
      title: "Logo insertion by admin",
    },
    {
      title: "Change in ticket view",
    },
    {
      title: "Search within the service",
    },
    {
      title: "Support for gifs and stickers",
    },
    {
      title: "Create image from text",
    },
    {
      title: "Automatic Distribution",
    },
    {
      title: "Tickets in Grid Mode",
    },
  ];



  const getItemList = () => {
    switch (i18n.language) {
      case 'pt':
        return itemListPT;
      case 'es':
        return itemListES;
      case 'en':
        return itemListEN;
      default:
        return itemListPT;
    }
  };


  const handleItemClick = (index) => {
    setSelectedItem(index);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleHidePopUp = () => {
    const now = new Date().getTime();
    localStorage.setItem('welcomeNewsShown', JSON.stringify({ shown: true, timestamp: now }));
  }


  useEffect(() => {
    const modalData = localStorage.getItem('welcomeNewsShown');

    if (modalData) {
      const { shown, timestamp } = JSON.parse(modalData);
      const thirtyMinutesInMillis = 30 * 60 * 1000;
      const currentTime = new Date().getTime();

      if (shown && currentTime - timestamp < thirtyMinutesInMillis) {
        setOpen(false);
      }
    } else {
      setOpen(true);
    }
  }, []);

  return (
    open ? (
      <div className={classes.root}>
        <Dialog open={open} onClose={handleClose} scroll="paper">
          <DialogTitle>
            {i18n.t("updatesModal.title")}
          </DialogTitle>

          <div style={{ padding: '15px 20px' }}>


            <Typography variant="body1" id="welcome-modal-description">
              <strong>{i18n.t("updatesModal.span")}</strong>
            </Typography>
            <div className={classes.blockResume} style={{ display: 'flex', flexDirection: 'row', background: 'rgba(0,0,0,0.1)', padding: 10, height: '65vh', overflow: 'auto' }}>
              <List>
                {getItemList().map((item, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    onClick={() => handleItemClick(index)}
                    button
                  >
                    <ListItemText primary={`- ${(item.title)}`} className={classes.listItem} />
                  </ListItem>
                ))}
              </List>

              <div className="blockImage">
                {selectedItem !== null && (

                  <>{/**
                  {selectedItem === 0 && (
                    <img src="path_to_image_for_first_item" alt="Image for the first item" />
                  )}
                  {selectedItem === 1 && (
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/VIDEO_ID"
                      title="YouTube video player"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  )}
                   */}
                  </>
                )}
              </div>
            </div>


          </div>

          <DialogActions>
            <FormControlLabel control={<Checkbox onChange={handleHidePopUp} />} label={i18n.t("updatesModal.dont_show")} />
            <Button onClick={handleClose} variant="contained" color="primary">
              {i18n.t("updatesModal.button")}
            </Button>
          </DialogActions>

        </Dialog>
      </div>
    ) : (<></>)
  );
};

export default UpdatesModal;
