import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { Badge, useTheme, Tooltip, Typography, Hidden } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import UserModal from "../components/UserModal";
import BackdropLoading from "../components/BackdropLoading";
import NotificationsPopOver from "../components/NotificationsPopOver";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { MdSettings } from "react-icons/md";
import { SlHome } from "react-icons/sl";
import { FaPhone, FaTasks } from "react-icons/fa";
import { FiUsers, FiLogOut } from "react-icons/fi";
import { PiRobot, PiStudent, PiTimerBold } from "react-icons/pi";
import { GrSync } from "react-icons/gr";
import { TiContacts } from "react-icons/ti";
import { LiaTagsSolid, LiaUserTieSolid } from "react-icons/lia";
import { BiMessageRoundedAdd } from "react-icons/bi";
import { HiOutlineQueueList } from "react-icons/hi2";
import { SettingsContext } from "../context/Settings/SettingsContext";
import ColorModeContext from "./themeContext";
import SettingsIcon from '@mui/icons-material/Settings';
import { useHistory } from "react-router-dom";
import NotifySettingsModal from "../components/NotifySettingsModal";
import { ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { PeopleAltOutlined as PeopleAltOutlinedIcon, AccountTreeOutlined as AccountTreeOutlinedIcon, ExpandLess, ExpandMore, Close } from '@mui/icons-material';
import { Can } from "../components/Can";
import Version from "../helpers/version";

function ListItemLink(props) {
    const { icon, primary, to, className, withSettings, settingsNotify } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to]
    );

    return (
        <li>
            <ListItem button component={renderLink} className={className}>
                {icon ? <ListItemIcon style={{ minWidth: 32 }}>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
                {withSettings && <SettingsIcon style={{ fontSize: 18, marginLeft: 10 }} onClick={settingsNotify(true)} />}
            </ListItem>
        </li>
    );
}


const useStyles = makeStyles(theme => ({
    menuItem: {
        fontSize: '14px !important',
        '&:hover': {
            color: 'unset'
        },
        '&:focus': {
            color: 'unset'
        },
        '& span': {
            fontSize: '14px !important',
        },
    },
    logo: {

    },
    menuItemList: {
        fontSize: '14px !important',
        padding: '10px 20px',
        cursor: 'pointer',
        '&:hover': {
            color: 'unset'
        },
        '&:focus': {
            color: 'unset'
        },
        '& span': {
            fontSize: '14px !important',
        },
    },

    menuItemLink: {
        '&:hover': {
            cursor: 'pointer',
            color: 'unset',
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },
        '&:focus': {
            color: 'unset'
        }
    },
    iconSettings: {
        fontSize: 14
    },
    boxDrop: {
        height: 'auto',
        position: 'absolute',
        top: 53,
        left: 0,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        background: '#eeeeee',
        boxShadow: '2px 10px 17px 0px rgba(0,0,0,0.12)',
    }
}));



const MainListItems = ({ isVertical, handleOpenMenuHeader, openedMenu }) => {
    const { whatsApps } = useContext(WhatsAppsContext);
    const { user,permissions, handleLogout,loading  } = useContext(AuthContext);
    const { isActive, getSettingValue } = useContext(SettingsContext);
    const [connectionWarning, setConnectionWarning] = useState(false);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [openSettingsNotify, setSettingsNotify] = useState(false);
    const [chatOpen, setChatOpen] = useState(false);
    const [adminOpen, setAdminOpen] = useState(false);
    const [settingOpen, setSettingOpen] = useState(false);

    


    const handleChatClick = () => {
        !settingOpen && !adminOpen && handleOpenMenuHeader(!openedMenu);
        setChatOpen(!chatOpen);
        setSettingOpen(false);
        setAdminOpen(false);
    };

    const handleAdminClick = () => {
        !chatOpen && !settingOpen && handleOpenMenuHeader(!openedMenu);
        setAdminOpen(!adminOpen);
        setChatOpen(false);
        setSettingOpen(false);
    };

    const handleSettingClick = () => {
        !chatOpen && !adminOpen && handleOpenMenuHeader(!openedMenu);
        setSettingOpen(!settingOpen);
        setAdminOpen(false);
        setChatOpen(false);

    };


    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();

    const handleChangelog = () => {
        //history.push('/updates');
    };

    const settingIsActive = (key) => {
        return isActive(key);
    }


    //Modal Configuraçoes de Notificações
    const handleOpenSettingsNotifications = () => {
        setSettingsNotify(true);
    };


    const handleClickLogout = () => {
        handleLogout();
    };


    function AdminItems() {


        return (
            !isVertical ? (
                <ListItem button onClick={handleAdminClick}>
                    <ListItemIcon style={{ minWidth: 32 }}>
                        <LiaUserTieSolid size={23} />
                    </ListItemIcon>
                    <ListItemText className={classes.menuItem} primary={i18n.t("mainDrawer.listItems.administration")} />
                    {adminOpen ? <ExpandLess /> : <ExpandMore />}

                    <Collapse in={adminOpen} className={classes.boxDrop}
                    
                        style={{ background: theme.mode === 'dark' ? '#000000' : '#ffffff' }}
                        timeout="auto" unmountOnExit>
                        <Can
                            role={user.roleId}
                            perform="users:show:page"
                            yes={() => (
                                <ListItemLink to="/users" primary={i18n.t("mainDrawer.listItems.users")} icon={<FiUsers size={17} />} className={classes.menuItemList} />
                            )}
                            no={() => <></>}
                        />
                        <Can
                            role={user.roleId}
                            perform="queue:show:page"
                            yes={() => (
                                <ListItemLink to="/queues" primary={i18n.t("mainDrawer.listItems.queues")} icon={<HiOutlineQueueList />} className={classes.menuItemList} />
                            )}
                            no={() => <></>}
                        />
                    </Collapse>
                </ListItem>
            ) : (
                <ListItem button onClick={handleAdminClick}>
                    <div className="flex flex-column">

                        <ListItemText className={classes.menuItem} primary={i18n.t("mainDrawer.listItems.administration")} icon={<LiaUserTieSolid size={23} />} />

                        <Can
                            role={user.roleId}
                            perform="users:show:page"
                            yes={() => (
                                <ListItemLink to="/users" primary={i18n.t("mainDrawer.listItems.users")} icon={<FiUsers size={17} />} className={classes.menuItemList} />
                            )}
                            no={() => <></>}
                        />
                        <Can
                            role={user.roleId}
                            perform="queue:show:page"
                            yes={() => (
                                <ListItemLink to="/queues" primary={i18n.t("mainDrawer.listItems.queues")} icon={<HiOutlineQueueList />} className={classes.menuItemList} />
                            )}
                            no={() => <></>}
                        />
                    </div>

                </ListItem>
            )
        );
    }

    function ChatItems() {

        return (
            !isVertical ? (
                <ListItem button onClick={handleChatClick} onAbort={handleChatClick}>
                    <ListItemIcon style={{ minWidth: 32 }}>
                        {user.id && <NotificationsPopOver />}
                    </ListItemIcon>
                    <ListItemText className={classes.menuItem} primary={i18n.t("mainDrawer.listItems.tickets")} />
                    {chatOpen ? <ExpandLess /> : <ExpandMore />}

                    <Collapse in={chatOpen} className={classes.boxDrop}

                        style={{ background: theme.mode === 'dark' ? '#000000' : '#ffffff' }}
                        timeout="auto" unmountOnExit>
                        <Can
                            role={user.roleId}
                            perform="tickets:show:page"
                            yes={() => (
                                <ListItemLink
                                    to="/tickets"
                                    primary={i18n.t("mainDrawer.listItems.tickets")}
                                    icon={user.id && <NotificationsPopOver />}
                                    className={classes.menuItemList}
                                    withSettings={true}
                                    settingsNotify={() => handleOpenSettingsNotifications}
                                />)}
                            no={() => <></>}
                        />

                        {settingIsActive("showContacts") ?
                            <Can
                                role={user.roleId}
                                perform="contacts:show:page"
                                yes={() => (
                                    <ListItemLink
                                        to="/contacts"
                                        primary={i18n.t("mainDrawer.listItems.contacts")}
                                        icon={<TiContacts size={18} />}
                                        className={classes.menuItemList}
                                    />)}
                                no={() => <></>}
                            />
                            : <></>}
                        <Can
                            role={user.roleId}
                            perform="shortcut-messages:show:page"
                            yes={() => (
                                <ListItemLink
                                    to="/shortcut-messages"
                                    primary={i18n.t("mainDrawer.listItems.shortcut_messages")}
                                    icon={<BiMessageRoundedAdd size={18} />}
                                    className={classes.menuItemList}
                                />)}
                            no={() => <></>}
                        />
                        <Can
                            role={user.roleId}
                            perform="scheduled-messages:show:page"
                            yes={() => (
                                <ListItemLink
                                    to="/scheduled-messages"
                                    primary={i18n.t("mainDrawer.listItems.scheduled_messages")}
                                    icon={<PiTimerBold size={18} />}
                                    className={classes.menuItemList}
                                />)}
                            no={() => <></>}
                        />
                        {settingIsActive("showTags") ?
                            <Can
                                perform="tags:show:page"
                                yes={() => (<ListItemLink
                                    to="/tags"
                                    primary={i18n.t("mainDrawer.listItems.tags")}
                                    icon={<LiaTagsSolid size={18} />}
                                    className={classes.menuItemList}
                                />)}
                                no={() => <></>}
                            /> : <></>}
                    </Collapse>
                </ListItem>
            ) : (

                <div className="flex flex-column">
                    <Can
                        role={user.roleId}
                        perform="tickets:show:page"
                        yes={() => (
                            <ListItemLink
                                to="/tickets"
                                primary={i18n.t("mainDrawer.listItems.tickets")}
                                icon={user.id && <NotificationsPopOver />}
                                className={classes.menuItemList}
                                withSettings={true}
                                settingsNotify={() => handleOpenSettingsNotifications}
                            />)}
                        no={() => <></>}
                    />

                    {settingIsActive("showContacts") ?
                        <Can
                            role={user.roleId}
                            perform="contacts:show:page"
                            yes={() => (
                                <ListItemLink
                                    to="/contacts"
                                    primary={i18n.t("mainDrawer.listItems.contacts")}
                                    icon={<TiContacts size={18} />}
                                    className={classes.menuItemList}
                                />)}
                            no={() => <></>}
                        />
                        : <></>}
                    <Can
                        role={user.roleId}
                        perform="shortcut-messages:show:page"
                        yes={() => (
                            <ListItemLink
                                to="/shortcut-messages"
                                primary={i18n.t("mainDrawer.listItems.shortcut_messages")}
                                icon={<BiMessageRoundedAdd size={18} />}
                                className={classes.menuItemlist}
                            />)}
                        no={() => <></>}
                    />
                    <Can
                        role={user.roleId}
                        perform="scheduled-messages:show:page"
                        yes={() => (
                            <ListItemLink
                                to="/scheduled-messages"
                                primary={i18n.t("mainDrawer.listItems.scheduled_messages")}
                                icon={<PiTimerBold size={18} />}
                                className={classes.menuItemList}
                            />)}
                        no={() => <></>}
                    />
                    {settingIsActive("showTags") ?
                        <Can
                            role={user.roleId}
                            perform="tags:show:page"
                            yes={() => (
                                <ListItemLink
                                    to="/tags"
                                    primary={i18n.t("mainDrawer.listItems.tags")}
                                    icon={<LiaTagsSolid size={18} />}
                                    className={classes.menuItemList}
                                />)}
                            no={() => <></>}
                        />
                        : <></>}
                </div>
            )
        );
    }


    function SettingsItems() {


        return (
            <div>
                {!isVertical ? (
                    <ListItem button onClick={handleSettingClick}>
                        <ListItemIcon
                            style={{ minWidth: 32 }}
                        >
                            <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                                <MdSettings size={22} />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText
                            className={classes.menuItem}
                            primary={i18n.t("mainDrawer.listItems.settings")}
                        />
                        {settingOpen ? <ExpandLess /> : <ExpandMore />}

                        <Collapse in={settingOpen} onClose={handleOpenMenuHeader} className={classes.boxDrop}
                            style={{ background: theme.mode === 'dark' ? '#000000' : '#ffffff' }}
                            timeout="auto" unmountOnExit>
                            <Can
                                role={user.roleId}
                                perform="settings:show:page"
                                yes={() => (
                                    <ListItemLink
                                        to="/Settings"
                                        primary={i18n.t("mainDrawer.listItems.settings")}
                                        icon={<MdSettings size={17} />}
                                        className={classes.menuItem}
                                    />)}
                                no={() => <></>}
                            />

                            <Can
                                role={user.roleId}
                                perform="whatsapp:show:page"
                                yes={() => (
                                    <ListItemLink
                                        to="/connections"
                                        primary={i18n.t("mainDrawer.listItems.connections")}
                                        icon={
                                            <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                                                <GrSync size={17} />
                                            </Badge>
                                        }
                                        className={classes.menuItem}
                                    />)}
                                no={() => <></>}
                            />
                        </Collapse>
                    </ListItem>
                ) : (
                    <>
                        <Can
                            role={user.roleId}
                            perform="settings:show:page"
                            yes={() => (
                                <ListItemLink
                                    to="/Settings"
                                    primary={i18n.t("mainDrawer.listItems.settings")}
                                    icon={<MdSettings size={22} />}
                                    className={classes.menuItem}
                                />)}
                            no={() => <></>}
                        />


                        <Can
                            role={user.roleId}
                            perform="whatsapp:show:page"
                            yes={() => (
                                <ListItemLink
                                    to="/connections"
                                    primary={i18n.t("mainDrawer.listItems.connections")}
                                    icon={
                                        <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                                            <GrSync size={17} />
                                        </Badge>
                                    }
                                    className={classes.menuItem}
                                />)}
                            no={() => <></>}
                        />

                    </>
                )}
            </div>);
    }




    useEffect(() => {
    }, [openSettingsNotify]);
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter(whats => {
                    return (
                        whats.status === "qrcode" ||
                        whats.status === "PAIRING" ||
                        whats.status === "DISCONNECTED" ||
                        whats.status === "TIMEOUT" ||
                        whats.status === "OPENING"
                    );
                });
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true);
                } else {
                    setConnectionWarning(false);
                }
            }
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [whatsApps]);

    if (loading) {
        return <BackdropLoading />;
    }

    return <>

        <UserModal
            open={userModalOpen}
            onClose={() => setUserModalOpen(false)}
            userId={user?.id}
        />
        <NotifySettingsModal
            open={openSettingsNotify}
            onClose={() => setSettingsNotify(false)}
            userId={user?.id}
        />

        {isVertical && (
            <div className={classes.shineEffect} onClick={() => history.push('/')}>
                <img className={`logo ${classes.logo}`} src="/logo-black.png" alt="Logo" style={{ height: 50, marginBottom: 20 }} />
            </div>)}



        <Can
            role={user.roleId}
            perform="dashboard:show:page"
            yes={() => (
                <ListItemLink
                    to="/"
                    primary="Dashboard"
                    icon={<SlHome size={18} />}
                    className={classes.menuItem}
                />
            )}
            no={() => <></>}
        />

        <ChatItems />

        {/*<ListItemLink
            to="/tasks"
            primary="Tarefas"
            icon={<FaTasks size={18} />}
            className={classes.menuItem}
        />*/}


        <Can
            role={user.roleId}
            perform="bots:show:page"
            yes={() => (
                <ListItemLink
                    to="/Bots"
                    primary={i18n.t("mainDrawer.listItems.bots")}
                    icon={<PiRobot size={23} />}
                    className={classes.menuItem}
                />)}
            no={() => <></>}
        />


        {
            (permissions.some((permission) => permission.key === 'users:show:page' || permission.key === 'queue:show:page') ||
                user.roleId === 1) && <AdminItems />
        }
        <Divider />
        {
            (permissions.some((permission) => permission.key === 'settings:show:page' || permission.key === 'whatsapp:show:page') ||
                user.roleId === 1) && <SettingsItems />
        }


        {
            isVertical &&
            <ListItemText
                primary={<React.Fragment>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}><FiLogOut size={18} />
                        {i18n.t("mainDrawer.appBar.user.logout")}</div>
                </React.Fragment>}
                onClick={handleClickLogout}
                className={`ml-10 ${classes.menuItemList}`}
            />
        }


        {
            isVertical && <React.Fragment>
                <Divider />
                <Hidden only={['sm', 'xs']}>
                    <img style={{ maxWidth: 250, padding: 5 }} src="/suporte.png" alt="image" />
                </Hidden>
                <Typography onClick={handleChangelog} style={{ fontSize: "12px", cursor: "pointer", padding: "10px", textAlign: "right", fontWeight: "bold" }}>
                    Versão: {Version}
                </Typography>
            </React.Fragment>
        }




    </>;
};

export default MainListItems;