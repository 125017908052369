import React, { useContext, useState } from "react";
import {
  FormControl,
  IconButton,
  InputLabel,
  Button,
  MenuItem,
  Select,
  ListItemText,
  ListItemIcon,
  ListItem,
  Paper,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PublishIcon from '@mui/icons-material/Publish';
import TextureIcon from '@mui/icons-material/Texture';
import { UserSettingsContext } from "../../context/Settings/UserSettingsContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import SaveIcon from '@mui/icons-material/Save';
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
  tabs: {
    width: '100%',

  },
  tab: {
    display: 'flex',
    "& span": {
      display: 'flex',
      flexDirection: 'row'
    }
  },
  inputFile: {
    display: 'none',
  },
  content: {

    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: 300,
    overflow: 'scroll',
    justifyContent: 'center',
    ...theme.scrollbarStylesSoft,
  },
  paper: {
    width: '100%',
    flexDirection: 'column',
    padding: 10,
    boxShadow: 'none'
  },
  boxTabs: {
    width: '100%',
    display: 'flex',
    flexDirection: ' column',
    padding: 0
  },
  menuItemContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    width: 500
  },

}));

const SettingBackgroundSelectionField = ({ name, value, label, helpText, userSettings, userId }) => {
  const { handleChangeSetting } = useContext(UserSettingsContext);
  const { user } = useContext(AuthContext);
  const [selectedFile, setSelectedFile] = useState(null);

 
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const [tab, setTab] = useState("textures");

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value) => {
    handleChangeSetting(name, value);
    handleClose();
  };

  const handleSubmit = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("background", selectedFile);
        await api.post(`/user/${user.id}/upload/background`, formData, { headers: { 'Content-Type': "multipart/form-data" } });
        toast.success(i18n.t("Salvo com sucesso"));
        handleClose();
        window.location.reload();
      } catch (err) {
        toastError(err);
      }
    }
  };

  const getMenuPattern = () => {
    const menuItems = [];
    for (let i = 1; i <= 21; i++) {
      const menuItemValue = `pattern${i}.png`;
      const backgroundImageUrl = `/patterns/pattern${i}.png`;
      menuItems.push(
        <MenuItem key={menuItemValue} onClick={() => handleChange(menuItemValue)} className={classes.menuItem}>
          <div style={{ width: 50, height: 50, borderRadius: 10, backgroundImage: `url(${backgroundImageUrl})`, backgroundSize: 'cover' }}></div>
        </MenuItem>
      );
    }
    return menuItems;
  };


  const getMenuBackground = () => {
    const menuItems = [];
    for (let i = 1; i <= 20; i++) {
      const menuItemValue = `bg${i}.png`;
      const backgroundImageUrl = `/backgrounds/bg${i}.png`;
      menuItems.push(
        <MenuItem key={menuItemValue} onClick={() => handleChange(menuItemValue)} className={classes.menuItem}>
          <div style={{ width: 50, height: 50, borderRadius: 10, backgroundImage: `url(${backgroundImageUrl})`, backgroundSize: 'cover' }}></div>
        </MenuItem>
      );
    }
    return menuItems;
  };

  return (
    <div className={classes.boxTabs}>
      <Tabs
        value={tab}
        className="tabs"
        onChange={handleChangeTab}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="icon label tabs example"
      >
        <Tab
          value={"textures"}
          icon={<TextureIcon />}
          // label={i18n.t("tickets.tabs.open.title")}
          label={'Texturas'}
          classes={{ root: classes.tab }}
        />

        <Tab
          value={"images"}
          icon={<PhotoCamera />}
          //label={i18n.t("tickets.tabs.open.title")}
          label={'Imagens'}
          classes={{ root: classes.tab }}
        />

      </Tabs>



      <Paper className={classes.paper}>
        <div className={classes.content}>
          {tab === 'textures' && <MenuItem onClick={() => handleChange('default')} className={classes.menuItem}>
            <div style={{
              width: 60, height: 60,
              borderRadius: 10,
              backgroundImage: `url(/patterns/wa-background.png)`,
              backgroundSize: 'cover'
            }}></div>
          </MenuItem>}
          {tab === 'textures' ? getMenuPattern() : getMenuBackground()}
        </div>
        {tab === 'images' &&
          <div style={{display:'flex', padding: '10px 0px'}}>
            <input
              accept="image/*"  
              className={classes.inputFile}
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" onChange={handleFileChange} color="primary" component="span">
                <PhotoCamera style={{ paddingRight: 10}} /> Upload
              </Button>
            </label>

            <label style={{alignItems: 'center', display: 'flex', padding: '0px 5px'}}>
              {selectedFile ? selectedFile.name : 'Nenhum arquivo selecionado'}
            </label>

            <Button variant="contained" color="success" component="span" onClick={handleSubmit}>
              <SaveIcon />
            </Button>
          </div>
        }
      </Paper>



    </div >

  );
}

export default SettingBackgroundSelectionField;