import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
	title: {
		color: theme.palette.textPrimary,
		margin: '15px 0px',
		fontWeight: 'bold',
		fontSize: 20,
		display: 'flex',
		alignItems: 'center',
		gap: 5
	}
}));

export default function Title(props) {
	const classes = useStyles();
	return (
		<Typography variant="h5" className={classes.title} gutterBottom>
			{props.children}
		</Typography>
	);
}
