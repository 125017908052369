import React, { useState, useEffect } from "react";
import { Grid, Paper, Button, Tooltip, IconButton } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { useHistory } from "react-router-dom";
import DashboardFeedbackVisual from "../../components/Dashboard/feedbackVisual";
import {
  Group,
  SouthEast, Clear, PersonRemove, PersonAdd, AttachMoney,
} from "@mui/icons-material";
import { FaInfoCircle } from "react-icons/fa";
import { i18n } from "../../translate/i18n";
import TicketFeedbackTable from "./TableFeedback";
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from "@mui/material";
import { IoArrowUpCircleOutline, IoArrowDownCircleOutline } from "react-icons/io5";

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    padding: 10,
    overflow: 'hidden'
  },
  fixedHeightPaper: {
    //padding: theme.spacing(2),
    padding: 6,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
    borderRadius: 0
  },
  highchartPaper: {
    height: 'auto',
    paddingLeft: '10px',
    marginBottom: 10,
    maxWidth: '100%',
    boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
  },
  blockListPaper: {
    height: 'auto',
    paddingLeft: '10px',
    paddingTop: '15px',
    paddingBottom: '15px',
    marginBottom: 10,
    maxWidth: '100%',
    rounded: 10,
    boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
  },
  filterContainer: {
    backgroundColor: '#FFF',
    marginBottom: 10
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
  },
  wrapperSelect: {
    padding: 0
  },
  totalPaper: {
    fontSize: 20
  },
  iconTitle: {
    color: theme.palette.dark.main,
    fontSize: 24,
    cursor: 'pointer'
  }
}));

const ChartFeedback = ({ period, userIds, contactIds, statuses, queueIds, tagIds, endpoint, title, yAxisTitle, colorChart, setLoading, theme, subtitle }) => {
  const [chartData, setChartData] = useState([]);
  const [chartTable, setChartTable] = useState([]);
  const classes = useStyles();
  const [transformedChartData, setTransformedChartData] = useState([]);
  const [internalLoading, setInternalLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [titleAxis, setYtitle] = useState('');
  const [typeChart, setTypeChart] = useState([]);
  const themeApp = useTheme();
  const [openBoxChart, setOpenBoxChart] = useState(true);
  const [allNames, setAllNames] = useState([]);

  const downloadExport = async () => {
    setExporting(true);
    try {
      const format = 'xlsx';
      const dateStart = moment(period[0]).format('YYYY-MM-DD');
      const dateEnd = moment(period[1]).format('YYYY-MM-DD');
      const userIdsString = JSON.stringify(userIds);
      const tagIdString = tagIds ? JSON.stringify(tagIds) : '';
      const statusesString = statuses ? JSON.stringify(statuses) : '';
      const keysString = typeChart ? JSON.stringify(typeChart) : '';
      const { data } = await api.get(`/report/export/reviews?userIdsString=${userIdsString}&tagIdsString=${tagIdString}&statuses=${statusesString}&dateStart=${dateStart}&dateEnd=${dateEnd}&format=${format}&keys=${keysString}&`, { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv' } });
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `historico_atendimentos_concluidos.${format}`;
      link.click();
      setExporting(false);
    } catch (err) {
      toastError(err);
      setExporting(false);
    }
  };



  useEffect(() => {
    let isMounted = true;
    setYtitle(yAxisTitle);
    const fetchData = async () => {
      try {
        setInternalLoading(true);
        const response = await api.get(endpoint, {
          params: {
            dateStart: moment(period[0]).format("YYYY-MM-DD"),
            dateEnd: moment(period[1]).format("YYYY-MM-DD"),
            contactIds: JSON.stringify(contactIds),
            userIds: JSON.stringify(userIds),
            queueIds: JSON.stringify(queueIds),
            statuses: JSON.stringify(statuses),
            tagIds: JSON.stringify(tagIds),
            keys: JSON.stringify(typeChart)
          },
        });

        if (response.data && isMounted) {
          // console.log('received data multiple', response);
          setChartData(response.data.data);

          const tickets = response.data.data.flatMap(item => item.tickets).slice(0, 500);
          //console.log(tickets, 'test', tickets.length, response.data);
          setChartTable(tickets);
          //console.log('test', transformedData)
          const names = response.data.data.length >= 1 ? response.data.data.map(item => item.name) : [];
          setAllNames(names);

          setInternalLoading(false);
          setLoading(false);
          setInternalLoading(false);
          setLoading(false);
        }


      } catch (err) {
        if (isMounted) {
          setInternalLoading(false);
          setLoading(false);
          toastError(err);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [period, userIds, contactIds, queueIds, tagIds, statuses, endpoint, colorChart, typeChart]);


  const formatTicketsRate = (rate) => {
    if (rate) {
      if (Number.isInteger(parseFloat(rate))) {
        return parseInt(rate);
      }
      return parseFloat(rate).toFixed(2).toString().replace('.', ',');
    }
    return i18n.t("dashboardHome.no_evaluation");
  }

  return (
    <>  <Paper className={classes.highchartPaper} style={{ marginTop: 50, paddingTop: 0 }} >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} style={{ paddingTop: 10 }}>
          <h2 style={{ fontSize: 20, margin: '0px 15px' }}>
            <div className="block-title-chart" style={{ display: 'flex', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span className="title-chart" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} onClick={() => setOpenBoxChart(!openBoxChart)}>
                {title}
                <Tooltip title={`Este gráfico exibe a classificação de atendimentos avaliados pelo cliente/contato.`}>
                  <IconButton>
                    <FaInfoCircle size={15} />
                  </IconButton>
                </Tooltip>
                {openBoxChart ? <IoArrowUpCircleOutline className={classes.iconTitle} /> : <IoArrowDownCircleOutline className={classes.iconTitle} />}
              </span>



              {openBoxChart && <small style={{ fontSize: 13, textAlign: 'center', lineHeight: '1.4' }}>
                * Não se aplica filtragem por status
                <br />Visualização somente de atendimentos avaliados pelo cliente
              </small>}

              {/*openBoxChart &&
                  <Button variant="outlined" size="small" onClick={() => downloadExport('open')} startIcon={<img src="/icons/xlsx-icon.png" height={30} />}>
                    Exportar Planilha
                  </Button>*/}
            </div>
          </h2>

          {openBoxChart ?
            chartData && chartData.length > 0 ? (
              <div style={{ height: 500, width: '100%', display: 'flex' }}>
                <ResponsiveBar
                  data={chartData}
                  keys={allNames}
                  indexBy="name"
                  height={500}
                  margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                  padding={0.3}
                  valueScale={{ type: 'linear' }}
                  indexScale={{ type: 'band', round: true }}
                  colors={{ scheme: theme }}
                  colorBy="id"
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  theme={{
                    text: {
                      fill: themeApp.mode === 'dark' ? '#ffffff' : '#000000'
                    }
                  }}
                  enableTotals
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 0,
                    tickRotation: -15,
                    legendPosition: 'start',
                    legendOffset: 2,
                    truncateTickAt: 100
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: titleAxis,
                    format: value => value !== 0 ? `${value.toFixed(0)}` : null,
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'top-right',
                      direction: 'column',
                      justify: false,
                      translateX: 100,
                      translateY: 0,
                      itemsSpacing: 4,
                      itemWidth: 100,
                      itemHeight: 10,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  role="application"
                  ariaLabel="Nivo bar chart demo"
                  barAriaLabel={e => e.formattedValue + e.indexValue + "atendimentos"}
                  tooltip={({
                    id,
                    value,
                    indexValue,
                    color,
                    total
                  }) => <div style={{
                    padding: 12,
                    color,
                    background: '#222222',
                    textAlign: 'center'
                  }}>
                      <h5>Média de avaliação</h5>
                      <strong>
                        {indexValue} <br />
                        Média: <DashboardFeedbackVisual className={classes.emojiRate} rate={value} />
                        ({formatTicketsRate(value)})
                      </strong>
                    </div>


                  }
                />


              </div>
            ) : chartData && chartData.length === 0 ? (
              <div
                className="boxChart"
                style={{ height: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', justifyContent: 'center' }}
              >
                {/*<img src="/loading/loadinginfinite.gif" height={30} alt="Loading..." />*/}
                Nada encontrado
              </div>) : (
              <div
                className="boxChart"
                style={{ height: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', justifyContent: 'center' }}
              >
                <img src="/loading/loadinginfinite.gif" height={30} alt="Loading..." />
                Carregando dados...
              </div>
            ) : <></>}

          {openBoxChart && <TicketFeedbackTable chartData={chartTable} />}
        </Grid>
      </Grid>
    </Paper>
    </>
  );
};

export default ChartFeedback;
