import React, { useState, useCallback, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import { GrSync } from "react-icons/gr";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import { ImConnection } from "react-icons/im";

import {
    Button,
    IconButton,
    Paper,
    Typography,
    CircularProgress,
    Card,
    CardContent,
} from "@mui/material";
import {
    Edit,
    CheckCircle,
    SignalCellularConnectedNoInternet2Bar,
    SignalCellularConnectedNoInternet0Bar,
    SignalCellular4Bar,
    CropFree,
    DeleteOutline,
    VpnKeyOutlined,
} from "@mui/icons-material";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import api from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";
import getSocket from "../../helpers/socket";
import ConnectionIcon from "../../components/ConnectionIcon";
import ForbiddenPage from "../../components/ForbiddenPage";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";


const useStyles = makeStyles(theme => ({
    blockCards: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        flexWrap: 'wrap',
        overflowY:'auto',
        maxHeight: '70vh',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 50,
        flex: 1,
        padding: '0px 15px',
        border: "0px",
       // minHeight: '-webkit-fill-available',
        overflow: 'auto',
        background: theme.palette.fancyBackground,
        borderRadius: 10,
        margin: 20,
        ...theme.scrollbarStylesSoft,
           
    },
    card: {
        width: '24%',
        maxWidth: 310,
        height: 320,
        cursor: 'pointer',
        margin: 20,
        borderRadius: 20,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
        position: 'relative',
        boxShadow: 'none',
        border: '1px solid #ddd',
        '&:hover': {
            boxShadow: '0px 0px 26px 0px rgba(255,255,255,1)',
            transform: 'scale(1.01)',
            '& $icon': {
                right: 300,
                fontSize: "10.5em",
            },
        },
        [theme.breakpoints.down('md')]: {
			width: '100%'
		}
	},
    icon: {
        position: "absolute",
        top:70,
        right: 0,
        fontSize: "10em",
        opacity: 0.03,
        transition: 'all ease 0.5s'
    },
    cardContent: {
        padding: '15px 0px',
    },
    flexCol: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 10px',
        backgroundColor: theme.palette.optionsBackground,
        borderBottom: '2px solid #eee'
    },
    mainPaper: {
        flex: 1,
        marginTop: 40,
        borderRadius: 20,
        border: '0px !important',
        marginBottom: 40,
        overflow: 'hidden'
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
        maxWidth: 450,
    },
    tooltipPopper: {
        textAlign: "center",
    },
    buttonProgress: {
        color: green[500],
    },
    disconnectButton: {
        marginLeft: theme.spacing(1)
    },
    buttonsTicket: {
        height: 40,
        borderRadius: '5px!important',
        display: 'inline-flex',
        alignItems: 'center',
        boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.07) !important',
        '&:hover': {
            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2) !important',
        },
        [theme.breakpoints.down('md')]: {
			fontSize: 13
		}
    },
    blockWhats: {
        backgroundColor: theme.palette.optionsBackground,
        padding: '5px 15px',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        fontWeight: 'bold'
    },
    blockStatus: {
        display: 'flex',
        flexDirection: 'column'
    },
    statusCard: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        justifyContent: 'center'
    }
}));


const socket = getSocket();

const Connections = () => {
    const classes = useStyles();
    const { user } = useContext(AuthContext)
    const { whatsApps, loading, limitConnections, countConnections, limitFacebook, countFacebook } = useContext(WhatsAppsContext);
    const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
    const [qrModalOpen, setQrModalOpen] = useState(false);
    const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [resetModalOpen, setResetModalOpen] = useState(false);
    const [addDisabled, setAddDisabled] = useState(false);
    const [restartServerDisabled, setRestartServerDisabled] = useState(false);
    const [resetButtonDisabled, setResetButtonDisabled] = useState(false);

    useEffect(() => {
        if (((limitConnections || limitConnections == "0") && limitConnections <= countConnections) && ((limitFacebook || limitFacebook == "0") && limitFacebook <= countFacebook)) {
            setAddDisabled(true);
        } else {
            setAddDisabled(false);
        }
    }, [limitConnections, countConnections, limitFacebook, countFacebook]);

    useEffect(() => {
        const whatsappSessionDone = data => {
            if (facebookAuthWindowHandle) {
                facebookAuthWindowHandle.close();
            }
        }

        socket.on("whatsappSessionAuthDone", whatsappSessionDone);

        return () => {
            socket.off("whatsappSessionAuthDone", whatsappSessionDone);
        };
    }, []);

    const confirmationModalInitialState = {
        action: "",
        title: "",
        message: "",
        whatsAppId: "",
        open: false,
    };
    const [confirmModalInfo, setConfirmModalInfo] = useState(
        confirmationModalInitialState
    );

    const handleStartWhatsAppSession = async whatsAppId => {
        try {
            await api.post(`/whatsappsession/${whatsAppId}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleRequestNewQrCode = async whatsAppId => {
        try {
            await api.put(`/whatsappsession/${whatsAppId}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleOpenWhatsAppModal = () => {
        setSelectedWhatsApp(null);
        setWhatsAppModalOpen(true);
    };

    const handleCloseWhatsAppModal = useCallback(() => {
        setWhatsAppModalOpen(false);
        setSelectedWhatsApp(null);
    }, [setSelectedWhatsApp, setWhatsAppModalOpen]);

    const handleOpenQrModal = whatsApp => {
        setSelectedWhatsApp(whatsApp);
        setQrModalOpen(true);
    };

    const handleCloseQrModal = useCallback(() => {
        setSelectedWhatsApp(null);
        setQrModalOpen(false);
    }, [setQrModalOpen, setSelectedWhatsApp]);

    const handleEditWhatsApp = whatsApp => {
        setSelectedWhatsApp(whatsApp);
        setWhatsAppModalOpen(true);
    };

    const handleResetWhatsApp = async whatsApp => {
        setResetButtonDisabled(true);
        try {
            await api.put(`/whatsapp/${whatsApp.id}/reset`);
            setResetButtonDisabled(false);
        } catch (err) {
            toastError(err);
        }
    };

    const handleOpenConfirmationModal = (action, whatsAppId) => {
        if (action === "disconnect") {
            setConfirmModalInfo({
                action: action,
                title: i18n.t("connections.confirmationModal.disconnectTitle"),
                message: i18n.t("connections.confirmationModal.disconnectMessage"),
                whatsAppId: whatsAppId,
            });
        }

        if (action === "delete") {
            setConfirmModalInfo({
                action: action,
                title: i18n.t("connections.confirmationModal.deleteTitle"),
                message: i18n.t("connections.confirmationModal.deleteMessage"),
                whatsAppId: whatsAppId,
            });
        }
        setConfirmModalOpen(true);
    };

    const handleSubmitConfirmationModal = async () => {
        if (confirmModalInfo.action === "disconnect") {
            try {
                await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
            } catch (err) {
                toastError(err);
            }
        }

        if (confirmModalInfo.action === "delete") {
            try {
                await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
                toast.success(i18n.t("connections.toasts.deleted"));
            } catch (err) {
                toastError(err);
            }
        }

        setConfirmModalInfo(confirmationModalInitialState);
    };

    const handleRestartServer = async () => {
        setResetModalOpen(true);
    }

    const handleSubmitResetModal = async () => {
        setRestartServerDisabled(true);
        try {
            await api.post("/restart-server");
            toast.success(i18n.t("connections.toasts.restarted"));
        } catch (err) {
            toastError(err);
        }
    }

    let facebookAuthWindowHandle;

    const handleFacebookAuth = async (whatsapp) => {
        try {
            const { data } = await api.get(`/whatsapp/${whatsapp.id}/facebook-auth-url`);
            facebookAuthWindowHandle = window.open(data.url, "facebookAuth", "width=600,height=800,left=300,top=100");

        } catch (err) {
            toastError(err);
        }
    }

    const renderActionButtons = whatsApp => {
        return <>
            {whatsApp.status === "qrcode" && (
                <Can
                    role={user.roleId}
                    perform="whatsapp:start:any"
                    yes={() => (
                        <Button
                            size="small"
                            style={{ background: '#2ecc71', color: '#000' }}
                            onClick={() => handleOpenQrModal(whatsApp)}
                        >
                            {i18n.t("connections.buttons.qrcode")}
                        </Button>)}
                    no={() => <></>}
                />
            )}
            {whatsApp.status === "DISCONNECTED" && (
                <>
                    <Can
                        role={user.roleId}
                        perform="whatsapp:session:restart:any"
                        yes={() => (
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => handleStartWhatsAppSession(whatsApp.id)}
                            >
                                {i18n.t("connections.buttons.tryAgain")}
                            </Button>)}
                        no={() => <></>}
                    />

                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleRequestNewQrCode(whatsApp.id)}
                    >
                        {i18n.t("connections.buttons.newQr")}
                    </Button>
                </>
            )}
            {whatsApp.status === "OPENING" && (
                <Button size="small" variant="outlined" disabled>
                    {i18n.t("connections.buttons.connecting")}
                </Button>
            )}
            {whatsApp.status === "FACEBOOK_AUTH" && (
                <Button size="small" variant="contained" onClick={() => handleFacebookAuth(whatsApp)} color="primary">
                    {i18n.t("connections.buttons.authenticate")}
                </Button>
            )}
            {whatsApp.status !== "FACEBOOK_AUTH" &&
                <Can
                    role={user.roleId}
                    perform="whatsapp:disconnect:any"
                    yes={() => (
                        <Button size="small" color="secondary" disabled={resetButtonDisabled} className={classes.disconnectButton} variant="outlined" onClick={() => {
                            handleResetWhatsApp(whatsApp)
                        }}>
                            {i18n.t("connections.buttons.disconnect")}
                        </Button>)}
                    no={() => <></>}
                />
            }
        </>;
    };

    const renderStatusToolTips = whatsApp => {
        return (
            <div className={classes.customTableCell}>
                {whatsApp.status === "DISCONNECTED" && (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'center' }}>
                            <SignalCellularConnectedNoInternet0Bar color="secondary" />
                            <h5>{i18n.t("connections.toolTips.disconnected.title")}</h5>
                        </div>
                        <p className={classes.contentInfo}>{i18n.t("connections.toolTips.disconnected.content")}</p>
                    </>
                )}
                {whatsApp.status === "OPENING" && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
                {whatsApp.status === "qrcode" && (
                    <div className={classes.blockStatus}>
                        <div className={classes.statusCard}>
                            <CropFree />
                            <h5>{i18n.t("connections.toolTips.qrcode.title")}</h5>
                        </div>
                        <p className={classes.contentInfo}>{i18n.t("connections.toolTips.qrcode.content")}</p>

                    </div>
                )}
                {whatsApp.status === "CONNECTED" && (
                    <div className={classes.blockStatus}>
                        <div className={classes.statusCard}>
                            <SignalCellular4Bar style={{ color: '#2ecc71' }} />
                            <h5>{i18n.t("connections.toolTips.connected.title")}</h5>
                        </div>
                        <p className={classes.contentInfo}></p>
                    </div>
                )}
                {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
                    <div className={classes.blockStatus}>
                        <div className={classes.statusCard}>
                            <SignalCellularConnectedNoInternet2Bar color="secondary" />
                            <h5>{i18n.t("connections.toolTips.qrcode.title")}</h5>
                        </div>
                        <p className={classes.contentInfo}>{i18n.t("connections.toolTips.qrcode.content")}</p>
                    </div>
                )}
                {whatsApp.status === "FACEBOOK_AUTH" && (
                    <>
                        <VpnKeyOutlined color="secondary" />
                        <h5>{i18n.t("connections.toolTips.qrcode.title")}</h5>
                        <p>{i18n.t("connections.toolTips.qrcode.content")}</p>
                    </>
                )}
            </div>
        );
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={confirmModalInfo.title}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={handleSubmitConfirmationModal}
            >
                {confirmModalInfo.message}
            </ConfirmationModal>
            <ConfirmationModal
                title="Tem certeza que deseja reiniciar o servidor?"
                open={resetModalOpen}
                onClose={setResetModalOpen}
                onConfirm={handleSubmitResetModal}
            >
                Essa operação leva em média de 1 ou 2 minutos e pode ser necessário reconectar após finalizada.
            </ConfirmationModal>
            <QrcodeModal
                open={qrModalOpen}
                onClose={handleCloseQrModal}
                whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
            />
            <WhatsAppModal
                open={whatsAppModalOpen}
                onClose={handleCloseWhatsAppModal}
                whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
                canCreateFacebook={limitFacebook > 0 && limitFacebook > countFacebook}
                canCreateWhatsapp={limitConnections > 0 && limitConnections > countConnections}
            />
            <Can
                role={user.roleId}
                perform="whatsapp:show:page"
                yes={() => (
                    <Paper className={classes.mainPaper} variant="outlined">
                        <MainHeader>
                            <Title><GrSync size={24} /> {i18n.t("connections.title")}</Title>
                            <MainHeaderButtonsWrapper>
                                <Can
                                    role={user.roleId}
                                    perform="whatsapp:reset:any"
                                    yes={() => (
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={handleRestartServer}
                                            className={classes.buttonsTicket}
                                            disabled={restartServerDisabled}>
                                            <ReplayIcon style={{ fontSize: 13 }} /> {i18n.t("connections.buttons.restartServer")}
                                        </Button>
                                    )}
                                    no={() => <></>}
                                />
                                <Can
                                    role={user.roleId}
                                    perform="whatsapp:create"
                                    yes={() => (
                                        <Button
                                            style={{ background: '#2ecc71', color: '#000' }}
                                            className={classes.buttonsTicket}
                                            onClick={handleOpenWhatsAppModal}
                                            disabled={addDisabled}
                                        >
                                            <AddIcon style={{ fontSize: 18 }} /> {i18n.t("connections.buttons.add")}
                                        </Button>)}
                                    no={() => <></>}
                                />
                            </MainHeaderButtonsWrapper>
                        </MainHeader>
                        <Paper className={classes.mainPaperTable} variant="outlined">
                
                                {loading ? (
                                    // Loading skeleton or spinner component
                                    <p>Loading...</p>
                                ) : (
                                    <div className={classes.blockCards}>
                                        {whatsApps?.length > 0 &&
                                            whatsApps.map((whatsApp) => (
                                                <Card key={whatsApp.id} className={classes.card}>
                                                    <CardContent className={classes.cardContent} style={{ padding: 0 }}>
                                                        <div className={classes.flexCol}>
                                                            <Typography variant="h6" align="center">
                                                                {whatsApp.name}
                                                            </Typography>
                                                            <Typography variant="body2" align="center">
                                                                {whatsApp.isDefault && (
                                                                    <div className={classes.customTableCell}>
                                                                        <CheckCircle style={{ color: '#2ecc71' }} />
                                                                    </div>
                                                                )}
                                                            </Typography>
                                                        </div>
                                                        <div className={classes.contentCard} style={{
                                                            padding: '15px 0px',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            flexDirection: 'column',
                                                            gap: '10px',
                                                            minHeight: 235
                                                        }}>
                                                            <span className={classes.icon}><ImConnection /></span>
                                                            <Typography variant="body2" align="center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <div className={classes.blockWhats}>
                                                                    <ConnectionIcon connectionType={whatsApp.type} width="24" height="24" />
                                                                    {whatsApp.status === 'CONNECTED' ? whatsApp.numberFormatted : ''}
                                                                </div>

                                                                <div>
                                                                    <Can
                                                                        role={user.roleId}
                                                                        perform="whatsapp:edit:any"
                                                                        yes={() => (
                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() => handleEditWhatsApp(whatsApp)}
                                                                            >
                                                                                <Edit  />
                                                                            </IconButton>
                                                                        )}
                                                                        no={() => <></>}
                                                                    />
                                                                    <Can
                                                                        role={user.roleId}
                                                                        perform="whatsapp:delete:any"
                                                                        yes={() => (
                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={(e) => {
                                                                                    handleOpenConfirmationModal("delete", whatsApp.id);
                                                                                }}
                                                                            >
                                                                                <DeleteOutline />
                                                                            </IconButton>
                                                                        )}
                                                                        no={() => <></>}
                                                                    />
                                                                </div>
                                                            </Typography>
                                                            <Typography variant="body2" align="center">
                                                                {renderStatusToolTips(whatsApp)}
                                                            </Typography>
                                                            <Typography variant="body2" align="center">
                                                                {renderActionButtons(whatsApp)}
                                                            </Typography>
                                                            <Typography variant="body2" align="center">
                                                                Ultima atualização: {format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}
                                                            </Typography>

                                                        </div>


                                                    </CardContent>
                                                </Card>
                                            ))}
                                    </div>
                                )}
                        </Paper>
                    </Paper>
                )}
                no={() => <>
                    <ForbiddenPage />
                </>}
            />
        </MainContainer>
    );
};

export default Connections;
