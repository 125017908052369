import React from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const SettingSelectionField = ({ name, settings, label, options, helpText }) => {
    let debounce;

    const handleChangeSetting = async e => {
        if (debounce) {
            clearTimeout(debounce);
        }
        const selectedValue = e.target.value;

        debounce = setTimeout(async() => {
            try {
                await api.put(`/settings/${name}`, {
                    value: selectedValue,
                });
                toast.success(i18n.t("settings.success"));
            } catch (err) {
                toastError(err);
            }
        }, 500);
    }

    const getSettingValue = key => {
        const { value } = settings && settings.length > 0 && settings.find(s => s.key === key);
        return value;
    };

    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select placeholder={label} value={getSettingValue(name)} onChange={handleChangeSetting}>
                {options.map(option => (
                    <MenuItem value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {helpText && <small style={{paddingTop: 5}}>{helpText}</small>}
        </FormControl>
    )
}

export default SettingSelectionField; 