import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";

import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import getSocket from "../../helpers/socket";
import IframeModal from "./modal";

const socket = getSocket();

const reducer = (state, action) => {
    if (action.type === "LOAD_IFRAMES") {
        const iframes = action.payload;
        const newUsers = [];

        iframes.forEach(iframe => {
            const iframeIndex = state.findIndex(u => u.id === iframe.id);
            if (iframeIndex !== -1) {
                state[iframeIndex] = iframe;
            } else {
                newUsers.push(iframe);
            }
        });

        return [...state, ...newUsers];
    }

    if (action.type === "UPDATE_IFRAMES") {
        const iframe = action.payload;
        const iframeIndex = state.findIndex(u => u.id === iframe.id);

        if (iframeIndex !== -1) {
            state[iframeIndex] = iframe;
            return [...state];
        } else {
            return [iframe, ...state];
        }
    }

    if (action.type === "DELETE_IFRAME") {
        const iframeId = action.payload;

        const iframeIndex = state.findIndex(u => u.id === iframeId);
        if (iframeIndex !== -1) {
            state.splice(iframeIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        //padding: theme.spacing(1),
        borderRadius:0,
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const IntegrationIframes = () => {
    const classes = useStyles();
    const [countIframes, setCountIframes] = useState(0);
    const [selectedIframe, setSelectedIframe] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [deletingIframe, setDeletingIframe] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const [searchParam, setSearchParam] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);


    const [iframes, dispatch] = useReducer(reducer, [])

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchUsers = async () => {
                try {
                    const { data } = await api.get("/integration/iframes/", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_IFRAMES", payload: data.iframes });
                    setCountIframes(data.count);
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchUsers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const iframeEvent = data => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_IFRAMES", payload: data.iframe });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_IFRAME", payload: +data.iframeId });
            }
        }

        socket.on("iframe", iframeEvent);

        return () => {
            socket.off("iframe", iframeEvent);
        };
    }, []);

    const handleOpenIframeModal = () => {
        setSelectedIframe(null);
        setModalOpen(true);
    };

    const handleCloseIframeModal = () => {
        setSelectedIframe(null);
        setModalOpen(false);
    };

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditIframe = iframe => {
        setSelectedIframe(iframe);
        setModalOpen(true);
    };

    const handleDeleteIframe = async iframeId => {
        try {
            await api.delete(`/integration/iframes/${iframeId}`);
            toast.success(i18n.t("iframes.toasts.deleted"));
            setCountIframes(countIframes - 1);
        } catch (err) {
            toastError(err);
        }
        setDeletingIframe(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const handleToggleEnabled = async(iframe) => {
        try {
            await api.put(`/integration/iframes/${iframe.id}`, {active: !iframe.active});
        } catch (err) {
            toastError(err);
        }
    }

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingIframe &&
                    `${i18n.t("iframes.confirmationModal.deleteTitle")} ${
                        deletingIframe.name
                    }?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteIframe(deletingIframe.id)}
            >
                {i18n.t("iframes.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <IframeModal
                open={modalOpen}
                onClose={handleCloseIframeModal}
                aria-labelledby="form-dialog-title"
                iframeId={selectedIframe && selectedIframe.id}
            />
            <MainHeader>
                <Title>{i18n.t("iframes.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t("iframes.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenIframeModal}
                    >
                        {i18n.t("iframes.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t("iframes.table.name")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("iframes.table.url")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("iframes.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {iframes.map(iframe => (
                                <TableRow key={iframe.id}>
                                    <TableCell align="center">
                                        {iframe.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {iframe.url}
                                    </TableCell>                                 
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleEditIframe(iframe)}
                                        >
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            aria-label={iframe.active ? "Desabilitar" : "Habilitar"}
                                            onClick={e => {
                                                handleToggleEnabled(iframe);
                                            }}
                                        >
                                            {iframe.active ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faLockOpen} />}
                                        </IconButton>
                                        
                                        <IconButton
                                            size="small"
                                            onClick={e => {
                                                setConfirmModalOpen(true);
                                                setDeletingIframe(iframe);
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default IntegrationIframes;
