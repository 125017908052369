import React, { createContext } from "react";
import useUserSettings from "../../hooks/useSettings/user";

const UserSettingsContext = createContext();

const UserSettingsProvider = ({ children }) => {
	const { isActiveUserSetting, getUserSettingValue,settings,  handleChangeSetting, setSettings, hashKey } = useUserSettings();

	return (
		<UserSettingsContext.Provider
			value={{ isActiveUserSetting, getUserSettingValue,settings, handleChangeSetting, setSettings,  hashKey }}
		>
			{children}
		</UserSettingsContext.Provider>
	);
};

export { UserSettingsContext, UserSettingsProvider };
