import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid, Avatar } from "@mui/material";
import { Layers, Person, VerifiedUser } from "@mui/icons-material";
import DashboardFeedbackVisual from "../../components/Dashboard/feedbackVisual";
import { IoPersonOutline } from "react-icons/io5";
import { HiOutlineQueueList } from "react-icons/hi2";
import { i18n } from "../../translate/i18n";


const useStyles = makeStyles(theme => ({
    box: {
        backgroundColor: theme.palette.background.paper,
        padding: 10,
        position: "relative",
        color: theme.palette.dark.main,
        alignItems: 'center',
        display: 'flex',
        
    },
    title: {
        fontSize: "1.2em",
        fontWeight: "normal"
    },
    grid: {
        display: 'flex',
        alignItems: 'center',
        gap: 10
    },
    icon: {
        position: "absolute",
        top: 25,
        right: 15
    },
    value: {
        fontSize: "2.5em",
        fontWeight: "bold"
    },
    block: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.paper,
    },

    avatar: {
        borderRadius: "50%",
        overflow: "hidden",

        marginRight: 10
    },
    avatarIcon: {
        width: "70px",
        borderRadius: "50%",
        height: "70px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 10,
        minWidth: "70px"
    },
    bigText: {
        fontSize: "2em",
        fontWeight: "bold"
    },
    label: {
        display: "block"
    },
    queues: {
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
        fontSize: 12,
    },
    queueTag: {
        borderRadius: 20,
        background: "#76ae63",
        padding: 10,
        color: "white",
        fontWeight: "bold",
        paddingTop: 5,
        paddingBottom: 5,
        marginRight: 5
    },
    queueNo: {
        fontStyle: "italic"
    },
    emojiRate: {
        fontSize: "2em"
    },
    userQueues: {
        overflowX: "hidden"
    }
}));

const DashboardUsersBox = ({ user, ...props }) => {
    const classes = useStyles();

    const formatTicketsRate = (rate) => {
        if (rate) {
            if (Number.isInteger(parseFloat(rate))) {
                return parseInt(rate);
            }
            return parseFloat(rate).toFixed(2).toString().replace('.', ',');
        }
        return i18n.t("dashboardHome.no_evaluation");
    }

    return (
        <Box className={classes.box} {...props}>
            <Grid container>
                <Grid item xs={12} sm={6} md={3} className={classes.grid}>
                    <div className={classes.block}>
                        <span className={classes.avatar}>
                            {user && user.userSettings && user.userSettings[0] && user.userSettings[0].avatar ?
                                <><Avatar src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${user.userSettings[0].avatar}`} style={{ width: 60, height: 60 }} /></>
                                : <Avatar src={`https://ui-avatars.com/api//?background=0D8ABC&color=fff&name=${user && user.name}`} style={{ width: 60, height: 60 }} />
                            }
                        </span>
                        <h6>{user.name}</h6>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.grid}>
                    <DashboardFeedbackVisual className={classes.emojiRate} rate={user.ticketsRate} /> ({formatTicketsRate(user.ticketsRate)})
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.grid}>
                    <div className={classes.block}>
                        <div className={classes.avatarIcon}>
                        <IoPersonOutline  size={40} color="#dddddd" />
                        </div>
                        <div>
                            <span className={classes.bigText}>{user.ticketsNumber}</span>
                            <h6>{i18n.t("dashboardHome.tickets")}</h6>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.grid}>
                    <div className={classes.block}>
                        <div className={classes.avatarIcon}>
                        <HiOutlineQueueList size={40} color="#dddddd" />
                        </div>
                        <div className={classes.userQueues}>
                            <h6>{i18n.t("dashboardHome.queues")}</h6>
                            <div className={classes.queues}>
                                {user.queues && user.queues.map(queue => (<span key={queue.id} className={classes.queueTag}>{queue.name}</span>))}
                                {(!user.queues || user.queues.length == 0) && <span className={classes.queueNo}>{i18n.t("dashboardHome.no_queue")}</span>}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <span className={classes.icon}></span>
            <span className={classes.value}></span>
        </Box>
    )
}

export default DashboardUsersBox;