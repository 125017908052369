import React, { useState, useEffect } from "react";
import { ResponsiveSunburst } from '@nivo/sunburst'

const QtyChart = ({ data, title, theme }) => {
    const [formattedChartData, setFormattedChartData] = useState({});

    useEffect(() => {
        //console.log('before', data)
        const transformData = (items) => {
            return items.map((item, index) => {
                return {
                    id: index,
                    name: item.name,
                    loc: item.qtdMsg || null
                };
            });
        };

        const transformedData = transformData(data);
        //console.log('changed', transformedData)
        setFormattedChartData({ "children": transformedData });
    }, [data]);

    return (
        <ResponsiveSunburst
            data={formattedChartData}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            id="id"
            name="name"
            value="loc"
            cornerRadius={2}
            borderColor={{ theme: 'background' }}
            colors={{ scheme: theme }}
            childColor={{
                from: 'color',
                modifiers: [
                    ['brighter', 0.1]
                ]
            }}
            enableArcLabels={true}
            arcLabelsSkipAngle={10}
            arcLabel={(d) => (d.data.name)}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [['darker', 1.4]]
            }}
            tooltip={(e) => (
                <div style={{ 
                color: '#000', 
                background:'rgba(255,255,255,0.9)',
                boxShadow: '0px 4px 29px 0px rgba(0,0,0,0.25)',
                padding: 10, 
                borderRadius: 20 }}>
                    <div>Nome: <b style={{fontSize: 14}}>{e.data.name}</b></div>
                    <div>Total: <b style={{fontSize: 14}}>{e.value}</b></div>
                    <div>Porcentagem: <b style={{fontSize: 14}}>{Math.round(e.percentage * 100) / 100}%</b></div>
                </div>
            )}

        />
    )
}

export default QtyChart;
