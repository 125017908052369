import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';

const YouTubePreview = ({ videoUrl }) => {
  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    // Extract the video ID from the URL
    const videoId = videoUrl.split('v=')[1];
    setVideoId(videoId);
  }, [videoUrl]);

  const opts = {
    height: '300',
    width: '100%',
  };

  return (
    <div>
      {videoId && <YouTube videoId={videoId} opts={opts} style={{borderRadius: 10, overflow: 'hidden'}} />}
    </div>
  );
};

export default YouTubePreview;