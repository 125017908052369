import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Box, CircularProgress } from "@mui/material";

const useStyles = makeStyles(theme => ({
    box: {
        backgroundColor: theme.palette.background.paper,
		boxShadow: "none",
        borderRadius: 20,
        padding: 10,
        position: "relative",
        color: theme.palette.dark.main,
        minHeight: 110
    },
    title: {
        fontSize: "1.2em",
        fontWeight: "600"
    },
    icon: {
        position: "absolute",
        top: 25,
        right: 15,
        fontSize: "3em"
    },
    value: {
        fontSize: "2.5em",
        fontWeight: "bold"
    },
    label: {
        fontSize: "1em",
        display: "block"
    }
}));

const DashboardBox = ({ icon, title, value, label, loading, marginTop }) => {
    const classes = useStyles();
    return (
        <Box className={classes.box} style={{ marginTop }}>
            {loading ? <CircularProgress color="inherit" /> : 
            <React.Fragment>
                
                <span className={classes.icon}>{icon}</span>
                <span className={classes.value}>{value}</span>
                {label && <span className={classes.label}>{label}</span>}
                <h3 className={classes.title}>{title}</h3>
            </React.Fragment>
            }
        </Box>
    )
}

export default DashboardBox;