import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

import makeStyles from '@mui/styles/makeStyles';
import { Button, Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { i18n } from "../../translate/i18n";
import Select from 'react-select';

import colourStyles from "../../constants/tagsColor";

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    pillBox: {
        width: '100%'
    },
}));

const AddTagToContactModal = ({ open, onClose, contactIds, allSelected }) => {
    const classes = useStyles();
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        const loadTags = async () => {
            try {
                const { data } = await api.get("/tags", {params: {all: true}});
                setTags(data.tags);
            } catch (err) {
                toastError(err);
            }
        }
    
        loadTags();
    }, [open]);

    const handleAddTags = async values => {
        setIsSubmitting(true);
        try {
            await api.post(`/tags/add`, {tagIds: selectedTags.map((tag) => tag.value), contactIds: contactIds, allSelected});
            handleClose();
            toast.success(i18n.t("tagModal.success"));
        } catch (err) {
            toastError(err);
        }
        setIsSubmitting(false);
    };

    const handleChange = (data) => {
        const selectedTags = data;
        setSelectedTags(selectedTags);
    }

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle id="form-dialog-title">
                    Adicionar tags ao contato
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="subtitle1" gutterBottom>
                        
                    </Typography>
                    <div className={classes.multiselectContainer}>
                        <Select
                            options={tags.map((tag) => {return {value: tag.id, label: tag.name, color: tag.color}})}
                            isMulti
                            onChange={handleChange}
                            onRemove={handleChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            menuPosition={'fixed'}
                            menuPortalTarget={document.body}
                            placeholder="Selecione as tags"
                            styles={colourStyles}
                            />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={isSubmitting}
                        variant="outlined"
                    >
                        {i18n.t("tagModal.buttons.cancel")}
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleAddTags}
                        variant="contained"
                        className={classes.btnWrapper}
                    >
                        {i18n.t("tagModal.buttons.okAdd")}
                        {isSubmitting && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddTagToContactModal;