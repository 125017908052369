import React, { useState, useEffect, useContext } from "react";

import 'reactflow/dist/style.css';
import makeStyles from '@mui/styles/makeStyles';
import { PiRobot } from "react-icons/pi";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Paper, Button, CircularProgress, Box, useTheme } from "@mui/material";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import BotList from "../../components/BotList";
import ConfirmationModal from "../../components/ConfirmationModal";
import { ReactFlowProvider } from 'reactflow';
import ForbiddenPage from "../../components/ForbiddenPage";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	mainPaper: {
		marginTop: 40,
		borderRadius: 20,
		border: '0px !important',
		marginBottom: 40,
		height: '100vh',
		overflowY: 'auto',
		...theme.scrollbarStylesSoft,

		//overflow: 'auto'
	},
	helpMessage: {
		padding: "10px"
	},
	tag: {
		padding: 3,
		paddingLeft: 10,
		paddingRight: 10,
		color: "#FFF",
		fontWeight: "bold",
		borderRadius: 4,
		display: "inline-block"
	},
	initialMessage: {
		padding: 10,
		textAlign: 'center'
	},
	mainBox: {
		padding: '0px 10%',
		//border: "1px solid #CCC",
		minHeight: '-webkit-fill-available',
		overflow: 'auto',
		background: theme.palette.fancyBackground,
		borderRadius: 10,
		margin: 20,
		[theme.breakpoints.down('md')]: {
            padding: '0px !important',
			overflow: 'initial',
			background: 'transparent',
			margin: 10
        }
	},
	options: {
		marginTop: 20
	},
	listItem: {
		listStyle: "none",
		border: "1px solid #CCC",
		padding: 5,

		margin: 5
	},
	listItems: {
		margin: 0,
		padding: 0
	},
	listItemNumber: {
		borderRadius: "50%",
		padding: 15,
		textAlign: "center",
		fontWeight: "bold",
		background: "#666",
		color: "#FFF",
		display: "inline-block",
		fontSize: "16px",
		marginRight: 20
	},
	listItemActionLabel: {
		display: "inline-block",
		marginLeft: 20,
		marginRight: 15
	},
	actions: {
		display: "inline-block"
	},
	cleanButtonWrapper: {
		textAlign: "right"
	}
}));




const Bots = () => {
	const { user } = useContext(AuthContext);
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [mainList, setMainList] = useState([]);
	const [loadingInitial, setLoadingInitial] = useState(false);
	const [reload, setReload] = useState(false);
	const [queues, setQueues] = useState([]);
	const [users, setUsers] = useState([]);
	const [showRemoveModal, setShowRemoveModal] = useState(false);


	useEffect(() => {
		setLoading(true);
		setLoadingInitial(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchFirstList = async () => {
				try {
					const { data } = await api.get("/bots/", {
						params: { root: "1" },
					});
					setMainList(data.lists);
					setLoading(false);
					setLoadingInitial(false);
				} catch (err) {
					toastError(err);
				}
			};
			const fetchQueues = async () => {
				try {
					const { data } = await api.get("/queue/");
					setQueues(data);
				} catch (err) {
					toastError(err);
				}
			}
			const fetchUsers = async () => {
				try {
					const { data } = await api.get("/users/", {
						params: { pageNumber: 'all' }
					});
					setUsers(data.users);
				} catch (err) {
					toastError(err);
				}
			}
			fetchFirstList();
			fetchQueues();
			fetchUsers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [reload]);

	const save = async (list) => {
		try {
			let url = `/bots/`;
			if (list && list.id) url = `/bots/${list.id}`;
			await api.post(url, list);
		} catch (err) {
			toastError(err);
		}
	}

	const enable = async () => {
		setLoadingInitial(true);
		await save({ first: true });
		setReload(!reload);
	}

	const cleanAll = async () => {
		try {
			let url = `/bots/${mainList.id}`;
			await api.delete(url);
			setShowRemoveModal(false);
			setReload(!reload);
		} catch (err) {
			toastError(err);
			setShowRemoveModal(false);
			setReload(!reload);
		}
	}

	return (
		<MainContainer className={classes.mainContainer}>
			<Can
				role={user.roleId}
				perform="bots:show:page"
				yes={() => (
					<Paper
						className={classes.mainPaper}
						variant="outlined"
					>

						<MainHeader style={{ justifyContent: 'space-between !important' }}>
							<Title><PiRobot size={24} />{i18n.t("bots.title")}</Title>
							<Can
								role={user.roleId}
								perform="bots:options:clear"
								yes={() => (
									<Button onClick={() => { setShowRemoveModal(true) }}>{i18n.t("bots.clear")}</Button>
								)}
								no={() => <></>}
							/>
						</MainHeader>


						<ConfirmationModal
							title="Confirme a exclusão"
							onConfirm={() => cleanAll()}
							onClose={() => setShowRemoveModal(false)}
							open={showRemoveModal}>
							{i18n.t("bots.confirmation_delete")}
						</ConfirmationModal>

						<Box
							color="text.primary"
							className={classes.mainBox}
							style={{
								padding: loadingInitial && 30,
								display: loadingInitial && 'flex',
								justifyContent: loadingInitial && 'center',
							}}
						>
							{!loadingInitial && !mainList && <p className={classes.initialMessage}>
								{i18n.t("bots.desactived")}
								<Can
									role={user.roleId}
									perform="bots:enabled"
									yes={() => (
										<Button onClick={enable}>{i18n.t("bots.click_edit")}</Button>
									)}
									no={() => <>
										<ForbiddenPage />
									</>}
								/>
							</p>
							}

							{loadingInitial && <CircularProgress />}
							{!loadingInitial && mainList &&
								<ReactFlowProvider>
									<BotList
										listId={mainList.id}
										queues={queues}
										users={users} />
								</ReactFlowProvider>
							}
						</Box>

					</Paper>
				)}
				no={() => <>
					<ForbiddenPage />
				</>}
			/>
		</MainContainer >
	);
};

export default Bots;
