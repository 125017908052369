import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Avatar from "@mui/material/Avatar";
import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";

import Paper from "@mui/material/Paper";
import SettingBoolField from "../UserSetting/boolField";
import { Button, FormControlLabel } from "@mui/material";
import { UserSettingsContext } from "../../context/Settings/UserSettingsContext";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ButtonWithSpinner from "../ButtonWithSpinner";
import { toast } from "react-toastify";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import IOSSwitch from "../IOSSwitch";
import AvatarUploader from "../AvatarUpload";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 0,
		boxShadow: 'none',
		'&>div': {
			width: "100%"
		}
	},

}));


const GroupSettingsModal = ({ ticket, open, onClose, userId }) => {
	const classes = useStyles();
    const history = useHistory();
	const [subject, setSubject] = useState('');
	const [description, setDescription] = useState('');
	const [loading, setLoading] = useState(false);
	const [permissions, setPermissions] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [avatar, setAvatar] = useState('/static/images/avatar/1.jpg'); // Initial avatar image

	const handleClose = () => {
		onClose();
	};

	const handleChangePermissions = (item) => {
		//console.log('permissions', permissions)
		if (item === 'announcement') {
			if (!permissions.includes('announcement')) {
				setPermissions(prevPermissions => [
					...prevPermissions.filter(p => p !== 'not_announcement'),
					'announcement'
				]);
			} else {
				setPermissions(prevPermissions => [
					...prevPermissions.filter(p => p !== 'announcement'),
					'not_announcement'
				]);
			}
		}

		if (item === 'locked') {
			if (!permissions.includes('locked')) {
				setPermissions(prevPermissions => [
					...prevPermissions.filter(p => p !== 'unlocked'),
					'locked'
				]);
			} else {
				setPermissions(prevPermissions => [
					...prevPermissions.filter(p => p !== 'locked'),
					'unlocked'
				]);
			}
		}
	}




	const handleGroupSettings = async () => {
		setLoading(true);
		try {
			if (selectedFile) {
				const formData = new FormData();
				formData.append("ticketId", ticket.id);
				formData.append("avatar", selectedFile);

				await api.post(`/tickets/group/settings/photo`,
					formData, { headers: { 'Content-Type': "multipart/form-data" } });
			}

			const response = await api.post("/tickets/group/settings", {
				ticketId: ticket.id,
				subject: subject,
				description: description,
				permissions: permissions
				//contacts: checked,

			});
			console.log(response, 'ticket in here');
			toast.success('Configurações de grupos, atualizado com sucesso!')
		} catch (err) {
			console.log('err', err)
			toastError(err);

		}
		setLoading(false);
		handleClose();
		history.push("/tickets");
		history.push(`/tickets/${ticket.id}`);
	};

	useEffect(() => {
		async function getGrupoData() {
			if (ticket.groupInfo) {
				ticket.groupInfo.avatar && setAvatar(ticket.groupInfo.avatar);
				ticket.groupInfo.subject && setSubject(ticket.groupInfo.subject);
				ticket.groupInfo.desc && setDescription(ticket.groupInfo.desc);
				if(ticket.groupInfo.announce){
					handleChangePermissions('announcement')
				}
				if(ticket.groupInfo.restrict){
					handleChangePermissions('locked')
				}

				//console.log(ticket.groupInfo)
			}
		}

		getGrupoData();
	}, [])


	const handleAvatar = async (e) => {
		setSelectedFile(null);
		setSelectedFile(e);
		setAvatar(e);
	}
	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					Configuração de Grupo
				</DialogTitle>
				<DialogContent>

					{/*<Paper className={classes.paper}>
						<div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
							<AvatarUploader setAvatar={(e) => handleAvatar(e)} avatar={avatar} />
						</div>
					</Paper>*/}

					<Paper className={classes.paper}>
						<TextField id="outlined-basic"
							label="Titulo do Grupo"
							defaultValue={subject}
							onChange={(e) => setSubject(e.target.value)}
							variant="outlined"
						/>
					</Paper>

					<Paper className={classes.paper}>
						<TextField id="outlined-basic"
							defaultValue={description}
							onChange={(e) => setDescription(e.target.value)}
							label="Descrição do Grupo"
							variant="outlined" />
					</Paper>

					<Paper className={classes.paper}>
						<FormControlLabel
							control={
								<IOSSwitch
									checked={
										permissions.includes('announcement')
									}
									onChange={() => handleChangePermissions('announcement')}
									name={'announcement'}
								/>}
							label="Somente admins pode enviar mensagens"

						/>
					</Paper>

					<Paper className={classes.paper}>
						<FormControlLabel
							control={
								<IOSSwitch
									checked={
										permissions.includes('locked')
									}
									onChange={() => handleChangePermissions('locked')}
									name={'locked'}
								/>}
							label="Permitido todos de modificar as configurações do grupo"
						/>
					</Paper>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Fechar</Button>

					<ButtonWithSpinner
						variant="contained"
						type="button"
						onClick={handleGroupSettings}
						color="primary"
						loading={loading}
					>
						{i18n.t("Confirmar")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default GroupSettingsModal;
