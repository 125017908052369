import React, { useState, useEffect, useReducer, useRef, useContext } from "react";
import { toast } from "react-toastify";

import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { FiUsers } from "react-icons/fi";
import InputAdornment from "@mui/material/InputAdornment";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import UserModal from "../../components/UserModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import UserStatusIcon from "../../components/User/statusIcon";
import { AuthContext } from "../../context/Auth/AuthContext";
import getSocket from "../../helpers/socket";
import ForbiddenPage from "../../components/ForbiddenPage";
import { Can } from "../../components/Can";

const socket = getSocket();

const reducer = (state, action) => {
    if (action.type === "LOAD_USERS") {
        const users = action.payload;
        const newUsers = [];

        users.forEach(user => {
            const userIndex = state.findIndex(u => u.id === user.id);
            if (userIndex !== -1) {
                state[userIndex] = user;
            } else {
                newUsers.push(user);
            }
        });

        return [...state, ...newUsers];
    }

    if (action.type === "UPDATE_USERS") {
        const user = action.payload;
        const userIndex = state.findIndex(u => u.id === user.id);

        if (userIndex !== -1) {
            state[userIndex] = user;
            return [...state];
        } else {
            return [user, ...state];
        }
    }

    if (action.type === "DELETE_USER") {
        const userId = action.payload;

        const userIndex = state.findIndex(u => u.id === userId);
        if (userIndex !== -1) {
            state.splice(userIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles(theme => ({
    mainContainer: {
        background: theme.palette.fancyBackground,
    },
    mainPaper: {
        flex: 1,
        marginTop: 40,
        borderRadius: 20,
        border: '0px !important',
        marginBottom: 40,
        overflow: 'hidden'
    },
    mainPaperTable: {
        flex: 1,
        overflow: 'auto',
        height: '68vh',
        ...theme.scrollbarStylesSoft,
    },
    totalsText: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.softBackground,
        color: theme.palette.textPrimary
    },
    buttonsTicket: {
        height: 40,
        borderRadius: '5px!important',
        display: 'inline-flex',
        alignItems: 'center',
        boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.07) !important',
        '&:hover': {
            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2) !important',
        },
    }

}));

const Users = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [deletingUser, setDeletingUser] = useState(null);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [users, dispatch] = useReducer(reducer, []);
    const [addDisabled, setAddDisabled] = useState(false);
    const [countUsers, setCountUsers] = useState(0);
    const [limitUsers, setLimitUsers] = useState(0);
    const [countEnabled, setCountEnabled] = useState(0);
    const countUsersRef = useRef();

    countUsersRef.current = countUsers;

    const { user } = useContext(AuthContext);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchUsers = async () => {
                try {
                    const { data } = await api.get("/users/", {
                        params: { searchParam, pageNumber },
                    });

                    dispatch({ type: "LOAD_USERS", payload: data.users });
                    setLimitUsers(data.limitUsers);
                    setCountUsers(data.count);
                    setCountEnabled(data.countEnabled);
                    setHasMore(data.hasMore);
                    setLoading(false);

                  //  console.log(data);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchUsers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, countEnabled]);

    useEffect(() => {
        if (limitUsers && limitUsers <= countEnabled) {
            setAddDisabled(true);
        } else {
            setAddDisabled(false);
        }
    }, [limitUsers, countUsers, countEnabled]);

    useEffect(() => {
        const userEvent = data => {
            if (data.action === "create") {
                setCountUsers(countUsersRef.current + 1);
            }
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_USERS", payload: data.user });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_USER", payload: +data.userId });
            }
        }

        socket.on("user", userEvent);

        return () => {
            socket.off("user", userEvent);
        };
    }, []);

    const handleOpenUserModal = () => {
        setSelectedUser(null);
        setUserModalOpen(true);
    };

    const handleCloseUserModal = () => {
        setSelectedUser(null);
        setUserModalOpen(false);
    };

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditUser = user => {
        setSelectedUser(user);
        setUserModalOpen(true);
    };

    const handleToggleEnabled = async (user) => {
        try {
            await api.put(`/users/${user.id}`, { enabled: !user.enabled });
            if (user.enabled) {
                setCountEnabled(countEnabled - 1);
            } else {
                setCountEnabled(countEnabled + 1);
            }
        } catch (err) {
            toastError(err);
        }
    }

    const handleDeleteUser = async userId => {
        try {
            await api.delete(`/users/${userId}`);
            toast.success(i18n.t("users.toasts.deleted"));
            setCountUsers(countUsers - 1);
            setCountEnabled(countEnabled - 1);
        } catch (err) {
            toastError(err);
        }
        setDeletingUser(null);
        setSearchParam("");
        setPageNumber(1);
    };

    let pageNumberInterval = null;

    const loadMore = () => {
        if (pageNumberInterval) {
            clearInterval(pageNumberInterval);
        }
        pageNumberInterval = setInterval(() => {
            setPageNumber(prevPageNumber => prevPageNumber + 1);
            clearInterval(pageNumberInterval);
        }, 300);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingUser &&
                    `${i18n.t("users.confirmationModal.deleteTitle")} ${deletingUser.name
                    }?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteUser(deletingUser.id)}
            >
                {i18n.t("users.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <UserModal
                open={userModalOpen}
                onClose={handleCloseUserModal}
                aria-labelledby="form-dialog-title"
                userId={selectedUser && selectedUser.id}
            />

            {/**  <div className={classes.totalsText}>

            </div>*/}
            <Can
                role={user.roleId}
                perform="users:show:page"
                yes={() => (
                    <Paper
                        className={classes.mainPaper}
                        variant="outlined"
                        onScroll={handleScroll}
                    >
                        <MainHeader>
                            <Title><FiUsers size={24} /> {i18n.t("users.title")}</Title>
                            <MainHeaderButtonsWrapper>

                                <span>{i18n.t("users.total")} {countUsers}
                                    {` | ${i18n.t("users.totalEnabled")} ${countEnabled}`}
                                    {limitUsers > 0 && ` | ${i18n.t("users.totalAllowed")} ${limitUsers}`}
                                </span>


                                <TextField
                                    placeholder={i18n.t("contacts.searchPlaceholder")}
                                    type="search"
                                    size="small"
                                    value={searchParam}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: "gray" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Can
                                    role={user.roleId}
                                    perform="users:create"
                                    yes={() => (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={addDisabled}
                                            onClick={handleOpenUserModal}
                                            className={classes.buttonsTicket}
                                        >
                                            <AddIcon style={{ fontSize: 13 }} /> {i18n.t("users.buttons.add")}
                                        </Button>
                                    )}
                                    no={() => <>
                                    </>}
                                />
                            </MainHeaderButtonsWrapper>
                        </MainHeader>
                        <Paper
                            className={classes.mainPaperTable}
                            variant="outlined"
                            onScroll={handleScroll}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            {i18n.t("users.table.name")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t("users.table.status")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t("users.table.email")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t("users.table.profile")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t("users.table.actions")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {users.map(userItem => (
                                            <TableRow key={userItem.id}>
                                                <TableCell align="center">
                                                    {userItem.name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {userItem.enabled ? <UserStatusIcon user={userItem} /> : <p>Desabilitado</p>}
                                                </TableCell>
                                                <TableCell align="center">{userItem.email}</TableCell>
                                                <TableCell align="center">{userItem.role && userItem.role.name ? userItem.role.name : ''}</TableCell>
                                                <TableCell align="center">
                                                    <Can
                                                        role={user.roleId}
                                                        perform="users:edit:any"
                                                        yes={() => (
                                                        
                                                                userItem.enabled && <IconButton
                                                                    size="small"
                                                                    onClick={() => handleEditUser(userItem)}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            )}
                                                        no={() => <></>}
                                                    />

                                                    {user.id != userItem.id && (
                                                        <IconButton
                                                            size="small"
                                                            aria-label={userItem.enabled ? "Desabilitar" : "Habilitar"}
                                                            onClick={e => {
                                                                handleToggleEnabled(userItem);
                                                            }}
                                                        >
                                                            {userItem.enabled ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faLockOpen} />}
                                                        </IconButton>)}
                                                    <Can
                                                        role={user.roleId}
                                                        perform="users:delete:any"
                                                        yes={() => (
                                                            <IconButton
                                                                size="small"
                                                                onClick={e => {
                                                                    setConfirmModalOpen(true);
                                                                    setDeletingUser(userItem);
                                                                }}
                                                            >
                                                                <DeleteOutlineIcon />
                                                            </IconButton>
                                                        )}
                                                        no={() => <></>}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {loading && <TableRowSkeleton columns={4} />}
                                    </>
                                </TableBody>
                            </Table>
                        </Paper>
                    </Paper>
                )}
                no={() => <>
                    <ForbiddenPage />
                </>}
            />
        </MainContainer>
    );
};

export default Users;
