import React from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";

const SettingSoundSelectionField = ({ name, settings, label, helpText, userSettings, userId }) => {
    let debounce;
    const playSound = (sound) => {
        const audio = new Audio(`/notificationSounds/${sound}.mp3`);
        audio.play();
    }



    const handlePlaySound = (e, sound) => {
        e.stopPropagation();
        e.preventDefault();
        playSound(sound);
    }

    const handleChangeSetting = async e => {
        if (debounce) {
            clearTimeout(debounce);
        }
        const selectedValue = e.target.value;

        debounce = setTimeout(async () => {
            try {

                if (!userSettings) {
                    await api.put(`/settings/${name}`, {
                        value: selectedValue
                    });
                } else {				
                    await api.put(`/user/${userId}/settings/${name}`, {
                        value: selectedValue,
                        settings: settings
                    });
                }
                toast.success(i18n.t("settings.success"));
            } catch (err) {
                toastError(err);
            }
        }, 500);
    }

    const getSettingValue = key => {
      
        if (!userSettings) {
            const { value } = settings && settings.length > 0 && settings.find(s => s.key === key);
            return value;
        } else {
            const value  = settings && settings.length > 0 && settings.notificationSound;
            return value;
        }
    
    };

    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select placeholder={label} value={getSettingValue(name)} onChange={handleChangeSetting}>
                <MenuItem value="default"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'default') }}><PlayArrow /></IconButton> Padrão</MenuItem>
                {/* <MenuItem value="silence"><IconButton size="small" onClick={(e) => {handlePlaySound(e, 'silence')}}><PlayArrow /></IconButton> Silecioso</MenuItem> */}
                <MenuItem value="eventually"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'eventually') }}><PlayArrow /></IconButton> Eventually"</MenuItem>
                <MenuItem value="magic"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'magic') }}><PlayArrow /></IconButton> Magic</MenuItem>
                <MenuItem value="make-it-possible"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'make-it-possible') }}><PlayArrow /></IconButton> I'll make it possible</MenuItem>
                <MenuItem value="message-with-intro"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'message-with-intro') }}><PlayArrow /></IconButton> Message with intro</MenuItem>
                <MenuItem value="msn"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'msn') }}><PlayArrow /></IconButton> MSN</MenuItem>
                <MenuItem value="piece-of-cake"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'piece-of-cake') }}><PlayArrow /></IconButton> Piece of Cake</MenuItem>
                <MenuItem value="upset-sound-tone"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'upset-sound-tone') }}><PlayArrow /></IconButton> Upset</MenuItem>
                <MenuItem value="when"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'when') }}><PlayArrow /></IconButton> When?</MenuItem>
            </Select>
        </FormControl>
    )
}

export default SettingSoundSelectionField; 