import { useState, useEffect, useContext } from "react";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";

const Can = ({ role, perform, yes, no }) => {
	const { user, permissions } = useContext(AuthContext);
	const hasPermission = permissions.some((permission) => permission.key === perform);
	//console.log("hasPermission:", hasPermission);
	//console.log(permissions,'vaai')
	return hasPermission || user.roleId === 1 ? yes() : no();
};


export { Can };
