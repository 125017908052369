import React, { useState, useEffect, useReducer, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import Title from "../../components/Title";
import makeStyles from '@mui/styles/makeStyles';
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MessageInputForward from "../MessageInputForward";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import Checkbox from '@mui/material/Checkbox';
import { Box, FormControlLabel, Button, IconButton, ClickAwayListener, Menu, MenuItem, Hidden, InputBase } from "@mui/material";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ConnectionIcon from "../../components/ConnectionIcon";
import { toast } from "react-toastify";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { i18n } from "../../translate/i18n";
import IOSSwitch from "../IOSSwitch";
import AvatarUploader from "../AvatarUpload";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FaCog } from "react-icons/fa";
import { Picker } from "emoji-mart";
import MoodIcon from "@mui/icons-material/Mood";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    paper: {
        background: 'transparent',
        boxShadow: 'none'
    },
    modalDialog: {
        width: 500
    },
    TitlePadding: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 10,
        paddingTop: 10
    },
    selectUsersDrop: {
        width: 150,
        marginLeft: 10,
        marginRight: 10
    },
    mainTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mainPaperOverflow: {
        height: '40vh',
        overflowY: 'scroll',
        ...theme.scrollbarStylesSoftBig
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    block_title: {
        display: "flex",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: 'space-between'
    },
    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    newMessageBox: {
        background: theme.palette.tabHeaderBackground,
        width: "100%",
        display: "flex",
        padding: "7px",
        alignItems: "center",
    },
    messageInputWrapper: {
        background: 'rgba(0,0,0,0.05)',
        padding: 6,
        marginRight: 7,
        //background: theme.palette.total,
        display: "flex",
        borderRadius: 20,
        flex: 1,
        position: "relative"
    },

    messageInput: {
        paddingLeft: 10,
        flex: 1,
        border: "none",
    },
    blockIcons: {
        background: theme.palette.tabHeaderBackground,
        position: 'absolute',
        borderRadius: 20,
        // maxWidth: 300,
        bottom: 100,
        zIndex: 99999
    },
    blockIconsTitle: {
        background: theme.palette.tabHeaderBackground,
        position: 'absolute',
        borderRadius: 20,
        top: 120,
        left: 30,
        zIndex: 99999
    },
    blockIconsDescription: {
        background: theme.palette.tabHeaderBackground,
        position: 'absolute',
        borderRadius: 20,
        top: 80,
        left: 10,
        zIndex: 99999
    },
}));



const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach(contact => {
            const contactIndex = state.findIndex(c => c.id === contact.id);
            if (contactIndex && contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }
    if (action.type === "RESET") {
        return [];
    }
};


const NewTicketGroupModal = ({ modalOpen, onClose, forContact }) => {
    const classes = useStyles();
    const history = useHistory();
    const { user, permissions } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [groupTitle, setGroupTitle] = useState("");
    const [search, setSearchParam] = useState("");
    const [contacts, dispatch] = useReducer(reducer, []);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const [openDrawerSetting, setOpenDrawerSetting] = useState(false);
    const [tagIds] = useState([]);
    const [userIds] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [checked, setChecked] = useState([]);
    const [onlyGroups, setOnlyGroups] = useState(false);
    const [totalContacts, setTotalContacts] = useState("");
    const [whatsapp, setWhatsapp] = useState(null);
    const [initialMessage, setinitialMessage] = useState('');
    const [avatar, setAvatar] = useState('/static/images/avatar/1.jpg'); // Initial avatar image
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [groupPermissions, setPermissions] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showEmoji, setShowEmoji] = useState(false);
    const inputRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showEmojiTitle, setShowEmojiTitle] = useState(false);
    const [showEmojiDescription, setShowEmojiDescription] = useState(false);
    const [showEmojiMessage, setShowEmojiMessage] = useState(false);

    const handleAvatar = async (e) => {
        setSelectedFile(null);
        setSelectedFile(e);
        setAvatar(e);
    }

    const handleMenuItemClick = (event) => {
        setAnchorEl(null);
    }

    const handleAddEmoji = e => {
        let emoji = e.native;
        setinitialMessage(prevState => prevState + emoji);
    };

    const handleAddEmojiDescription = (e) => {
        let emoji = e.native;
        setDescription(prevState => prevState + emoji);
    };

    const handleAddEmojiTitle = (e) => {
        let emoji = e.native;
        setGroupTitle(prevState => prevState + emoji);
    };


    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [search, tagIds, userIds, onlyGroups]);

    const toggleDrawer = (newOpen) => () => {
        setOpenDrawerSetting(newOpen);
    };


    const handleToggle = (event) => {
        const currentIndex = checked.indexOf(event.target.value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(event.target.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    }

    const toggleAll = (event) => {
        if (event.target.checked) {
            checkAll();
        } else {
            uncheckAll();
        }
    }
    const checkAll = () => {
        setChecked(contacts.map((element) => { return "" + element.id }));
    }

    const uncheckAll = () => {
        setChecked([]);
    }


    const handleChangePermissions = (item) => {
        //console.log('permissions', permissions)
        if (item === 'announcement') {
            if (!permissions.includes('announcement')) {
                setPermissions(prevPermissions => [
                    ...prevPermissions.filter(p => p !== 'not_announcement'),
                    'announcement'
                ]);
            } else {
                setPermissions(prevPermissions => [
                    ...prevPermissions.filter(p => p !== 'announcement'),
                    'not_announcement'
                ]);
            }
        }

        if (item === 'locked') {
            if (!permissions.includes('locked')) {
                setPermissions(prevPermissions => [
                    ...prevPermissions.filter(p => p !== 'unlocked'),
                    'locked'
                ]);
            } else {
                setPermissions(prevPermissions => [
                    ...prevPermissions.filter(p => p !== 'locked'),
                    'unlocked'
                ]);
            }
        }
    }


    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("/contacts/", {
                        params: { searchParam: search, pageNumber, onlyGroups: onlyGroups ? "1" : "0", tagIds, userIds },
                    });
                    dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
                    setHasMore(data.hasMore);
                    setTotalContacts(`(${data.count})`);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [search, pageNumber, onlyGroups, tagIds, userIds]);

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleGroupTitle = event => {
        setGroupTitle(event.target.value);
    };

    const handleInitialMessage = event => {
        setinitialMessage(event.target.value);
    };


    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };


    const handleSaveTicket = async () => {
        // console.log('contactId', selectedContact)
        //console.log(checked, 'checked')
        if (!initialMessage) {
            toast.error("Campo de Mensagem Inicial é requerido.");
            return;
        };
        if (checked && checked.length === 0) return;
        const hasPermission = permissions.some((permission) => permission.key === 'tickets:create:with:queue');
        // console.log(hasPermission, selectedQueue)
        if (hasPermission && !selectedQueue && user.roleId !== 1) {
            toast.error("Selecione um setor para criar um atendimento");
            return;
        }

        setLoading(true);
        try {

            const response = await api.post("/tickets/group", {
                contacts: checked,
                title: groupTitle,
                initialMessage: initialMessage,
                userId: user.id,
                status: "group",
                whatsappId: whatsapp ? whatsapp.id : null,
                queueId: selectedQueue ? selectedQueue.id : null,
                description: description,
                permissions: groupPermissions

            });
            console.log(response.data, 'ticket in here');
            history.push(`/tickets/${response.data[0].id}`);
        } catch (err) {
            console.log('err', err)
            toastError(err);

        }
        setLoading(false);
        handleClose();
    };


    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleClose = () => {
        setSearchParam('')
        setChecked([]);
        onClose();
    };

    const handleOpenEmojiTitle = () => {
        setShowEmojiTitle(prevState => !prevState);
    }

    const handleOpenEmojiDescription = () => {
        setShowEmojiDescription(prevState => !prevState);
    }

    const handleOpenEmojiMessage = () => {
        setShowEmojiMessage(prevState => !prevState);
    }

    const DrawerList = (
        <div style={{ display: 'flex', flexWrap: 'wrap', padding: 10 }}>
            {/*<Paper className={classes.paper}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    <AvatarUploader setAvatar={(e) => handleAvatar(e)} avatar={avatar} />
                </div>
            </Paper>*/}

            <Paper className={classes.paper} style={{ display: 'flex', flexDirection: 'column', padding: 5 }}>
                <Paper
                    //className={}
                    variant="outlined"
                    onScroll={handleScroll}
                    style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, paddingLeft: 10, paddingRight: 10 }}
                >
                    <div className={classes.newMessageBox}>
                        {showEmojiDescription ? (
                            <div className={classes.blockIconsDescription}>
                                <ClickAwayListener onClickAway={e => setShowEmoji(false)}>
                                    <>
                                        {showEmojiDescription && (
                                            <Picker
                                                perLine={16}
                                                showPreview={false}
                                                showSkinTones={false}
                                                onSelect={handleAddEmojiDescription}
                                                dynamicWidth={true}
                                            />
                                        )}


                                    </>
                                </ClickAwayListener>
                            </div>
                        ) : null}
                        <IconButton
                            aria-label="emojiPicker"
                            component="span"
                            onClick={handleOpenEmojiDescription}
                            size="large">
                            <MoodIcon className={classes.sendMessageIcons} />
                        </IconButton>

                        <div className={classes.messageInputWrapper}>
                            <InputBase
                                className={classes.messageInput}
                                placeholder={"Descrição do Grupo"}
                                multiline
                                maxRows={5}
                                value={description}
                                required
                                onChange={(e) => setDescription(e.target.value)}

                            />
                        </div>
                    </div>
                </Paper>


                <FormControlLabel
                    control={
                        <IOSSwitch
                            checked={
                                groupPermissions.includes('announcement')
                            }
                            onChange={() => handleChangePermissions('announcement')}
                            name={'announcement'}
                        />}
                    label="Somente admins pode enviar mensagens"

                />

                <FormControlLabel
                    control={
                        <IOSSwitch
                            checked={
                                groupPermissions.includes('locked')
                            }
                            onChange={() => handleChangePermissions('locked')}
                            name={'locked'}
                        />}
                    label="Permitido todos de modificar as configurações do grupo"
                />
            </Paper>
        </div>
    )

    return (
        <Dialog open={modalOpen} onClose={handleClose} maxWidth="xl" scroll="paper">
            <DialogContent style={{ padding: 0 }}>
                <div id="form-dialog-title" className={classes.TitlePadding}>
                    <div className={classes.mainTitle}>
                        <Title>Adicionar Grupo</Title>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ width: '90%', marginBottom: 15 }}>
                            {/*<TextField
                                placeholder={i18n.t("Titulo do Grupo")}
                                type="text"
                                value={groupTitle}
                                onChange={handleGroupTitle}
                                style={{ width: '100%' }}
                            />*/}

                            <Paper
                                //className={}
                                variant="outlined"
                                onScroll={handleScroll}
                                style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, paddingLeft: 10, paddingRight: 10 }}
                            >
                                <div className={classes.newMessageBox}>
                                    {showEmojiTitle ? (
                                        <div className={classes.blockIconsTitle}>
                                            <ClickAwayListener onClickAway={e => setShowEmoji(false)}>
                                                <>
                                                    {showEmojiTitle && (
                                                        <Picker
                                                            perLine={16}
                                                            showPreview={false}
                                                            showSkinTones={false}
                                                            onSelect={handleAddEmojiTitle}
                                                            width={300}
                                                        />
                                                    )}


                                                </>
                                            </ClickAwayListener>
                                        </div>
                                    ) : null}
                                    <IconButton
                                        aria-label="emojiPicker"
                                        component="span"
                                        onClick={handleOpenEmojiTitle}
                                        size="large">
                                        <MoodIcon className={classes.sendMessageIcons} />
                                    </IconButton>

                                    <div className={classes.messageInputWrapper}>
                                        <InputBase
                                            className={classes.messageInput}
                                            placeholder={"Titulo do Grupo"}
                                            multiline
                                            maxRows={5}
                                            value={groupTitle}
                                            required
                                            onChange={(e) => setGroupTitle(e.target.value)}

                                        />
                                    </div>
                                </div>
                            </Paper>

                        </div>
                        <Button onClick={toggleDrawer(!openDrawerSetting)}><FaCog size={18} /></Button>
                    </div>

                    {openDrawerSetting &&
                        (<Box style={{ marginBottom: 15, background: 'rgba(0,0,0,0.05)', borderRadius: 10 }}>
                            {DrawerList}
                        </Box>)
                    }
                    <div className={classes.mainTitle}>
                        <TextField
                            placeholder={i18n.t("Buscar Contato")}
                            type="search"
                            value={search}
                            onChange={handleSearch}
                            className={classes.messageInput}
                            size="small"

                        />
                        <div>&nbsp;&nbsp;&nbsp;</div>

                        <span style={{ display: 'flex', gap: '3px', fontSize: 18 }}>
                            {checked && checked.length >= 1 ? checked.length : 0} contato{checked && checked.length >= 2 ? 's' : ''} selecionado{checked && checked.length >= 2 ? 's' : ''}
                        </span>
                    </div>


                </div>

                <Paper
                    className={classes.mainPaperOverflow}
                    variant="outlined"
                    onScroll={handleScroll}
                    style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, height: openDrawerSetting ? `20vh` : `40vh` }}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox onChange={toggleAll} />
                                </TableCell>
                                <TableCell padding="checkbox" />
                                <TableCell></TableCell>
                                <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                                <Can
                                    role={user.roleId}
                                    perform="contacts:list:any"
                                    yes={() => (
                                        <TableCell align="center">
                                            {i18n.t("contacts.table.whatsapp")}
                                        </TableCell>)}
                                    no={() => <></>}
                                />
                                <TableCell>
                                    {i18n.t("contacts.table.user")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {contacts ? contacts.map(contact => (
                                    <TableRow key={contact.id}>
                                        <TableCell>
                                            <Checkbox value={contact.id} checked={checked.indexOf("" + contact.id) !== -1} onChange={handleToggle} />
                                        </TableCell>
                                        <TableCell>
                                            {contact.blocked && <BlockIcon />}
                                        </TableCell>
                                        <TableCell style={{ paddingRight: 0 }}>
                                            {<Avatar src={contact.profilePicUrl} />}
                                        </TableCell>
                                        <TableCell>{contact.name}</TableCell>
                                        <Can
                                            role={user.roleId}
                                            perform="contacts:list:any"
                                            yes={() => (
                                                <TableCell align="center">
                                                    {contact.isWhatsapp && <ConnectionIcon connectionType={'whatsapp'} />}
                                                    {contact.isFacebook && <ConnectionIcon connectionType={'facebook'} />}
                                                    {contact.isInstagram && <ConnectionIcon connectionType={'instagram'} />}
                                                    {contact.numberFormatted}
                                                </TableCell>)}
                                            no={() => <></>}
                                        />
                                        <TableCell>
                                            {contact.user ? contact.user.name : ''} {contact.queue ? `(${contact.queue.name})` : ''}
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell style={{ paddingRight: 0 }}>
                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                        <TableCell align="center">
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell align="center"></TableCell>

                                    </TableRow>
                                )}
                                {loading &&
                                    <Can
                                        role={user.roleId}
                                        perform="contacts:list:any"
                                        yes={() => (<TableRowSkeleton columns={5} />)}
                                        no={() => (<TableRowSkeleton columns={4} />)}
                                    />}
                            </>
                        </TableBody>
                    </Table>
                </Paper>

                <Paper
                    //className={}
                    variant="outlined"
                    onScroll={handleScroll}
                    style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, paddingLeft: 10, paddingRight: 10 }}
                >
                    <div className={classes.newMessageBox}>
                        {showEmojiMessage ? (
                            <div className={classes.blockIcons}>
                                <ClickAwayListener onClickAway={e => setShowEmoji(false)}>
                                    <>
                                        {showEmojiMessage && (
                                            <Picker
                                                perLine={16}
                                                showPreview={false}
                                                showSkinTones={false}
                                                onSelect={handleAddEmoji}
                                                width={300}
                                            />
                                        )}


                                    </>
                                </ClickAwayListener>
                            </div>
                        ) : null}
                        <IconButton
                            aria-label="emojiPicker"
                            component="span"
                            onClick={handleOpenEmojiMessage}
                            size="large">
                            <MoodIcon className={classes.sendMessageIcons} />
                        </IconButton>

                        <div className={classes.messageInputWrapper}>
                            <InputBase
                                className={classes.messageInput}
                                placeholder={"Mensagem Inicial"}
                                multiline
                                maxRows={5}
                                value={initialMessage}
                                required
                                onChange={handleInitialMessage}

                            />
                        </div>
                    </div>
                </Paper>

            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={loading}
                    variant="outlined"
                >
                    {i18n.t("newTicketModal.buttons.cancel")}
                </Button>
                <ButtonWithSpinner
                    variant="contained"
                    type="button"
                    disabled={checked.length === 0 && !initialMessage}
                    onClick={handleSaveTicket}
                    color="primary"
                    loading={loading}
                >
                    {i18n.t("newTicketModal.buttons.ok")}
                </ButtonWithSpinner>
            </DialogActions>

        </Dialog>
    );
};

export default NewTicketGroupModal;