import React from "react";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
	contactsHeader: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		padding: "0px 15px 5px 15px",
		color: theme.palette.text.primary
	},
}));

const MainHeader = ({ children }) => {
	const classes = useStyles();

	return <div className={classes.contactsHeader}>{children}</div>;
};

export default MainHeader;