import React, { useState } from "react"
import ScheduledMessageDateModal from "../../components/ScheduledMessageDateModal";
import { Button } from "@mui/material";

const Tests = ({}) => {
    const [testDateOpen, setTestDateOpen] = useState(false);
    return <div>
        <Button
        variant="contained"
        color="primary"
        onClick={e => setTestDateOpen(true)}
        >Testar data como dia útil</Button>
        {testDateOpen && <ScheduledMessageDateModal open={testDateOpen} onClose={() => setTestDateOpen(false)} />}
    </div>
}

export default Tests;