import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Paper, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";


const DraggableList = ({ items, setItems, setOrderItems }) => {
    let debounce = null;
    console.log('items', items)
    const handleChangeSetting = async (item) => {
        if (debounce) {
            clearTimeout(debounce);
        }
        const selectedValue = item;
        const settingKey = 'distributionList';
        debounce = setTimeout(async() => {
            try {
                await api.put(`/settings/${settingKey}`, {
                    value: selectedValue,
                });
                toast.success(i18n.t("settings.success"));
            } catch (err) {
                toastError(err);
            }
        }, 500);
    }


    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const newItems = [...items];
        const [movedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, movedItem);

        setItems(newItems);
        //just add the id of user orderItems need be like [4,5,6] the id of each users
        const orderItemIds = newItems.map(item => item.id);
        handleChangeSetting(JSON.stringify(orderItemIds))
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <p style={{textAlign: 'center', opacity: '0.5', width: '100%'}}>
                Arraste e solte para trocar ordem dos usuários</p>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <List {...provided.droppableProps} ref={provided.innerRef} component={Paper} elevation={0} style={{ border: '1px solid #eee', borderRadius: 20, padding: 0, overflow: 'auto', maxWidth: '75%', margin: '20px auto' }}>
                        {items.map((item, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                {(provided) => (
                                    item && item.name && 
                                    <ListItem
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        className='boxItem'
                                    >
                                        {/** <ListItemAvatar>
                                            <Avatar alt={item.name} src={item.photo} />
                                        </ListItemAvatar> **/}

                                        <div className='number'>{index + 1}</div>
                                        <ListItemText
                                            primary={item.name && item.name}
                                            secondary={
                                                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                    {item.queues && item.queues.map((queue) => (
                                                        <div key={queue.id} style={{ backgroundColor: queue.color, borderRadius: 5, color: '#000', padding: '5px', margin: '0px', fontSize: 12 }}>
                                                            {queue.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        />
                                        <div className={item.status && item.status === 'online' ? 'online' : 'offline'}></div>
                                        {item.status && item.status === 'online' ? 'Online' : 'Offline'}
                                    </ListItem>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DraggableList;