import { FormControlLabel, Switch } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import React from "react";

const IOSSwitch = withStyles((theme) => ({
	root: {
	  width: 42,
	  height: 26,
	  padding: 0,
	  margin: theme.spacing(1),
	},
	switchBase: {
	  padding: 1,
	  '&$checked': {
		transform: 'translateX(16px)',
		color: theme.palette.common.white,
		'& + $track': {
		  backgroundColor: '#52d869',
		  opacity: 1,
		  border: 'none',
		},
	  },
	  '&$focusVisible $thumb': {
		color: '#52d869',
		border: '6px solid #fff',
	  },
	},
	thumb: {
	  width: 24,
	  height: 24,
	},
	track: {
	  borderRadius: 26 / 2,
	  border: `1px solid ${theme.palette.grey[400]}`,
	  backgroundColor: theme.palette.grey[50],
	  opacity: 1,
	  transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
  }))(({ classes, ...props }) => {
	return (
		<React.Fragment>
			{props.label ? 
			<FormControlLabel label={props.label} control={<Switch
				focusVisibleClassName={classes.focusVisible}
				disableRipple
				classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
				}}
				{...props}
			/>} /> :
			<Switch
				focusVisibleClassName={classes.focusVisible}
				disableRipple
				classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
				}}
				{...props}
			/>}
		</React.Fragment>
	);
  });

export default IOSSwitch;