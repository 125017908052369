import React, { useState, useEffect } from "react";
//import { ResponsiveBar } from '@nivo/bar';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	infoText: {
		marginBottom: theme.spacing(2),
	},
}));

const GroupChartModal = ({ open, onClose, ticket, messages }) => {
	const classes = useStyles();
	const [infoData, setInfoData] = useState(null);
	const [chartData, setChartData] = useState(null);
	const [reportInfo, setReportInfo] = useState({});

	useEffect(() => {
		const fetchMessages = async () => {
			try {
				const { data } = await api.get("/messages/" + ticket.id);
				if(data) {
					setInfoData(data)
					//console.log(data, 'messagesInfo')
				}
			} catch (err) {
				console.log(err)
			}
		}
		fetchMessages();
	}, [ticket.id]);

	useEffect(() => {
		if (infoData) {
			const timestamps = infoData.messages.map(msg => new Date(msg.createdAt).toLocaleTimeString());
			const dataMsgs = infoData.messages.map(msg => msg.body.length); // Using the message length as example

			const totalMessages = infoData.messages.length;
			const totalLength = dataMsgs.reduce((sum, length) => sum + length, 0);
			const averageLength = (totalLength / totalMessages).toFixed(2);
			const firstMessageTime = new Date(infoData.messages[0].createdAt).toLocaleString();
			const lastMessageTime = new Date(infoData.messages[infoData.messages.length - 1].createdAt).toLocaleString();

			const chartData = timestamps.map((timestamp, index) => ({
				timestamp,
				length: dataMsgs[index]
			}));

			setChartData(chartData);
			setReportInfo({
				totalMessages,
				averageLength,
				firstMessageTime,
				lastMessageTime,
			});
		}
	}, [infoData]);

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={onClose} scroll="paper">
				<DialogTitle>{ticket?.contact?.name}</DialogTitle>
				<DialogContent dividers>
					{chartData ? (
						<>
							<Typography variant="h6" className={classes.infoText}>
								Total de Mensagens: {reportInfo.totalMessages}
							</Typography>
							<Typography variant="h6" className={classes.infoText}>
								Comprimento Médio das Mensagens: {reportInfo.averageLength}
							</Typography>
							<Typography variant="h6" className={classes.infoText}>
								Primeira Mensagem Enviada em: {reportInfo.firstMessageTime}
							</Typography>
							<Typography variant="h6" className={classes.infoText}>
								Última Mensagem Enviada em: {reportInfo.lastMessageTime}
							</Typography>
							{/*<div style={{ height: 400 }}>
								<ResponsiveBar
									data={chartData}
									keys={['length']}
									indexBy="timestamp"
									margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
									padding={0.3}
									valueScale={{ type: 'linear' }}
									indexScale={{ type: 'band', round: true }}
									colors={{ scheme: 'nivo' }}
									borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
									axisTop={null}
									axisRight={null}
									axisBottom={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 90,
										legend: 'Timestamp',
										legendPosition: 'middle',
										legendOffset: 32
									}}
									axisLeft={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legend: 'Length',
										legendPosition: 'middle',
										legendOffset: -40
									}}
									labelSkipWidth={12}
									labelSkipHeight={12}
									labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
									legends={[
										{
											dataFrom: 'keys',
											anchor: 'bottom-right',
											direction: 'column',
											justify: false,
											translateX: 120,
											translateY: 0,
											itemsSpacing: 2,
											itemWidth: 100,
											itemHeight: 20,
											itemDirection: 'left-to-right',
											itemOpacity: 0.85,
											symbolSize: 20,
											effects: [
												{
													on: 'hover',
													style: {
														itemOpacity: 1
													}
												}
											]
										}
									]}
									animate={true}
									motionStiffness={90}
									motionDamping={15}
								/>
							</div>*/}
						</>
					) : (
						<div>Carregando informações...</div>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default GroupChartModal;