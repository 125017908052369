import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, List, ListItem, ListItemText, Divider, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import documentationData from './docs.json';
import MainContainer from "../../components/MainContainer";
import { useLocation, useHistory } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  sidebar: {
    height: '80vh',
    overflow: 'auto',
    marginTop: 40,
    borderRadius: 20,
    border: 0,
    ...theme.scrollbarStylesSoft
  },
  root: {
    background: theme.palette.fancyBackground,
    minHeight: '100%',
    padding: '0px 24px 24px 24px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 5px 24px 5px',
    },
    '& h2': {
      color: theme.palette.dark.main
    }
  },
  mainPaper: {
    flex: 1,
    marginTop: 40,
    borderRadius: 20,
    border: '0px !important',
    marginBottom: 40,
    overflow: 'hidden'
  },
  titleDoc: {
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 15,
    paddingTop: 5,
    paddingBottom: 5,
    background: theme.palette.fancyBackground,
  },
  titlePage: {
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 40
  },
  listItem: {
    fontSize: 14
  },
  linkDoc: {
    fontSize: 13,
    display: 'flex',
    gap: 5
  },
  contentPage: {
    marginTop: 40,
    padding: 20,
    borderRadius: 20,
    overflow: 'auto',
    height: '80vh',
    flex: 1,
    ...theme.scrollbarStylesSoft
  },
  basicHeader:{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    background: '#fff',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 3%'
  }
}));


function DocumentationPage() {
  const classes = useStyles();
  const [selectedContent, setSelectedContent] = useState(null);
  const history = useHistory();
  const handleLinkClick = (content) => {
    setSelectedContent(content);
  };

  const location = useLocation();
  const isHelpPage = location.pathname === '/help';

  useEffect(() => {
    async function gotoFirst() {
      documentationData && documentationData[0].links &&
        handleLinkClick(documentationData[0].links[1])
    };
    gotoFirst();
  }, [])

  return (
    <div className={classes.root}>
      <MainContainer className={classes.mainContainer}>
        {isHelpPage && (
          <div className={classes.basicHeader}>
            <Button onClick={() => history.push('/')}>
              <FaArrowLeft /> Voltar
            </Button>
          </div>
        )
        }
        <Grid container spacing={2} style={{ minHeight: isHelpPage ? '98vh' : '80Vh', alignItems: 'center' }}>
          {/* Left Column - Links */}
          <Grid item xs={12} md={3}>
            <Paper style={{}} className={classes.sidebar}>
              <List>
                {documentationData.map((section, index) => (
                  <div key={index}>
                    <Typography variant="h6" className={classes.titleDoc}>{section.section}</Typography>
                    <Divider />
                    {section.links.map((link, linkIndex) => (
                      <ListItem
                        button
                        key={linkIndex}
                        onClick={() => handleLinkClick(link)}
                        className={classes.listItem}
                      >
                        <ListItemText primary={link.title} secondary={link.new && 'novo'} className={classes.linkDoc} />
                      </ListItem>
                    ))}
                  </div>
                ))}
              </List>
            </Paper>
          </Grid>

          <Grid item xs={12} md={9}>
            <Paper className={classes.contentPage}>
              {selectedContent && (
                <>
                  <Typography variant="h4" align="center" className={classes.titlePage} gutterBottom>
                    {selectedContent.title}
                  </Typography>
                  <Typography variant="body1">
                    <div dangerouslySetInnerHTML={{ __html: selectedContent.content }} />

                    {
                      selectedContent.video && <video src={selectedContent.video}
                        style={{
                          maxWidth: '90%',
                          display: 'flex',
                          alignSelf: 'center',
                          borderRadius: 20,
                          overflow: 'hidden',
                          margin: '20px auto'
                        }} controls />
                    }

                    {
                      selectedContent.image &&
                      <img src={selectedContent.image}
                        style={{
                          maxWidth: '90%',
                          display: 'flex',
                          alignSelf: 'center',
                          borderRadius: 20,
                          overflow: 'hidden',
                          margin: '20px auto'
                        }}
                      />
                    }

                    {selectedContent.content1 && <div dangerouslySetInnerHTML={{ __html: selectedContent.content1 }} />}

                    {
                      selectedContent.image1 &&
                      <img src={selectedContent.image1}
                        style={{
                          maxWidth: '90%',
                          display: 'flex',
                          alignSelf: 'center',
                          borderRadius: 20,
                          overflow: 'hidden',
                          margin: '20px auto'
                        }}
                      />
                    }
                    {selectedContent.content2 && <div dangerouslySetInnerHTML={{ __html: selectedContent.content2 }} />}

                    {
                      selectedContent.image2 &&
                      <img src={selectedContent.image2}
                        style={{
                          maxWidth: '90%',
                          display: 'flex',
                          alignSelf: 'center',
                          borderRadius: 20,
                          overflow: 'hidden',
                          margin: '20px auto'
                        }}
                      />
                    }

                    {
                      selectedContent.image3 &&
                      <img src={selectedContent.image3}
                        style={{
                          maxWidth: '90%',
                          display: 'flex',
                          alignSelf: 'center',
                          borderRadius: 20,
                          overflow: 'hidden',
                          margin: '20px auto'
                        }}
                      />
                    }


                    {selectedContent.content3 && <div dangerouslySetInnerHTML={{ __html: selectedContent.content3 }} />}
                    {selectedContent.content4 && <div dangerouslySetInnerHTML={{ __html: selectedContent.content4 }} />}
                  </Typography>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </MainContainer>
    </div>
  );
}

export default DocumentationPage;
