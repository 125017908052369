import React, { useState } from "react";

import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { DatePicker } from "rsuite";
//import "rsuite/dist/styles/rsuite-default.css";
import "./styles.css";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        flexWrap: "wrap",
    },
    datePickerField: {
        width: "100%",
        zIndex: 1000000,
        marginBottom: 10
    },
    datePickerFieldMenu: {
        zIndex: 9999999999
    },
    dialogBody: {
        padding: 10
    },
    result: {
        border: '1px solid #CCC',
        padding: 3,
        marginBottom: 10
    }
}));

const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sáb',
    ok: 'OK',
    today: 'Hoje',
    yesterday: 'Ontem',
    last7Days: 'Últimos 7 dias',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
    last30Days: 'Últimos 30 dias',
    thisMonth: 'Esse mês',
    lastMonth: 'Último mês',
    thisYear: 'Esse Ano',
    lastYear: 'Último Ano',
    formattedMonthPattern: 'MMM, YYYY',
      formattedDayPattern: 'DD MMM, YYYY'
};

const ScheduledMessageDateModal = ({ open, onClose }) => {
    const classes = useStyles();

    const [date, setDate] = useState(null);
    const [result, setResult] = useState(null);

    const checkDate = async() => {
        try {
            const { data } = await api.post(`/scheduled-messages/check-date`, { date });
            
            setResult(data);
        } catch (err) {
            toastError(err);
        }
    }

    const handleClose = () => {
        onClose();
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle id="form-dialog-title">
                    Teste de data como dia útil
                </DialogTitle>
                <div className={classes.dialogBody}>
                    {result && 
                        <div className={classes.result}>
                        {result.result[0] && "É um dia útil"}
                        {!result.result[0] && <span>Não é dia útil por ser {result.result[1] === "isWeekend" ? "Final de semana" : "Feriado"}</span>}
                        </div>}
                    <DatePicker 
                        format="DD/MM/YYYY HH:mm"
                        label={i18n.t("scheduledMessageModal.form.date")}
                        onChange={(value) => {setDate(value)}}
                        className={classes.datePickerField}
                        container={document.body}
                        menuClassName={classes.datePickerFieldMenu}
                        locale={locale}
                        ranges={[
                            {
                                label: 'Agora',
                                value: new Date()
                            }
                        ]}
                    />
                    <Button onClick={checkDate}>Teste</Button>
                </div>
            </Dialog>
        </div>
    );
};

export default ScheduledMessageDateModal;
