import React, { useRef } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { FormControl, FormHelperText, Input, InputLabel } from "@mui/material";

const SettingTextField = ({ name, settings, label, helpText }) => {
    let debounce = null;
    const inputRef = useRef();
    const getSettingValue = key => {
		const { value } = settings && settings.length > 0 && settings.find(s => s.key === key);
		return value;
	};

    const handleChangeSetting = async e => {
        if (debounce) {
            clearTimeout(debounce);
        }
        const selectedValue = e.target.value;
        const settingKey = e.target.name;
        debounce = setTimeout(async() => {
            try {
                await api.put(`/settings/${settingKey}`, {
                    value: selectedValue,
                });
                toast.success(i18n.t("settings.success"));
            } catch (err) {
                toastError(err);
            }
        }, 500);
    }

    return (<FormControl>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Input id={name} name={name} type="text" aria-describedby={`${name}-helper`} inputRef={inputRef} defaultValue={getSettingValue(name)} onChange={handleChangeSetting} />
        {helpText && <FormHelperText id={`${name}-helper`}>{helpText}</FormHelperText>}
    </FormControl>)
}

export default SettingTextField; 