import React, { useContext, useEffect, useReducer, useState } from "react";

import {
	Button,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { HiOutlineQueueList } from "react-icons/hi2";
import makeStyles from '@mui/styles/makeStyles';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@mui/icons-material";
import QueueModal from "../../components/QueueModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import getSocket from "../../helpers/socket";
import AddIcon from "@mui/icons-material/Add";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import ForbiddenPage from "../../components/ForbiddenPage";

const socket = getSocket();

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		marginTop: 40,
		borderRadius: 20,
		border: '0px !important',
		marginBottom: 40,
		overflow: 'hidden'
	},
	mainPaperTable: {
		flex: 1,
		overflow: 'auto',
		height: '68vh',
		...theme.scrollbarStylesSoft,
	},
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	buttonsTicket: {
		height: 33,
		borderRadius: '5px!important',
		display: 'inline-flex',
		alignItems: 'center',
		boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.07) !important',
		'&:hover': {
			boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2) !important',
		},
	}
}));

const reducer = (state, action) => {
	if (action.type === "LOAD_QUEUES") {
		const queues = action.payload;
		const newQueues = [];

		queues.forEach(queue => {
			const queueIndex = state.findIndex(q => q.id === queue.id);
			if (queueIndex !== -1) {
				state[queueIndex] = queue;
			} else {
				newQueues.push(queue);
			}
		});

		return [...state, ...newQueues];
	}

	if (action.type === "UPDATE_QUEUES") {
		const queue = action.payload;
		const queueIndex = state.findIndex(u => u.id === queue.id);

		if (queueIndex !== -1) {
			state[queueIndex] = queue;
			return [...state];
		} else {
			return [queue, ...state];
		}
	}

	if (action.type === "DELETE_QUEUE") {
		const queueId = action.payload;
		const queueIndex = state.findIndex(q => q.id === queueId);
		if (queueIndex !== -1) {
			state.splice(queueIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const Queues = () => {
	const classes = useStyles();
	const { user } = useContext(AuthContext)
	const [queues, dispatch] = useReducer(reducer, []);
	const [loading, setLoading] = useState(false);

	const [queueModalOpen, setQueueModalOpen] = useState(false);
	const [selectedQueue, setSelectedQueue] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const { data } = await api.get("/queue");
				dispatch({ type: "LOAD_QUEUES", payload: data });

				setLoading(false);
			} catch (err) {
				toastError(err);
				setLoading(false);
			}
		})();
	}, []);

	useEffect(() => {
		const queueEvent = data => {
			if (data.action === "update" || data.action === "create") {
				dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
			}
		}

		socket.on("queue", queueEvent);

		return () => {
			socket.off("queue", queueEvent);
		};
	}, []);

	const handleOpenQueueModal = () => {
		setQueueModalOpen(true);
		setSelectedQueue(null);
	};

	const handleCloseQueueModal = () => {
		setQueueModalOpen(false);
		setSelectedQueue(null);
	};

	const handleEditQueue = queue => {
		setSelectedQueue(queue);
		setQueueModalOpen(true);
	};

	const handleCloseConfirmationModal = () => {
		setConfirmModalOpen(false);
		setSelectedQueue(null);
	};

	const handleDeleteQueue = async queueId => {
		try {
			await api.delete(`/queue/${queueId}`);
			toast.success(i18n.t("Setor excluído com sucesso!"));
		} catch (err) {
			toastError(err);
		}
		setSelectedQueue(null);
	};

	return (
		<MainContainer>
			<ConfirmationModal
				title={
					selectedQueue &&
					`${i18n.t("queues.confirmationModal.deleteTitle")} ${selectedQueue.name
					}?`
				}
				open={confirmModalOpen}
				onClose={handleCloseConfirmationModal}
				onConfirm={() => handleDeleteQueue(selectedQueue.id)}
			>
				{i18n.t("queues.confirmationModal.deleteMessage")}
			</ConfirmationModal>
			<QueueModal
				open={queueModalOpen}
				onClose={handleCloseQueueModal}
				queueId={selectedQueue?.id}
			/>
			<Can
			role={user.roleId}
			perform="queue:show:page"
			yes={() => (
			<Paper className={classes.mainPaper} variant="outlined">
				<MainHeader>
					<Title><HiOutlineQueueList size={24} /> {i18n.t("queues.title")}</Title>
					<MainHeaderButtonsWrapper>
						<Can
							role={user.roleId}
							perform="queue:create"
							yes={() => (
								<Button
									variant="contained"
									color="primary"
									className={classes.buttonsTicket}
									onClick={handleOpenQueueModal}
								>
									<AddIcon style={{ fontSize: 13 }} /> {i18n.t("queues.buttons.add")}
								</Button>)}
							no={() => <></>}
						/>
					</MainHeaderButtonsWrapper>
				</MainHeader>
				<Paper className={classes.mainPaperTable} variant="outlined">
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell align="center">
									{i18n.t("queues.table.name")}
								</TableCell>
								<TableCell align="center">
									{i18n.t("queues.table.color")}
								</TableCell>
								<TableCell align="center">
									{i18n.t("queues.table.greeting")}
								</TableCell>
								<TableCell align="center">
									{i18n.t("queues.table.actions")}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<>
								{queues.map(queue => (
									<TableRow key={queue.id}>
										<TableCell align="center">{queue.name}</TableCell>
										<TableCell align="center">
											<div className={classes.customTableCell}>
												<span
													style={{
														backgroundColor: queue.color,
														width: 60,
														height: 20,
														alignSelf: "center",
													}}
												/>
											</div>
										</TableCell>
										<TableCell align="center">
											<div className={classes.customTableCell}>
												<Typography
													style={{ width: 300, align: "center" }}
													noWrap
													variant="body2"
												>
													{queue.greetingMessage}
												</Typography>
											</div>
										</TableCell>
										<TableCell align="center">
											<Can
												role={user.roleId}
												perform="queue:edit:any"
												yes={() => (
													<IconButton
														size="small"
														onClick={() => handleEditQueue(queue)}
													>
														<Edit />
													</IconButton>
												)}
												no={() => <></>}
											/>
											<Can
												role={user.roleId}
												perform="queue:delete:any"
												yes={() => (
													<IconButton
														size="small"
														onClick={() => {
															setSelectedQueue(queue);
															setConfirmModalOpen(true);
														}}
													>
														<DeleteOutline />
													</IconButton>)}
												no={() => <></>}
											/>
										</TableCell>
									</TableRow>
								))}
								{loading && <TableRowSkeleton columns={4} />}
							</>
						</TableBody>
					</Table>
				</Paper>
			</Paper>
			)}
			no={() => <>
				<ForbiddenPage />
			</>}
		/>
		</MainContainer>
	);
};

export default Queues;
