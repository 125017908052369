import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import ModalImage from "react-modal-image";

const useStyles = props => makeStyles(theme => ({
    fileIconContainer: {
        width: props.width || "100%"
    },
    messageMedia: {
        width: props.width || "100%"
    }
}));

const FileIconImage = ({ fileUrl, width, localFile }) => {
    const [url, setUrl] = useState(fileUrl);
    const [extension, setExtension] = useState("");

    useEffect(() => {
        if (fileUrl) {
            setUrl(fileUrl);
            setExtension(fileUrl.split(".").pop());
        } else {
            setUrl(URL.createObjectURL(localFile));
            setExtension(localFile.name.split(".").pop());
        }
    }, [fileUrl, localFile]);

    const classes = useStyles({ width })();

    return <React.Fragment>
            {["jpg", "jpeg", "gif", "png", "webp"].indexOf(extension) > -1 ? 
                <ModalImage
                    className={classes.messageMedia}
                    small={url}
                    medium={url}
                    large={url}
                    alt="image"
                /> :
                <div className={classes.fileIconContainer}>
                    <FileIcon extension={extension} {...defaultStyles[extension]} />
                </div>}
            </React.Fragment>
}

export default FileIconImage;