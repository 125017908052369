import { format } from "date-fns";
import React, { useContext, useEffect, useRef } from "react";
import useSound from "use-sound";
import { AuthContext } from "../../context/Auth/AuthContext";
import getSocket from "../../helpers/socket";

const socket = getSocket();

const ChatNotification = ({ notificationSound, onClickOpen }) => {
    const soundAlertRef = useRef();
    const { user } = useContext(AuthContext);
    const [play] = useSound(`/notificationSounds/${notificationSound || 'default'}.mp3`, { notificationSound });

    const newMessageEvent = data => {
        if (data.message.userId !== user.id) {
            handleNotifications(data.message);
        }
    }

    useEffect(() => {
        const publicToken = window.localStorage.getItem('public-token');

        socket.on(`chat:message:new#${publicToken}`, newMessageEvent);

        return () => {
            socket.off(`chat:message:new#${publicToken}`, newMessageEvent);
        }
    }, [user]);

    useEffect(() => {
        soundAlertRef.current = play;

        if (!("Notification" in window)) {
            console.log("This browser doesn't support notifications");
        } else {
            Notification.requestPermission();
        }
    }, [play]);

    const handleNotifications = (message) => {
        const options = {
            body: `${message.body} - ${format(new Date(), "HH:mm")}`,
            icon: message.user.avatarUrl,
            tag: message.roomId,
            renotify: true,
        };

        const notification = new Notification(
            `Chat Interno - ${message.user.name}`,
            options
        );

        notification.onclick = e => {
            e.preventDefault();
            window.focus();
            if (onClickOpen) onClickOpen(message.room);
        };

        soundAlertRef.current();
    }

    return (<></>);

}

export default ChatNotification;