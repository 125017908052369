import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import TextareaAutosize from '@mui/material/TextareaAutosize';

const useStyles = makeStyles(theme => ({
    AddMargin: {
        marginTop: 10,
        marginBottom: 10
    },
    boxFiles: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    iconText: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.75,
        borderRadius: 4,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        color: '#000000'
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column"
    },
    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
    },

    cancelAudioIcon: {
        color: "red",
    },

    sendAudioIcon: {
        color: "green",
    },
    uploadInput: {
        display: "none"
    },
    textField: {
        marginRight: theme.spacing(1),
        width: '100%'
    },
    textareaField: {
        marginRight: theme.spacing(1),
        width: '100%',
        minHeight: 100
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    messageSaved: {
        boxShadow: "0 1px 1px #b3b3b3",
        background: "#f5f5f5",
        padding: '5px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    cont: {
        fontStyle: "italic",
    },
    timestamp: {
        fontStyle: 'normal',
        fontSize: 11,
        color: '#666666'
    }
}));

const ContactSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, "Muito Curto!")
        .max(200, "Muito Longo!")
        .required("Obrigatório"),
    content: Yup.string().min(3, "Muito Pequeno!"),
});

const AddColumnTaskTicketModal = ({ open, order, onClose, taskId, isModal, message, ticketId, onSave, noteCreated }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const { user } = useContext(AuthContext);
    const [initialState, setinitialState] = useState({});
    const [contact, setContact] = useState(initialState);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {

        setinitialState({
            title: "",
            content: "",
            //medias: media,
            //color: hex
        })
    }, [message]);

    const fileRef = useRef();

   /* const showFilePicker = () => {
        fileRef.current.click();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setIsAudio(false);
    };*/

    const handleClose = () => {
        onClose();
        setContact(initialState);
    };

    const handleCloseTask = () => {
        noteCreated();
    };

    const handleSaveTask = async (values) => {
        try {
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('order', order ? order : 0);
            formData.append('content', values.content);
            /*formData.append('isAudio', isAudio)
            //formData.append('color', hex);
            if (selectedFile) {
                formData.append('medias', selectedFile);
            }*/

            const { data } = await api.post(`/tasks/column/add`, formData);

            console.log(data);
            if (onSave) {
                onSave(data);
            }

            handleClose();
            handleCloseTask();
            toast.success(i18n.t('addTaskModal.success'));
        } catch (err) {
            console.log(err);
            toastError(err);
        }
    };
/*
    const handleChangeColor = (color) => {
        setHex(color.hex)
    };
*/
 
    const FormTask = () => {
        return (
            <Formik
                initialValues={contact}
                enableReinitialize={true}
                validationSchema={ContactSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSaveTask(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form>
                        <DialogContent dividers>
                            <div className={classes.AddMargin}>
                                <Field
                                    as={TextField}
                                    label={i18n.t("addTaskModal.form.title")}
                                    name="title"
                                    autoFocus
                                    error={touched.title && Boolean(errors.title)}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textField}
                                />
                            </div>
                            <div className={classes.AddMargin} 
                            style={{ display: 'flex', flexDirection: 'column' }}>
                                <label className={classes.label}>Descrição</label>
                                <Field
                                    as={TextareaAutosize}
                                    label={i18n.t("addTaskModal.form.content")}
                                    name="content"
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textareaField}
                                />
                            </div>



                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color="secondary"
                                disabled={isSubmitting}
                                variant="outlined"
                            >
                                {i18n.t("contactModal.buttons.cancel")}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSubmitting}
                                variant="contained"
                                className={classes.btnWrapper}
                            >
                                {/*contactId
                                ? `${i18n.t("contactModal.buttons.okEdit")}`
                                :*/ `${i18n.t("contactModal.buttons.okAdd")}`}
                                {isSubmitting && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <div className={classes.root}>
            {isModal ?
                <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                    <DialogTitle id="form-dialog-title">
                        {taskId
                            ? `${i18n.t("addTaskModal.title.column.edit")}`
                            : `${i18n.t("addTaskModal.title.column.add")}`}
                    </DialogTitle>
                    <FormTask />
                </Dialog>
                : <FormTask />}
        </div>
    );
};

export default AddColumnTaskTicketModal;
