import { useState, useEffect, useReducer, useRef } from "react";
import toastError from "../../errors/toastError";
import getSocket from "../../helpers/socket";

import api from "../../services/api";

const socket = getSocket();

const reducer = (state, action) => {
    if (action.type === "LOAD_WHATSAPPS") {
        const whatsApps = action.payload;

        return [...whatsApps];
    }

    if (action.type === "UPDATE_WHATSAPPS") {
        const whatsApp = action.payload;
        const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);

        if (whatsAppIndex !== -1) {
            state[whatsAppIndex] = whatsApp;
            return [...state];
        } else {
            return [whatsApp, ...state];
        }
    }

    if (action.type === "UPDATE_SESSION") {
        const whatsApp = action.payload;
        const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);

        if (whatsAppIndex !== -1) {
            state[whatsAppIndex].status = whatsApp.status;
            state[whatsAppIndex].updatedAt = whatsApp.updatedAt;
            state[whatsAppIndex].qrcode = whatsApp.qrcode;
            state[whatsAppIndex].retries = whatsApp.retries;
            return [...state];
        } else {
            return [...state];
        }
    }

    if (action.type === "DELETE_WHATSAPPS") {
        const whatsAppId = action.payload;

        const whatsAppIndex = state.findIndex(s => s.id === whatsAppId);
        if (whatsAppIndex !== -1) {
            state.splice(whatsAppIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useWhatsApps = () => {
    const [whatsApps, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(true);
    const [limitConnections, setLimitConnections] = useState(0);
    const [limitFacebook, setLimitFacebook] = useState(0);
    const [countConnections, setCountConnections] = useState(0);
    const [countFacebook, setCountFacebook] = useState(0);

    const countConnectionsRef = useRef();

    countConnectionsRef.current = countConnections;

    useEffect(() => {
        setLoading(true);
        const fetchSession = async () => {
            try {
                const { data } = await api.get("/whatsapp/");
                dispatch({ type: "LOAD_WHATSAPPS", payload: data.whatsapps });
                setLimitConnections(data.limitConnections);
                setLimitFacebook(data.limitFacebook);
                setCountFacebook(data.countFacebook);
                setCountConnections(data.count);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toastError(err);
            }
        };
        fetchSession();
    }, []);

    useEffect(() => {
        const whatsappEvent = data => {
            if (data.action === "update") {
                dispatch({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
                if (data.created) {
                    setCountConnections(countConnectionsRef.current + 1);
                }
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
                setCountConnections(countConnectionsRef.current - 1);
            }
        }
        const whatsappSessionEvent = data => {
            if (data.action === "update") {
                dispatch({ type: "UPDATE_SESSION", payload: data.session });
            }
        }

        socket.on("whatsapp", whatsappEvent);
        socket.on("whatsappSession", whatsappSessionEvent);

        return () => {
            socket.off("whatsapp", whatsappEvent);
            socket.off("whatsappSession", whatsappSessionEvent);
        };
    }, []);

    return { whatsApps, loading, limitConnections, limitFacebook, countConnections, countFacebook };
};

export default useWhatsApps;
