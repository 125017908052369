import React from "react";
import { useState } from "react";
import AsyncSelect from "../AsyncSelect";
import { FormControl } from "@mui/material";
import { i18n } from "../../translate/i18n";

const SelectContacts = ({onChange, selectedContacts, disabled, noMultiple}) => {
    const [internalSelectedContacts, setInternalSelectedContacts] = useState(selectedContacts ? selectedContacts : []);

    const handleChange = (event, changedContacts) => {
        setInternalSelectedContacts(changedContacts);
        if (onChange) {
            onChange(changedContacts);
        }
    };

    return (
        <FormControl fullWidth variant="outlined">
            <AsyncSelect
                multiple={!noMultiple ? true:false}
                width="100%"
                onChange={handleChange}
                disabled={disabled}
                initialValue={selectedContacts}
                url="/contacts"
                dictKey="contacts"
                value={internalSelectedContacts}
                className="basic-multi-select"
                placeholder="Selecione os contatos..."
                label={i18n.t("dashboardHome.contacts")}
                />
        </FormControl>
    );
};

export default SelectContacts;