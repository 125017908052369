import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from "@mui/material";

const useStyles = makeStyles(() => ({
    chartContainer: {
        width: "100%",
        height: "450px",
    }
}));

const ChartTickets = ({ data }) => {
    const classes = useStyles();
    const themeApp = useTheme();
    const colors = data.map(item => item.color);


    return (
        <div className={classes.chartContainer}>
            <ResponsivePie
                data={data}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                colors={colors}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor={themeApp.mode === 'dark' ? '#ffffff' : '#333333'}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                barAriaLabel={e => e.formattedValue + e.indexValue + "atendimentos"}      
                tooltip={({ datum: t }) => (
                    <div
                        style={{
                            padding: '10px',
                            color: t.color,
                            background: themeApp.mode === 'dark' ?'#2d2d2d' : '#ffffff',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                            fontSize: '14px',
                            textAlign: 'left',
                        }}
                    >
                        <div style={{ marginBottom: '8px' }}>{t.id}: {`${t.value} Atendimento${t.value > 1 ?'s':''}`}</div>                    </div>
                )}
                
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                /*fill={[
                    { match: { id: 'Resolvidos' }, id: 'lines' },
                    { match: { id: 'Pendentes' }, id: 'dots' },
                ]}*/
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        translateX: 0,
                        translateY: 56,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
        </div>
    );
}

export default ChartTickets;