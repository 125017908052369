import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import { Box, Modal } from "@mui/material";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	notificationModal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: 700,
		backgroundColor: '#fff',
		boxShadow: '0 0 1em rgba(0, 0, 0, 0.2)',
		borderRadius: 10,
		padding: 0,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			textAlign: 'center',
			height: '100%',
			minWidth: 'auto'
		},
		'& img': {
			height:'700px',
			['@media (min-height: 450px) and (max-height: 800px)']: {
				height:'60vh',
			},
			[theme.breakpoints.down('md')]: {
				height:'auto',
				width:'90%'
			}
		}
	},
	notificationModalHeader: {
		borderBottom: '1px solid #e0e0e0',
		padding: 20,
		fontSize: '2em',
		fontWeight: "bold"
	},
	notificationModalBody: {
		padding: 10,
		minHeight: 400,
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	notificationModalFooter: {
		borderTop: '1px solid #e0e0e0',
		padding: 10
	},
	notificationModalClose: {
		position: 'absolute',
		right: 0,
		top: 20,
		color: '#000',
		"& .MuiSvgIcon-root": {
			fontSize: "2rem"
		}
	}
}));

const NotificationBlocked = ({open}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Modal
				open={open}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box className={classes.notificationModal}>
					<div className={classes.notificationModalHeader}>
						Importante!
					</div>
					<div className={classes.notificationModalBody}>
						<img src="/blocked.png" alt="Há um problema com o seu acesso. Por favor, entre em contato com o suporte." />
					</div>
				</Box>
            </Modal>
        </div>
    )
}

export default NotificationBlocked;