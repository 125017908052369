import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
	root: {
		position: "fixed",
        width:'100%',
        height:'30px',
		top: 0,
        overflow: "hidden",
        padding: "5px",
        zIndex: 9999,
        color: "white",
        backgroundColor: "#A00"
	},
    content: {
        textAlign: "center",
        display: "block"
    }
}));

const NotificationLimit = ({overflowUsers}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <span className={classes.content}>
                Atenção: Sistema sobrecarregado, você está utilizando mais {overflowUsers ? 'Usuários' : 'Conexões'} que o plano contratado, entre em contato com o suporte para regularizar.
            </span>
        </div>
    )
}

export default NotificationLimit;