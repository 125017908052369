import React, { useState, useEffect, useReducer, useContext } from "react";
import { useHistory } from "react-router-dom";
import Title from "../Title";
import makeStyles from '@mui/styles/makeStyles';
import TableRowSkeleton from "../TableRowSkeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MessageInputForward from "../MessageInputForward";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, IconButton } from "@mui/material";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ConnectionIcon from "../ConnectionIcon";
//import SelectUsers from "../../components/SelectUsers";
import ButtonWithSpinner from "../ButtonWithSpinner";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Input } from "@mui/base";
import { FaTrash } from "react-icons/fa";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    modalDialog: {
        width: 500
    },
    TitlePadding: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 10,
        paddingTop: 10
    },
    selectUsersDrop: {
        width: 150,
        marginLeft: 10,
        marginRight: 10
    },
    mainTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mainPaperOverflow: {
        height: '40vh',
        overflowY: 'scroll',
        ...theme.scrollbarStylesSoftBig
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    block_title: {
        display: "flex",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: 'space-between'
    },
    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));



const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach(contact => {
            const contactIndex = state.findIndex(c => c.id === contact.id);
            if (contactIndex && contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }
    if (action.type === "RESET") {
        return [];
    }
};


const GroupListContacts = ({ modalOpen, onClose, ticket }) => {
    const classes = useStyles();
    const history = useHistory();
    const { user, permissions } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [groupTitle, setGroupTitle] = useState("");
    const [search, setSearchParam] = useState("");
    const [contacts, dispatch] = useReducer(reducer, []);
    const [tagIds] = useState([]);
    const [userIds] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [checked, setChecked] = useState([]);
    const [onlyGroups, setOnlyGroups] = useState(false);
    const [totalContacts, setTotalContacts] = useState("");
    const [contactList, setContactsList] = useState(null);
    const [groupOwner, setgroupOwner] = useState(false);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [search, tagIds, userIds, onlyGroups]);





    const handleToggle = (event) => {
        const currentIndex = checked.indexOf(event.target.value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(event.target.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    }

    const toggleAll = (event) => {
        if (event.target.checked) {
            checkAll();
        } else {
            uncheckAll();
        }
    }
    const checkAll = () => {
        setChecked(contacts.map((element) => { return "" + element.id }));
    }

    const uncheckAll = () => {
        setChecked([]);
    }

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get(`/tickets/group/info/` + ticket.id + `/list/participants`);
                    //console.log(data, 'id group', ticket.id);
                    setContactsList(data.ticket.participants)
                    //  dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
                    setHasMore(data.hasMore);
                    setTotalContacts(`(${data.count})`);
                    data.ticket.owner && setgroupOwner(true);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [modalOpen, search]);

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };


    const handleRemoveUser = async (id) => {

        if (!id) return;

        setLoading(true);
        try {
            await api.post("/tickets/group/remove/users", {
                ticketId: ticket.id,
                contacts: [id + '@s.whatsapp.net'],
            });
            toast.success('Participante removido com sucesso!')
        } catch (err) {
            console.log('err', err)
            toastError(err);

        }
        setLoading(false);
        handleClose();
    };


    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleOnlyGroupChange = e => {
        setOnlyGroups(e.target.checked);
    }
    //const { user } = useContext(AuthContext);

    const handleClose = () => {
        setSearchParam('')
        setChecked([]);
        onClose();
    };

    return (
        <Dialog open={modalOpen} onClose={handleClose} maxWidth="xl" scroll="paper">
            <DialogContent style={{ padding: 0 }}>
                <div id="form-dialog-title" className={classes.TitlePadding}>
                    <div className={classes.mainTitle}>
                        <Title>Contatos - {ticket.contact.name}</Title>
                        {/* <SelectUsers width="200px" className={classes.selectUsersDrop} multiple onChange={(value) => setUserIds(value ? value.map(userItem => userItem.id) : [])} outlined></SelectUsers> */}
                    </div>
                    <div className={classes.mainTitle}>
                        {/*<TextField
                            placeholder={i18n.t("Buscar Contato")}
                            type="search"
                            value={search}
                            onChange={handleSearch}

                        />
                        <div>&nbsp;&nbsp;&nbsp;</div>

                        <span style={{ display: 'flex', gap: '3px', fontSize: 18 }}>
                            {checked && checked.length >= 1 ? checked.length : 0} contato{checked && checked.length >= 2 ? 's' : ''} selecionado{checked && checked.length >= 2 ? 's' : ''}
    </span>*/}
                    </div>


                </div>

                <Paper
                    className={classes.mainPaperOverflow}
                    variant="outlined"
                    onScroll={handleScroll}
                    style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {/*<TableCell padding="checkbox">
                                    <Checkbox onChange={toggleAll} />
                                </TableCell>*/}

                                <TableCell></TableCell>
                                <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                                <TableCell align="center">
                                    {i18n.t("contacts.table.whatsapp")}
                                </TableCell>

                                {groupOwner ? <TableCell>
                                    {i18n.t("Ações")}
                                </TableCell> : <></>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {(contactList && contactList.length >= 1) ? contactList.map(contact => (
                                    <TableRow key={contact.id}>
                                        {/*<TableCell>
                                            <Checkbox value={contact.id} checked={checked.indexOf("" + contact.id) !== -1} onChange={handleToggle} />
                                </TableCell>*/}
                                        <TableCell style={{ paddingRight: 0 }}>
                                            {<Avatar src={contact.profilePicUrl} />}
                                        </TableCell>
                                        <TableCell>{contact.name ? contact.name : contact.id}</TableCell>
                                        <TableCell>{contact.number}</TableCell>
                                        {groupOwner ? 
                                        <TableCell>
                                            <IconButton onClick={() => handleRemoveUser(contact.number)}>
                                                <FaTrash size={14} />
                                            </IconButton>
                                        </TableCell> :
                                            <></>
                                        }
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell style={{ paddingRight: 0 }}>
                                        </TableCell>

                                        <TableCell align="center">

                                        </TableCell>

                                    </TableRow>
                                )}
                                {loading &&
                                    <Can
                                        role={user.roleId}
                                        perform="contacts:list:any"
                                        yes={() => (<TableRowSkeleton columns={3} />)}
                                        no={() => (<TableRowSkeleton columns={3} />)}
                                    />}
                            </>
                        </TableBody>
                    </Table>

                    <div style={{ width: '100%', marginBottom: 15 }}>

                    </div>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={loading}
                    variant="outlined"
                >
                    {i18n.t("newTicketModal.buttons.cancel")}
                </Button>
            </DialogActions>

        </Dialog>
    );
};

export default GroupListContacts;