import React, { useContext} from "react";


import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { i18n } from "../../translate/i18n";

import Paper from "@mui/material/Paper";
import SettingBackgroundSelectionField from "../UserSetting/backgroundSelectionField";
import getSocket from "../../helpers/socket";
import { Button } from "@mui/material";
import { UserSettingsContext } from "../../context/Settings/UserSettingsContext";

const socket = getSocket();


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	paper: {
		padding: 0,
		display: "flex",
		alignItems: "center",
		marginBottom: 10,
		boxShadow: 'none',
		'&>div': {
			width: "100%"
		}
	},

}));


const ChatSettingsModal = ({ open, onClose, userId }) => {
	const classes = useStyles();
	const { settings } = useContext(UserSettingsContext);
	
	const handleClose = () => {
		onClose();
	};
	

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					Configuração do Chat
				</DialogTitle>
				<DialogContent>
					

					<Paper className={classes.paper}>
						<SettingBackgroundSelectionField name="backgroundChat"
							userId={userId} className={classes.paper} value={settings ? settings.backgroundChat:''}
							userSettings={true}
							label={i18n.t("settings.settings.backgroundChat.name")} />
					</Paper>

					{/*
					<Paper className={classes.paper}>
						<SettingBoolField name="backgroundChat" value={settings ? settings.backgroundChat:''}
							userSettings={true} userId={userId}
							label={i18n.t("settings.settings.backgroundChat.name")} 
							placeholder={i18n.t("select")}
							/>
					</Paper> */}

				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Fechar</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ChatSettingsModal;
