import React, { useState, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";

import makeStyles from '@mui/styles/makeStyles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { PiTimerBold } from "react-icons/pi";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ScheduledMessageModal from "../../components/ScheduledMessageModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";

import * as moment from "moment";
import ScheduledMessageRecipients from "../../components/ScheduledMessageRecipients";
import FileIconImage from "../../components/FileIconImage";
import getSocket from "../../helpers/socket";
import ScheduledMessageSents from "../../components/ScheduledMessageSents";
import ForbiddenPage from "../../components/ForbiddenPage";

const socket = getSocket();

const reducer = (state, action) => {
	if (action.type === "LOAD_SCHEDULED_MESSAGES") {
		const scheduledMessages = action.payload;
		const newScheduledMessages = [];

		scheduledMessages.forEach(scheduledMessage => {
			const index = state.findIndex(c => c.id === scheduledMessage.id);
			if (index !== -1) {
				state[index] = scheduledMessage;
			} else {
				newScheduledMessages.push(scheduledMessage);
			}
		});

		return [...state, ...newScheduledMessages];
	}

	if (action.type === "UPDATE_SCHEDULED_MESSAGES") {
		const scheduledMessage = action.payload;
		const index = state.findIndex(c => c.id === scheduledMessage.id);

		if (index !== -1) {
			state[index] = scheduledMessage;
			return [...state];
		} else {
			return [scheduledMessage, ...state];
		}
	}

	if (action.type === "DELETE_SCHEDULED_MESSAGES") {
		const scheduledMessageId = action.payload;

		const index = state.findIndex(c => c.id === scheduledMessageId);
		if (index !== -1) {
			state.splice(index, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		marginTop: 40,
		borderRadius: 20,
		border: '0px !important',
		marginBottom: 40,
		overflow: 'hidden'
	},
	mainPaperTable: {
		flex: 1,
		overflow: 'auto',
		height: '68vh',
		...theme.scrollbarStylesSoft,
	},
	helpMessage: {
		padding: "10px"
	},
	buttonsTicket: {
		height: 40,
		borderRadius: '5px!important',
		display: 'inline-flex',
		alignItems: 'center',
		boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.07) !important',
		'&:hover': {
			boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2) !important',
		},
	}
}));

const ScheduledMessages = () => {
	const classes = useStyles();
	const { user } = useContext(AuthContext);

	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [searchParam, setSearchParam] = useState("");
	const [scheduledMessages, dispatch] = useReducer(reducer, []);
	const [selectedScheduledMessageId, setSelectedScheduledMessageId] = useState(0);
	const [scheduledMessageModalOpen, setScheduledMessageModalOpen] = useState(false);
	const [deletingScheduledMessage, setDeletingScheduledMessage] = useState(null);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [scheduledMessageModalRecipientOpen, setScheduledMessageModalRecipientOpen] = useState(false);
	const [scheduledMessageModalSentsOpen, setScheduledMessageModalSentsOpen] = useState(false);

	const formatDate = (value) => {
		return value ? moment(value).format("DD/MM/YYYY à[s] HH:mm") : '';
	}

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [searchParam]);

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchScheduledMessages = async () => {
				try {
					const { data } = await api.get("/scheduled-messages/", {
						params: { searchParam, pageNumber },
					});
					dispatch({ type: "LOAD_SCHEDULED_MESSAGES", payload: data.scheduledMessages });
					setHasMore(data.hasMore);
					setLoading(false);
				} catch (err) {
					toastError(err);
				}
			};
			fetchScheduledMessages();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, pageNumber]);

	useEffect(() => {
		const scheduledMessageEvent = data => {
			if (data.action === "update" || data.action === "create") {
				dispatch({ type: "UPDATE_SCHEDULED_MESSAGES", payload: data.scheduledMessage });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_SCHEDULED_MESSAGES", payload: +data.scheduledMessageId });
			}
		}

		socket.on("scheduledMessage", scheduledMessageEvent);

		return () => {
			socket.off("scheduledMessage", scheduledMessageEvent);
		};
	}, []);

	const handleSearch = event => {
		setSearchParam(event.target.value.toLowerCase());
	};

	const handleOpenScheduledMessageModal = () => {
		setSelectedScheduledMessageId(0);
		setScheduledMessageModalOpen(true);
	};

	const handleCloseScheduledMessageModal = () => {
		setSelectedScheduledMessageId(0);
		setScheduledMessageModalOpen(false);
	};

	const hadleEditScheduledMessage = scheduledMessageId => {
		setSelectedScheduledMessageId(scheduledMessageId);
		setScheduledMessageModalOpen(true);
	};

	const handleDeleteScheduledMessage = async scheduledMessageId => {
		try {
			await api.delete(`/scheduled-messages/${scheduledMessageId}`);
			toast.success(i18n.t("scheduled_messages.toasts.deleted"));
		} catch (err) {
			toastError(err);
		}
		setDeletingScheduledMessage(null);
		setSearchParam("");
		setPageNumber(1);
	};

	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = e => {
		if (!hasMore || loading) return;
		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			loadMore();
		}
	};

	const showRecipients = (scheduledMessage) => {
		setSelectedScheduledMessageId(scheduledMessage.id);
		setScheduledMessageModalRecipientOpen(true);
	}

	const showSents = (scheduledMessage) => {
		setSelectedScheduledMessageId(scheduledMessage.id);
		setScheduledMessageModalSentsOpen(true);
	}

	const onCloseModalRecipients = () => {
		setSelectedScheduledMessageId(0);
		setScheduledMessageModalRecipientOpen(false);
	}

	const onCloseModalSents = () => {
		setSelectedScheduledMessageId(0);
		setScheduledMessageModalSentsOpen(false);
	}

	return (
		<MainContainer className={classes.mainContainer}>
			<ScheduledMessageRecipients open={scheduledMessageModalRecipientOpen} scheduledMessageId={selectedScheduledMessageId} onClose={onCloseModalRecipients} />
			<ScheduledMessageSents open={scheduledMessageModalSentsOpen} scheduledMessageId={selectedScheduledMessageId} onClose={onCloseModalSents} />
			{scheduledMessageModalOpen && <ScheduledMessageModal
				open={scheduledMessageModalOpen}
				onClose={handleCloseScheduledMessageModal}
				aria-labelledby="form-dialog-title"
				scheduledMessageId={selectedScheduledMessageId}
			></ScheduledMessageModal>}
			<ConfirmationModal
				title={
					deletingScheduledMessage
						? `${i18n.t("scheduled_messages.confirmationModal.deleteTitle")} ${deletingScheduledMessage.name
						}?`
						: `${i18n.t("scheduled_messages.confirmationModal.importTitlte")}`
				}
				open={confirmOpen}
				onClose={setConfirmOpen}
				onConfirm={e =>
					deletingScheduledMessage
						? handleDeleteScheduledMessage(deletingScheduledMessage.id)
						: null
				}
			>
				{deletingScheduledMessage
					? `${i18n.t("scheduled_messages.confirmationModal.deleteMessage")}`
					: `${i18n.t("scheduled_messages.confirmationModal.importMessage")}`}
			</ConfirmationModal>
			<Can
			role={user.roleId}
			perform="scheduled-messages:show:page"
			yes={() => (
			<Paper
				className={classes.mainPaper}
				variant="outlined"
				onScroll={handleScroll}
			>

				<MainHeader>
					<Title><PiTimerBold size={24} /> {i18n.t("scheduled_messages.title")}</Title>
					<MainHeaderButtonsWrapper className="headerContent">
						<TextField
							placeholder={i18n.t("scheduled_messages.searchPlaceholder")}
							type="search"
							size="small"
							value={searchParam}
							onChange={handleSearch}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon style={{ color: "gray" }} />
									</InputAdornment>
								),
							}}
						/>
						<Can
							role={user.roleId}
							perform="scheduled-messages:create"
							yes={() => (
								<Button
									variant="contained"
									color="primary"
									onClick={handleOpenScheduledMessageModal}
									className={classes.buttonsTicket}
								>
									<AddIcon style={{ fontSize: 13 }} />	{i18n.t("scheduled_messages.buttons.add")}
								</Button>)}
							no={() => <></>}
						/>
					</MainHeaderButtonsWrapper>
				</MainHeader>
				<Paper
					className={classes.mainPaperTable}
					variant="outlined"
					onScroll={handleScroll}
				>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>{i18n.t("scheduled_messages.table.date")}</TableCell>
								<TableCell>{i18n.t("scheduled_messages.table.to")}</TableCell>
								<TableCell>{i18n.t("scheduled_messages.table.sent")}</TableCell>
								<TableCell align="center">
									{i18n.t("scheduled_messages.table.file")}
								</TableCell>
								<TableCell align="center">
									{i18n.t("scheduled_messages.table.content")}
								</TableCell>
								<TableCell align="center">
									{i18n.t("scheduled_messages.table.actions")}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<>
								{scheduledMessages.map(scheduledMessage => (
									<TableRow key={scheduledMessage.id}>
										<TableCell>{formatDate(scheduledMessage.date)}</TableCell>
										<TableCell>
											{scheduledMessage.contact ? scheduledMessage.contact.name :
												<Can
													perform="scheduled-messages:view:any"
													yes={() => (
														<Button onClick={() => showRecipients(scheduledMessage)}>Ver destinatários</Button>
													)}
													no={() => <></>}
												/>
											}
										</TableCell>
										<TableCell>
											{formatDate(scheduledMessage.sent)}
											<RemoveRedEye onClick={() => showSents(scheduledMessage)} />
										</TableCell>
										<TableCell align="center">
											{scheduledMessage.absoluteMediaUrl && <FileIconImage width="30px" fileUrl={scheduledMessage.absoluteMediaUrl} />}
										</TableCell>
										<TableCell>{scheduledMessage.content}</TableCell>
										<TableCell align="center">
											<Can
												perform="scheduled-messages:edit:any"
												yes={() => (
													<IconButton
														size="small"
														onClick={() => hadleEditScheduledMessage(scheduledMessage.id)}
													>
														<EditIcon />
													</IconButton>)}
												no={() => <></>}
											/>
											<Can
												perform="scheduled-messages:delete:any"
												yes={() => (
													<IconButton
														size="small"
														onClick={e => {
															setConfirmOpen(true);
															setDeletingScheduledMessage(scheduledMessage);
														}}
													>
														<DeleteOutlineIcon />
													</IconButton>)}
												no={() => <></>}
											/>
										</TableCell>
									</TableRow>
								))}
								{loading && <TableRowSkeleton avatar columns={4} />}
							</>
						</TableBody>
					</Table>
				</Paper>
			</Paper>
			)}
			no={() => <>
				<ForbiddenPage />
			</>}
		/>
		</MainContainer>
	);
};

export default ScheduledMessages;
