const formatTime = (totalMinutes, round) => {
  const totalSeconds = totalMinutes * 60;
  const days = Math.floor(totalSeconds / 86400);
  const hours = Math.floor((totalSeconds % 86400) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${days > 0 ? `${days}d ` : ``}${hours > 0 ? `${hours}h ` : ``}${minutes > 0 ? `${minutes.toFixed(0)}${round ? 'm' : 'm '}` : ``}${seconds > 0 ? `${seconds.toFixed(0)}${round ? 's' : 's'}` : ``}`;
};

export default formatTime;
