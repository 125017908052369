import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";
import { Chip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "../../context/Auth/AuthContext";
import SelectQueues from "../SelectQueues";
import { i18n } from "../../translate/i18n";
import TicketsQueueSelect from "../TicketsQueueSelect";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		width: "100%"
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	textFieldNameContainer: {
		width: "100%"
	},
	textFieldContentContainer: {
		width: "100%"
	},
	inputContainer: {
		marginTop: 10
	}
}));

const ShortcutMessageSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "O nome é muito curto")
		.max(150, "Informe um nome de até 150 caracteres")
		.required("Required"),
	content: Yup.string().min(8, "Informe uma mensagem de no mínimo 8 caracteres").max(60000, "Informe uma mensagem de no máximo 60.000 caracteres"),
});

const ShortcutMessageModal = ({ open, onClose, shortcutMessageId, initialValues, onSave }) => {
	const classes = useStyles();
	const isMounted = useRef(true);
	const [queueIds, setQueueIds] = useState([]);
	const [queueIdEdit, setQueueIdsEdit] = useState([]);
	const [noSelectQueues, setnoSelectQueues] = useState(false);
	const { user } = useContext(AuthContext);

	const handleChangeQueueIds = (values) => {

		const ids = values && values.map((item) => item.id);
		setQueueIds(ids);
	};

	const initialState = {
		name: "",
		content: ""
	};

	const [shortcutMessage, setShortcutMessage] = useState(initialState);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchShortcutMessage = async () => {
			if (initialValues) {
				setShortcutMessage(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!shortcutMessageId) return;

			try {
				const { data } = await api.get(`/shortcut-messages/${shortcutMessageId}`);
				if (isMounted.current) {
					setShortcutMessage(data);
					let queueIds = [];
					if (data.queueIds) {
						queueIds = data.queueIds.split(',').map(Number);
						setnoSelectQueues(true)
					}
					//console.log(queueIds);
					//console.log(data);
					setQueueIds(queueIds);
				}
			} catch (error) {
				toastError(error);
				console.error('Error fetching shortcut message:', error);
			}
		};

		fetchShortcutMessage();
	}, [shortcutMessageId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setShortcutMessage(initialState);
	};

	const handleDelete = () => {
		setnoSelectQueues(false);
	};

	const handleSaveShortcutMessage = async values => {
		try {

			let queues = queueIds;
			if (queueIds.length === 0 && !(user.profile === 'admin')) {
				const userQueues = user.queues && user.queues.map(queue => queue.id);
				queues = userQueues;
			}
			const formData = new FormData();
			formData.append("name", values.name);
			formData.append("content", values.content);
			formData.append("queueIds", queues)
			formData.append("medias", values.file);
			if (shortcutMessageId) {
				await api.put(`/shortcut-messages/${shortcutMessageId}`, formData, { headers: { 'Content-Type': "multipart/form-data" } });
				handleClose();
			} else {
				const { data } = await api.post("/shortcut-messages", formData, { headers: { 'Content-Type': "multipart/form-data" } });
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}
			toast.success(i18n.t("shortcutMessageModal.success"));
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{shortcutMessageId
						? `${i18n.t("shortcutMessageModal.title.edit")}`
						: `${i18n.t("shortcutMessageModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={shortcutMessage}
					enableReinitialize={true}
					validationSchema={ShortcutMessageSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveShortcutMessage(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting, setFieldValue }) => (
						<Form>
							<DialogContent dividers>
								<Typography variant="subtitle1" gutterBottom>
									{i18n.t("shortcutMessageModal.form.mainInfo")}
								</Typography>
								<div className={classes.textFieldNameContainer}>
									<Field
										as={TextField}
										label={i18n.t("shortcutMessageModal.form.name")}
										name="name"
										autoFocus
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
								</div>
								<div className={classes.textFieldContentContainer}>
									<Field
										as={TextField}
										label={i18n.t("shortcutMessageModal.form.content")}
										name="content"
										error={touched.content && Boolean(errors.content)}
										helperText={touched.content && errors.content}
										placeholder="Conteúdo da mensagem"
										variant="outlined"
										margin="dense"
										className={classes.textField}
										multiline
										rows={5}
									/>
								</div>
								{user && user.roleId === 1 && (
									<div className={classes.textFieldContentContainer}>
										{queueIds.length > 0 && noSelectQueues ?
											<Chip
												label={queueIds.length + i18n.t("tickets.search.selectedQueues")}
												style={{ marginRight: 5, marginBottom: 5, marginTop: 5 }} 
												onDelete={handleDelete} />
											: (
												<SelectQueues onChange={handleChangeQueueIds} />
											)}

									</div>)}

								<div className={classes.inputContainer}>
									<input type="file" name="file" id="file" onChange={(event) => {
										setFieldValue("file", event.currentTarget.files[0]);
									}} />
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("shortcutMessageModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{shortcutMessageId
										? `${i18n.t("shortcutMessageModal.buttons.okEdit")}`
										: `${i18n.t("shortcutMessageModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ShortcutMessageModal;
