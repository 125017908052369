import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import openSocket from "socket.io-client";
import toastError from "../../errors/toastError";

import { Dialog, DialogContent, Paper, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import getSocket from "../../helpers/socket";

const socket = getSocket();

const useStyles = makeStyles(theme => ({
    qrCodeBackground: {
        backgroundColor: '#FFF',
		textAlign: 'center'
    }
}));

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
	const [qrCode, setQrCode] = useState("");

	const classes = useStyles();

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`/whatsapp/${whatsAppId}`);
				setQrCode(data.qrcode);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	useEffect(() => {
		if (!whatsAppId) return;

		const whatsappSessionEvent = data => {
			if (data.action === "update" && data.session.id === whatsAppId) {
				setQrCode(data.session.qrcode);
			}

			if (data.action === "update" && data.session.qrcode === "") {
				onClose();
			}
		}

		socket.on("whatsappSession", whatsappSessionEvent);

		return () => {
			socket.off("whatsappSession", whatsappSessionEvent);
		};
	}, [whatsAppId, onClose]);

	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
			<DialogContent className={classes.qrCodeBackground}>
				<Paper elevation={0} className={classes.qrCodeBackground}>
					<Typography color="primary" gutterBottom>
						{i18n.t("qrCode.message")}
					</Typography>
					{qrCode ? (
						<QRCode value={qrCode} size={256} />
					) : (
						<span>Waiting for QR Code</span>
					)}
				</Paper>
			</DialogContent>
		</Dialog>
	);
};

export default React.memo(QrcodeModal);
