import React, { useEffect, useState } from 'react';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableSortLabel,
    TablePagination,
    Button,
    Tooltip
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FaEye } from "react-icons/fa";


function TicketTable({ chartData }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('day');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const history = useHistory();

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const sortedData = chartData.sort((a, b) => {
        if (orderBy === 'day') {
            return order === 'asc' ? new Date(a[orderBy]) - new Date(b[orderBy]) : new Date(b[orderBy]) - new Date(a[orderBy]);
        } else if (orderBy === 'amount') {
            return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] -a[orderBy];
        }else {
            return order === 'asc' ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
        }
    });
    let paginatedData = chartData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    useEffect(() => {
        paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [sortedData])


    const transformedReason = (item) => {
        if (item === 'earning') {
            return 'Ganho';
        }
        if (item === 'loss') {
            return 'Perda';
        }
        if (item === 'cancel') {
            return 'Cancelamento';
        }
        if (item === 'withdrawal') {
            return 'Desistência';
        }
    };

    const formatDate = (item) => {
        const date = new Date(item);
    
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    
        const formattedTime = date.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    
        return `${formattedDate} às ${formattedTime.replace(':', ':')}hrs`;
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="ticket table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'day'}
                                direction={orderBy === 'day' ? order : 'asc'}
                                onClick={() => handleRequestSort('day')}
                            >
                                Data
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel
                                active={orderBy === 'name'}
                                direction={orderBy === 'name' ? order : 'asc'}
                                onClick={() => handleRequestSort('name')}
                            >
                                Usuário
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel
                                active={orderBy === 'contact'}
                                direction={orderBy === 'contact' ? order : 'asc'}
                                onClick={() => handleRequestSort('contact')}
                            >
                                Contato
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel
                                active={orderBy === 'reason'}
                                direction={orderBy === 'reason' ? order : 'asc'}
                                onClick={() => handleRequestSort('reason')}
                            >
                                Avaliação
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel
                                active={orderBy === 'amount'}
                                direction={orderBy === 'amount' ? order : 'asc'}
                                onClick={() => handleRequestSort('amount')}
                            >
                                Total R$
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel
                                active={orderBy === 'notes'}
                                direction={orderBy === 'notes' ? order : 'asc'}
                                onClick={() => handleRequestSort('notes')}
                            >
                                Anotação
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <FaEye />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paginatedData && paginatedData.length >= 1 && paginatedData.map((row) => (
                        <TableRow key={row.ticketId}>
                            <TableCell component="th" scope="row">
                                {formatDate(row.day)}
                            </TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.contact}</TableCell>
                            <TableCell align="center">{transformedReason(row.reason)}</TableCell>
                            <TableCell align="center">{row.amount && parseFloat(row.amount).toFixed(2).replace('.', ',')}</TableCell>
                            <TableCell align="center">
                                <Tooltip title={row.notes}>
                                    <span className='desc-table'>
                                        {row.notes}
                                    </span>
                                </Tooltip>
                                </TableCell>
                            <TableCell align="center">
                                <Button onClick={() => history.push(`/tickets/${row.ticketId}`)}>
                                <FaEye />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={chartData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Colunas da tabela"
            />
        </TableContainer>
    );
}

export default TicketTable;