import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Badge } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AuthContext } from '../../context/Auth/AuthContext';
import UserStatusIcon from '../User/statusIcon';
import getSocket from '../../helpers/socket';

const socket = getSocket();

const useStyles = makeStyles(theme => ({
    minimizedChat: {
        listStyle: "none",
        margin: 0,
        padding: 0,
        cursor: "pointer",
        '&:hover': {
            //background: theme.palette.total
        },
        '& img': {
            borderRadius: '50%',
            width: "48px",
            height: "48px"
        },
        position: "relative",
        marginTop: "5px",
        marginLeft: "6px",
        '&>span': {
            bottom: 0,
            right: 10,
        }
    },
    statusIcon: {
        position: "absolute",
        bottom: 0,
        left: 0
    }
}));

const RoomMinimized = ({ room, onClick }) => {
    const classes = useStyles();

    const { user } = useContext(AuthContext);
    const [roomMe, setRoomMe] = useState({});

    const handleRoomClick = (room) => {
        if (onClick) onClick(room);
    }

    useEffect(() => {
        if (room.participants) {
            const me = room.participants.find(p => p.userId === user.id);
            if (me) setRoomMe(me);
        }
    }, [room, user]);

    const readCountEvent = data => {
        if (room.id === data.roomId) {
            setRoomMe({ ...roomMe, unreadMessages: data.unreadCount });
        }
    }

    useEffect(() => {
        const publicToken = window.localStorage.getItem('public-token');

        socket.on(`chat:room:readCount#${publicToken}`, readCountEvent);

        return () => {
            socket.off(`chat:room:readCount#${publicToken}`, readCountEvent);
        }
    }, [room]);

    return (
        <li className={classes.minimizedChat} onClick={() => handleRoomClick(room)}>
            <Badge badgeContent={roomMe.unreadMessages} color="secondary">
                {/*<img alt="avatar" src={`https://ui-avatars.com/api/?name=${room.userId === user.id ? room.participant.name : room.user.name}`} /> */}
                

                {
                    room.participant && room.participant.userSettings && room.participant.userSettings && room.participant.userSettings.avatar  ?
                    <><Avatar src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${room.participant.userSettings[0].avatar}`} style={{
                        boxShadow: room.participant && room.participant.online ? '0px 0px 5px 0px rgba(5,252,13,1)' : '0px 0px 5px 0px rgba(250,5,5,1)',
                        outline: room.participant && room.participant.online ? '3px solid #2ecc71' : '3px solid #ff7979'
                    }} /></>
                    : <Avatar src={`https://ui-avatars.com/api//?background=0D8ABC&color=fff&name=${room.participant && room.participant.name}`} style={{
                        boxShadow: room.participant && room.participant.status === 'online' ? '0px 0px 5px 0px rgba(5,252,13,1)' : '0px 0px 5px 0px rgba(250,5,5,1)',
                        outline: room.participant && room.participant.status === 'online'  ? '3px solid #2ecc71' : '3px solid #ff7979',
                    }} />
                    
                }
                
                
                {/*room.participant && room.participant.userSettings && room.participant.userSettings[0] && room.participant.userSettings[0].avatar  ?
                    <><Avatar src={`${process.env.REACT_APP_BACKEND_URL}/public/app/${room.participant.userSettings[0].avatar}`} style={{
                        boxShadow: room.participant && room.participant.online ? '0px 0px 5px 0px rgba(5,252,13,1)' : '0px 0px 5px 0px rgba(250,5,5,1)',
                        outline: room.participant && room.participant.online ? '3px solid #2ecc71' : '3px solid #ff7979'
                    }} /></>
                    : <Avatar src={`https://ui-avatars.com/api//?background=0D8ABC&color=fff&name=${room.participant && room.participant.name}`} style={{
                        boxShadow: room.participant && room.participant.online ? '0px 0px 5px 0px rgba(5,252,13,1)' : '0px 0px 5px 0px rgba(250,5,5,1)',
                        outline: room.participant && room.participant.online ? '3px solid #2ecc71' : '3px solid #ff7979',
                    }} />*/
                }
                <UserStatusIcon user={room.userId === user.id ? room.participant : room.user} className={classes.statusIcon} />
            </Badge>
        </li>
    );
};

export default RoomMinimized;