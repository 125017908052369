import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	withCredentials: true,
});

api.interceptors.response.use(
	(response) => response,
	(error) => {
	  if (error.response && error.response.status === 402) {
		toast.error('Funcionalidade não está habilitada');
		//console.log(error.response);
	  }
	  return Promise.reject(error);
	}
  );
  

export default api;
