import React from 'react';
import { parseISO, format, formatDistanceToNow } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { ptBR } from 'date-fns/locale'; 
const TicketTime = ({ updatedAt }) => {
    //const { t } = useTranslation();
  const formattedDate = format(parseISO(updatedAt), "dd/MM/yyyy HH:mm");
  const timeAgo = formatDistanceToNow(parseISO(updatedAt), { addSuffix: true, locale: ptBR });


  return (
    <>
    <AiOutlineClockCircle />
    <Tooltip title={formattedDate}>
      <span style={{fontSize: 11}}>{timeAgo}</span>
    </Tooltip>
    </>
  );
};

export default TicketTime;