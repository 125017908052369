import React, { createContext, useState } from 'react';

export const MutedItemsContext = createContext();

export const MutedItemsProvider = ({ children }) => {
  const [mutedItems, setMutedItems] = useState([]);

  const muteTicket = (ticketId) => {
    if (mutedItems.includes(ticketId)) {
      const updatedMutedItems = mutedItems.filter((id) => id !== ticketId);
      setMutedItems(updatedMutedItems);
      localStorage.setItem('mutedTickets', JSON.stringify(updatedMutedItems));
    } else {
      const updatedMutedItems = [...mutedItems, ticketId];
      setMutedItems(updatedMutedItems);
      localStorage.setItem('mutedTickets', JSON.stringify(updatedMutedItems));
    }
  };
  

  return (
    <MutedItemsContext.Provider value={{ mutedItems, muteTicket }}>
      {children}
    </MutedItemsContext.Provider>
  );
};