import { makeStyles } from "@mui/styles";
import { Block } from "@mui/icons-material";
import React from "react";

const useStyles = makeStyles({
    deletedIcon: {
        fontSize: 18,
        verticalAlign: "middle",
        marginRight: 4,
    },
    unsupportedMessage: {
        fontStyle: "italic",
        color: "#AAA"
    }
});

const MessageError = ({ message }) => {
    const classes = useStyles();

    return <span className={classes.unsupportedMessage}>
    <Block
        color="disabled"
        fontSize="small"
        className={classes.deletedIcon}
    />
    {message.error === "ERR_WAPP_DOWNLOAD_MEDIA" && <span>Erro ao fazer download do arquivo. Essa mensagem não pôde ser processada.</span>}
    {message.error !== "ERR_WAPP_DOWNLOAD_MEDIA" && <span>Ocorreu um erro ao processar essa mensagem. Entre em contato com o suporte, informando o seguinte erro: {message.error}.</span>}
</span>
}

export default MessageError;