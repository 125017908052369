import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";

import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
//import UserModal from "../../components/UserModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
//import UserStatusIcon from "../../components/User/statusIcon";
//import { AuthContext } from "../../context/Auth/AuthContext";
import getSocket from "../../helpers/socket";
import WebhookModal from "../../components/WebhookModal";

const socket = getSocket();

const reducer = (state, action) => {
    if (action.type === "LOAD_WEBHOOKS") {
        const webhooks = action.payload;
        const newUsers = [];

        webhooks.forEach(webhook => {
            const webhookIndex = state.findIndex(u => u.id === webhook.id);
            if (webhookIndex !== -1) {
                state[webhookIndex] = webhook;
            } else {
                newUsers.push(webhook);
            }
        });

        return [...state, ...newUsers];
    }

    if (action.type === "UPDATE_WEBHOOKS") {
        const webhook = action.payload;
        const webhookIndex = state.findIndex(u => u.id === webhook.id);

        if (webhookIndex !== -1) {
            state[webhookIndex] = webhook;
            return [...state];
        } else {
            return [webhook, ...state];
        }
    }

    if (action.type === "DELETE_WEBHOOK") {
        const webhookId = action.payload;

        const webhookIndex = state.findIndex(u => u.id === webhookId);
        if (webhookIndex !== -1) {
            state.splice(webhookIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        //padding: theme.spacing(1),
        borderRadius:0,
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const Webhooks = () => {
    const classes = useStyles();
    const [countWebhooks, setCountWebhooks] = useState(0);
    const [selectedWebhook, setSelectedWebhook] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [deletingWebhook, setDeletingWebhook] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const [searchParam, setSearchParam] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);


    const [webhooks, dispatch] = useReducer(reducer, [])

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchUsers = async () => {
                try {
                    const { data } = await api.get("/webhooks/", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_WEBHOOKS", payload: data.webhooks });
                    setCountWebhooks(data.count);
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchUsers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const webhookEvent = data => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_WEBHOOKS", payload: data.webhook });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_WEBHOOK", payload: +data.webhookId });
            }
        }

        socket.on("webhook", webhookEvent);

        return () => {
            socket.off("webhook", webhookEvent);
        };
    }, []);

    const handleOpenWebhookModal = () => {
        setSelectedWebhook(null);
        setModalOpen(true);
    };

    const handleCloseWebhookModal = () => {
        setSelectedWebhook(null);
        setModalOpen(false);
    };

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditWebhook = webhook => {
        setSelectedWebhook(webhook);
        setModalOpen(true);
    };

    const handleDeleteWebhook = async webhookId => {
        try {
            await api.delete(`/webhooks/${webhookId}`);
            toast.success(i18n.t("webhooks.toasts.deleted"));
            setCountWebhooks(countWebhooks - 1);
        } catch (err) {
            toastError(err);
        }
        setDeletingWebhook(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const handleToggleEnabled = async(webhook) => {
        try {
            await api.put(`/webhooks/${webhook.id}`, {active: !webhook.active});
        } catch (err) {
            toastError(err);
        }
    }

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingWebhook &&
                    `${i18n.t("webhooks.confirmationModal.deleteTitle")} ${
                        deletingWebhook.name
                    }?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteWebhook(deletingWebhook.id)}
            >
                {i18n.t("webhooks.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <WebhookModal
                open={modalOpen}
                onClose={handleCloseWebhookModal}
                aria-labelledby="form-dialog-title"
                webhookId={selectedWebhook && selectedWebhook.id}
            />
            <MainHeader>
                <Title>{i18n.t("webhooks.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t("webhooks.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenWebhookModal}
                    >
                        {i18n.t("webhooks.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t("webhooks.table.name")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("webhooks.table.url")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("webhooks.table.type")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("webhooks.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {webhooks.map(webhook => (
                                <TableRow key={webhook.id}>
                                    <TableCell align="center">
                                        {webhook.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {webhook.url}
                                    </TableCell>
                                    <TableCell align="center">{webhook.type}</TableCell>                                    
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleEditWebhook(webhook)}
                                        >
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            aria-label={webhook.active ? "Desabilitar" : "Habilitar"}
                                            onClick={e => {
                                                handleToggleEnabled(webhook);
                                            }}
                                        >
                                            {webhook.active ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faLockOpen} />}
                                        </IconButton>
                                        
                                        <IconButton
                                            size="small"
                                            onClick={e => {
                                                setConfirmModalOpen(true);
                                                setDeletingWebhook(webhook);
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Webhooks;
