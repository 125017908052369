import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Box, CircularProgress } from "@mui/material";

const useStyles = makeStyles(theme => ({
    box: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "none",
        borderRadius: 20,
        padding: 10,
        position: "relative",
        color: theme.palette.dark.main,
        minHeight: 110,
        textAlign: 'center',
        transition: 'all ease 0.5s',
        overflow: 'hidden',
        '&:hover': {
            boxShadow: '0px 0px 26px 0px rgba(255,255,255,1)',
            '& $icon': {
                right: 300,
                fontSize: "4.5em",
            },
        },
    },
    title: {
        fontSize: "1.2em",
        fontWeight: "600"
    },
    icon: {
        position: "absolute",
        top: 25,
        right: 0,
        fontSize: "4em",
        opacity: 0.05,
        transition: 'all ease 0.5s'
    },
    value: {
        fontSize: "2.5em",
        fontWeight: "bold"
    },
    label: {
        fontSize: "1em",
        display: "block"
    }
}));

const DashboardInfoBox = ({ icon, title, value, label, color, loading, price }) => {
    const classes = useStyles();

    const formatPrice = (number) => {
        let formattedNumber = Number(number).toFixed(2);
        let parts = formattedNumber.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return parts.join(',');
    }

    return (
        <Box className={`boxDashboard ${classes.box}`}>
            {loading ? <CircularProgress color="inherit" /> :
                <React.Fragment>

                    <span className={classes.icon}>{icon}</span>

                    {!price ? 
                        <span className={`value-box ${classes.value}`} style={{ color: color }}>
                            {value}
                        </span> :
                        <span className={`value-box ${classes.value}`} style={{ color: color }}>
                            {formatPrice(value)}
                        </span>
                    }
                    {label && <span className={classes.label}>{label}</span>}
                    <h3 className={classes.title}>{title}</h3>
                </React.Fragment>
            }
        </Box>
    )
}

export default DashboardInfoBox;