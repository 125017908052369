import React, { createContext } from "react";

import useSettings from "../../hooks/useSettings";

const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
	const { isActive, getSettingValue, info, hashKey } = useSettings();

	return (
		<SettingsContext.Provider
			value={{ isActive, getSettingValue, info, hashKey }}
		>
			{children}
		</SettingsContext.Provider>
	);
};

export { SettingsContext, SettingsProvider };
