import React, { useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useTheme } from "@mui/material";

const useStyles = makeStyles(theme => ({
    chartContainer: {
        width: "100%",
        height: "450px",
        fontSize: 14
    }
}));

const ChartUsers = ({ data, color }) => {
    const classes = useStyles();
    const chartRef = useRef(null);
    const colorChart = am5.color(color);
    const theme = useTheme();
    useEffect(() => {
        if (chartRef.current) {
            let root = am5.Root.new(chartRef.current);


            let fillColor = colorChart || root.interfaceColors.get("alternativeBackground");


            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    wheelX: "none",
                    wheelY: "none",
                    paddingLeft: 50,
                    paddingRight: 40
                })
            );
            // Create Y-axis

            let yRenderer = am5xy.AxisRendererY.new(root, {});
            yRenderer.grid.template.set("visible", false);

            let yAxis = chart.yAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: "name",
                    renderer: yRenderer,
                    paddingRight: 40,              
                    invert: theme.mode === 'light'? 0:1,
                })
            );

            let xRenderer = am5xy.AxisRendererX.new(root, {});
            xRenderer.grid.template.set("strokeDasharray", [3]);

            let xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    min: 0,
                    renderer: xRenderer,
                    invert: theme.mode === 'light'? 0:1,
                })
            );

            yAxis.data.setAll(data);

            let series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                  name: "Income",
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueXField: "ticketsNumber",
                  categoryYField: "name",
                  sequencedInterpolation: true,
                  calculateAggregates: true,
                  maskBullets: false,
                  tooltip: am5.Tooltip.new(root, {
                    dy: -30,
                    pointerOrientation: "vertical",
                    labelText: "{valueX}",                    
                  })
                })
              );
              
              series.columns.template.setAll({
                strokeOpacity: 0,
                cornerRadiusBR: 10,
                cornerRadiusTR: 10,
                cornerRadiusBL: 10,
                cornerRadiusTL: 10,
                maxHeight: 50,
                fillOpacity: 0.8
              });
              
              let currentlyHovered;
              
              series.columns.template.events.on("pointerover", function(e) {
                handleHover(e.target.dataItem);
              });
              
              series.columns.template.events.on("pointerout", function(e) {
                handleOut();
              });
              
              function handleHover(dataItem) {
                if (dataItem && dataItem.bullets && currentlyHovered != dataItem) {
                  handleOut();
                  currentlyHovered = dataItem;
                  let bullet = dataItem.bullets[0];
                  bullet.animate({
                    key: "locationX",
                    to: 1,
                    duration: 600,
                    easing: am5.ease.out(am5.ease.cubic)
                  });
                }
              }
              
              function handleOut() {
                if (currentlyHovered) {
                  let bullet = currentlyHovered.bullets[0];
                  bullet.animate({
                    key: "locationX",
                    to: 0,
                    duration: 600,
                    easing: am5.ease.out(am5.ease.cubic)
                  });
                }
              }
              
              
              let circleTemplate = am5.Template.new({});
              
              series.bullets.push(function(root, series, dataItem) {
                let bulletContainer = am5.Container.new(root, {});
                let circle = bulletContainer.children.push(
                  am5.Circle.new(
                    root,
                    {
                      radius: 34
                    },
                    circleTemplate
                  )
                );
              
                let maskCircle = bulletContainer.children.push(
                  am5.Circle.new(root, { radius: 27 })
                );
              
                // only containers can be masked, so we add image to another container
                let imageContainer = bulletContainer.children.push(
                  am5.Container.new(root, {
                    mask: maskCircle
                  })
                );
              
                // not working
                let image = imageContainer.children.push(
                    am5.Picture.new(root, {
                        templateField: "avatar",
                        centerX: am5.p50,
                        centerY: am5.p50,
                        width: 60,
                        height: 60
                      })
                );
              
                return am5.Bullet.new(root, {
                  locationX: 0,
                  sprite: bulletContainer
                });
              });
              
              // heatrule
              series.set("heatRules", [
                {
                  dataField: "valueX",
                  min: am5.color(theme.palette.primary.main),
                  max: am5.color(theme.palette.primary.main),
                  target: series.columns.template,
                  key: "fill"
                },
                {
                  dataField: "valueX",
                  min: am5.color(theme.palette.primary.main),
                  max: am5.color(theme.palette.primary.main),
                  target: circleTemplate,
                  key: "fill"
                }
              ]);
              
              series.data.setAll(data);
              yAxis.data.setAll(data);
              
              let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
              cursor.lineX.set("visible", false);
              cursor.lineY.set("visible", false);
              
              cursor.events.on("cursormoved", function() {
                let dataItem = series.get("tooltip").dataItem;
                if (dataItem) {
                  handleHover(dataItem)
                }
                else {
                  handleOut();
                }
              })
              
              // Make stuff animate on load
              // https://www.amcharts.com/docs/v5/concepts/animations/
              series.appear();
              chart.appear(1000, 100);

            return () => {
                root.dispose();
            };
        }
    }, [data, color]);

    return (
        <div ref={chartRef} className={classes.chartContainer}></div>
    );
}

export default ChartUsers;