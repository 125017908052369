import api from "../services/api";
import getSocket from "./socket";

const storageKey = 'jupiterUserOptions';

const saveOptions = async (key, value, type) => {
    await api.post(`/users/options/${key}`, {
        value: type == 'array' || type == 'object' ? JSON.stringify(value) : value,
        type: type
    });
}

const addFixedTicket = async (groupId) => {
    let options = getLocalOptions();
    let exists = false;
    options.fixedTickets.forEach((value) => {
        if (value == groupId) {
            exists = true;
        }
    });

    if (!exists) {
        options.fixedTickets.push(groupId);
    }

    await saveOptions('fixedTickets', options.fixedTickets, 'array');
}

const toggleFixedTicket = async (groupId) => {
    let options = getLocalOptions();
  //  console.log('groupId', groupId)
    let indexToDelete = null;
    options.fixedTickets.forEach((value, index) => {
        if (value == groupId) {
            indexToDelete = index;
        }
    });

    if (indexToDelete !== null) {
        options.fixedTickets.splice(indexToDelete, 1);
    } else {
        options.fixedTickets.push(groupId);
    }

    await saveOptions('fixedTickets', options.fixedTickets, 'array');
}

const removeFixedTicket = async (groupId) => {
    let options = getLocalOptions();
    let indexToDelete = null;
    options.fixedTickets.forEach((value, index) => {
        if (value == groupId) {
            indexToDelete = index;
        }
    });

    if (indexToDelete !== null) {
        options.fixedTickets.splice(indexToDelete, 1);
    }

    await saveOptions('fixedTickets', options.fixedTickets, 'array');
}

const loadOptions = async () => {
    const token = localStorage.getItem('token');
    if (token) {
        const { data } = await api.get(`/users/options`);
        saveLocalOptions(data.options);
        const userId = window.localStorage.getItem("user-id");
        socket.emit(`user_options_${userId}`, {options: data.options});
    } else {
        setTimeout(() => {
            loadOptions();
        }, 1000);
    }
}

const getLocalOptions = () => {
    let result;
    try {
        result = JSON.parse(window.localStorage.getItem(storageKey));
        if (typeof (result) === 'string') {
            result = JSON.parse(result);
        }
    } catch (e) {
        return { fixedTickets: [] };
    }

    if (!result) {
        return { fixedTickets: [] };
    }
    if (!result.fixedTickets) {
        result.fixedTickets = [];
    }
    return result;
}

const saveLocalOptions = async (data) => {
    return window.localStorage.setItem(storageKey, JSON.stringify(data));
}

const cleanLocalOptions = () => {
    return window.localStorage.removeItem(storageKey);
}

var socket;
var currentTimeout;

const userOptionsEvent = (data) => {
    saveLocalOptions(data.options);
}

const initUserOptions = async () => {
    socket = getSocket();
    const userId = window.localStorage.getItem("user-id");
    socket.on(`user_options_${userId}`, userOptionsEvent);

    currentTimeout = setTimeout(() => {
        loadOptions();
    }, 500);
}

const cleanInitUserOptions = () => {
    if (currentTimeout) {
        clearTimeout(currentTimeout);
    }
    const userId = window.localStorage.getItem("user-id");
    if (socket) {
        socket.off(`user_options_${userId}`, userOptionsEvent);
    }
}

export { saveOptions, loadOptions, initUserOptions, cleanInitUserOptions, addFixedTicket, removeFixedTicket, getLocalOptions, toggleFixedTicket, cleanLocalOptions };