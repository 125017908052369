import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { cleanLocalOptions } from "../../helpers/userOptions";
import getSocket from "../../helpers/socket";

const socket = getSocket();

const useAuth = () => {
    const history = useHistory();
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [permissions, setPermissions] = useState([]);
    const [whatsType, setWhatsType] = useState('');
    const [nextTimeOff, setNextTimeOff] = useState(null);

    const setUserObject = (user) => {
        // Format the meta to get unreadMessages
        /*if (user && user.ticketMeta) {
            console.log(user.ticketMeta, 'ticketMeta')
            user.ticketMeta.map(meta => {
                if (typeof (user[meta.ticketId]) == 'undefined') {
                    user[meta.ticketId] = {};
                }
                user[meta.ticketId][meta.type] = meta.value;
            });
        }*/
        setUser(user);
    }

    api.interceptors.request.use(
        config => {
            const token = localStorage.getItem("token");
            if (token) {
                config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
                setIsAuth(true);
            }
            return config;
        },
        error => {
            Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        response => {
            return response;
        },
        async error => {
            const originalRequest = error.config;
            if (error?.response?.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true;

                const { data } = await api.post("/auth/refresh_token");
                if (data) {
                    localStorage.setItem("token", JSON.stringify(data.token));
                    api.defaults.headers.Authorization = `Bearer ${data.token}`;
                }
                return api(originalRequest);
            }
            if (error?.response?.status === 401) {
                localStorage.removeItem("token");
                api.defaults.headers.Authorization = undefined;
                setIsAuth(false);
            }
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        const token = localStorage.getItem("token");
        (async () => {
            if (token) {
                try {
                    const { data } = await api.post("/auth/refresh_token");
                    api.defaults.headers.Authorization = `Bearer ${data.token}`;
                    setIsAuth(true);
                    setUserObject(data.user);
                } catch (err) {
                    toastError(err);
                }
            }
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        const userEvent = data => {
            if (data.action === "update" && data.user.id === user.id) {
                setUser(data.user);

            }
        }

        const logoutEvent = data => {
            if (data.token === localStorage.getItem("public-token")) {
                handleLogout();
            }
        }

        socket.on("user", userEvent);
        socket.on("logout", logoutEvent);

        return () => {
            socket.off('user', userEvent);
            socket.off('logout', logoutEvent);
        };


    }, [user]);

    async function fetchPermissions(role) {
        try {
            if (user && user.id && role != 1) {
                const response = await api.get(`/roles/${role}`);
                setPermissions(response.data.role.permissions);
                //console.log('permissions',response.data.role.permissions)
            }
        } catch (error) {
            console.error("Error fetching permissions:", error);
        }
    }

    useEffect(() => {
        if (permissions.length === 0) {
            const role = user.roleId ? user.roleId : 2;
            fetchPermissions(role);
        }
    }, [user]);


    const handleLogin = async (userData) => {
        setLoading(true);

        try {
            const { data } = await api.post("/auth/login", userData);
            // Lógica do login bem-sucedido
            localStorage.setItem("token", JSON.stringify(data.token));
            localStorage.setItem("public-token", data.user.token);
            localStorage.setItem("user-id", data.user.id);
            api.defaults.headers.Authorization = `Bearer ${data.token}`;
            setUserObject(data.user);
            setIsAuth(true);
            fetchPermissions(data.user.roleId);
            setWhatsType(data.whatsappType);
            setNextTimeOff(data.timeToClose);
            history.push('/tickets');
            localStorage.setItem("typeWhats", data.whatsappType);
            toast.success(i18n.t("auth.toasts.success"));

        } catch (err) {
            if (err.response && err.response.status === 423) {
                const message = err.response.data?.error || "";
                const nextAvailableDateMatch = message;
               // console.log(err.response);
                if (nextAvailableDateMatch) {
                    const nextAvailableDate = new Date(nextAvailableDateMatch).getTime();
                    localStorage.setItem("next-time-login", nextAvailableDate);
                }
                toast.error(i18n.t("Erro. Acesso não autorizado por restrição de tempo!"));
                history.push("/off");
            } else {
                toastError(err);
            }
        } finally {
            setLoading(false);
        }
    };



    const handleLogout = async () => {
        setLoading(true);

        try {
            await api.delete("/auth/logout");
            //   cleanLocalOptions();
            localStorage.clear();
            setIsAuth(false);
            setUser({});
            api.defaults.headers.Authorization = undefined;
            setLoading(false);
            history.push("/login");
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    };

    return { isAuth, user, loading, handleLogin, handleLogout, permissions, fetchPermissions, whatsType, nextTimeOff, setNextTimeOff };
};

export default useAuth;
