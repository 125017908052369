import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { HiOutlineInboxIn } from "react-icons/hi";
import { PiPause, PiChatDotsBold, PiTimerBold, PiRobot } from "react-icons/pi";
import { BsCheckCircle } from "react-icons/bs";
import { TiGroupOutline } from "react-icons/ti";
import { GoSortAsc, GoSortDesc } from "react-icons/go";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Badge, Button, Checkbox, Chip, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Tooltip } from "@mui/material";
import SelectTags from "../SelectTags";
import SelectStatus from "../SelectStatus";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { useTicketCounts } from '../../context/Tickets/Count';
import toastError from "../../errors/toastError";
import api from "../../services/api";

import getSocket from "../../helpers/socket";
import IOSSwitch from "../IOSSwitch";

import AsyncSelect from "../AsyncSelect";
import chroma from "chroma-js";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

import useCountPaused from '../../hooks/countMessages';
import { FaPlus } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { HiOutlineUsers } from "react-icons/hi2"
import { Can } from "../../components/Can";
import NewTicketGroupModal from "../NewTicketModal/group";

const socket = getSocket();

const useStyles = makeStyles(theme => ({
    ticketsWrapperRadius: {
        position: "relative",
        display: "flex",
        height: "85vh",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: 20,
        background: theme.palette.inputBackground,
        border: 0,
    },
    ticketsWrapper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        border: 0,
    },
    gridDisabled: {
        backgroundColor: '#f5f5f5',
        position: 'relative',
    },
    gridDisabledOverlay: {
        content: ' ',
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 9,
    },
    tabsHeader: {
        flex: "none",
    },

    tabsInternal: {
        flex: "none",
        backgroundColor: theme.palette.tabHeaderBackground
    },

    settingsIcon: {
        alignSelf: "center",
        marginLeft: "auto",
        padding: 8,
    },

    tab: {
        minWidth: 90,
        width: 90,
        textTransform: 'capitalize',
    },

    internalTab: {
        minHeight: 70,
        minWidth: 100,
        width: 100,
        padding: 5,
        borderRight: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : '#f9f9f9'}`,
        color: theme.palette.mode === 'dark' ? '#fff' : '#696969'
    },
    resetButton: {
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: '#ffffff'
    },
    ticketOptionsBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: theme.palette.optionsBackground,
        boxShadow: 'inset 0px 0px 26px 0px rgba(0,0,0,0.05)',
        padding: theme.spacing(1),
    },

    ticketSearchLine: {
        padding: theme.spacing(1),
    },

    searchInputHeader: {
        display: "flex",
        width: "100%",
        position: "relative"
    },

    searchInputWrapper: {
        flex: 18,
        background: theme.palette.total,
        display: "flex",
        borderRadius: 40,
        padding: 4,
        marginRight: theme.spacing(1),
    },
    searchToggleShow: {
        flex: 1
    },
    searchToggleIcon: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)"
    },

    searchIcon: {
        color: theme.palette.primary.main,
        marginLeft: 6,
        marginRight: 6,
        alignSelf: "center",
    },

    searchInput: {
        flex: 1,
        border: "none",
        borderRadius: 30,
    },

    insiderTabPanel: {
        height: '89%',
        marginTop: "0px",
        paddingTop: "0px"
    },

    insiderDoubleTabPanel: {
        display: "flex",
        flexDirection: "column",
        marginTop: "-72px",
        paddingTop: "72px",
        height: "100%"
    },

    labelContainer: {
        width: "auto",
        padding: 0
    },
    iconLabelWrapper: {
        flexDirection: "row",
        '& > *:first-child': {
            marginBottom: '3px !important',
            marginRight: 16
        }
    },
    insiderTabLabel: {
        [theme.breakpoints.down(1600)]: {
            display: 'none'
        }
    },
    smallFormControl: {
        '& .MuiOutlinedInput-input': {
            padding: "12px 10px",
        },
        '& .MuiInputLabel-outlined': {
            marginTop: "-6px"
        }
    },
    FormControl: {
        border: '1px solid #bdbdbd',
        borderRadius: 4,
        padding: "5px 10px",
        width: '100%'
    }
}));

const TicketsManager = (mode) => {
    const classes = useStyles();
    const { term } = useParams();
    const { isActive, getSettingValue } = useContext(SettingsContext);
    const [searchParam, setSearchParam] = useState("");
    const [searchById, setSearchByTicketId] = useState(null);
    const [searchOnMessages, setSearchOnMessages] = useState(false);
    const [tab, setTab] = useState("open");
    const [runningTab, setRunningTab] = useState("open");
    const [showBotList, setShowBotList] = useState(true);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [newTicketModalGroupOpen, setNewTicketModalGroupOpen] = useState(false);
    const [showAllTickets, setShowAllTickets] = useState(false);
    const [showAllSearch, setShowAllSearch] = useState(false);
    const [sortAscending, setSortAscending] = useState(false);
    const [disableControls, setDisableControls] = useState(false);
    const searchInputRef = useRef();
    const searchTicketIdRef = useRef();
    const { user, permissions } = useContext(AuthContext);
    const [tagIds, setTagIds] = useState([]);
    const [connectionIds, setConnectionIds] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [forceSearch, setForceSearch] = useState(false);
    // const { ticketCounts } = useTicketCounts();
    const [countGroupUnread, setCountGroupUnread] = useState(0);
    const { countPaused, setCountPaused } = useCountPaused();
    const [showSearchBar, setShowSearchBar] = useState(true);
    const [keyControl, setKeyControl] = useState(1);
    const inputRefDateFrom = useRef();
    const inputRefDateTo = useRef();
    const tabFake = "1";

    const localStorageQueueIds = window.localStorage.getItem('selectedQueueIds') ? JSON.parse(window.localStorage.getItem('selectedQueueIds')) : null;
    const userQueueIds = localStorageQueueIds ? localStorageQueueIds : user && user.queues ? user.queues.map(q => q.id) : [];
    const userId = window.localStorage.getItem('user-id');
    const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds ? [-1, ...userQueueIds] : [-1]);
    const [internalSelectedQueueIds, setInternalSelectedQueueIds] = useState([]);
    const [unreadMessagesSearchMe, setUnreadMessagesSearchMe] = useState(false);
    const [unreadMessagesSearchClient, setUnreadMessagesSearchClient] = useState(false);
    const [lastClientActivityMe, setLastClientActivityMe] = useState(null);
    const [lastClientActivityClient, setLastClientActivityClient] = useState(null);
    const [selectedUnreadMessageOption, setSelectedUnreadMessage] = useState('');
    const [formDisabled, setFormDisabled] = useState(false);
    const [isSearched, setisSearched] = useState(false);
    const [listQueues, setListQueues] = useState({})
    const [listTags, setListTags] = useState({})
    const [listConnections, setListConnections] = useState({})
    const [listUsers, setListUsers] = useState({})

    const [countPending, setCountPending] = useState(0);
    const [countOpen, setCountOpen] = useState(0);
    const [countBot, setCountBot] = useState(0);
    const [newCountPaused, setNewCountPaused] = useState(0);
    const [showBotsOptions, setShowBotsOptions] = useState(false);

    const handleUnanswered = (event) => {
        const target = event.target.value;
        setSelectedUnreadMessage(target);
        switch (target) {
            case 'allMe':
                setUnreadMessagesSearchMe(true);
                setUnreadMessagesSearchClient(null);
                setLastClientActivityClient(null);
                setLastClientActivityMe(null);
                break;
            case 'allClient':
                setUnreadMessagesSearchMe(null);
                setUnreadMessagesSearchClient(true);
                setLastClientActivityClient(null);
                setLastClientActivityMe(null);
                break;
            case 'customClient':
                setUnreadMessagesSearchMe(null);
                setUnreadMessagesSearchClient(null);
                setLastClientActivityClient(true);
                setLastClientActivityMe(null);
                break;
            case 'customMe':
                setUnreadMessagesSearchMe(null);
                setUnreadMessagesSearchClient(null);
                setLastClientActivityClient(null);
                setLastClientActivityMe(true);
                break;
            default:
                setUnreadMessagesSearchMe(null);
                setUnreadMessagesSearchClient(null);
                setLastClientActivityClient(null);
                setLastClientActivityMe(null);
        }
    };

    window.updateTicketManager = () => {
        setKeyControl(keyControl + 1);
    }

    useEffect(() => {
        setTagIds([]);
        if (tab === "search") {
            searchInputRef.current.focus();
        }
        setKeyControl(keyControl + 1);

    }, [tab]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchList = async () => {
                try {
                    const { data } = await api.get('/bots/enabled');
                    setShowBotList(data.enabled);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchList();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, []);

    useEffect(() => {
        const showAllTickets = JSON.parse(window.localStorage.getItem('showAllTickets'));
        const sortAscendingItem = JSON.parse(window.localStorage.getItem('sortAscending'));
        if (showAllTickets) {
            setShowAllTickets(showAllTickets);
        }
        if (sortAscendingItem) {
            setSortAscending(sortAscendingItem);
        }
    
        const hasViewAllPermission = permissions.some((permission) => permission.key === "tickets:view:all");
        const hasBotsOptionsClearPermission = permissions.some((permission) => permission.key === "bots:options:clear");
        //const searchAllTickets = user.roleId === 1 || isActive('ignoreUserConnectionForGroups');
        setShowAllSearch(user.roleId === 1 || hasViewAllPermission);
        setShowBotsOptions(user.roleId === 1 || hasBotsOptionsClearPermission);
    }, []);
    

    useEffect(() => {
        setSelectedQueueIds(userQueueIds ? [-1, ...userQueueIds] : [-1]);
        setLastClientActivityMe((prevState) => prevState);
        setLastClientActivityClient((prevState) => prevState);
        setSearchParam((prevState) => prevState);
        setSearchByTicketId((prevState) => prevState);
        setUserIds((prevState) => prevState);
        setTagIds((prevState) => prevState);
        setConnectionIds((prevState) => prevState);
        setDateFrom((prevState) => prevState);
        setDateTo((prevState) => prevState);
        setSelectedStatuses((prevState) => prevState);
    }, [keyControl]);


    useEffect(() => {

        const botEvent = data => {
            if (data.type === "create" || data.type === "update") {
                if (data.bot.first) {
                    setShowBotList(data.enabledBotStatus);
                }
            }
        }

        socket.on("bot", botEvent);

        return () => {
            socket.off("bot", botEvent);
        };
    }, []);


    useEffect(() => {
        if (term) {
            setTab('search')
            setSearchParam(term);
            handleSearch();
        }
    }, [term]);

    let searchTimeout;

    const handleChangeSearch = e => {
        const searchedTerm = e.target.value.toLowerCase();
        setSearchParam(searchedTerm);         
    };

    let searchTimeoutTicketId;

    const handleChangeTicketId = e => {
        const searchedId = e.target.value;
        clearTimeout(searchTimeoutTicketId);
        if (searchedId === "") {
            setFormDisabled(false);
            setSearchByTicketId(searchedId);
            return;
        }
        searchTimeoutTicketId = setTimeout(() => {
            setSearchByTicketId(searchedId);
            setFormDisabled(true);
        }, 500);
    };

    const handleChangeTab = (e, newValue) => {
        setTab(newValue);
        setRunningTab(newValue);
        setFormDisabled(false);
    };

    const handleChangeRunningTab = (e, newValue) => {
        setRunningTab(newValue);
    }

    const handleChangeTags = (changedTags) => {
        setTagIds(changedTags.map((tag) => { return tag.id }));
    }

    const handleChangeStatus = (changedStatus) => {
        setSelectedStatuses(changedStatus.map((status) => { return status.id }));
    }

    const handleSearch = () => {
        setSelectedQueueIds(internalSelectedQueueIds);
        setForceSearch(!forceSearch);
        setShowSearchBar(false);
        setisSearched(true);
    }

    const handleClean = () => {
        setShowSearchBar(true);
        setKeyControl(keyControl + 1);
        setFormDisabled(false);
        setLastClientActivityMe(null);
        setLastClientActivityClient(null);
        setSearchParam("");
        setSearchByTicketId(null);
        setUserIds([]);
        setTagIds(null);
        setConnectionIds([]);
        setDateFrom(null);
        setDateTo(null);
        setisSearched(null);
        setSelectedStatuses([]);
    }

    const handleShowAllTickets = () => {
        setShowAllTickets(!showAllTickets);
        localStorage.setItem('showAllTickets', !showAllTickets)
    }

    const handleSortAscending = () => {
        setSortAscending(!sortAscending)
        localStorage.setItem('sortAscending', !sortAscending)
    }

    useEffect(() => {
        const socket = getSocket(true);
        const connectEvent = () => socket.emit("joinNotification");
        const appMessageEvent = data => {
            if (
                data.action === "create" &&
                !data.message.read &&
                (data.ticket.userId === user?.id || !data.ticket.userId)
            ) {
                if (data.ticket.status == 'paused') {
                    let pauseds = 1;
                    setCountPaused(1);
                }
            }
        }
        socket.on("connect", connectEvent);
        socket.on("appMessage", appMessageEvent);
        if (socket.connected) {
            connectEvent();
        }
        return () => {
            socket.disconnect();
        };
    }, [user, countPaused, setCountPaused]);

    return (

        <>
            <Paper key={keyControl} elevation={0} variant="outlined" className={classes.ticketsWrapperRadius}>
                <NewTicketModal
                    modalOpen={newTicketModalOpen}
                    onClose={e => setNewTicketModalOpen(false)}
                />
                <NewTicketGroupModal
                    modalOpen={newTicketModalGroupOpen}
                    onClose={e => setNewTicketModalGroupOpen(false)}
                />
                <Paper elevation={0} square className={classes.tabsHeader}>
                    <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="icon label tabs example"
                    >
                        <Tab
                            value={"open"}
                            icon={<HiOutlineInboxIn size={23} />}
                            label={i18n.t("tickets.tabs.open.title")}
                            classes={{ root: classes.tab }}
                        />
                         {permissions && permissions.some((permission) => permission.key === 'tickets:pause:any') || user.roleId === 1 ?
                            <Tab
                                value={"paused"}
                                icon={<Badge badgeContent={countPaused} color="primary" max={99999}>
                                    <PiPause size={23} />
                                </Badge>}
                                label={i18n.t("tickets.tabs.paused.title")}
                                classes={{ root: classes.tab }}
                            />
                        : <></>}
                        <Tab
                            value={"closed"}
                            icon={<BsCheckCircle size={23} />}
                            label={i18n.t("tickets.tabs.closed.title")}
                            classes={{ root: classes.tab }}
                        />
                        <Tab
                            value={"search"}
                            icon={<FiSearch size={23} />}
                            label={i18n.t("tickets.tabs.search.title")}
                            classes={{ root: classes.tab }}
                        />
                    </Tabs>
                </Paper>
                <Paper square elevation={0} className={`${classes.ticketOptionsBox}`}>
                    {tab === "search" ? (
                        <div className={classes.searchInputHeader}>
                            <div className={classes.searchInputWrapper}>
                                <SearchIcon className={classes.searchIcon} />
                                <InputBase
                                    className={classes.searchInput}
                                    inputRef={searchInputRef}
                                    placeholder={i18n.t("tickets.search.placeholder")}
                                    type="search"
                                    onChange={handleChangeSearch}
                                    disabled={disableControls}
                                    defaultValue={term ? term : null}
                                />
                                <Tooltip placement="top" title={i18n.t("tickets.search.tooltip")}>
                                    <div>
                                        <IOSSwitch checked={searchOnMessages} disabled={disableControls} onChange={(e) => { setSearchOnMessages(e.target.checked) }} />
                                    </div>
                                </Tooltip>

                            </div>
                            <div className={classes.searchToggleShow}>
                                {showSearchBar && <ArrowUpward onClick={() => setShowSearchBar(prevState => !prevState)} className={classes.searchToggleIcon} />}
                                {!showSearchBar && <ArrowDownward onClick={() => setShowSearchBar(prevState => !prevState)} className={classes.searchToggleIcon} />}
                            </div>
                        </div>
                    ) : (
                        <>
                        {runningTab === 'group' ?
                            <Can
                                role={user.roleId}
                                perform={"tickets:groups:create:tickets"}
                                yes={() => (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        style={{ background: "#2ecc71", boxShadow: 'none', borderRadius: 10, border: 0, color: '#000', gap: 3, fontWeight: 'bold' }}
                                        onClick={() => setNewTicketModalGroupOpen(true)}
                                    >
                                        <FaPlus size={12} /> {i18n.t("ticketsManager.buttons.newTicket")}
                                    </Button>
                                )}
                                no={() => <></>}
                            /> :
                            <Can
                                role={user.roleId}
                                perform={"tickets:create"}
                                yes={() => (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        style={{ background: "#2ecc71", boxShadow: 'none', borderRadius: 10, border: 0, color: '#000', gap: 3, fontWeight: 'bold' }}
                                        onClick={() => setNewTicketModalOpen(true)}
                                    >
                                        <FaPlus size={12} /> {i18n.t("ticketsManager.buttons.newTicket")}
                                    </Button>
                                )}
                                no={() => <></>}
                            />}

                            {((isActive('showResolved') && runningTab == 'closed') || (runningTab == 'open' || runningTab == 'paused' || runningTab == 'closed' || runningTab == 'group')) && (

                                <Can
                                    role={user.roleId}
                                    perform="tickets:view:all"
                                    yes={() => (
                                        <Tooltip placement="bottom" title={i18n.t("tickets.buttons.showAll")}>
                                            <FormControlLabel
                                                label={<HiOutlineUsers size={28} color={showAllTickets ? '#2ecc71' : '#ddd'} style={{ marginTop: 5 }} />}
                                                labelPlacement="start"
                                                control={
                                                    <Switch
                                                        size="small"
                                                        checked={showAllTickets}
                                                        onChange={() => {
                                                            handleShowAllTickets();
                                                        }
                                                        }
                                                        name="showAllTickets"
                                                        color="success"
                                                    />
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                    no={() => <></>}
                                />
                            )}

                            {(runningTab == 'pending' || runningTab == 'open') &&
                                <Tooltip placement="bottom" title={i18n.t("tickets.buttons.sortAscending")}>
                                    <FormControlLabel
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        label={
                                            sortAscending ?
                                                <GoSortDesc size={28} color={sortAscending ? '#2ecc71' : '#ddd'} style={{ marginTop: 5 }} /> :
                                                <GoSortAsc size={28} color={sortAscending ? '#2ecc71' : '#ddd'} style={{ marginTop: 5 }} />
                                        }
                                        labelPlacement="start"
                                        control={
                                            <Switch
                                                size="small"
                                                checked={sortAscending}
                                                onChange={() => handleSortAscending()}
                                                name="sortAscending"
                                                color="primary"
                                            />
                                        }
                                    />
                                </Tooltip>
                            }
                        </>
                    )}
                    {tab !== "search" &&
                        <Can
                            role={user.roleId}
                            perform="tickets:filters:queue"
                            yes={() => (
                                <TicketsQueueSelect
                                    style={{ marginLeft: 6 }}
                                    selectedQueueIds={selectedQueueIds}
                                    userQueues={user?.queues}
                                    onChange={values => setSelectedQueueIds(values)}
                                />)}
                            no={() => (<></>)}
                        />
                    }
                </Paper>
                {tab === "search" && showSearchBar && (
                    <Grid container spacing={1} className={`${classes.ticketSearchLine} search-block`}>
                        <Grid item xs={12} md={12}>
                            <InputBase
                                className={classes.FormControl}
                                placeholder={i18n.t("tickets.search.ticketId")}
                                defaultValue={searchById}
                                onChange={handleChangeTicketId}
                                InputProps={{ sx: { borderRadius: 0 } }}
                            />
                        </Grid>
                        {!formDisabled && (
                            <>
                                <Grid item xs={12} md={6} >
                                    {isSearched && selectedQueueIds.length > 0 ?
                                        <Chip
                                            label={selectedQueueIds.length + i18n.t("tickets.search.selectedQueues")}
                                            style={{ marginRight: 5, marginBottom: 5 }} />
                                        : (
                                            <AsyncSelect
                                                url="/queue"
                                                multiple={true}
                                                width="100%"
                                                disableControls={formDisabled}
                                                isSearched={isSearched}
                                                defaultValue={selectedQueueIds}
                                                setListQueues={setListQueues}
                                                disabled={formDisabled}
                                                isOptionDisabled={(option) => formDisabled || option.id === -1} // Disable options as well when formDisabled is true
                                                addFirstOption={{ id: -1, name: i18n.t("tickets.search.noQueues"), color: 'grey' }}
                                                label={i18n.t("ticketsQueueSelect.placeholder")}
                                                onChange={(event, values) => {
                                                    setInternalSelectedQueueIds(values.map((value) => value.id))
                                                }}
                                                disableCloseOnSelect
                                                renderTags={(tags, getCustomizedTagProps) => (!tags || tags.length === 0 ? i18n.t("ticketsQueueSelect.placeholder") : tags.map((option, index) => {
                                                    return (<Chip
                                                        label={option.name}
                                                        size='medium'
                                                        style={{ backgroundColor: option.color, color: chroma.contrast(option.color, 'white') > 2 ? 'white' : 'black' }}
                                                        {...getCustomizedTagProps({ index })}
                                                    />)
                                                }
                                                ))}
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            style={{
                                                                color: option.color,
                                                            }}
                                                            size="small"
                                                            color="primary"
                                                            checked={selected}
                                                        />
                                                        <ListItemText primary={option.name} />
                                                    </React.Fragment>
                                                )}
                                            />)}
                                </Grid>

                                <Grid item xs={12} md={6} >
                                    {isSearched && tagIds.length > 0 ?
                                        <Chip
                                            label={tagIds.length + i18n.t("tickets.search.selectedTags")}
                                            style={{ marginRight: 5, marginBottom: 5 }} />
                                        : (
                                            <SelectTags
                                                onChange={handleChangeTags}
                                                defaultValue={tagIds}
                                                isSearched={isSearched}
                                                setListTags={setListTags}
                                                listTags={listTags}
                                                disabled={formDisabled}
                                                outlined>
                                            </SelectTags>
                                        )}
                                </Grid>
                                <Grid item xs={6} md={6} >
                                    {isSearched && connectionIds.length > 0 ?
                                        <Chip
                                            label={connectionIds.length + i18n.t("tickets.search.selectedConnections")}
                                            style={{ marginRight: 5, marginBottom: 5 }} />
                                        : (
                                            <AsyncSelect url="/whatsapp"
                                                isSearched={isSearched}
                                                listConnections={listConnections}
                                                setListConnections={setListConnections}
                                                disabled={formDisabled}
                                                multiple={true} label={i18n.t("tickets.search.connections")} width="100%"
                                                dictKey="whatsapps"
                                                onChange={(event, value) => { setConnectionIds(value.map((connection) => connection.id)) }}>
                                            </AsyncSelect>
                                        )}
                                </Grid>
                                <Grid item xs={6} md={6} >
                                    {isSearched && userIds.length > 0 ?
                                        <Chip
                                            label={userIds.length + i18n.t("tickets.search.selectedUsers")}
                                            style={{ marginRight: 5, marginBottom: 5 }} />
                                        : (
                                            <AsyncSelect
                                                defaultValue={userIds}
                                                listUsers={listUsers}
                                                setListUsers={setListUsers}
                                                url="/users"
                                                disabled={formDisabled}
                                                isSearched={isSearched}
                                                multiple={true} label={i18n.t("tickets.search.users")} dictKey="users" width="100%"
                                                onChange={(event, value) => { setUserIds(value.map((user) => user.id)) }}>
                                            </AsyncSelect>
                                        )}
                                </Grid>
                                <Grid item xs={6} md={6} >
                                    <FormControl variant="outlined" className={classes.smallFormControl} fullWidth>
                                        <InputLabel id="search-date-from-label">{i18n.t("tickets.search.from")}</InputLabel>
                                        <OutlinedInput
                                            label={i18n.t("tickets.search.from")}
                                            ref={inputRefDateFrom}
                                            onFocus={() => inputRefDateFrom.current.children[0].type = "date"}
                                            onBlur={() => inputRefDateFrom.current.children[0].type = "text"}
                                            type="text"
                                            placeholder={i18n.t("tickets.search.from")}
                                            disabled={formDisabled}
                                            defaultValue={dateFrom}
                                            fullWidth
                                            onChange={(event) => { setDateFrom(event.target.value) }}></OutlinedInput>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={6} >
                                    <FormControl variant="outlined" className={classes.smallFormControl} fullWidth>
                                        <InputLabel id="search-date-to-label">{i18n.t("tickets.search.to")}</InputLabel>
                                        <OutlinedInput
                                            label={i18n.t("tickets.search.to")}
                                            type="text"
                                            fullWidth
                                            ref={inputRefDateTo}
                                            disabled={formDisabled}
                                            defaultValue={dateTo}
                                            onFocus={() => inputRefDateTo.current.children[0].type = "date"}
                                            onBlur={() => inputRefDateTo.current.children[0].type = "text"}
                                            placeholder={i18n.t("tickets.search.to")}
                                            onChange={(event) => { setDateTo(event.target.value) }}></OutlinedInput>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={selectedStatuses && selectedStatuses.length >= 2 ? 12 : 6} style={{ marginTop: 5 }}>
                                    {isSearched && selectedStatuses.length > 0 ? selectedStatuses.map((item, index) => (
                                        <Chip key={index}
                                            label={
                                                item === 'paused'
                                                    ? 'Pausados'
                                                    : item === 'open'
                                                        ? 'Atendimento'
                                                        : item === 'pending'
                                                            ? 'Aguardando'
                                                            : 'Resolvidos'
                                            }
                                            style={{ marginRight: 5, marginBottom: 5 }} />
                                    )) :
                                        (
                                            <SelectStatus isSearched={isSearched} defaultValue={selectedStatuses} onChange={handleChangeStatus} disabled={formDisabled} outlined></SelectStatus>
                                        )}
                                </Grid>
                                <Grid item xs={12} md={selectedStatuses && selectedStatuses.length >= 2 ? 12 : 6} style={{ marginTop: 5 }}>
                                    {selectedUnreadMessageOption == 'customClient' || selectedUnreadMessageOption == 'customMe' ? (
                                        <FormControl variant="outlined" className={classes.smallFormControl} fullWidth>
                                            <InputLabel id="input-time-label">minutos</InputLabel>
                                            <OutlinedInput
                                                label="minutos"
                                                type="number"
                                                placeholder="   "
                                                disabled={formDisabled}
                                                fullWidth
                                                onChange={(event) => {
                                                    selectedUnreadMessageOption == 'customClient' ?
                                                        setLastClientActivityClient(event.target.value) : setLastClientActivityMe(event.target.value)
                                                }}
                                            />
                                            <Button size="small" className={classes.resetButton} onClick={() => setSelectedUnreadMessage('')}>Resetar</Button>
                                        </FormControl>
                                    ) : (
                                        <FormControl variant="outlined" className={classes.smallFormControl} fullWidth>
                                            <InputLabel id="select-option-label">{i18n.t("tickets.search.unanswered")}</InputLabel>
                                            <Select
                                                labelId="select-option-label"
                                                value={selectedUnreadMessageOption}
                                                onChange={handleUnanswered}
                                                defaultValue={selectedUnreadMessageOption}
                                                disabled={formDisabled}
                                                label={i18n.t("tickets.search.unanswered")}
                                            >
                                                <MenuItem value="">{i18n.t("tickets.search.unansweredDisabled")}</MenuItem>
                                                <MenuItem value="allClient">{i18n.t("tickets.search.unansweredClient")}</MenuItem>
                                                <MenuItem value="allMe">{i18n.t("tickets.search.unansweredMe")}</MenuItem>
                                                <MenuItem value="customClient">{i18n.t("tickets.search.unansweredTimeClient")}</MenuItem>
                                                <MenuItem value="customMe">{i18n.t("tickets.search.unansweredTimeMe")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}


                                </Grid>
                            </>
                        )}
                        <Grid item xs={6} md={6}>
                            <Button variant="contained" onClick={handleClean} fullWidth>{i18n.t("tickets.buttons.clear")}</Button>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Button variant="contained" style={{ backgroundColor: "#2ecc71" }}
                                onClick={handleSearch} fullWidth>{i18n.t("tickets.buttons.search")}</Button>
                        </Grid>
                    </Grid>
                )}
                <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
                    <Paper elevation={0} square className={classes.tabsInternal}>
                        <Tabs
                            value={runningTab}
                            onChange={handleChangeRunningTab}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="icon label tabs example"
                        >
                            <Tab
                                value={"open"}
                                icon={<Badge badgeContent={countOpen} max={99999} color="primary"><PiChatDotsBold size={26} /></Badge>}
                                classes={{
                                    wrapper: classes.iconLabelWrapper,
                                    root: classes.internalTab
                                }}
                                label={<span className={classes.insiderTabLabel}>{i18n.t("tickets.status.open")}</span>}
                            />
                            {permissions && permissions.some((permission) => permission.key === 'tickets:pending:all') || user.roleId === 1 ?
                                <Tab
                                    value={"pending"}
                                    icon={<Badge badgeContent={countPending} max={99999} color="primary"><PiTimerBold size={26} /></Badge>}
                                    label={<span className={classes.insiderTabLabel}>{i18n.t("tickets.status.pending")}</span>}
                                    classes={{
                                        wrapper: classes.iconLabelWrapper,
                                        root: classes.internalTab
                                    }}
                                />
                                : <></>
                            }


                            {permissions && permissions.some((permission) => permission.key === 'bots:show:page:users') || user.roleId === 1 ?
                                <Tab
                                    value={"bot"}
                                    icon={<Badge badgeContent={countBot} max={99999} color="primary"><PiRobot size={23} /></Badge>}
                                    label={<span className={classes.insiderTabLabel}>{i18n.t("tickets.status.bot")}</span>}
                                    classes={{
                                        wrapper: classes.iconLabelWrapper,
                                        root: classes.internalTab
                                    }}
                                />
                                : <></>}



                            <Tab value={"group"}
                                icon={<Badge badgeContent={countGroupUnread} max={99999} color="primary"><TiGroupOutline size={26} /></Badge>}
                                label={<span className={classes.insiderTabLabel}>{i18n.t("tickets.status.group")}</span>}
                                classes={{
                                    wrapper: classes.iconLabelWrapper,
                                    root: classes.internalTab
                                }}
                            />
                        </Tabs>
                    </Paper>
                    <TabPanel value={tabFake} name="1" className={classes.insiderTabPanel} style={{ display: runningTab === 'open' ? 'block' : 'none' }}>
                        <TicketsList
                            status="open"
                            showAll={showAllTickets}
                            selectedQueueIds={selectedQueueIds}
                            sortDir={sortAscending ? 'ASC' : 'DESC'}
                            selectedTagIds={tagIds}
                            updateCount={setCountOpen}
                        />
                    </TabPanel>
                    <TabPanel value={tabFake} name="1" className={classes.insiderTabPanel}
                        style={{ display: runningTab === 'pending' ? 'flex' : 'none' }}>
                        <TicketsList
                            status="pending"
                            sortDir={sortAscending ? 'ASC' : 'DESC'}
                            showAll={showAllTickets}
                            selectedQueueIds={selectedQueueIds}
                            updateCount={setCountPending}
                        />
                    </TabPanel>

                    <TabPanel value={tabFake} name="1" className={classes.insiderTabPanel} style={{ display: runningTab === 'bot' ? 'flex' : 'none' }}>
                        <Can
                            role={user.roleId}
                            perform="bots:show:page:users"
                            yes={() => (
                                <TicketsList
                                    status="bot"
                                    updateCount={setCountBot}
                                    selectedQueueIds={selectedQueueIds}
                                    showAll={showAllTickets}
                                />)
                            }
                            no={() => (<></>)}
                        />
                    </TabPanel>

                    <TabPanel value={tabFake} name="1" className={classes.insiderTabPanel} style={{ display: runningTab === 'group' ? 'block' : 'none' }}>
                        <TicketsList
                            status="group"
                            showAll={showAllTickets}
                            updateCountBadge={setCountGroupUnread}
                            selectedQueueIds={selectedQueueIds}
                            sortDir={sortAscending ? 'ASC' : 'DESC'}
                            selectedTagIds={tagIds}
                        />
                    </TabPanel>
                </TabPanel>

                <TabPanel value={tab} name="paused" className={classes.ticketsWrapper}>
                    <TicketsList
                        status="paused"
                        showAll={showAllTickets}
                        // updateCounts={() => handleUpdateCounts('paused')}
                        updatePausedBadge={setCountPaused}
                        selectedQueueIds={selectedQueueIds}
                        selectedTagIds={tagIds}
                    />
                </TabPanel>

                <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
                    <TicketsList
                        status="closed"
                        showAll={showAllTickets}
                        selectedQueueIds={selectedQueueIds}
                        selectedTagIds={tagIds}
                    />
                </TabPanel>
                <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
                    <TicketsList
                        searchParam={searchParam}
                        searchById={searchById}
                        showAll={showAllSearch}
                        searchOnMessages={searchOnMessages}
                        disableControls={setDisableControls}
                        selectedQueueIds={selectedQueueIds}
                        selectedTagIds={tagIds}
                        selectedConnectionIds={connectionIds ? connectionIds : null}
                        selectedUserIds={userIds ? userIds : null}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        selectedStatuses={selectedStatuses}
                        forceSearch={forceSearch}
                        lastClientActivityMe={lastClientActivityMe ? lastClientActivityMe : null}
                        lastClientActivityClient={lastClientActivityClient ? lastClientActivityClient : null}
                        unreadMessagesSearchClient={unreadMessagesSearchClient}
                        unreadMessagesSearchMe={unreadMessagesSearchMe}
                        showBotsOptions={showBotsOptions}
                    />
                </TabPanel>

            </Paper>
        </>
    );
};

export default TicketsManager;
