import { makeStyles } from '@mui/styles';
import { Room } from '@mui/icons-material';
import React, { useState } from 'react';

const useStyles = makeStyles({
    mapPlaceholder: {
        background:"#DDD",
        width: "100%",
        height: "200px",
        lineHeight: "240px",
        verticalAlign: "middle",
        textAlign: "center",
        color: "#333",
        "& .MuiSvgIcon-root": {
            fontSize: "64px"
        }
    } 
});

const MapPlaceholder = ({ width, height }) => {
    const classes = useStyles();

    return <div className={classes.mapPlaceholder} style={{ width: width, height: height }}>
        <Room />
    </div>
}

const MessageLocation = ({ message, showThumbnail }) => {
    const width = 350;
    const height = 200;

    const [loaded, setLoaded] = useState(false);
    const imageStyle = !loaded ? {display: 'none'} : {};

    const handleImageLoaded = () => {
        setLoaded(true);
    }
    
    return (
    <a href={`https://maps.google.com/?q=${message.locationLatitude},${message.locationLongitude}`} target="_blank">
        {!loaded && <MapPlaceholder width={width} height={height} />}
        {showThumbnail && <img src={`data:image/jpeg;base64,${message.body}`} style={imageStyle} width={width} height={height} onLoad={handleImageLoaded} />}
        {!showThumbnail && <img src={`${process.env.REACT_APP_BACKEND_URL}/messages/${message.id}/map?lat=${message.locationLatitude}&lng=${message.locationLongitude}&width=${width}&height=${height}`} onLoad={handleImageLoaded} style={imageStyle} width={width} height={height} />}
    </a>);
}

export default MessageLocation;