import { Checkbox, Chip, ListItemText } from "@mui/material";
import chroma from "chroma-js";
import React from "react";
import { useState, useEffect } from "react";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import AsyncSelect from "../AsyncSelect";

const SelectQueues = ({ onChange, selectedQueues, disabled }) => {
    const [internalSelectedQueues, setInternalSelectedQueues] = useState([]);
    const [selectedInitial, setSelectedQueues] = useState([]);


    useEffect(() => {
        if (selectedQueues && selectedQueues.length > 0) {
            const matchingQueues = selectedQueues.filter((queue) => Number(queue))
            async function getQueue() {
                try {
                    const { data } = await api.get("/queue");
                    const filteredData = await data.filter((queue) =>
                        matchingQueues.find((selectedQueue) => selectedQueue === queue.id)
                    );
                    setSelectedQueues(filteredData);
                } catch (err) {
                    console.log(err);
                }
            }
            getQueue();
        }

    }, [selectedQueues]);

    const handleChange = (event, changedQueues) => {
        setInternalSelectedQueues(changedQueues);
        if (onChange) {
            onChange(changedQueues);
        }
    };

    return (
        <AsyncSelect
            width="100%"
            url="/queue"
            multiple
            onChange={handleChange}
            value={internalSelectedQueues}
            disabled={disabled}
            size='small'
            initialValue={selectedInitial}
            onScrollEnd={null}
            placeholder="Selecione os setores..."
            label={i18n.t("dashboardHome.queues")}
            disableCloseOnSelect
            renderTags={(tags, getCustomizedTagProps) => (!tags || tags.length === 0 ? i18n.t("ticketsQueueSelect.placeholder") : tags.map((option, index) => {
                return (<Chip
                    label={option.name}
                    size='medium'
                    style={{ backgroundColor: option.color, color: chroma.contrast(option.color, 'white') > 2 ? 'white' : 'black' }}
                    {...getCustomizedTagProps({ index })}
                />)
            }
            ))}
            renderOption={(option, meta) => (
                <React.Fragment>
                    <Checkbox
                        style={{
                            color: option.color,
                        }}
                        size="small"
                        color="primary"
                        checked={meta.selected || internalSelectedQueues.find((item) => item.id === option.id)}
                    />
                    <ListItemText primary={option.name} />
                </React.Fragment>
            )}
        />
    );
};

export default SelectQueues;