import React, { useState, useContext, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';

import {
    CssBaseline,
    Typography,
    Container,
    IconButton,
    useTheme,
} from "@mui/material";

import Version from "../../helpers/version";
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import LightModeIcon from '@mui/icons-material/LightMode';
import { LanguageSelector, i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import ColorModeContext from "../../layout/themeContext";
import { useHistory } from "react-router-dom";

const backgroundLoginURL = `/background.svg`;

const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            background: `url('${backgroundLoginURL}')`,
            backgroundSize: "cover",
            minHeight: '100vh',
            backgroundPosition: 'center bottom',
            backgroundRepeat: 'no-repeat'
        }
    },
    colFull: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    qrcode: {
        width: '50%',
        textAlign: "center"
    },
    paper: {
        alignItems: "center",
        background: theme.palette.lightWhite.main,
        borderRadius: 20,
        boxShadow: '0px 4px 25px 0px rgba(0,0, 0, 0.05)',
        padding: 30,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 780,
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        }    
    },
    avatar: {
        height: 50
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
        textAlign: "center",
        marginTop: "5%",

    },
    versao: {
        fontSize: "15px",
        textAlign: "center",
        width: '100%',
        padding: '15px 0px'
    },
    notificationWarning: {
        color: "red",
    },
    notificationModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
        '& img': {
            height: '700px',
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
                width: '90%'
            }
        }
    },
    notificationModalClose: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: '#fff',
        "& .MuiSvgIcon-root": {
            fontSize: "3rem"
        }
    },
    qrcode: {
        width: "100%",
        textAlign: "center",
        marginBottom: 20
    },
    qrcodeImage: {
        width: 130,
    },
    playStoreButton: {
        height: 50
    },
    h3App: {
        fontWeight: 600,
        fontSize: 16
    },
    buttonsActionLogin: {
        display: 'flex',
        flexDirection: 'row'
    },
    formLogin: {
        width: '100%',
        textAlign: 'center',
        padding: '50px 0',
        color: theme.palette.dark.main
    }
}));


const LoginTimeOut = () => {
    const classes = useStyles();
    const theme = useTheme();
    const apiURL = process.env.REACT_APP_BACKEND_URL;
    const history = useHistory();
    const [user, setUser] = useState({ email: "", password: "" });
    const [isFromFlutter, setIsFromFlutter] = useState(false);
    const [pushToken, setPushToken] = useState("");
    const [showQRCode, setShowQRCode] = useState(false);
    const { colorMode } = useContext(ColorModeContext);

    const appUserAgent = "Onecode Jupiter App 1.0";
    const fromApp = navigator.userAgent === appUserAgent;

    const { handleLogin } = useContext(AuthContext);
    const [timeRemaining, setTimeRemaining] = useState(0);

    const handleChangeInput = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };



    useEffect(() => {
        const nextTime = localStorage.getItem('next-time-login');

        if (nextTime) {
            const targetTime = parseInt(nextTime, 10);
            const now = Date.now();

            const timeDifference = targetTime - now;

            if (timeDifference > 0) {
                setTimeRemaining(timeDifference);

                const interval = setInterval(() => {
                    const updatedTimeDifference = targetTime - Date.now();

                    if (updatedTimeDifference <= 0) {
                        //console.log('result in here')
                        toast.success('Tempo de espera acabou. Faça o login novamente');
                        localStorage.removeItem("next-time-login");
                        clearInterval(interval);
                        setTimeRemaining(0);
                        history.push('/login');
                    } else {
                        setTimeRemaining(updatedTimeDifference);
                    }
                }, 1000);

                return () => clearInterval(interval);
            } else {
                toast.success('Tempo de espera acabou. Faça o login novamente');
                localStorage.removeItem("next-time-login");
                history.push('/login');
            }
        }
    }, [history]);

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const days = Math.floor(totalSeconds / (3600 * 24));
        const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    window.addEventListener("flutterInAppWebViewPlatformReady", function (event) {
        setIsFromFlutter(true);
        window.flutter_inappwebview.callHandler('getPushToken').then((data) => {
            setPushToken(data.token);
            window.localStorage.setItem("pushToken", data.token);
        });
    });

    const toggleColorMode = () => {
        colorMode.toggleColorMode();
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <div className={classes.colFull} style={{marginBottom: 10}}>
                    <img className={classes.avatar} src="/logo-black.png" alt="OneCode Logo" />
                    <div className={classes.buttonsActionLogin}>
                        <IconButton edge="start" onClick={toggleColorMode} color="inherit" size="large">
                            {theme.mode === 'dark' ? <LightModeIcon /> : <NightlightRoundIcon />}
                        </IconButton>
                        <LanguageSelector />
                    </div>
                </div>

                <div className={classes.colFull}>

                    <div className={classes.formLogin} style={{width: '100%'}}>
                            <h3 style={{textAlign: 'center'}}>{i18n.t("login.unable")}</h3>
                            <p  style={{textAlign: 'center'}}>{i18n.t("login.nextTime")}</p>
                            <h2>{formatTime(timeRemaining)}</h2>
                    </div>
                </div>

                <div className={classes.colFull}>
                    <Typography className={classes.versao}>
                        Versão: {Version} {fromApp ? <span>. Flutter: {isFromFlutter ? <span>Sim. Push Token: {pushToken.substring(0, 6)}...</span> : ""}</span> : ""}
                        {fromApp && (!isFromFlutter || !pushToken) ? <p className={classes.notificationWarning}>Houve um problema ao inicializar a aplicação. Você não receberá notificações das mensagens nesse dispositivo. Tente reiniciar a aplicação.</p> : ""}
                    </Typography>
                </div>
            </div>
        </Container>
    );
};

export default LoginTimeOut;