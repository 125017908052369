import React, { useState, useEffect, useRef, useReducer, useContext } from "react";

import * as Yup from "yup";

import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import TableRowSkeleton from "../../components/TableRowSkeleton";



const reducer = (state, action) => {
	if (action.type === "LOAD_TAGS") {
		const tags = action.payload;
		const newTags = [];

		tags.forEach(tag => {
			const index = state.findIndex(c => c.id === tag.id);
			if (index !== -1) {
				state[index] = tag;
			} else {
				newTags.push(tag);
			}
		});

		return [...state, ...newTags];
	}

	if (action.type === "UPDATE_TAGS") {
		const tag = action.payload;
		const index = state.findIndex(c => c.id === tag.id);

		if (index !== -1) {
			state[index] = tag;
			return [...state];
		} else {
			return [tag, ...state];
		}
	}
	if (action.type === "DELETE_TAGS") {
		const tagId = action.payload;
		const index = state.findIndex(c => c.id === tagId);

		if (index !== -1) {
			const newState = [...state.slice(0, index), ...state.slice(index + 1)];
			return newState;
		}

		return state;
	}


	if (action.type === "DELETE_MULTIPLE_TAGS") {
		const tagsIds = action.payload;
		const newState = state.filter(tag => !tagsIds.includes(String(tag.id)));
		return newState;
	}

	if (action.type === "RESET") {
		return [];
	}
};


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
	flexButtonsTags:{
		display: "flex",
		flexDirection: 'row',
		gap: 3,
		padding: '0px 10px 15px 15px'
	},
	tag: {
		padding: '5px 15px',
		borderRadius: 5
	},
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
    email: Yup.string().email("Invalid email"),
});

const ContactTagsModal = ({ open, onClose, contactId, initialValues, onSave }) => {

    const isMounted = useRef(true);

    const classes = useStyles();
	const { user } = useContext(AuthContext);
	const [checked, setChecked] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [searchParam, setSearchParam] = useState("");
	const [tags, dispatch] = useReducer(reducer, []);
	const [selectedTagId, setSelectedTagId] = useState(null);
	const [tagModalOpen, setTagModalOpen] = useState(false);
	const [deletingTag, setDeletingTag] = useState(null);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [hasMore, setHasMore] = useState(false);



    const initialState = {
        name: "",
        number: "",
        email: "",
        blocked: false
    };

    const [contact, setContact] = useState(initialState);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchTags = async () => {
				try {
					const { data } = await api.get("/tags/", {
						params: { searchParam, pageNumber },
					});
					dispatch({ type: "LOAD_TAGS", payload: data.tags });
					setHasMore(data.hasMore);
					setLoading(false);
				} catch (err) {
					toastError(err);
				}
			};
			fetchTags();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, pageNumber]);

    useEffect(() => {
        const fetchContact = async () => {
            if (initialValues) {
                setContact(prevState => {
                    return { ...prevState, ...initialValues };
                });
            }

            if (!contactId) return;

            try {
                const { data } = await api.get(`/contacts/${contactId}`);
                if (isMounted.current) {
                    setContact(data);
                }
            } catch (err) {
                toastError(err);
            }
        };

        fetchContact();
    }, [contactId, open, initialValues]);

    const handleClose = () => {
        onClose();
        setContact(initialState);
    };

    const handleSaveContact = async values => {
        try {
            if (contactId) {
                await api.put(`/contacts/${contactId}`, values);
                handleClose();
            } else {
                const { data } = await api.post("/contacts", values);
                if (onSave) {
                    onSave(data);
                }
                handleClose();
            }
            toast.success(i18n.t("contactModal.success"));
        } catch (err) {
            toastError(err);
        }
    };


    //Tags functions
    const handleToggle = (event) => {
		const currentIndex = checked.indexOf(event.target.value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(event.target.value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	}

	const toggleAll = (event) => {
		if (event.target.checked) {
			checkAll();
		} else {
			uncheckAll();
		}
	}

    const checkAll = () => {
		setChecked(tags.map((element) => { return "" + element.id }));
	}

	const uncheckAll = () => {
		setChecked([]);
	}
     //Tags functions
    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle id="form-dialog-title">
                    Gerenciador de Contatos de Tags
                </DialogTitle>
                <div>
                    <div>
                    <Table size="small">
				
					<TableBody>
						<>
														{/**	<TableCell align="center">{tag.contacts_count ? (<span>{tag.contacts_count}</span>) : <span>0</span>}</TableCell>
									<TableCell align="center">
									</TableCell> */}
						<div className={classes.flexButtonsTags}>		
							{tags.map(tag => (
													
									<div className={classes.tag} key={tag.id} style={{ backgroundColor: tag.color }}>{tag.name}</div>

								

							))}
							</div>
							{loading && <TableRowSkeleton avatar columns={3} />}
						</>
					</TableBody>
				</Table>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ContactTagsModal;
