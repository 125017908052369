import React, { useState, useContext } from "react";
import {
    Tooltip,
    Typography,
    Button,
    Modal,
    FormControl,
    InputLabel,
    TextField,
    Select, MenuItem,
    Grid
} from "@mui/material";
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { TiMessages } from "react-icons/ti";
import { BiSupport } from "react-icons/bi";
import { PiStudent } from "react-icons/pi";
import { FaCheck, FaEye, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { useDropzone } from 'react-dropzone';
import api from "../../services/api";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({

    arrow: {
        "&:before": {
            border: "1px solid #f5f5f9"
        },
        color: '#f5f5f9'
    },
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
        boxShadow: '0px 0px 18px 0px rgba(0,0,0,0.17)',
        maxWidth: 450,
    },
    tooltipPopper: {
        textAlign: "center",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        display: 'flex',
        flexDirection: 'column'
    },
    paperForm: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        flexDirection: 'column',
        border: '0px',
        borderRadius: 20,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        gap: 10,
        width: '50%'
    },
    loadingTicket: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        flexDirection: 'column',
        border: '0px',
        borderRadius: 20,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        gap: 10,
        width: '50%',
        minHeight: 200,
        justifyContent: 'center',
        alignItems: 'center'
    },
    paperFormTickets: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        flexDirection: 'column',
        border: '0px',
        borderRadius: 20,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        gap: 10,
        maxWidth: '50%'
    },
    flexis: {
        display: 'flex',
        alignItems: 'center'
    },
    blockAccordion: {

    }
}));

export const Support = ({ children }) => {
    const classes = useStyles();
    const history = useHistory();
    const [isSupportTicketModalOpen, setSupportTicketModalOpen] = useState(false);
    const [isVerSupportTicketsModalOpen, setVerSupportTicketsModalOpen] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [loadingTicket, setLoadingTicket] = useState(false);
    const [responseTicket, setResponseTicket] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    const [screenshot, setScreenshot] = useState(null);
    const { user } = useContext(AuthContext);
    const [contactSupport, setContactSupport] = useState(null);
    const ticketSupport = [
        {
            id: 1,
            category: 'Atendimento',
            title: 'Problemas com o relógio das mensagens que está acontecendo',
            description: 'Descrição detalhada do problema com o relógio das mensagens.',
            dateCreated: '2023-12-15T12:45:00',
            messages: [
                {
                    sender: 'Mateus Suporte Técnico',
                    timestamp: '2023-12-15T12:45:00',
                    content: 'Foi corrigido o problema mencionado.',
                },
                {
                    sender: 'Cliente',
                    timestamp: '2023-12-14T16:45:00',
                    content: 'Não atualiza o ticket atual. Problema quando recebe uma mensagem no atendimento.',
                },
            ],
        },
        {
            id: 3,
            category: "Configuração",
            title: "Configuração de notificações push",
            description: "Gostaria de configurar notificações push no aplicativo, mas não encontro a opção correta.",
            dateCreated: "2023-12-13T14:15:00",
            messages: [
                {
                    sender: "Suporte Técnico",
                    timestamp: "2023-12-13T14:15:00",
                    content: "Para configurar notificações push, vá para as configurações do aplicativo e encontre a seção 'Notificações'."
                },
                {
                    sender: "Cliente",
                    timestamp: "2023-12-13T12:30:00",
                    content: "Obrigado pela orientação. Vou verificar e tentar configurar."
                }
            ]
        }
    ];

    const handleSupportTicketModalOpen = () => {
        // alert('abriu')
        setSupportTicketModalOpen(true);
    };

    const handleSupportTicketModalClose = () => {
        setSupportTicketModalOpen(false);
        setLoadingTicket(false);
        setResponseTicket(false);
        setScreenshot(null);
        setThumbnail(null);
    };

    const handleViewSupportTicketsModalOpen = () => {
        setVerSupportTicketsModalOpen(true);
        setLoadingTicket(false);
        setResponseTicket(false);
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };


    const handleViewSupportTicketsModalClose = () => {
        setVerSupportTicketsModalOpen(false);
    };

    const onDrop = (acceptedFiles) => {
        const uploadedFile = acceptedFiles[0];
        setScreenshot(uploadedFile);

        const thumbnailURL = URL.createObjectURL(uploadedFile);
        setThumbnail(thumbnailURL);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop

    });

    const handleSubmit = async () => {
        setLoadingTicket(true)
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('title', title);
        formData.append('phone', phone)
        formData.append('description', description);
        formData.append('medias', screenshot);
        formData.append('category', category);
        try {
            const response = await api.post('send/feedback', formData);
            if (response.data) {
                setLoadingTicket(false)
                setResponseTicket(true)
            }
        } catch (error) {
            console.elog(error)
        }
    };

    const sendMessageSupport = async (id) => {
        try {
            if (id) {
                const response = await api.post("/tickets", {
                    contactId: id,
                    userId: user.id,
                    status: "open",
                    // whatsappId: whatsapp ? whatsapp.id : null,
                    // queueId: selectedQueue ? selectedQueue.id : null
                });
    
                if (response.status >= 200 && response.status < 300) {
                    history.push(`/tickets/${response.data.id}`);
                }
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errorData) {
                const { ticketId } = error.response.data.errorData;
                //console.log(`Ticket ID from error response: ${ticketId}`);
                history.push(`/tickets/${ticketId}`);
            }
        }
    };


    //Call a Contact
    const handleCheckandSaveHelpContact = async () => {

        const contact = {
            name: "Suporte OneCode",
            number: "556181779591",
            email: "",
            blocked: false
        };
        try {
            const response = await api.get(`/contacts/number/${contact.number}`);
            if (!response.data.message) {
               /// console.log('foi na checkagem', response.data);
                sendMessageSupport(response.data.id);   
            } else {
                const newContact = await api.post("/contacts", contact);
                if (newContact) {
                 //   console.log('foi criado um', newContact.data);                      
                    sendMessageSupport(newContact.data.id);          
                }

            }

          
        } catch (err) {
            console.log(err)
        }
    };

  
    const renderSupportTicketModal = (
        <Modal
            open={isSupportTicketModalOpen}
            onClose={handleSupportTicketModalClose}
            className={classes.modal}
        >
            <div className={classes.paperForm}>
                {loadingTicket ?
                    <div style={{ textAlign: 'center' }}>
                        <img src={'/loading/loadinginitial.gif'} width={200} />
                        <h3>Estamos processando...</h3>
                    </div>
                    : !responseTicket ? (
                        <>
                            <h4>Abrir um chamado para resolução de problemas</h4>
                            <p>Deixe-nos uma mensagem que em breve o setor responsável irá entrar em contato</p>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="Nome" onChange={(e) => setName(e.target.value)} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="Email" onChange={(e) => setEmail(e.target.value)} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="Telefone" onChange={(e) => setPhone(e.target.value)} fullWidth />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                        <Select
                                            label="Categoria"
                                            placeholder="Categoria"
                                            value={category}
                                            onChange={handleCategoryChange}
                                        >
                                            <MenuItem value="Financeiro">Financeiro</MenuItem>
                                            <MenuItem value="Atendimentos">Atendimentos</MenuItem>
                                            <MenuItem value="API">API</MenuItem>
                                            <MenuItem value="Inteligência Artificial">Inteligência Artificial</MenuItem>
                                            <MenuItem value="Lentidão nas mensagens">Lentidão nas mensagens</MenuItem>
                                            <MenuItem value="Configuração">Configuração</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField label="Titulo" onChange={(e) => setTitle(e.target.value)} fullWidth />
                                </Grid>
                            </Grid>

                            <div {...getRootProps()}
                                style={{ marginBottom: '16px', width: '100%', textAlign: 'center', border: '3px dotted #c6c6c6', padding: 20 }}>
                                <input {...getInputProps()} />
                                {!thumbnail && <p>Arraste e solte a captura de tela aqui ou clique para selecionar.</p>}
                                {thumbnail && <img src={thumbnail} alt="Thumbnail" style={{ maxWidth: '100%', maxHeight: '100px' }} />}
                            </div>
                            <TextField label="Descrição"
                                onChange={(e) => setDescription(e.target.value)}
                                multiline minRows={4} maxRows={4} placeholder="Descreva detalhadamente o seu problema" />
                            <Button variant="contained" style={{ background: '#2ecc71', fontWeight: 'bold', color: '#000' }} onClick={handleSubmit}>
                                Enviar solicitação
                            </Button>
                        </>
                    ) :
                        <div style={{ textAlign: 'center', color: '#2ecc71', minHeight: 300 }}>
                            <FaCheck size={200} />
                            <h3>Sua solicitação foi enviada com sucesso!</h3>
                        </div>
                }

            </div>
        </Modal>
    );




    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const renderVerSupportTicketsModal = (
        <Modal
            open={isVerSupportTicketsModalOpen}
            onClose={handleViewSupportTicketsModalClose}
            className={classes.modal}
        >
            <div className={classes.paperFormTickets}>
                <h3>Meus Chamados</h3>
                {ticketSupport.map((ticket) => (
                    <Accordion
                        key={ticket.id}
                        expanded={expanded === `panel${ticket.id}`}
                        style={{ boxShadow: 'none', padding: 0 }}
                        onChange={handleChange(`panel${ticket.id}`)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${ticket.id}bh-content`}
                            id={`panel${ticket.id}bh-header`}
                            style={{ display: 'flex', padding: 0, alignItems: 'center' }}
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                {ticket.category}
                            </Typography>
                            <Typography sx={{ fontSize: 15, color: 'text.secondary' }}>
                                {ticket.title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {ticket.messages.map((message, index) => (
                                <div
                                    key={index}
                                    style={{
                                        color: '#737373',
                                        borderBottom: '1px solid #eee',
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                    }}
                                >
                                    <Typography style={{ fontWeight: 'bold' }}>
                                        {message.timestamp}
                                    </Typography>
                                    <Typography>{`${message.sender} - ${message.content}`}</Typography>
                                </div>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </Modal>
    );




    return (
        <React.Fragment>
            <Tooltip
                arrow
                classes={{
                    arrow: classes.arrow,
                    tooltip: classes.tooltip,
                    popper: classes.tooltipPopper,
                }}
                title={
                    <React.Fragment>
                        <h5 style={{ marginTop: 5 }}>Suporte</h5>
                        <Button
                            variant="text"
                            color="success"
                            onClick={handleCheckandSaveHelpContact}
                            style={{ width: '100%', fontWeight: 'bold', fontSize: 18, marginBottom: '5px', display: 'flex', alignItems: 'center', gap: 5 }}
                        ><FaWhatsapp /> (61) 8177-9591</Button>
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={() => history.push('/docs')}
                            style={{ width: '100%', background: '#f1c40f', color: '#000', boxShadow: 'none', fontWeight: 'bold', marginBottom: 5, display: 'flex', alignItems: 'center', gap: 5 }}
                        ><PiStudent /> Tutorial
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSupportTicketModalOpen}
                            style={{
                                width: '100%',
                                marginBottom: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 5,
                                background: '#2ecc71',
                                fontWeight: 'bold',
                                boxShadow: 'none',
                                color: '#000'
                            }}
                        >
                            <TiMessages /> Abrir um Chamado
                        </Button>
                        <Button
                            variant="contained"
                            color="info"
                            onClick={handleViewSupportTicketsModalOpen}
                            style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 5,
                                background: '#ecf0f1',
                                fontWeight: 'bold',
                                boxShadow: 'none',
                                color: '#000'
                            }}
                        >
                            <FaEye /> Ver Chamados
                        </Button>
                    </React.Fragment>
                }
            >
                {children}
            </Tooltip>
            {renderSupportTicketModal}
            {renderVerSupportTicketsModal}
        </React.Fragment>
    );
};